import "./Achievements.css";
import { Image, Nav, Tab } from "react-bootstrap";
import * as React from "react";
import { useState, useEffect } from "react"; // <-- Added useEffect
import AchievementTableItem from "../../Components/AchievementComponents/AchievementTableItemView";
import axios from "axios";
import "../../Components/PopupModal/PopupModal.css";
import AchievementListItem from "../../Components/AchievementComponents/AchievementListItemView";
import "react-circular-progressbar/dist/styles.css";
import { Icon } from "@iconify/react";
import { useTranslation } from 'react-i18next';
import {ProfilePicture} from "../../Components/ProfilePicture/ProfilePicture";
import AchievementHistory from "../../Models/AchievementHistory";

function ProgressCircleImage(props: any) {
    return (
        <div className={`achievement-profile exp-level ${props.level === 1 ? 'exp-level-1' : props.level === 2 ? 'exp-level-2' : props.level === 3 ? 'exp-level-3' : props.level === 4 ? 'exp-level-4' : 'exp-level-5'}`}>
            <i>
                <ProfilePicture/>
            </i>
        </div>
    );
}

function MyAchievements() {
    // This is created to switch between list and table mode
    const [IsListViewActive, setIsListViewActive] = useState(false);
    const changeView = () => { setIsListViewActive(!IsListViewActive) };

    // State for the achievements history list
    const [achievementsHistoryList, setAchievementsHistoryList] = useState<AchievementHistory[]>([]);

    // Fetching the achievements list using axios
    useEffect(() => {
        if(localStorage.getItem("oauthToken")) {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("oauthToken")}`
                }
            };
            axios.get(import.meta.env.VITE_SERVER_ADDRESS + "getAchievements", config)
                .then((response) => {
                    setAchievementsHistoryList(response?.data || []);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []); // The empty dependency array ensures this runs once when the component mounts

    function AchievementShowTable() {
        const [t] = useTranslation("achievements");
        return (
            <>
                <ul className="tableachievementWrap">
                    {achievementsHistoryList.map(function (data) {
                        if(data.AchievementId != 0)
                        {
                            let loadText: string = data.AchievementId.toString() + data.Level
                            const achievementProps = {
                                title: t(`${loadText}.title`),
                                desc: t(`${loadText}.desc`),
                                level: data.Level,
                                finalValue: data.NextLevel,
                                currentValue: data.CurrentValue,
                                exp: data.NextPoints,
                                orbox: data.NextOrbox,
                            };
                            return <AchievementTableItem {...achievementProps} />;
                        }
                    })}
                </ul>
            </>
        );
    }

    function AchievementsShowList() {
        const [t] = useTranslation("achievements");
        return (
            <>
                {achievementsHistoryList.map(function (data) {
                    if(data.AchievementId != 0)
                    {
                        let loadText: string = data.AchievementId.toString() + data.Level
                        const achievementProps = {
                            title: t(`${loadText}.title`),
                            desc: t(`${loadText}.desc`),
                            level: data.Level,
                            finalValue: data.NextLevel,
                            currentValue: data.CurrentValue,
                            exp: data.NextPoints,
                            orbox: data.NextOrbox,
                        };
                        return <AchievementListItem {...achievementProps} />;
                    }
                })}
            </>
        );
    }

    function ProfileInfo(props: any){

        return(
                <div className="achievementsTitles">
                    <div className="achievementsProfileMain">
                        <ProgressCircleImage>
                            xs=8{" "}
                        </ProgressCircleImage>
                    </div>
                    <p className="next-level-text">
                        Total experience:
                        {" " + props.currentValue} pts
                    </p>
                </div>
        );

    }

    function AchievementShowProfile(){
        const [t] = useTranslation("achievements");
        return (
            <>
                {achievementsHistoryList.map(function (data) {
                    if(data.AchievementId == 0)
                    {
                        let loadText: string = data.AchievementId.toString() + data.Level
                        const achievementProps = {
                            title: t(`${loadText}.title`),
                            desc: t(`${loadText}.desc`),
                            level: data.Level,
                            finalValue: data.NextLevel,
                            currentValue: data.CurrentValue,
                            exp: data.NextPoints,
                            orbox: data.NextOrbox,
                        };
                        return <ProfileInfo {...achievementProps} />;
                    }
                })}
            </>
        );
    }

    return (
        <div className={`${"main-content"} home-page`}>
           <AchievementShowProfile />
            <Tab.Container id="achievements-tabs-example" defaultActiveKey="table">
                <Nav variant="pills" className="custom-navs achievements-custom-navs">
                    <Nav.Item>
                        <Nav.Link eventKey="table">
                            <span>
                                <Icon icon="mi:list" />
                            </span>
                            List
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="list">
                            <span>
                                <Icon icon="fluent:grid-28-filled" />
                            </span>
                            Table
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="table">
                        <AchievementsShowList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="list">
                        <AchievementShowTable />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    );
}

export default MyAchievements;

