import Axios from "../../AxiosApi";

export const onGettingAllAiGeneratedImages = (prompt, page) =>
{
    return Axios({
      method: "get",
      url: `clipsearch?prompt=${prompt}&page=${page}`,
    })
      .then((response) =>
      {
        return response;
      })
      .catch((err) =>
      {
        return err.response;
      });
  };
export const onGettingAllSimilarImages = (image_url, page) =>
{
    return Axios({
      method: "get",
      url: `image_similarity?image_url=${image_url}&page=${page}`,
    })
      .then((response) =>
      {
        return response;
      })
      .catch((err) =>
      {
        return err.response;
      });
};