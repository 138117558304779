import PopupModal from "../PopupModal";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  PinterestShareButton,
  PinterestIcon,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  RedditShareButton,
  RedditIcon,
  TumblrShareButton,
  TumblrIcon,
} from "react-share";
import {Link} from "react-router-dom";
import {Icon} from "@iconify/react";
import {Button} from "react-bootstrap";
import {useState} from "react";
import {SetAchievement} from "../../AchievementComponents/AchievementsHandler";
import {AchievementList} from "../../../Utils/Constants";
import {userDataSelector} from "../../../Redux/Slices/userDataSlice";
import {useSelector} from "react-redux";
import User from "../../../Models/User";

const PopupShareContent = ({
  className = "",
  buttonText = "",
  popupTitle = "Share",
  icon = <></>,
  inviteFriend = false,
  link = "https://www.orbofi.com",
  title = "Visit Orbofi website to make your own images.",
  hashtag = "#Orbofi",
}) => {
  const [popupActive, setPopupActive] = useState(false);
  const changeView = () => {
    setPopupActive(!popupActive);
  };

    const userData: User = useSelector(userDataSelector);

    if(inviteFriend) {
        link += "/?user=" + userData.UserId;
    }

  return (
    <>
      <Link to="#" className={className} onClick={() => changeView()}>
        <span>{icon}</span>
        {buttonText}
      </Link>
      <PopupModal
        show={popupActive}
        handleClose={{}}
        className="FactoryListPopup"
        backdrop={"static"}
      >
        <Link
          to="#"
          className="CloseBtn"
          onClick={() => {
            changeView();
            //SetAchievement(AchievementList.Share, 1, userData.role);
          }}
        >
          <Icon icon="ion:close-circle" color="white" />
        </Link>
        <h5 className="factoryListTitle">{popupTitle}</h5>
        <hr />
        <div className="shareModalbtns">
          <TwitterShareButton title={title} url={link}>
            <TwitterIcon size={64} round />
          </TwitterShareButton>
          <FacebookShareButton quote={title} hashtag={hashtag} url={link}>
            <FacebookIcon size={64} round />
          </FacebookShareButton>
          <EmailShareButton subject={"Orbofi Image"} body={title} url={link}>
            <EmailIcon size={64} round />
          </EmailShareButton>
          <LineShareButton title={title} url={link}>
            <LineIcon size={64} round />
          </LineShareButton>
          <TelegramShareButton title={title} url={link}>
            <TelegramIcon size={64} round />
          </TelegramShareButton>
          <PinterestShareButton title={title} media={link} url={link}>
            <PinterestIcon size={64} round />
          </PinterestShareButton>
          <RedditShareButton title={title} url={link}>
            <RedditIcon size={64} round />
          </RedditShareButton>
          <TumblrShareButton title={title} url={link}>
            <TumblrIcon size={64} round />
          </TumblrShareButton>
        </div>
      </PopupModal>
    </>
  );
};

export default PopupShareContent;
