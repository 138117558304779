// Chat.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import CircularProgress from '@mui/joy/CircularProgress';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { Icon } from '@iconify/react';
// import { ChatBot } from "./ChatBot";
import { Col, Row, Modal, Button, Image, Form, Tooltip, OverlayTrigger, Dropdown, Nav } from 'react-bootstrap';
import { Typewriter } from 'react-simple-typewriter';
import { Link, useNavigate } from 'react-router-dom';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './CreateChatbot.css';
import { toast } from 'react-hot-toast';
import Select from 'react-select';
import parse from 'html-react-parser';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { WindupChildren } from 'windups';
import Slider from 'rc-slider';
import { useLocation } from 'react-router-dom';
import { getStorage } from 'firebase/storage';
import { getFirestore, setDoc, addDoc } from '@firebase/firestore';
import { doc, collection, getDocs } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { v4 as uuidv4 } from 'uuid';
import { SSE } from 'sse';
import Hls from 'hls.js';
// import { useHistory } from 'react-router-dom';
// import HLSPlayer from '../../Components/HlsPlayer/HlsPlayer';
import { getFirestoreInstance } from '../../Utils/Utils';
import { styled } from '@mui/material/styles';
import PopupModal from '../../Components/PopupModal/PopupModal';
import {
  ref,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage';
import { initializeApp } from 'firebase/app';
import ImageSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { themeSelector } from '../../Redux/Slices/themeSlice';
import {useDispatch, useSelector} from 'react-redux';
import useAutosizeTextArea from '../../Utils/useAutosizeTextArea';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import cx from 'classnames';
import Preview from '../ChatBot/Preview';
import CodeMirror from '../ChatBot/CodeMirror';
import { generateCode } from '../ChatBot/generateCode';
import { userDataSelector } from '../../Redux/Slices/userDataSlice';
import Subscription from '../../Models/Subscription';
import { subDataSelector } from '../../Redux/Slices/subscriptionDataSlice';
import { showPopUpAction } from '../../Redux/Slices/loginPopUpSlice';
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#36e899' : '#36e899',
  },
}));



const customStyles2 = {
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: '#333',  // Change this to your desired color
  }),
  menuPortal: base => ({ ...base, zIndex: 9993 })
};

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: '#333',  // Change this to your desired color
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 })
};

var numSentence = 0;

function CreateChatBot(props) {
  const userData = useSelector(userDataSelector);
  const subData = useSelector(subDataSelector);
  const [chatHistory, setChatHistory] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [currentMessage, setCurrentMessage] = useState('');
  const [avatarVideoUrl, setavatarVideoUrl] = useState('');
  const audioRef = useRef('');
  const [isLoading, setIsLoading] = useState(false);
  const [timesGenerated, setTimesGenerated] = useState(0);

  // const history = useHistory();
  const authToken = localStorage.getItem('oauthToken');
  const dispatch = useDispatch();

  const textAreaRef = useRef(null);
  const textAreaRefMobile = useRef(null);

  function splitSentenceAt4thLastSpace(sentence) {
    // Check if the sentence length is more than 50 characters
    if (sentence.length > 50)
    {
      // Initialize splitIndex
      let splitIndex = -1;

      // Find the last 4th space before the 50th character
      for (let i = 0, spaceCount = 0; i < 50; i++)
      {
        if (sentence[i] === ' ')
        {
          spaceCount++;
          if (spaceCount === 4)
          {
            splitIndex = i;
            break;
          }
        }
      }

      // If there's no 4th space, split at the 50th character
      if (splitIndex === -1) splitIndex = 50;

      // Split the sentence into two parts
      let part1 = sentence.substring(0, splitIndex);
      let part2 = sentence.substring(splitIndex + 1);

      return [part1, part2];
    } else
    {
      // If the sentence is less than or equal to 50 characters, return it as is
      return [sentence];
    }
  }

  useAutosizeTextArea(textAreaRef.current, currentMessage);
  useAutosizeTextArea(textAreaRefMobile.current, currentMessage);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const [showFullPage, setShowFullPage] = useState(false);

  const [isIntroLoading, setIsIntroLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [url2, setUrl2] = useState('https://xq3pl2oc187t2f-5000.proxy.runpod.net/hls/3s.m3u8');
  const [url3, setUrl3] = useState('');
  const [url4, setUrl4] = useState('');
  const [imagesStatus, setImagesStatus] = useState(['loading', 'loading', 'loading', 'loading']);
  const [generatedImageUrlsChatbot, setGeneratedImageUrlsChatbot] = useState([]);
  const [timerRunning, setTimerRunning] = useState(false);
  const chatFieldRef = useRef(null);



  const urlIndexRef = useRef(0);
  const urls = ['https://xq3pl2oc187t2f-5000.proxy.runpod.net/hls/3s.m3u8', 'https://xq3pl2oc187t2f-5000.proxy.runpod.net/hls/6s.m3u8',];

  var idleAnimation = 'https://xq3pl2oc187t2f-5000.proxy.runpod.net/hls/3s.m3u8';

  const [index, setIndex] = useState(0);
  const GENERATION_TIME = 1000;
  const [isTimerActive, setIsTimerActive] = useState(false);

  const texts = [
    'Initiating Avatar Genesis Protocol...',
    'Crafting Digital DNA Sequence...',
    'Sculpting Virtual Physiognomy...',
    'Infusing Personality Algorithms...',
    'Embedding Unique Digital Signatures...',
    'Optimizing Neural Network Integration...',
    'Enriching with Adaptive Learning Capabilities...',
    'Polishing Aesthetic Features...',
    'Synchronizing with User Preferences...',
    'Finalizing Avatar Consciousness...',
    'Launching Self-awareness Sequence...',
    'Completing Avatar Generation...',
    'Completing Avatar Generation...',
    'Completing Avatar Generation...',
    'Completing Avatar Generation...',
    'Completing Avatar Generation...',
    'Completing Avatar Generation...',
    'Completing Avatar Generation...',
    'Completing Avatar Generation...',
    'Completing Avatar Generation...',
    'Completing Avatar Generation...',
  ];

  const onHandleCurrentMessageChange = (event) => {
    // const inputHeight = event.target.scrollHeight > 50 ? textareaHeight + 25 : 50;
    setCurrentMessage(event.target.value);
    // setTextareaHeight(inputHeight);   
  };

  useEffect(() => {
    let interval;
    if (isTimerActive)
    {
      interval = setInterval(() => {
        // Check if index is the last element in the array
        if (index < texts.length - 1)
        {
          setIndex(prevIndex => prevIndex + 1);
        } else
        {
          // Stop the timer once it reaches the last element
          // setIsTimerActive(false);
        }
      }, 6000);
    }

    // Cleanup on component unmount or if timer is stopped
    return () => {
      clearInterval(interval);
    };
  }, [isTimerActive]);

  const [totalDocs, setTotalDocs] = useState(0);

  const renderLoadingIndicators = () => {
    let indicators = [];
    for (let i = 0; i < 4; i++)
    {
      indicators.push(
        <div key={i} style={{
          backgroundColor: '#38418b', // This sets a dark background color
          borderRadius: '5%', // This makes the border circular
          width: '125px', height: '125px', margin: '5px',
          display: 'flex', // Use flex to center the CircularProgress
          justifyContent: 'center', // Center horizontally
          alignItems: 'center' // Center vertically
        }}>
          <CircularProgress />
        </div>
      );
    }
    return indicators;
  };
  // const [isResponseWaiting, setResponseWaiting] = useState(false);

  var isResponseWaiting = false;

  const navigate = useNavigate();

  const [show2, setShow2] = useState(false);
  const [name, setName] = useState('');
  const [isOutputted, setisOutputted] = useState(true);
  const [description, setDescription] = useState('');
  const [coverImage, setCoverImage] = useState('');

  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayingImage, setIsPlayingImage] = useState(false);
  const [generatedHelloVideo, setGeneratedHello] = useState('');

  const [premiumVoices, setIsPremiumVoices] = useState({});
  const [speechGenData, setSpeechGenData] = useState({});

  const [yawScale, setYaw] = useState(0);
  const [headMovement, setHeadMovement] = useState(4);
  const [pitchScale, setPitchScale] = useState(0);
  const [rollScale, setRollScale] = useState(0);
  const [inputPrompt, setInputPrompt] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [hasSaidHello, sethasSaidHello] = useState(false);



  const [sessionuid, setSessionUid] = useState('');
  const hasCreatedSession = useRef(false);

  const [isCreating, setIsCreating] = useState(true);

  const [intervalId, setIntervalId] = useState(null);

  const isThemeDark = useSelector(themeSelector);
  console.log({ isThemeDark });
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [queue, queueList] = useState([]);

  const [visible, setVisible] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);


  // useEffect(() => {
  //   // setShow2(true);
  //   const timer3 = setTimeout(() => {
  //     setVisible(false);
  //   }, 2000);  // Set a timer to hide overlay after 2 seconds

  //   return () => clearTimeout(timer3);  // Clear timer on component unmount
  // }, []);  // Empty dependency array means this useEffect runs once, similar to componentDidMount

  const [countDown2, setCountDown2] = useState(0);

  useEffect(() => {
    let intervalId;

    intervalId = setInterval(() => {
      setCountDown2(prevCount => Math.min(prevCount + 2, 100));  // Increment by 2
    }, 100);

    const speedUpTimer = setTimeout(() => {
      clearInterval(intervalId);  // Clear the initial slow interval

      // Start a faster interval of 50ms after the first second
      intervalId = setInterval(() => {
        setCountDown2(prevCount => Math.min(prevCount + 10, 100));  // Increment by 10
      }, 180);
    }, 400);

    const timer2 = setTimeout(() => {
      setVisible(false);
      clearInterval(intervalId);  // Clear the interval when hiding the overlay
    }, 2000);

    return () => {
      clearTimeout(timer2);
      clearInterval(intervalId);  // Clear the interval on component unmount
    };
  }, []);

  function replaceHtmlCharsWithDot(inputString) {
    // Replace HTML tags with a dot
    let result = inputString.replace(/<\/?[^>]+(>|$)/g, '.');

    // Replace HTML entities with a dot
    result = result.replace(/&[^;\s]+;/g, '.');

    return result;
  }

  function replaceSpecialCharsWithDot(inputString) {
    // Replace specified characters with a dot
    return inputString.replace(/[!,:\?]/g, '.');
  }

  const onSaveChatBot = () => {
    if(authToken === '')
    {
      dispatch(showPopUpAction(true));
      return;
    }
    if (name === '')
    {
      toast.error('Please enter a unique name & AI prompt for the chatbot.', {
        icon: '👏',
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
    }
    else
    {
      setShow3(false);
      sendMessageHeadOnPlay();
      setIsPlayingImage(!isPlayingImage);
    }
  };


  const createSession = async () => {
    // console.log("CREATING SESSION");

    // Timer that runs every 2 seconds
    // const asyncTimer = async () => {

    //   console.log(sessionId);
    //   try
    //   {
    //     const response = await fetch('https://i5z2sbz6n8ylzb-5000.proxy.runpod.net/sessions/get_vid', {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify({
    //         "sessionuid": sessionId,
    //       }),
    //     });

    //     // const response = await fetch(`https://i5z2sbz6n8ylzb-5000.proxy.runpod.net/sessions/${sessionuid}/videos/has_videos`);
    //     const data = await response.json();
    //     console.log(data);

    //     if (data['total_vids'] > 0)
    //     {
    //       // vid_queue.append(data['video']);
    //       queueList(prevList => [...prevList, data['video']]);
    //     } else
    //     {
    //       setUrl("https://i5z2sbz6n8ylzb-5000.proxy.runpod.net/hls/3s.m3u8");
    //     }
    //     console.log(queue);

    //   } catch (error)
    //   {
    //     console.error('Error:', error);
    //   }
    //   // Place any additional code you want to run here
    // };
    // const intervalId = setInterval(asyncTimer, 2000);
    // // Return a cleanup function to clear the interval when the component unmounts
    // return () => clearInterval(intervalId);
  };

  function endsWithNumber(str) {
    const lastChar = str.slice(-1);
    return !isNaN(lastChar) && lastChar !== ' ';
  }

  useEffect(() => {
    const sessionId = uuidv4();  // Generate a unique session ID
    setSessionUid(sessionId);
    console.log(`SESSION ID = ${sessionId}`);
    try
    {
      fetch('https://xq3pl2oc187t2f-5000.proxy.runpod.net/sessions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          key: sessionId,
          value: {
            'videos': []
          },
        }),
      });


    } catch (error)
    {
      console.error('Error:', error);
    }
  }, []);

  // useEffect(() => {
  //   console.log(queue);
  // }, [queue]);



  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);

  const [isIdleFirst, setIsIdleFirst] = useState(true);

  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // const [numGenerated, setNumGenerated] = useState(0);

  var numGenerated = 0;

  var hasGeneratedSequence = false;

  let isExecuted = false;


  const playVideo = (video, url, autoplay = true) => {
    try
    {
      setIsTimerActive(false);
      if (Hls.isSupported())
      {
        const hls = new Hls();
        hls.loadSource(url);
        hls.attachMedia(video);
        if (autoplay)
        {
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            video.play();
          });
        }
        return hls;  // Return the hls instance for cleanup
      } else if (video.canPlayType('application/vnd.apple.mpegurl'))
      {
        video.src = url;
        if (autoplay)
        {
          video.addEventListener('loadedmetadata', () => {
            video.play();
          });
        }
      }
    } catch (e)
    {
      console.error(e);
    }
  };

  useEffect(() => {
    if (sessionuid !== '')
    {
      console.log('sessionUid:', sessionuid);
    }
  }, [sessionuid]);


  useEffect(() => {
    if (sessionuid !== '' && showFullPage)
    {
      console.log('useffect called');
      setTimeout(() => {
        const video = videoRef2.current;
        const video2 = videoRef3.current;

        idleAnimation = url;

        console.log('useffect video');

        if (!video || !video2)
        {
          console.error('Video is null');
          return;
        }

        video.style.display = 'block';  // Show video1
        video2.style.display = 'none';  // Hide video2

        let hls = playVideo(video, url);  // Load and play the first video
        let hls2 = playVideo(video2, url2, false);  // Preload the second video without playing

        const handleVideo1Ended = async () => {

          idleAnimation = url2;

          console.log(`TOTAL OF SENTENCES GENERATED  : ${numSentence}`);
          if (numSentence == numGenerated && hasGeneratedSequence)
          {
            console.log('DONE SEQUENCE');
            numGenerated = 0;
            numSentence = 0;
            hasGeneratedSequence = false;
            setIsLoading(false);
            setisOutputted(true);
          }

          console.log('RUNNING Vid 1 ENDED');
          console.log(queue);

          var tmp_url = idleAnimation;

          video.style.display = 'none';  // Hide video1
          video2.style.display = 'block';  // Show video2

          if (hls)
          {
            hls.destroy();  // Optionally destroy the hls instance for video1
          }

          try
          {
            console.log(`NUMBER OF SENTENCES GENERATED ON UI : ${numGenerated}`);
            const response = await fetch('https://xq3pl2oc187t2f-5000.proxy.runpod.net/sessions/get_vid', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                'sessionuid': sessionuid,
                'numGenerated': numGenerated
              }),
            });

            // const response = await fetch(`https://i5z2sbz6n8ylzb-5000.proxy.runpod.net/sessions/${sessionuid}/videos/has_videos`);
            const data = await response.json();
            console.log(data);
            if (data['total_vids'] > 0)
            {

              // setNumGenerated(numGenerated + 1);
              numGenerated += 1;
              hasGeneratedSequence = true;
              tmp_url = data['video'];
              // const timer = setTimeout(() => {
              //   // This code will run 3 seconds after the component is mounted
              //   setisOutputted(true);
              // }, 2000);
              // clearTimeout(timer);
            } else
            {
              tmp_url = idleAnimation;
            }
          } catch (error)
          {
            console.error('Error:', error);
          }

          hls = playVideo(video, tmp_url, false);

          video2.play();  // Play the preloaded second video
        };


        const handleVideo2Ended = async () => {
          // if (hls)
          // {
          //   hls.destroy();  // Optionally destroy the hls instance for video1
          // }

          console.log(`TOTAL OF SENTENCES GENERATED  : ${numSentence}`);

          if (numSentence == numGenerated && hasGeneratedSequence)
          {
            console.log('DONE SEQUENCE');
            numGenerated = 0;
            numSentence = 0;
            hasGeneratedSequence = false;
            setIsLoading(false);
            setisOutputted(true);
          }

          console.log('RUNNING Vid 2 ENDED');

          var tmp_url = idleAnimation;
          console.log(queue);

          video.style.display = 'block';  // Hide video1
          video2.style.display = 'none';  // Show video2

          if (hls2)
          {
            hls2.destroy();  // Optionally destroy the hls instance for video1
          }

          try
          {
            console.log(`NUMBER OF SENTENCES GENERATED ON UI : ${numGenerated}`);
            const response = await fetch('https://xq3pl2oc187t2f-5000.proxy.runpod.net/sessions/get_vid', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                'sessionuid': sessionuid,
                'numGenerated': numGenerated
              }),
            });

            // const response = await fetch(`https://i5z2sbz6n8ylzb-5000.proxy.runpod.net/sessions/${sessionuid}/videos/has_videos`);
            const data = await response.json();
            console.log(data);
            if (data['total_vids'] > 0)
            {
              // if (isResponseWaiting)
              // {
              //   console.log('showing response on UI');
              // setIsTyping(false);
              // setChatHistory([
              //   ...newChatHistory,
              //   {
              //     text: resultRef.current,
              //     sender: "system",
              //     profile: activeImage, //
              //     audioUrl: "", //
              //     // videoUrl: '',
              //   },
              // ]);
              //   isResponseWaiting = false;
              // }
              numGenerated += 1;
              hasGeneratedSequence = true;
              tmp_url = data['video'];
              const timer = setTimeout(() => {
                // This code will run 3 seconds after the component is mounted
                setisOutputted(true);
              }, 2000);
              clearTimeout(timer);
            } else
            {
              tmp_url = idleAnimation;
            }
          } catch (error)
          {
            console.error('Error:', error);
          }

          // hls2 = playVideo(video2, urls[0], false);
          hls2 = playVideo(video2, tmp_url, false);

          video.play();  // Play the preloaded second video
          // urlIndexRef.current = 1 - urlIndexRef.current;  // Toggle the URL index
          // hls2 = playVideo(video2, urls[urlIndexRef.current], false);  // Update the source for video1

        };

        console.log('Attaching event listener');
        video.addEventListener('ended', handleVideo1Ended);
        video2.addEventListener('ended', handleVideo2Ended);

        return () => {
          video.removeEventListener('ended', handleVideo1Ended);
          if (hls2)
          {
            hls2.destroy();  // Cleanup for the second video
          }
          // ... other cleanup ...
        };
      }, 1000);
    }
  }, [sessionuid, url, url2]);

  // }, [url]);  // re-run this useEffect block whenever 'src' prop changes

  const deleteSession = async () => {
    if (!sessionuid)
    {
      console.error('Session ID is required');
      return;
    }

    try
    {
      const response = await fetch(`https://xq3pl2oc187t2f-5000.proxy.runpod.net/sessions/${sessionuid}`, {
        method: 'DELETE',
      });

      if (response.ok)
      {
        console.log('Session deleted successfully');
      } else
      {
        console.error('Failed to delete session:', response.statusText);
      }
    } catch (error)
    {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    // Cleanup function to clear the timer when the component unmounts
    return () => {
      if (intervalId)
      {
        deleteSession();
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);



  const imageUrls = [
    'https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/danny_generations%2Fphoto_2023-09-28_15-46-20.jpg?alt=media&token=de616bb0-74ba-41b5-bfe4-5a3fb1795b9c&_gl=1*p1tjg6*_ga*NTA5MjA0MjA0LjE2NzM0MDg0ODI.*_ga_CW55HF8NVT*MTY5NTkzMDMwMy44Ny4xLjE2OTU5MzExNjMuMjkuMC4w',
    'https://storage.googleapis.com/generativeartbucket1/eezyl/20231004-025022/20231004-025022-2.webp',
    'https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/photo_2023-10-04_09-53-57%20(2).jpg?alt=media&token=999fe359-f0c3-43c0-a448-8b79e41f8a98&_gl=1*1dh2d3f*_ga*NTA5MjA0MjA0LjE2NzM0MDg0ODI.*_ga_CW55HF8NVT*MTY5NjUyMDU0OC44NS4xLjE2OTY1MjA2NDUuNjAuMC4w',
    'https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/danny_generations%2Fphoto_2023-09-28_15-45-31.jpg?alt=media&token=54471489-4e51-4828-8576-093d818a9332&_gl=1*1ubwjf*_ga*NTA5MjA0MjA0LjE2NzM0MDg0ODI.*_ga_CW55HF8NVT*MTY5NTkzMDMwMy44Ny4xLjE2OTU5MzExMDQuMjAuMC4w',
    'https://storage.googleapis.com/generativeartbucket1/eezyl/20231004-025949/20231004-025949-1.webp',
    'https://storage.googleapis.com/generativeartbucket1/eezyl/20231004-025949/20231004-025949-0.webp',
    'https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/danny_generations%2Fphoto_2023-09-28_15-46-05.jpg?alt=media&token=e298f36b-3e15-4ad3-93ee-145143c6d093&_gl=1*12agna0*_ga*NTA5MjA0MjA0LjE2NzM0MDg0ODI.*_ga_CW55HF8NVT*MTY5NTkzMDMwMy44Ny4xLjE2OTU5MzExMTkuNS4wLjA.',
    'https://storage.googleapis.com/generativeartbucket1/eezyl/20231004-023057/20231004-023057-1.webp',
  ];
  // Initialize Firebase
  const db = getFirestoreInstance();





  const uploadData = async () => {

    if (name.length < 2)
    {
      toast.error('Make sure you have a name with more than 2 characters!');
      return;
    }
    // if (description.length < 2)
    // {
    //   toast.error("Make sure you have a description with more than 2 characters!");
    //   return
    // }
    if (inputPrompt.length < 2)
    {
      toast.error('Make sure you have an Input Prompt with more than 10 characters!');
      return;
    }
    // if (activeImage == "https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/chat-default-image.png?alt=media&token=936b0f65-f711-4153-a1d9-0f1250ad1411")
    // {
    //   toast.error("Make sure you have selected an Avatar Image");
    //   return
    // }

    try
    {
      const colRef = collection(db, 'ChatBots'); // Replace 'models' with your actual collection name
      const chatbotDocRef = doc(colRef, name.replaceAll(' ', '')); // Use the custom ID

      await setDoc(chatbotDocRef, { 'created_by': userData?.Email?.toString() ?? 'Unknown', 'date_created': Date.now(), 'description': '', 'id': name.replaceAll(' ', ''), 'name': name, 'inputImage': activeImage, 'inputPrompt': inputPrompt, 'likes': 0, 'generated_intro_vid': url2, 'generated_hello_video': generatedHelloVideo, 'language': chosenLanguage, 'voice': selectedVoiceForSelectBox, 'cover_image': coverImage, 'generated_image': url3, 'generated_image_finish': url4 });
      console.log('Document added to Chatbots collection.');
      toast.success('Chatbot Uploaded Successfully!');
      setShow2(false);
      // setChatbotData({
      //   name: '',
      //   description: '',
      // });
    } catch (error)
    {
      console.error('Error adding document: ', error);
    }
    // setIsUploading(true);

    // console.log("Running Talking Head");

    // sendMessageHeadIntro();


    // Add your upload logic here
  };

  const handleInputPromptChange = (e) => {
    setInputPrompt(e.target.value);
  };

  // useEffect(() => {
  //   console.log(props);
  // })


  const location = useLocation();

  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file)
    {
      const reader = new FileReader();
      reader.onload = (e) => {
        setActiveImage(e.target.result);
        // setFaceOrAudioToggle(true);
        // setGeneratedImages([e.target.result]);
        setIsDannyTrejoPicture(true);
        setIsRegenerated(true);
        setselectedAvatarURL(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };


  const [transcript, setTranscript] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [isDannyTrejoPicture, setIsDannyTrejoPicture] = useState(false);
  const inputRef = useRef(null);

  const [audioUrl, setAudioUrl] = useState('');

  const startListening = () => {
    // setCurrentMessage("");
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

    if (SpeechRecognition)
    {
      const recognition = new SpeechRecognition();
      recognition.interimResults = true;

      recognition.onresult = (event) => {
        let interimTranscript = '';
        for (let i = event.resultIndex; i < event.results.length; i++)
        {
          const transcriptionSegment = event.results[i][0].transcript;
          if (event.results[i].isFinal)
          {
            // setCurrentMessage((prev) => prev + transcriptionSegment);
          } else
          {
            console.log(transcriptionSegment);
            interimTranscript += transcriptionSegment;
            setCurrentMessage(interimTranscript);
          }
        }
        inputRef.current.value = transcript + interimTranscript;
      };

      recognition.onstart = () => {
        setIsListening(true);
      };

      recognition.onend = () => {
        setIsListening(false);
      };

      recognition.start();
    } else
    {
      toast.error('Speech Recognition API not supported in this browser.');
    }
  };

  const handleAudioEnd = () => {
    // Restart the audio when it ends
    audioRef.current.play();
  };

  const togglePlayback = () => {
    const audioElement = audioRef.current;

    if (isPlaying)
    {
      audioElement.pause();
    } else
    {
      audioElement.play();
    }

    setIsPlaying(!isPlaying);
  };

  const videoRef = useRef(null);
  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(false);
  const [isAudio, setIsAudio] = React.useState(false);
  const [isVideo, setIsVideo] = React.useState(false);
  const [timerRate, setTimerRate] = useState(1300);

  useEffect(() => {
    if (avatarVideoUrl && videoRef.current)
    {

      videoRef.current.addEventListener('loadedmetadata', () => {
        videoRef.current.play();
      });

      videoRef.current.load();  // Ensure the new source is loaded
    }
  }, [avatarVideoUrl]);

  const [isGenerating, setIsGenerating] = useState(false);
  const [hasGenerated, setHasGenerating] = useState(false);
  const [isRegenerated, setIsRegenerated] = useState(false);

  const [isProgressing, setIsProgressing] = useState(false);

  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [hasGeneratedImage, setHasGeneratingImage] = useState(false);

  const [superenhance, setSuperenhance] = useState(false);

  const handleCheckboxChange = (event) => {
    setSuperenhance(event.target.checked);
  };

  const [queueId, setQueueId] = useState(null);
  const [queueIdIntro, setQueueIdIntro] = useState(null);
  const [queueIdOnPlay, setQueueIdOnPlay] = useState(null);

  const [, setQueuePosition] = useState(null);
  const [, setResult] = useState(null);

  const [generatedImages, setGeneratedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [message, setMessage] = useState(
    ''
  );
  const [faceOrAudioToggle, setFaceOrAudioToggle] = useState(false);

  const handlePromptChange = (event) => {
    setMessage(event.target.value);
  };

  const [show, setShow] = useState(false);
  const [sliderValue1, setSliderValue1] = useState(50);
  const [sliderValue2, setSliderValue2] = useState(50);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSliderChange1 = (e) => setSliderValue1(e.target.value);
  const handleSliderChange2 = (e) => setSliderValue2(e.target.value);


  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);

  let [resultt, setResultt] = useState('');

  const resultRef = useRef();

  useEffect(() => {
    resultRef.current = resultt;
  }, [resultt]);

  // const [languages, setLanguages] = useState([
  //   "English",
  //   "Spanish",
  //   "Chinese",
  //   "French",
  //   "Japanese",
  //   "Arabic",
  //   "German"
  // ]);


  var models = [
    {
      value: 1,
      text: 'Llama2',
      icon: <Icon icon="emojione:flag-for-united-states" />,
    },
    {
      value: 2,
      text: 'ChatGPT',
      icon: <Icon icon="emojione:flag-for-spain" />,
    },

  ];
  const [chosenModel, setChosenModel] = useState('chat');

  const [languages, setLanguages] = useState([]);
  useEffect(() => {
    // This code will run after the component renders
    // and also every time `languages` changes.

    console.log('languages state has changed:', languages);

    // Any other side effects related to the change in `languages` can be placed here.

  }, [languages]); // <- Notice the `languages` dependency in this array
  // var languages = [
  //   {
  //     value: 1,
  //     text: "English",
  //     icon: <Icon icon="emojione:flag-for-united-states" />,
  //   },
  //   {
  //     value: 2,
  //     text: "Spanish",
  //     icon: <Icon icon="emojione:flag-for-spain" />,
  //   },
  //   {
  //     value: 3,
  //     text: "Chinese",
  //     icon: <Icon icon="emojione:flag-for-china" />,
  //   },
  //   {
  //     value: 4,
  //     text: "French",
  //     icon: <Icon icon="emojione:flag-for-france" />,
  //   },
  //   {
  //     value: 5,
  //     text: "Japanese",
  //     icon: <Icon icon="emojione:flag-for-japan" />,
  //   },
  //   {
  //     value: 6,
  //     text: "Arabic",
  //     icon: <Icon icon="emojione:flag-for-united-arab-emirates" />,
  //   },
  //   {
  //     value: 7,
  //     text: "German",
  //     icon: <Icon icon="emojione:flag-for-germany" />,
  //   },
  // ];
  const [chosenLanguage, setSelectedLanguage] = useState('English (US)');

  const [selectedModelOption, setSelectedModelOption] = useState({
    value: 1,
    text: 'Llama2',
    icon: <Icon icon="emojione:flag-for-united-states" />,
  });
  const [selectedOption, setSelectedOption] = useState({ 'value': 'English (US)', 'label': 'English (US)', });

  function replaceDannyTrejo(str) {
    return str.replace(/Danny Trejo|danny trejo/g, '<lora:danny2500:1.24>');
  }


  const [selectedValue, setSelectedValue] = useState('Angel');
  const [selectedSample, setSelectedSample] = useState('');
  const [selectedVoiceForSelectBox, setSelectedVoiceForSelectBox] = useState();
  const audioRef2 = useRef(null);

  const handleVoiceChange = (event) => {
    setSelectedVoice(event.target.value);

    setIsPlaying(false);
    if (audioRef.current)
    {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  const playPreview = () => {
    if (audioRef2.current)
    {
      audioRef2.current.play();
    }
  };

  const pausePreview = () => {
    if (audioRef2.current)
    {
      audioRef2.current.pause();
    }
  };

  const selectedVoiceSample = voices.find(
    (voice) => voice.value === selectedVoice
  )?.sample;

  useEffect(() => {
    // try
    // {
    //   if (location.state.generated_intro_vid != null)
    //   {
    //     setHasGenerating(true);
    //     setIsVideo(true);
    //     setavatarVideoUrl(location.state.generated_intro_vid);





    //     if (videoRef.current)
    //     {
    //       // toast.error('video is ready and is trying to start');
    //       videoRef.current.play();
    //     }
    //   }
    // } catch (error)
    // {

    //   console.error("No Input Video:", error);
    //   setIsCreating(true);
    //   setShow(2);
    // }


    // if (location.state.inputImage != null)
    // {

    // } else
    // {
    //   console.log("CREATING");
    //   setIsCreating(true);
    // }
    const fetchData = async () => {
      await fetch('https://speechgen.io/index.php?r=api/voices')
        .then(response => {
          // Check if the request was successful
          if (response.status === 200)
          {
            return response.json();
          } else
          {
            throw new Error(`Failed: ${response.statusText}`);
          }
        })
        .then(data => {

          // const newElement = { 'Premium Voices': newTestArray };
          const newList = data;
          // console.log("SPEECHGENDATA:");
          // console.log(newList);

          //set languages
          setSpeechGenData(newList);
          const languages_temp = Object.keys(newList);
          const options = languages_temp.map(language => ({ value: language, label: language }));
          console.log(options);
          setLanguages(options);

          //set voices
          var newArray = data['English (US)'].map((item) => {
            return {
              value: item.voice,
              name: item.voice,
              language: 'English (US)',
              // ... any additional keys you want
            };
          });

          newArray = [{
            'value': 'Fast',
            'name': 'Fast',
            'language': 'Fast',
          }, ...newArray];

          // var randNum = getRandomNumber(0, 5);
          setVoices(newArray);
          console.log(newArray[0]);
          setSelectedVoiceForSelectBox(newArray[0]);
          setSelectedValue(newArray[0].value);
          //set language
          // setSelectedLanguage(location.state.language);
          // setSelectedVoiceForSelectBox('Brandon');
          // setSelectedValue('Brandon');

          try
          {
            if (location.state.inputImage != null)
            {
              console.log('SENT DATA=');
              console.log(location.state);
              setActiveImage(location.state.inputImage);
              setGeneratedImages([location.state.inputImage]);
              setIsRegenerated(true);


              // const findLanguageByText = (searchText) => {
              //   return languages.find(language => language.text === searchText);
              // };
              // const selectedLanguage = findLanguageByText(location.state.language);
              // setSelectedOption(location.state.language);
              // console.log(location.state.language.name);
              // console.log(newList);
              // console.log(newList[location.state.language.name]);

              // var lst = newList[location.state.language.name];
              // const newArray = lst.map((item) => {
              //   return {
              //     value: item.voice,
              //     name: item.voice,
              //     language: location.state.language.name,
              //     // ... any additional keys you want
              //   };
              // });
              //set voices
              // const newArray = speechGenData[location.state.language.name].map((item) => {
              //   return {
              //     value: item.voice,
              //     name: item.voice,
              //     language: location.state.language,
              //     // ... any additional keys you want
              //   };
              // });
              // setSelectedLanguage(location.state.language.name);
              // setVoices(newArray);
              // console.log(newArray);
              // console.log(newArray[0]);



              // setSelectedVoiceForSelectBox({ "value": location.state.voice, "name": location.state.voice, 'language': location.state.language.name });
              // setSelectedValue(location.state.voice);

              //set talking head parameters
              // setYaw(location.state.yawScale);
              // setHeadMovement(location.state.expression_scale);
              // setPitchScale(location.state.pitchScale);
              // setRollScale(location.state.rollScale);



              // setSelectedLanguage(location.state.language);
              // setSelectedOption({ "value": location.state.language, "name": location.state.language, "language": location.state.language, })

              // setSelectedValue(location.state.voice);
              // setSelectedVoiceForSelectBox({ 'name': location.state.voice, 'value': location.state.voice, 'sample': location.state.voice });

              // console.log("LANGUAGE= ");

              // console.log(speechGenData[location.state.language]);




              // const newArray = speechGenData[location.state.language].map((item) => {
              //   return {
              //     value: item.voice,
              //     name: item.voice,
              //     language: location.state.language,
              //     // ... any additional keys you want
              //   };
              // });

              // // setVoices(newArray);

              // console.log(newArray[0]);




              //set input prompt
              setInputPrompt(location.state.inputPrompt);

              //set voice
              // const selectedVoiceName = location.state.voice;
              // const selectedVoice = filteredVoices.find(
              //   (voice) => voice.name === selectedVoiceName
              // );
              // console.log(selectedVoice);
              // setSelectedVoiceForSelectBox(selectedVoice);
              // if (selectedVoice)
              // {
              //   setSelectedValue(selectedVoice.value);
              //   // setSelectedSample(selectedVoice.sample);
              // }
            } else
            {
              console.log('CREATING');
              setIsCreating(true);
            }
          } catch (e)
          {
            console.log(e);
            console.log('CREATING');
            setIsCreating(true);
          }

          // set voices & language
          // setSelectedVoiceForSelectBox({ 'name': data['English (US)'][0].voice, 'value': data['English (US)'][0].voice, 'sample': data['English (US)'][0].voice });
          // setSelectedValue(newList['English (US)'][0].voice);


          // console.log(`Success: ${Object.keys(data)}`);
          // console.log(typeof Object.keys(data));
          // setLanguages(Object.keys(data));
          // const languages_temp = ["Premium Voices", ...Object.keys(data)]


          // console.log(languages_temp);



          // console.log("options");


          // const newList = [premiumVoices, ...options];





          // const newArray = speechGenData['English (US)'].map((item) => {
          //   return {
          //     value: item.voice,
          //     name: item.voice,
          //     language: 'English (US)',
          //     // ... any additional keys you want
          //   };
          // });

          // console.log("VOICES");
          // console.log(newArray);

          // setVoices(newArray);

          // setSelectedVoiceForSelectBox({ 'name': newList['English (US)'][0].voice, 'value': newList['English (US)'][0].voice, 'sample': newList['English (US)'][0].voice });
          // setSelectedValue(newList['English (US)'][0].voice);

          // console.log("LANGUAGES:");
          // console.log(languages);
        })
        .catch(error => {
          console.log(error);
        });
      console.log(location.state);


      // const url = "https://play.ht/api/v1/getVoices";
      // const options = {
      //   method: "GET",
      //   headers: {
      //     accept: "application/json",
      //     AUTHORIZATION: "Bearer 8a2f84fbe0694f768083dcbc62fee063",
      //     "X-USER-ID": "DKvC8LJWfVYiFwly0WcGADPW5xb2",
      //   },
      // };
      // // console.log(`SessionID: `);


      // try
      // {
      //   console.log("RETRIEVED VOICES:")
      //   const response = fetch(url, options).then(response => {
      //     // Check if the request was successful
      //     if (response.status === 200)
      //     {
      //       return response.json();
      //     } else
      //     {
      //       throw new Error(`Failed: ${response.statusText}`);
      //     }
      //   })
      //     .then(data => {

      //       console.log(data);

      //       data.voices.splice(0, 2);
      //       var filteredVoices = data.voices.filter(

      //         (voice) => voice.languageCode === "en-US"
      //       );
      //       // filteredVoices.append({
      //       //   'name': 'Danny Trejo',
      //       //   'sample': 'local'
      //       // })
      //       filteredVoices = [
      //         ...filteredVoices,
      //         {
      //           name: "Joe Rogan",
      //           value: "Joe Rogan",
      //           sample: "local",
      //         },
      //       ];
      //       filteredVoices = [
      //         ...filteredVoices,
      //         {
      //           name: "Andrew Tate",
      //           value: "Andrew Tate",
      //           sample: "local",
      //         },
      //       ];

      //       filteredVoices = [
      //         ...filteredVoices,
      //         {
      //           name: "Danny Trejo",
      //           value: "Danny Trejo",
      //           sample: "local",
      //         },
      //       ];
      //       filteredVoices = filteredVoices.reverse();
      //       filteredVoices = filteredVoices.filter(
      //         (voice) => voice.name !== "Nancy"
      //       );
      //       console.log("FILTERED VOICES:");
      //       console.log(filteredVoices)

      //       const newTestArray = filteredVoices.map((item) => {
      //         return {
      //           voice: item.name,
      //           cpm: 1,
      //           pro: '1'
      //           // voice: item.name,
      //           // ... any additional keys you want
      //         };
      //       });
      //       console.log(newTestArray);
      //       fetch('https://speechgen.io/index.php?r=api/voices')
      //         .then(response => {
      //           // Check if the request was successful
      //           if (response.status === 200)
      //           {
      //             return response.json();
      //           } else
      //           {
      //             throw new Error(`Failed: ${response.statusText}`);
      //           }
      //         })
      //         .then(data => {
      //           // console.log("DATA");
      //           // console.log(data);
      //           // console.log(newTestArray);
      //           // allData = data;
      //           // console.log(premiumVoices);
      //           const newElement = { 'Premium Voices': newTestArray };
      //           const newList = { ...data, ...newElement }
      //           console.log("SPEECHGENDATA:");
      //           console.log(newList);

      //           //set languages
      //           setSpeechGenData(newList);
      //           const languages_temp = Object.keys(newList);
      //           const options = languages_temp.map(language => ({ value: language, label: language }));
      //           console.log(options);
      //           setLanguages(options);

      //           //set voices
      //           const newArray = data['English (US)'].map((item) => {
      //             return {
      //               value: item.voice,
      //               name: item.voice,
      //               language: 'English (US)',
      //               // ... any additional keys you want
      //             };
      //           });
      //           setVoices(newArray);
      //           console.log(newArray[0]);
      //           setSelectedVoiceForSelectBox(newArray[4]);
      //           setSelectedValue(newArray[4].value);

      //           try
      //           {
      //             if (location.state.inputImage != null)
      //             {
      //               console.log("SENT DATA=");
      //               console.log(location.state);
      //               setActiveImage(location.state.inputImage);
      //               setGeneratedImages([location.state.inputImage]);
      //               setIsRegenerated(true)

      //               //set language
      //               // setSelectedLanguage(location.state.language);
      //               // const findLanguageByText = (searchText) => {
      //               //   return languages.find(language => language.text === searchText);
      //               // };
      //               // const selectedLanguage = findLanguageByText(location.state.language);
      //               // setSelectedOption(location.state.language);
      //               // console.log(location.state.language.name);
      //               // console.log(newList);
      //               // console.log(newList[location.state.language.name]);

      //               // var lst = newList[location.state.language.name];
      //               // const newArray = lst.map((item) => {
      //               //   return {
      //               //     value: item.voice,
      //               //     name: item.voice,
      //               //     language: location.state.language.name,
      //               //     // ... any additional keys you want
      //               //   };
      //               // });
      //               //set voices
      //               // const newArray = speechGenData[location.state.language.name].map((item) => {
      //               //   return {
      //               //     value: item.voice,
      //               //     name: item.voice,
      //               //     language: location.state.language,
      //               //     // ... any additional keys you want
      //               //   };
      //               // });
      //               // setSelectedLanguage(location.state.language.name);
      //               setVoices(newArray);
      //               console.log(newArray);
      //               // console.log(newArray[0]);



      //               // setSelectedVoiceForSelectBox({ "value": location.state.voice, "name": location.state.voice, 'language': location.state.language.name });
      //               // setSelectedValue(location.state.voice);

      //               //set talking head parameters
      //               // setYaw(location.state.yawScale);
      //               // setHeadMovement(location.state.expression_scale);
      //               // setPitchScale(location.state.pitchScale);
      //               // setRollScale(location.state.rollScale);



      //               // setSelectedLanguage(location.state.language);
      //               // setSelectedOption({ "value": location.state.language, "name": location.state.language, "language": location.state.language, })

      //               // setSelectedValue(location.state.voice);
      //               // setSelectedVoiceForSelectBox({ 'name': location.state.voice, 'value': location.state.voice, 'sample': location.state.voice });

      //               // console.log("LANGUAGE= ");

      //               // console.log(speechGenData[location.state.language]);




      //               // const newArray = speechGenData[location.state.language].map((item) => {
      //               //   return {
      //               //     value: item.voice,
      //               //     name: item.voice,
      //               //     language: location.state.language,
      //               //     // ... any additional keys you want
      //               //   };
      //               // });

      //               // // setVoices(newArray);

      //               // console.log(newArray[0]);




      //               //set input prompt
      //               setInputPrompt(location.state.inputPrompt);

      //               //set voice
      //               // const selectedVoiceName = location.state.voice;
      //               // const selectedVoice = filteredVoices.find(
      //               //   (voice) => voice.name === selectedVoiceName
      //               // );
      //               // console.log(selectedVoice);
      //               // setSelectedVoiceForSelectBox(selectedVoice);
      //               // if (selectedVoice)
      //               // {
      //               //   setSelectedValue(selectedVoice.value);
      //               //   // setSelectedSample(selectedVoice.sample);
      //               // }
      //             } else
      //             {
      //               console.log("CREATING");
      //               setIsCreating(true);
      //             }
      //           } catch (e)
      //           {
      //             console.log(e);
      //             console.log("CREATING");
      //             setIsCreating(true);
      //           }

      //           // set voices & language
      //           // setSelectedVoiceForSelectBox({ 'name': data['English (US)'][0].voice, 'value': data['English (US)'][0].voice, 'sample': data['English (US)'][0].voice });
      //           // setSelectedValue(newList['English (US)'][0].voice);


      //           // console.log(`Success: ${Object.keys(data)}`);
      //           // console.log(typeof Object.keys(data));
      //           // setLanguages(Object.keys(data));
      //           // const languages_temp = ["Premium Voices", ...Object.keys(data)]


      //           // console.log(languages_temp);



      //           // console.log("options");


      //           // const newList = [premiumVoices, ...options];





      //           // const newArray = speechGenData['English (US)'].map((item) => {
      //           //   return {
      //           //     value: item.voice,
      //           //     name: item.voice,
      //           //     language: 'English (US)',
      //           //     // ... any additional keys you want
      //           //   };
      //           // });

      //           // console.log("VOICES");
      //           // console.log(newArray);

      //           // setVoices(newArray);

      //           // setSelectedVoiceForSelectBox({ 'name': newList['English (US)'][0].voice, 'value': newList['English (US)'][0].voice, 'sample': newList['English (US)'][0].voice });
      //           // setSelectedValue(newList['English (US)'][0].voice);

      //           // console.log("LANGUAGES:");
      //           // console.log(languages);
      //         })
      //         .catch(error => {
      //           console.log(error);
      //         });
      //       console.log(location.state);


      //     });




      //   //set chatbot parameters


      //   // if (location.state.created_by == "1")
      //   // {

      //   // }
      // } catch (error)
      // {
      //   console.error("Error fetching voices:", error);
      // }


    };

    fetchData();


  }, []); // empty dependency array means this useEffect runs once after initial render

  const [avatarImageUrlSelected, setAvatarImageUrl] = useState('');
  const [isAvatarGenerated, setIsAvatarGenerated] = useState(false);
  const [selectedAvatarURL, setselectedAvatarURL] = useState('');
  const [modelsCheckbox, setModelsCheckbox] = useState({
    image: false,
    chat: false,
    app: false,
    vision: false,
    gpt: false,
  });


  const handleCategoryChange = (event) => {
    const selectedVoiceName = event.name;
    const selectedVoice = voices.find(
      (voice) => voice.name === selectedVoiceName
    );

    setIsPlaying(false);

    if (selectedVoice)
    {
      setSelectedValue(selectedVoice.value);
      // setSelectedSample(selectedVoice.sample);
    }
    setSelectedVoiceForSelectBox(event);
    // your logic here
  };

  const handleModelChange = (event) => {
    const selectedLanguage = event.target.value;
    console.log({ event: event.target.value, checked: modelsCheckbox[selectedLanguage] });

    // Check if selected language is 'gpt' or 'vision'
    if (selectedLanguage === 'gpt' || selectedLanguage === 'vision')
    {
      // Check if subscription level is less than 2
      if (subData.SubscriptionLevel < 2)
      {
        // Redirect to the pricing page
        window.open('../pricing', '_blank');
      } else
      {
        // Toggle the checkbox state for the selected language
        setModelsCheckbox({ ...modelsCheckbox, [selectedLanguage]: !modelsCheckbox[selectedLanguage] });
      }
    } else
    {
      // Toggle the checkbox state for the selected language
      setModelsCheckbox({ ...modelsCheckbox, [selectedLanguage]: !modelsCheckbox[selectedLanguage] });
    }
  };



  const handleLanguageChange = (event) => {
    const selectedLanguage = event.value;
    console.log(selectedLanguage);
    setSelectedLanguage(selectedLanguage);

    // setSelectedLanguage(selectedLanguage);
    setSelectedOption({ 'value': selectedLanguage, 'name': selectedLanguage, 'language': selectedLanguage, });

    console.log(speechGenData[event.value]);




    const newArray = speechGenData[event.value].map((item) => {

      return {
        value: item.voice,
        name: item.voice,
        language: selectedLanguage,
      };
    });

    setVoices(newArray);

    console.log(newArray[0]);

    setSelectedVoiceForSelectBox(newArray[0]);
    setSelectedValue(newArray[0].value);
    setSelectedOption({ 'value': selectedLanguage, 'name': selectedLanguage, 'language': selectedLanguage, });
    // your logic here
  };

  const [activeImage, setActiveImage] = useState('');
  useEffect(() => {
    // if (!isThemeDark)
    // {
    setActiveImage('https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/chat-default-image.png?alt=media&token=936b0f65-f711-4153-a1d9-0f1250ad1411');
    // } else
    // {
    //   setActiveImage("https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/dark-img-upload.png?alt=media&token=474bcca1-fd06-44d8-8c2e-2898651dbb60&_gl=1*11hdrzi*_ga*NDI5MDg0OTczLjE2ODI0Njk1NzQ.*_ga_CW55HF8NVT*MTY5NjI1MjY2MS41LjEuMTY5NjI1MjY4Ni4zNS4wLjA")
    // }
  }, [isThemeDark]);
  // const location = useLocation();
  // const avatarImageUrl = location.state?.avatarImageUrl;

  // let [, setInputIm] = React.useState("");
  // let [pair, setPair] = React.useState("");
  // let query = window.location.search.substring(1);
  // let vars = query.split("&");
  // useEffect(() => {
  //   let tmpPair = vars[0]?.split("=");

  //   // if (tmpPair[1] == null) {
  //   //   console.log("no image");
  //   // } else {
  //   //   console.log(tmpPair[1]);
  //   //   setAvatarImageUrl(tmpPair[1]);
  //   //   // setAvatarImageUrl(image);
  //   // }

  //   // setInputIm(tmpPair);  // Assuming setting inputIm doesn't need any condition

  //   // if (tmpPair[0] === "url" && JSON.stringify(pair) !== JSON.stringify(tmpPair)) {
  //   //     setPair(tmpPair);
  //   // }
  // }, [vars]);

  const playAudio = async () => {
    // Your function here...
    // This is where your function finishes running.
    // Let's play the audio now.
    // audioRef.current.play();
  };

  const onSelectImages = (image, index) => {
    let allImage = selectedImage;
    let updateGeneratedImage = [...generatedImages];
    if (image.isChecked)
    {
      updateGeneratedImage[index].isChecked = false;
      const imgIndex = allImage.indexOf(image.imageUrl);
      if (index > -1)
      {
        allImage.splice(imgIndex, 1);
      }
    } else
    {
      updateGeneratedImage[index].isChecked = true;
      allImage.push(image.imageUrl);
    }
    setGeneratedImages(updateGeneratedImage);
    setIsRegenerated(true);
    setSelectedImage(allImage);
  };
  // const onShowTokenize = () => {
  //   if (authToken) {
  //     setShowTokenNize(true);
  //   } else {
  //     setShowLoginPopup(true);
  //   }
  // };

  const fetchGeneration = async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    setGeneratedImages([]);
    setCountDown(0);
    setIsDannyTrejoPicture(false);

    // const db = getFirestore();

    // const db = getFirestore();

    // const db = firebase.firestore();

    var isUploaded = false;


    setTimerRate(350);
    // setGeneratedImages([]);
    // const aspect1 = document.getElementById("AspectRatio01").checked;
    // // togglerTimer();
    // document.getElementById("progressClass").style.display = "block";
    // document.getElementById("BtnGenerate").style.display = "block";

    setIsGeneratingImage((t) => true);

    setHasGeneratingImage((t) => false);
    setRunTimer((t) => true);

    var tmpMessage = `${replaceDannyTrejo(message)}, portrait, headshot, frontal face, realistic, high quality, real headshot, hd, 4k, one person headshot, clear headshot portrait`;
    const postData = {
      model: 'dall-e-3',
      prompt: tmpMessage,
      n: 4,
      size: '1024x1024'
    };
    console.log('calling api');
    var prompts = [tmpMessage, tmpMessage, tmpMessage, tmpMessage];
    let allImages = [];
    Promise.all(prompts.map((prompt, i) => {
      const postData = {
        input: {
          prompt: prompt, // Your prompt goes here
          num_inference_steps: 25,
          refiner_inference_steps: 50,
          width: 1024,
          height: 1024,
          guidance_scale: 7.5,
          strength: 0.3,
          seed: null,
          num_images: 1
        }
      };

      return axios.post('https://api.runpod.ai/v2/sdxl/runsync', postData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'XNLHZ5FP018JAYKXTFE4AJJHR32E1BR1DLUPT1VJ' // Your API key
        }
      })
        .then(response => {
          // Process each response
          const imageUrl = response.data.output.image_url;
          const newData = {
            imageUrl: imageUrl,
            isChecked: false
          };
          allImages.push(newData);
        })
        .catch(error => {
          // Handle errors
          console.error('Error generating image for prompt:', prompt, error);
          setIsLoading(false);
        });
    })).then(() => {
      setCountDown(100);
      setGeneratedImages(allImages);
      setIsRegenerated(true);
      setActiveImage(allImages[0].imageUrl);
      setIsAvatarGenerated(true);
      setSelectedImage(allImages[0].imageUrl);



      setIsGeneratingImage((t) => false);
      setHasGeneratingImage(true);
    }).catch((error) => {
      // toast.error(error.message);
      setRunTimer((t) => false);
      setCountDown(0);
      setIsGeneratingImage((t) => false);
      setHasGeneratingImage(false);
    });

    // await fetch(
    //   `https://yjbx86uhoesmxn-3000.proxy.runpod.net/factory_inference?model_uid=sdxl&prompt=${tmpMessage}&cgs=8&steps=20&n_imgs=4&email=${userData?.email}`,
    //   {
    //     headers: {
    //       connection: "keep-alive",
    //       // 'content-type': 'application/json'
    //     },
    //     keepalive: true,
    //     timeout: 8000000,
    //   }
    // )
    //   .then((response) => response.json())
    //   .then(async (data) => {

    //     setCountDown(100);
    //     setRunTimer((t) => false);
    //     setTimeout(() => {
    //       let allImages = [];
    //       data.map((image) => {
    //         const newData = {
    //           imageUrl: image,
    //           isChecked: false,
    //         };
    //         allImages.push(newData);
    //       });

    //       setGeneratedImages(allImages);
    // setIsRegenerated(true)
    // setActiveImage(allImages[0].imageUrl);
    // setIsAvatarGenerated(true);
    // setSelectedImage();



    // setIsGeneratingImage((t) => false);
    // setHasGeneratingImage(true);
    //     }, 1000);

    //     // useEffect(() => {
    //     // //   const fetchData = async () => {
    //     // const docRef = doc(db, 'FactoryRecents', qty);
    //     // const docSnap = await getDoc(docRef);

    //     // if (docSnap.exists()) {
    //     //   setRecentGenerations(docSnap.data()['recent_generations'].reverse());
    //     // } else {
    //     //   console.log('No such document!');
    //     // }
    //     //   };

    //     //   fetchData();
    //     // }, [qty]);
    //   })
    // .catch((error) => {
    //   // toast.error(error.message);
    //   setRunTimer((t) => false);
    //   setCountDown(0);
    //   setIsGeneratingImage((t) => false);
    //   setHasGeneratingImage(false);
    // });


    // try {

    // setTimeout(() => {
    //   controller.abort();
    // }, 120000); // 2 minutes (120000 milliseconds) timeout
    // if (authToken) {


    // } else {
    //   setShowLoginPopup(true);
    // }
    // return () => {
    //   // Clean up the controller when the component is unmounted
    //   controller.abort();
    // };
  };

  // useEffect(() => {
  //   playAudio();
  // }, []);

  // useEffect(() => {
  //     console.log(pair[1]);
  // }, [pair]);

  React.useEffect(() => {
    let timerId;

    if (runTimer)
    {
      setCountDown(0);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown + 1);
      }, timerRate);
    } else
    {
      setVisible(false);
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer, timerRate]);

  const scrollToBottom = () => {
    const chatField = chatFieldRef.current;
    if (chatField)
    {
      chatField.scrollTop = chatField.scrollHeight;
    }
  };

  React.useEffect(() => {
    if (countDown > 100 && runTimer)
    {
      setRunTimer(false);
      setVisible(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const convertMessages = (messages) => {
    const formattedMessages = messages.map((message) => ({
      role: message.sender,
      content: message.text,
    }));

    // if (messages.length < 2) {
    formattedMessages.unshift({
      role: 'system',
      content: `You are an Orbofi A.I. Assistant created by the engineers at orbofi. You are able to act as any character given the characteristics that the user inputs. Here is the characteristics you should replicate: ${inputPrompt}. Add lots of punctuation, and keep your sentences short. Maximum 350 characters for your responses.  Conversation begins:\n`
    });
    // }

    return formattedMessages;
  };

  const convertMessagesForImageGeneration = (messages) => {
    const formattedMessages = messages.map((message) => ({
      role: message.sender,
      content: message.text,
    }));

    // if (messages.length < 2) {
    formattedMessages.unshift({
      role: 'system',
      content: `You are an Orbofi A.I. Assistant created by the engineers at orbofi. You are able to act as any character given the characteristics that the user inputs. Here is the characteristics you should replicate: ${inputPrompt}. When the user asks to generate images, you will create 4 image generation prompts of whatever they ask. These 4 prompts will be fed into an AI image generator, so make sure to format the response is in a numbered list, 1.2.3.4, with<code> wrapped around each prompt.  Conversation begins:\n`
    });
    // }

    return formattedMessages;
  };
  // useEffect(() => {
  //   if (queueId)
  //   {
  //     const interval = setInterval(async () => {
  //       try
  //       {
  //         console.log("Fetching User Position")
  //         const response = await axios.get(
  //           `https://2ook38ir4ea9ub-3000.proxy.runpod.net/queue_position/${queueId}`
  //         ); // adjust with your API base url
  //         const position = response.data.queue_position; // adjust this based on the API response
  //         console.log(response.data);
  //         setQueuePosition(position);

  //         const data = {};

  //         // if (response.data["response"] != null) {
  //         //   setIsTyping(false);
  //         //   // console.log({ response212: response });
  //         //   setChatHistory([
  //         //     ...chatHistory,
  //         //     // { text: currentMessage, sender: "user", profile: '', },
  //         //     {
  //         //       text: response.data["response"],
  //         //       sender: "system",
  //         //       profile: activeImage, //
  //         //       audioUrl: "", //
  //         //       // videoUrl: '',
  //         //     },
  //         //   ]);
  //         // }

  //         if (position === null)
  //         {
  //           var resultResponse = "";
  //           try
  //           {
  //             resultResponse = await axios.get(
  //               `https://2ook38ir4ea9ub-3000.proxy.runpod.net/generated_imgs/${queueId}`
  //             ); // adjust with your API base url
  //             const checkResponseTime = resultResponse["data"]["response"]
  //               .split(".")
  //               .pop();
  //             console.log("RESULTS=");
  //             console.log(resultResponse["data"]);

  //             try
  //             {
  //               console.log(resultResponse['data']['error']);
  //               if (resultResponse['data']['error'] === 'yes')
  //               {
  //                 console.log("SHOWING POPUP NO FACE");
  //                 toast.error("Face not detected. Please try again with another image. Face must be clearly visible.");
  //               }

  //             } catch (e)
  //             {
  //               console.log(e);
  //             }

  //             // setGeneratedImages(allImages);
  //             // setSelectedImage([]);

  //             setRunTimer((t) => false);

  //             setCountDown(100);
  //             setTimeout(() => {
  //               setIsGenerating((t) => false);
  //               setHasGenerating(true);
  //               setQueueId(null);
  //               setIsLoading(false);
  //               setIsTyping(false);
  //               // setChatHistory([
  //               //   ...chatHistory,
  //               //   // { text: currentMessage, sender: "user", profile: '', },
  //               //   {
  //               //     text: resultResponse["data"]["text"],
  //               //     sender: "system",
  //               //     profile: activeImage,
  //               //     audioUrl:
  //               //       checkResponseTime === "mp3"
  //               //         ? resultResponse["data"]["response"]
  //               //         : "",
  //               //     // videoUrl: checkResponseTime === 'mp4' ? resultResponse["data"]["response"] : "",
  //               //   },
  //               // ]);
  //               if (checkResponseTime === "mp4")
  //               {
  //                 setavatarVideoUrl(resultResponse["data"]["response"]);
  //                 setIsVideo(true);

  //                 // console.log("VIDVID");
  //                 // if (videoRef.current)
  //                 // {
  //                 //   console.log("READY");
  //                 //   toast.error('video is ready and is trying to start');
  //                 // videoRef.current.play();
  //                 // }
  //                 console.log("DONE");
  //               } else
  //               {
  //                 setIsAudio(true);
  //                 setavatarVideoUrl(resultResponse["data"]["response"]);
  //               }

  //               // if (isAudio)
  //               // {
  //               //   playAudio();
  //               // }
  //               setCurrentMessage("");
  //             }, 1000);






  //           } catch (error)
  //           {
  //             // toast.error(error.message);
  //           }

  //           // setResult(resultResponse.data.generationUrls); // adjust this based on the API response
  //           // clearInterval(interval);

  //           // let allImages = [];
  //           // resultResponse.data.generationUrls.map((image) => {
  //           //     const newData = {
  //           //         imageUrl: image,
  //           //         isChecked: false,
  //           //     };
  //           //     allImages.push(newData);
  //           //     return allImages;
  //           // });

  //           // if (resultResponse == {}) {
  //           //   setShow(true);
  //           // }
  //         }
  //       } catch (error)
  //       {
  //         // clearInterval(interval);
  //         toast.error(error);
  //         setRunTimer((t) => false);
  //         setCountDown(0);
  //         setIsGenerating((t) => false);
  //         setHasGenerating(false);
  //         setIsTyping(false);
  //         setIsLoading(false);
  //       }
  //     }, 2000); // Adjust this interval based on your need. For this example, it's checking every 5 seconds.

  //     return () => clearInterval(interval);
  //   }
  // }, [queueId]);

  // useEffect(() => {
  //   if (queueIdOnPlay)
  //   {
  //     const interval = setInterval(async () => {
  //       try
  //       {
  //         console.log("Fetching User Position")
  //         const response = await axios.get(
  //           `https://2ook38ir4ea9ub-3000.proxy.runpod.net/queue_position/${queueIdOnPlay}`
  //         ); // adjust with your API base url
  //         const position = response.data.queue_position; // adjust this based on the API response
  //         console.log(response.data);
  //         setQueuePosition(position);

  //         const data = {};

  //         // if (response.data["response"] != null)
  //         // {
  //         //   setIsTyping(false);
  //         //   // console.log({ response212: response });
  //         //   setChatHistory([
  //         //     ...chatHistory,
  //         //     // { text: currentMessage, sender: "user", profile: '', },
  //         //     {
  //         //       text: response.data["response"],
  //         //       sender: "system",
  //         //       profile: activeImage, //
  //         //       audioUrl: "", //
  //         //       // videoUrl: '',
  //         //     },
  //         //   ]);
  //         // }

  //         if (position === null)
  //         {
  //           var resultResponse = "";
  //           try
  //           {
  //             resultResponse = await axios.get(
  //               `https://2ook38ir4ea9ub-3000.proxy.runpod.net/generated_imgs/${queueIdOnPlay}`
  //             ); // adjust with your API base url
  //             const checkResponseTime = resultResponse["data"]["response"]
  //               .split(".")
  //               .pop();
  //             console.log("RESULTS=");
  //             console.log(resultResponse["data"]);

  //             try
  //             {
  //               console.log(resultResponse['data']['error']);
  //               if (resultResponse['data']['error'] === 'yes')
  //               {
  //                 console.log("SHOWING POPUP NO FACE");
  //                 toast.error("Face not detected. Please try again with another image. Face must be clearly visible.");
  //               }

  //             } catch (e)
  //             {
  //               console.log(e);
  //             }

  //             // setGeneratedImages(allImages);
  //             // setSelectedImage([]);

  //             setRunTimer((t) => false);

  //             setCountDown(100);
  //             setTimeout(() => {
  //               setIsGenerating((t) => false);
  //               setHasGenerating(true);
  //               setQueueIdOnPlay(null);
  //               setIsLoading(false);
  //               // setIsTyping(false);
  //               // setChatHistory()

  //               // if (checkResponseTime === "mp4")
  //               // {
  //               setavatarVideoUrl(resultResponse["data"]["response"]);
  //               setIsVideo(true);
  //               // console.log("VIDVID");
  //               // if (videoRef.current)
  //               // {
  //               // console.log("READY");
  //               // toast.error('video is ready and is trying to start');
  //               // videoRef.current.play();
  //               // }
  //               console.log("DONE");
  //             }, 1000);

  //             // } else
  //             // {
  //             //   setIsAudio(true);
  //             // }

  //             // if (isAudio)
  //             // {
  //             //   playAudio();
  //             // }
  //             // if (videoRef.current)
  //             // {
  //             //   try
  //             //   {
  //             //     await videoRef.current.play();
  //             //   } catch (e)
  //             //   {
  //             //     console.error("Playback failed", e);
  //             //   }
  //             // }

  //             // setCurrentMessage("");
  //           } catch (error)
  //           {
  //             // toast.error(error.message);
  //           }

  //           // setResult(resultResponse.data.generationUrls); // adjust this based on the API response
  //           // clearInterval(interval);

  //           // let allImages = [];
  //           // resultResponse.data.generationUrls.map((image) => {
  //           //     const newData = {
  //           //         imageUrl: image,
  //           //         isChecked: false,
  //           //     };
  //           //     allImages.push(newData);
  //           //     return allImages;
  //           // });

  //           // if (resultResponse == {}) {
  //           //   setShow(true);
  //           // }
  //         }
  //       } catch (error)
  //       {
  //         // clearInterval(interval);
  //         toast.error(error);
  //         setRunTimer((t) => false);
  //         setCountDown(0);
  //         setIsGenerating((t) => false);
  //         setHasGenerating(false);
  //         setIsTyping(false);
  //         setIsLoading(false);
  //       }
  //     }, 1000); // Adjust this interval based on your need. For this example, it's checking every 5 seconds.

  //     return () => clearInterval(interval);
  //   }
  // }, [queueIdOnPlay]);


  // useEffect(() => {
  //   if (queueIdIntro)
  //   {
  //     const interval = setInterval(async () => {
  //       try
  //       {
  //         console.log("Fetching User Position Intro Chatbot")
  //         const response = await axios.get(
  //           `https://2ook38ir4ea9ub-3000.proxy.runpod.net/queue_position/${queueIdIntro}`
  //         ); // adjust with your API base url
  //         const position = response.data.queue_position; // adjust this based on the API response
  //         console.log(response.data);
  //         setQueuePosition(position);

  //         const data = {};

  //         // if (response.data["response"] != null)
  //         // {
  //         //   // setIsTyping(false);
  //         //   // console.log({ response212: response });
  //         //   setChatHistory([
  //         //     ...chatHistory,
  //         //     // { text: currentMessage, sender: "user", profile: '', },
  //         //     {
  //         //       text: response.data["response"],
  //         //       sender: "system",
  //         //       profile: activeImage, //
  //         //       audioUrl: "", //
  //         //       // videoUrl: '',
  //         //     },
  //         //   ]);
  //         // }

  //         if (position === null)
  //         {
  //           var resultResponse = "";
  //           try
  //           {
  //             resultResponse = await axios.get(
  //               `https://2ook38ir4ea9ub-3000.proxy.runpod.net/generated_imgs/${queueIdIntro}`
  //             ); // adjust with your API base url
  //             const checkResponseTime = resultResponse["data"]["response"]
  //               .split(".")
  //               .pop();
  //             console.log("RESULTS=");
  //             console.log(resultResponse["data"]);

  //             try
  //             {
  //               console.log(resultResponse['data']['error']);
  //               if (resultResponse['data']['error'] === 'yes')
  //               {
  //                 console.log("SHOWING POPUP NO FACE");
  //                 toast.error("Face not detected. Please try again with another image. Face must be clearly visible.");
  //               }

  //             } catch (e)
  //             {
  //               console.log(e);
  //             }

  //             // setGeneratedImages(allImages);
  //             // setSelectedImage([]);

  //             setRunTimer((t) => false);

  //             setCountDown(100);
  //             // setIsGenerating((t) => false);
  //             // setHasGenerating(true);
  //             setQueueIdIntro(null);

  //             // setIsTyping(false);

  //             console.log('Uploading data...');

  //             const querySnapshot = await getDocs(collection(db, "ChatBots"));
  //             setTotalDocs(querySnapshot.size);
  //             console.log(querySnapshot.size);

  //             try
  //             {
  //               const docRef = doc(db, 'ChatBots', (name).replace(/ /g, "-"));
  //               await setDoc(docRef, {
  //                 'likes': 0,
  //                 'date_created': new Date(),
  //                 'created_by': (userData?.name) ?? 'Unknown',
  //                 'description': description,
  //                 'expression_scale': headMovement,
  //                 'id': (name).replace(/ /g, "-"),
  //                 'inputImage': activeImage,
  //                 'inputPrompt': inputPrompt,
  //                 'language': selectedOption,
  //                 'name': name,
  //                 'pitchScale': pitchScale,
  //                 'rollScale': rollScale,
  //                 'yawScale': yawScale,
  //                 'tokenized': false,
  //                 'voice': selectedValue,
  //                 'numChatbot': querySnapshot.size,
  //                 'generated_intro_vid': resultResponse["data"]["response"]
  //               });



  //               console.log("Document written with ID: ", docRef.id);
  //               setIsUploading(false);
  //               setIsIntroLoading(false);
  //               navigate('/Chatbots');
  //               // window.open('https://www.orbofi.com/Chatbots/' );
  //               // incrementNumFactory();
  //             }
  //             catch (error)
  //             {
  //               console.error("Error adding document: ", error);
  //             }

  //             // setChatHistory()


  //           } catch (error)
  //           {
  //             // toast.error(error.message);
  //           }

  //           // setResult(resultResponse.data.generationUrls); // adjust this based on the API response
  //           // clearInterval(interval);

  //           // let allImages = [];
  //           // resultResponse.data.generationUrls.map((image) => {
  //           //     const newData = {
  //           //         imageUrl: image,
  //           //         isChecked: false,
  //           //     };
  //           //     allImages.push(newData);
  //           //     return allImages;
  //           // });

  //           // if (resultResponse == {}) {
  //           //   setShow(true);
  //           // }
  //         }
  //       } catch (error)
  //       {
  //         // clearInterval(interval);
  //         toast.error(error);
  //         setRunTimer((t) => false);
  //         setCountDown(0);
  //         setIsGenerating((t) => false);
  //         setHasGenerating(false);
  //         setIsTyping(false);
  //         setIsLoading(false);
  //       }
  //     }, 2000); // Adjust this interval based on your need. For this example, it's checking every 5 seconds.

  //     return () => clearInterval(interval);
  //   }
  // }, [queueIdIntro]);

  const postData = async (url = '', data = {}) => {
    let now = new Date();
    // setIsTyping(true);
    // setIsGenerating((t) => true);
    // setIsLoading(true);

    // setRunTimer((t) => true);

    await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        data,
        // add all other data here
      }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        console.log('DATA');
        // setQueueId(data["queue_id"]);
      })
      .catch((error) => {
        toast.error(error.message);
        // toast.error("RRERERERER");
        // setRunTimer((t) => false);
        // setCountDown(0);
        // setIsGenerating((t) => false);
        // setHasGenerating(false);
        // setIsTyping(false);
        setIsLoading(false);
      });
  };

  const postDataIdle = async (url = '', data = {}) => {
    let now = new Date();
    // setIsTyping(true);
    // setIsGenerating((t) => true);
    // setIsLoading(true);

    // setRunTimer((t) => true);

    await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        data,
        // add all other data here
      }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then(async (data) => {

        if (data['text'] == 'No Face Found')
        {
          toast.error('No Face Detected');

          setVisible(false);
          setIsIntroLoading(false);
          setIsTimerActive(false);
          setShowFullPage(false);
          setRunTimer((t) => false);
          setIsGeneratingImage((t) => false);
          setIsPlayingImage(false);
          // setIsAvatarGenerated(false);
          setIsDannyTrejoPicture(false);


        } else
        {
          // console.log("DATA");
          console.log('DONE IDLE ANIMATION');
          console.log(data);

          setGeneratedHello(`https://xq3pl2oc187t2f-5000.proxy.runpod.net/hls/${data['text']}`);
          setUrl(`https://xq3pl2oc187t2f-5000.proxy.runpod.net/hls/${data['text']}`);
          setUrl2(`https://xq3pl2oc187t2f-5000.proxy.runpod.net/hls/${data['text2']}`);
          setUrl3(`https://xq3pl2oc187t2f-5000.proxy.runpod.net/hls/${data['generating']}`);
          setUrl4(`https://xq3pl2oc187t2f-5000.proxy.runpod.net/hls/${data['completed']}`);
          setCoverImage(data['cover_image']);

          // setShowFullPage(true);
          // setIsIntroLoading(false);

          // setIsTimerActive(!isTimerActive);


          try
          {
            const colRef = collection(db, 'ChatBots'); // Replace 'models' with your actual collection name
            const chatbotDocRef = doc(colRef, name.replaceAll(' ', '')); // Use the custom ID

            // setCountDown(100);
            // // setIsGenerating(false);
            // // setIsGeneratingImage((t) => false);
            // setRunTimer((t) => false);
            // setIsPlayingImage(true);
            // setHasGenerating(true);
            // setIsVideo(true);

            await setDoc(chatbotDocRef, { 'created_by': userData?.Email?.toString() ?? 'Unknown', 'date_created': Date.now(), 'description': '', 'id': name.replaceAll(' ', ''), 'name': name, 'inputImage': data['active_image'], 'inputPrompt': inputPrompt, 'likes': 0, 'generated_intro_vid': `https://xq3pl2oc187t2f-5000.proxy.runpod.net/hls/${data['text2']}`, 'generated_hello_video': `https://xq3pl2oc187t2f-5000.proxy.runpod.net/hls/${data['text']}`, 'language': chosenLanguage, 'voice': selectedVoiceForSelectBox, 'cover_image': data['cover_image'], 'generated_image': `https://xq3pl2oc187t2f-5000.proxy.runpod.net/hls/${data['generating']}`, 'generated_image_finish': `https://xq3pl2oc187t2f-5000.proxy.runpod.net/hls/${data['completed']}`, 'webp_cover_image': data['webp_cover_image'] });
            console.log('Document added to Chatbots collection.');
            // toast.success(`Chatbot Uploaded Successfully! ${userData?.Email?.toString()}`);
            // setShow2(false);
            // navigate(`/Chatbot/${name.replaceAll(' ', '')}`);

            // setChatbotData({
            //   name: '',
            //   description: '',
            // });
          } catch (error)
          {
            console.error('Error adding document: ', error);
          }

          try
          {
            const colRef = collection(db, 'Orbox', userData?.Email?.toString() ?? 'Unknown', 'chatbots');
            // const colRef = collection(db, "Orbox/" + userData?.Email?.toString() ?? "Unknown" + "/chatbots");
            const chatbotDocRef2 = doc(colRef, name.replaceAll(' ', '')); // Use the custom ID

            setCountDown(100);
            // setIsGenerating(false);
            // setIsGeneratingImage((t) => false);
            setRunTimer((t) => false);
            // setIsPlayingImage(true);
            // setHasGenerating(true);
            // setIsVideo(true);

            await setDoc(chatbotDocRef2, { 'created_by': userData?.Email?.toString() ?? 'Unknown', 'date_created': Date.now(), 'description': '', 'id': name.replaceAll(' ', ''), 'name': name, 'inputImage': activeImage, 'inputPrompt': inputPrompt, 'likes': 0, 'generated_intro_vid': `https://kn1b72ftyk6i6d-5000.proxy.runpod.net/hls/${data['text2']}`, 'generated_hello_video': `https://kn1b72ftyk6i6d-5000.proxy.runpod.net/hls/${data['text']}`, 'language': chosenLanguage, 'voice': selectedVoiceForSelectBox, 'cover_image': data['cover_image'], 'generated_image': `https://kn1b72ftyk6i6d-5000.proxy.runpod.net/hls/${data['generating']}`, 'generated_image_finish': `https://kn1b72ftyk6i6d-5000.proxy.runpod.net/hls/${data['completed']}`, 'webp_cover_image': data['webp_cover_image'] });
            console.log('Document added to Private Chatbots collection.');
            toast.success('Private Chatbot Uploaded Successfully!');
            // setShow2(false);
            navigate(`/Chatbot/${name.replaceAll(' ', '')}`);

            // setChatbotData({
            //   name: '',
            //   description: '',
            // });
          } catch (error)
          {
            setCountDown(100);
            setRunTimer((t) => false);
            navigate(`/Chatbot/${name.replaceAll(' ', '')}`);
            console.error('Error adding document: ', error);
          }



        }

        // setU
        // return data;
        // setQueueId(data["queue_id"]);
      })
      .catch((error) => {
        toast.error('No Face Detected');
        // setRunTimer((t) => false);
        // setCountDown(0);
        // setIsGenerating((t) => false);
        // setHasGenerating(false);
        // setIsTyping(false);

        setVisible(false);
        setIsIntroLoading(false);
        setIsTimerActive(false);
        setShowFullPage(false);
        setRunTimer((t) => false);
        setIsGeneratingImage((t) => false);
        setIsPlayingImage(false);
        // setIsAvatarGenerated(false);
        setIsDannyTrejoPicture(false);
        setIsLoading(false);
      });
  };


  const postDataOnPlay = async (url = '', data = {}) => {
    let now = new Date();
    // setIsTyping(true);
    setIsGenerating((t) => true);
    setIsLoading(true);

    setRunTimer((t) => true);

    await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        data,
        // add all other data here
      }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        setQueueIdOnPlay(data['queue_id']);
      })
      .catch((error) => {
        toast.error(error.message);
        setRunTimer((t) => false);
        setCountDown(0);
        setIsGenerating((t) => false);
        setHasGenerating(false);
        setIsTyping(false);
        setIsLoading(false);
      });
  };

  const postDataIntro = async (url = '', data = {}) => {
    let now = new Date();
    // setIsTyping(true);
    // setIsGenerating((t) => true);
    setIsIntroLoading(true);

    setRunTimer((t) => true);

    await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        data,
        // add all other data here
      }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        setQueueIdIntro(data['queue_id']);
      })
      .catch((error) => {
        toast.error(error.message);
        setRunTimer((t) => false);
        setCountDown(0);
        setIsGenerating((t) => false);
        setHasGenerating(false);
        setIsTyping(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (videoRef.current)
    {
      videoRef.current.load();
    }
  }, [avatarVideoUrl]);

  const onSendDirectMessage = (message) => {
    setCurrentMessage(message);
    // sendMessageHead();
  };

  function containsImageRequest(text) {
    var sentences = text.toLowerCase().split(/[.!?]+/); // Split text into sentences
    return sentences.some(sentence => {
      return (sentence.includes('create') && sentence.includes('image')) ||
        (sentence.includes('generate') && sentence.includes('image')) ||
        (sentence.includes('make') && sentence.includes('it')) ||
        (sentence.includes('make') && sentence.includes('image')) ||
        (sentence.includes('make') && sentence.includes('them')) ||
        (sentence.includes('make') && sentence.includes('him')) ||
        (sentence.includes('make') && sentence.includes('her'));
    });
  }

  function extractListItems(text) {
    // Regular expression to match the numbered items
    const regex = /\d\.\s([^]+?)(?=(?:\d\.|$))/g;

    // Extract the matches
    const matches = text.match(regex);

    // Check if there are matches
    if (!matches)
    {
      return []; // Return an empty array if no matches are found
    }

    // Process the matches to clean them up
    return matches.map(item => item.trim().replace(/^\d\.\s/, ''));
  }



  const streamTextGeneration = async () => {
    if (currentMessage.length < 1)
    {
      toast.error('Make sure input text is greater than 1 character', {
        icon: '👏',
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          position: 'bottom-center',
          // index: '1'
        },
      });
      return;

    }

    numSentence = 0;
    setIsTyping(true);
    setIsLoading(true);
    setIsProgressing(true);
    setIsGenerating((t) => true);
    setCurrentMessage('');
    setSelectedFiles([]);
    setImagePreviews([]);
    console.log('STREAMING');
    setTimerRunning(true);
    scrollToBottom();

    if (containsImageRequest(currentMessage))
    {
      console.log('User wants to Generate Image.');
      setselectedAvatarURL(activeImage);

      setResultt('');

      // if (currentMessage.trim() !== "")
      // {

      const newChatHistory = [
        ...chatHistory,
        {
          text: currentMessage,
          sender: 'user',
          profile: userData?.profile_picture ?? '/images/profile.png',
          audioUrl: '',
          // videoUrl: '',
        },
        // { text: '', sender: "bot", profile: avatarImageUrlSelected },
      ];
      setChatHistory(newChatHistory);

      console.log(convertMessagesForImageGeneration(newChatHistory));
      let url = 'https://api.openai.com/v1/chat/completions';
      let tmp = {
        model: 'gpt-4',
        messages: convertMessagesForImageGeneration(newChatHistory),
        temperature: 0.75,
        top_p: 0.95,
        // max_tokens: 100,
        stream: true,
        // n: 1,
      };

      let source = new SSE(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer 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',
        },
        method: 'POST',
        payload: JSON.stringify(tmp),
      });
      source.addEventListener('message', (e) => {
        setIsRunning(true);
        scrollToBottom();


        if (e.data != '[DONE]')
        {

          setIsTyping(false);
          // setIsTyping(false);
          let payload = JSON.parse(e.data);
          // console.log(payload['choices']);
          let text = payload.choices[0].delta['content'];
          if (text != null)
          {
            // console.log("Text: " + text);
            resultRef.current = resultRef.current + text;
            setResultt(resultRef.current);


          }

        } else
        {
          // setResultt(resultRef.current);
          source.close();
        }

      });

      source.addEventListener('readystatechange', async (e) => {
        if (e.readyState >= 2)
        {
          console.log('COMPLETED');
          // setIsTyping(false);
          isResponseWaiting = true;

          setIsRunning(false);

          setChatHistory([
            ...newChatHistory,
            {
              text: resultRef.current,
              sender: 'system',
              profile: activeImage, //
              audioUrl: '', //
              images: [],
            },
          ]);

          var prompts = extractListItems(resultRef.current);
          var images = [];
          console.log('Generating Images...');
          console.log(prompts);
          setUrl(url3);
          Promise.all(prompts.map((prompt, i) => {
            const postData = {
              input: {
                prompt: prompt.replaceAll('<code>', '').replaceAll('</code>', ''),
                num_inference_steps: 25,
                refiner_inference_steps: 50,
                width: 1024,
                height: 1024,
                guidance_scale: 10.5,
                seed: null,
                num_images: 1
              },

            };

            return axios.post('https://api.runpod.ai/v2/sdxl/runsync', postData, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': '5TRN2R2RCTJJTADT98ZRYSJDX4YGPVNKQVWVWX91'
              }
            })

              .then(response => {
                console.log(response.data);
                // console.log(response.data.data[0].url);
                // setGeneratedImageUrlsChatbot(prevUrls => [...prevUrls, response.data.data[0].url]);

                // images.push(response.data.data[0].url);
                images.push(response.data['output']['image_url']);
                console.log('GENERATED IMAGES');
                console.log(response.data['output']['image_url']);
                setImagesStatus(prevStatus => {
                  const newStatus = [...prevStatus];
                  newStatus[i] = 'loaded';
                  return newStatus;
                });

              }).catch(error => {
                console.error('Error generating image for prompt:', prompt, error);
                // toast.error("Error, please try again.");
                // setIsRunning(false);
                // setIsProgressing(false);
                setIsLoading(false);
              });
          })).then(() => {

            setChatHistory([
              ...newChatHistory,
              {
                text: resultRef.current,
                sender: 'system',
                profile: activeImage, //
                audioUrl: '', //
                images: images,
              },
            ]);


            // setTimesGenerated(timesGenerated + 4);
            console.log('IMAGE STATUSES');
            console.log(imagesStatus);
            // setNumImagesCreated(numImagesCreated + 4);
            // console.log("NUM IMAGES CREATED");
            // console.log(numImagesCreated);

            // setResultingData(prevUrls => [...prevUrls, [images]]);
            // console.log("RESULTING");
            // console.log(resultingData);
            setIsRunning(false);
            setIsProgressing(false);
            setIsLoading(false);
            // if (setisCreateImageRec)
            // {
            setUrl(url4);
            // }

          });




        }
      });
      source.stream();
    }
    else
    {

      setselectedAvatarURL(activeImage);

      setResultt('');

      // if (currentMessage.trim() !== "")
      // {

      const newChatHistory = [
        ...chatHistory,
        {
          text: currentMessage,
          sender: 'user',
          profile: userData?.profile_picture ?? '/images/profile.png',
          audioUrl: '',
          // videoUrl: '',
        },
        // { text: '', sender: "bot", profile: avatarImageUrlSelected },
      ];
      setChatHistory(newChatHistory);

      console.log(convertMessages(newChatHistory));
      let url = 'https://api.openai.com/v1/chat/completions';
      let tmp = {
        model: 'gpt-4',
        messages: convertMessages(newChatHistory),
        temperature: 0.75,
        top_p: 0.95,
        // max_tokens: 100,
        stream: true,
        // n: 1,
      };


      let source = new SSE(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer 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',
        },
        method: 'POST',
        payload: JSON.stringify(tmp),
      });

      // setTimeout(() => {
      //   // Code block to execute after 1.5 seconds
      //   console.log('This will run after 1.5 seconds');
      //   setIsTyping(false);
      //   setResultt(resultRef.current);
      // }, 1500);

      // let receivingMessage = false;



      // var t = ""
      if (faceOrAudioToggle)
      {
        source.addEventListener('message', (e) => {
          setIsRunning(true);
          scrollToBottom();


          if (e.data != '[DONE]')
          {

            setIsTyping(false);
            // setIsTyping(false);
            let payload = JSON.parse(e.data);
            // console.log(payload['choices']);
            let text = payload.choices[0].delta['content'];
            if (text != null)
            {
              // console.log("Text: " + text);
              resultRef.current = resultRef.current + text;
              setResultt(resultRef.current);

              var textTosay = '';
              textTosay = replaceHtmlCharsWithDot(resultRef.current);
              textTosay = replaceSpecialCharsWithDot(textTosay);
              // textTosay = splitEveryFourWords(textTosay);
              console.log('TEXT 2 SAY');
              console.log(textTosay);
              if (textTosay.endsWith('.'))
              {
                let sentences = textTosay.split('.');
                console.log('SPLIT SENTENCES ARE: ');
                sentences = sentences.filter(element => element !== '');

                console.log(sentences);
                let lastSentence = sentences[sentences.length - 1];

                console.log('LAST SENTENCE TO GENERATE IS: ');
                console.log(lastSentence);

                if (lastSentence.length > 5)
                {
                  console.log(`SENDING SENTENCE: ${lastSentence}`);
                  postData(
                    'https://xq3pl2oc187t2f-6000.proxy.runpod.net/talking_voice_clone',
                    {
                      img_url: activeImage,
                      prompt: currentMessage,
                      is_audio: false,
                      dc: convertMessages(chatHistory),
                      voice: selectedValue,
                      language: chosenLanguage,
                      yawScale: yawScale,
                      headMovement: headMovement,
                      pitchScale: pitchScale,
                      rollScale: rollScale,
                      inputPrompt: inputPrompt,
                      superenhance: superenhance,
                      answer: lastSentence,
                      sessionuid: sessionuid,
                      numSentence: numSentence
                    }
                  );
                  if (numSentence == 0)
                  {
                    setisOutputted(false);
                  }
                  numSentence++;
                  console.log('Increasing Number of Sentences...');
                }
                // Make a non-blocking API call



              }

            }

          } else
          {
            // setResultt(resultRef.current);
            source.close();
          }

        });

        source.addEventListener('readystatechange', (e) => {
          if (e.readyState >= 2)
          {
            console.log('COMPLETED');
            // setIsTyping(false);
            isResponseWaiting = true;

            setIsRunning(false);

            setChatHistory([
              ...newChatHistory,
              {
                text: resultRef.current,
                sender: 'system',
                profile: activeImage, //
                audioUrl: '', //
              },
            ]);

          }
        });
        source.stream();
      }
      else
      {
        console.log('Audio Generation');
        source.addEventListener('message', (e) => {
          setIsRunning(true);
          setIsTyping(false);
          scrollToBottom();
          if (e.data != '[DONE]')
          {
            setIsTyping(false);
            // setIsTyping(false);
            let payload = JSON.parse(e.data);
            // console.log(payload['choices']);
            let text = payload.choices[0].delta['content'];
            if (text != null)
            {
              // console.log("Text: " + text);
              resultRef.current = resultRef.current + text;
              console.log(resultRef.current);
              setResultt(resultRef.current);

              var textTosay = '';
              textTosay = replaceHtmlCharsWithDot(resultRef.current);
              textTosay = replaceSpecialCharsWithDot(textTosay);
              // textTosay = splitEveryFourWords(textTosay);
              console.log('TEXT 2 SAY');
              console.log(textTosay);
              // if (textTosay.endsWith('.'))
              // {
              //   let sentences = textTosay.split('.');
              //   console.log("SPLIT SENTENCES ARE: ")
              //   console.log(sentences);
              //   let lastSentence = sentences[sentences.length - 2];

              //   console.log("LAST SENTENCE TO GENERATE IS: ")
              //   console.log(lastSentence);

              //   if (lastSentence.length > 8)
              //   {

              //     postData(
              //       "https://lzc6smy1cj2us5-6000.proxy.runpod.net/talking_voice_clone",
              //       {
              //         img_url: activeImage,
              //         prompt: currentMessage,
              //         is_audio: false,
              //         dc: convertMessages(chatHistory),
              //         voice: selectedValue,
              //         language: chosenLanguage,
              //         yawScale: yawScale,
              //         headMovement: headMovement,
              //         pitchScale: pitchScale,
              //         rollScale: rollScale,
              //         inputPrompt: inputPrompt,
              //         superenhance: superenhance,
              //         answer: lastSentence,
              //         sessionuid: sessionuid,
              //         numSentence: numSentence
              //       }
              //     );
              //     numSentence++;
              //     console.log("Increasing Number of Sentences...");
              //   }
              //   // Make a non-blocking API call



              // }

            }

          } else
          {
            // setResultt(resultRef.current);
            source.close();
          }
        });

        var ran = false;

        source.addEventListener('readystatechange', async (e) => {
          if (e.readyState >= 2 && ran == false)
          {
            ran = true;
            console.log('COMPLETED');

            // setIsTyping(false);
            isResponseWaiting = true;



            // const audio_url = postDataAudio(
            //   "https://lzc6smy1cj2us5-6000.proxy.runpod.net/audio_generation",
            //   {
            //     prompt: currentMessage,
            //     is_audio: false,
            //     dc: convertMessages(chatHistory),
            //     voice: selectedValue,
            //     language: chosenLanguage,
            //     yawScale: yawScale,
            //     headMovement: headMovement,
            //     pitchScale: pitchScale,
            //     rollScale: rollScale,
            //     inputPrompt: inputPrompt,
            //     superenhance: superenhance,
            //     answer: resultRef.current,
            //     sessionuid: sessionuid,
            //   }
            // );


            var input_data = {
              prompt: replaceSpecialCharsWithDot(currentMessage),
              is_audio: false,
              dc: convertMessages(chatHistory),
              voice: selectedValue,
              language: chosenLanguage,
              yawScale: yawScale,
              headMovement: headMovement,
              pitchScale: pitchScale,
              rollScale: rollScale,
              inputPrompt: inputPrompt,
              superenhance: superenhance,
              answer: resultRef.current,
              sessionuid: sessionuid,
            };
            await fetch('https://xq3pl2oc187t2f-6000.proxy.runpod.net/audio_generation', {
              method: 'POST',
              body: JSON.stringify(input_data // add all other data here
              ),
              headers: {
                'content-type': 'application/json',
              },
            })
              .then((response) => response.json())
              .then(async (data) => {
                console.log(data);
                setIsRunning(false);
                setIsProgressing(false);

                setChatHistory([
                  ...newChatHistory,
                  {
                    text: resultRef.current,
                    sender: 'system',
                    profile: activeImage, //
                    audioUrl: data['audio'], //
                  },
                ]);
                setIsLoading(false);
                // return data;

                // setQueueId(data["queue_id"]);
              })
              .catch((error) => {
                toast.error(error.message);
                // toast.error("RRERERERER");
                // setRunTimer((t) => false);
                // setCountDown(0);
                // setIsGenerating((t) => false);
                // setHasGenerating(false);
                setIsTyping(false);
                setIsLoading(false);
              });




          }
        });
        source.stream();
        // console.log('test')
      }

    }






  };


  const sendMessageHead = async () => {
    setIsAudio(false);





    // const botResponse = ChatBotBot.getResponse(currentMessage);
    var data = await postData(
      'https://xq3pl2oc187t2f-3000.proxy.runpod.net/queue',
      {
        img_url: activeImage,
        prompt: currentMessage,
        is_audio: false,
        dc: convertMessages(chatHistory),
        voice: selectedValue,
        language: chosenLanguage,
        yawScale: yawScale,
        headMovement: headMovement,
        pitchScale: pitchScale,
        rollScale: rollScale,
        inputPrompt: inputPrompt,
        superenhance: superenhance,
        answer: resultRef.current
      }
    );
    // setavatarVideoUrl("");
    // setavatarVideoUrl(data['response']);

    // setChatHistory([
    //     ...chatHistory,
    //     { text: currentMessage, sender: "user" },
    //     { text: data['text'], sender: "bot" },
    // ]);

    // setCurrentMessage("");

  };

  const sendMessageHeadIntro = async () => {
    setIsAudio(false);

    // if (superenhance)
    // {
    //   setTimerRate(3000);
    // } else
    // {
    //   setTimerRate(800);
    // }

    let intervalId;

    intervalId = setInterval(() => {
      setCountDown2(prevCount => Math.min(prevCount + 2, 100));  // Increment by 2
    }, 100);

    setVisible(true);

    setIsPlaying(false);
    setIsIntroLoading(true);
    setCountDown(0);

    const timer3 = setTimeout(() => {
      setVisible(false);
      clearInterval(intervalId);
      setShowFullPage(true);
    }, 3000);  // Set a timer to hide overlay after 2 seconds

    // return () => clearTimeout(timer3);  // Clear timer on component unmount

    // if (currentMessage.length < 2)
    // {
    //   // setError(error.message);
    //   // handleShow();
    //   setRunTimer((t) => false);
    //   setCountDown(0);
    //   setIsGenerating((t) => false);
    //   setHasGenerating(false);
    //   toast.error("Make sure input text is greater than 3 characters", {
    //     icon: "👏",
    //     style: {
    //       borderRadius: "10px",
    //       background: "#333",
    //       color: "#fff",
    //       position: "bottom-center",
    //       // index: '1'
    //     },
    //   });
    //   return;
    //   // setError("Make sure input text is greater than 3 characters");

    //   // handleShow();
    // }
    // audioRef.current.pause();

    if (
      activeImage ===
      'https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/chat-default-image.png?alt=media&token=936b0f65-f711-4153-a1d9-0f1250ad1411'
    )
    {
      setRunTimer((t) => false);
      setCountDown(0);
      setIsGenerating((t) => false);
      setHasGenerating(false);
      toast.error('Please Generate an Image', {
        icon: '👏',
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
      return;
    }

    // if (avatarImageUrlSelected == "") {
    setselectedAvatarURL(activeImage);
    // } else {
    // setselectedAvatarURL(avatarImageUrlSelected);
    // }


    // setChatHistory([]);
    const introChatHistory = [];

    // const newChatHistory = [
    //   ...chatHistory,
    //   {
    //     text: currentMessage,
    //     sender: "user",
    //     profile: userData?.profile_picture ?? "/images/profile.png",
    //     audioUrl: "",
    //     // videoUrl: '',
    //   },
    //   // { text: '', sender: "bot", profile: avatarImageUrlSelected },
    // ];
    setChatHistory([
      // ...introChatHistory,
      {
        text: 'say \'Hello how are you\'',
        sender: 'user',
        profile: userData?.profile_picture ?? '/images/profile.png',
        audioUrl: '',
        // videoUrl: '',
      },
      // { text: '', sender: "bot", profile: avatarImageUrlSelected },
    ]);

    console.log(convertMessages(chatHistory));

    // const botResponse = ChatBotBot.getResponse(currentMessage);
    var data = await postDataIntro(
      'https://xq3pl2oc187t2f-3000.proxy.runpod.net/queue',
      {
        img_url: activeImage,
        prompt: currentMessage,
        is_audio: false,
        dc: convertMessages([{
          text: 'introduce yourself and say \'hello how are you?\'',
          sender: 'user',
          profile: userData?.profile_picture ?? '/images/profile.png',
          audioUrl: '',
          // videoUrl: '',
        }]),
        voice: selectedValue,
        language: chosenLanguage,
        yawScale: yawScale,
        headMovement: headMovement,
        pitchScale: pitchScale,
        rollScale: rollScale,
        inputPrompt: inputPrompt,
        superenhance: true
      }
    );
    // setavatarVideoUrl("");
    // setavatarVideoUrl(data['response']);

    // setChatHistory([
    //     ...chatHistory,
    //     { text: currentMessage, sender: "user" },
    //     { text: data['text'], sender: "bot" },
    // ]);

    // setCurrentMessage("");

  };

  const sendMessageHeadOnPlay = async () => {
    // setIsAudio(false);
    // setIsTyping(true);

    setVisible(true);
    setIsIntroLoading(true);
    setIsTimerActive(true);

    // if (superenhance)
    // {
    //   setTimerRate(2000);
    // } else
    // {
    //   setTimerRate(800);
    // }

    setTimerRate(GENERATION_TIME);
    setIsPlaying(false);
    setCountDown(0);

    // if (currentMessage.length < 2)
    // {
    //   // setError(error.message);
    //   // handleShow();
    //   setRunTimer((t) => false);
    //   setCountDown(0);
    //   setIsGenerating((t) => false);
    //   setHasGenerating(false);
    //   toast.error("Make sure input text is greater than 3 characters", {
    //     icon: "👏",
    //     style: {
    //       borderRadius: "10px",
    //       background: "#333",
    //       color: "#fff",
    //       position: "bottom-center",
    //       // index: '1'
    //     },
    //   });
    //   return;
    //   // setError("Make sure input text is greater than 3 characters");

    //   // handleShow();
    // }
    // audioRef.current.pause();

    if (
      activeImage ===
      'https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/chat-default-image.png?alt=media&token=936b0f65-f711-4153-a1d9-0f1250ad1411'
    )
    {
      setRunTimer((t) => false);
      setCountDown(0);
      setIsGenerating((t) => false);
      setHasGenerating(false);
      toast.error('Please Generate an Image', {
        icon: '👏',
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
      return;
    }

    // if (avatarImageUrlSelected == "") {
    setselectedAvatarURL(activeImage);
    // } else {
    // setselectedAvatarURL(avatarImageUrlSelected);
    // }


    // setChatHistory([]);
    const introChatHistory = [];
    setCountDown(0);

    setIsGeneratingImage((t) => true);

    // setHasGeneratingImage((t) => false);
    setRunTimer((t) => true);
    // const newChatHistory = [
    //   ...chatHistory,
    //   {
    //     text: currentMessage,
    //     sender: "user",
    //     profile: userData?.profile_picture ?? "/images/profile.png",
    //     audioUrl: "",
    //     // videoUrl: '',
    //   },
    //   // { text: '', sender: "bot", profile: avatarImageUrlSelected },
    // ];
    // setChatHistory([
    //   // ...introChatHistory,
    //   {
    //     text: "say 'Hello how are you'",
    //     sender: "user",
    //     profile: userData?.profile_picture ?? "/images/profile.png",
    //     audioUrl: "",
    //     // videoUrl: '',
    //   },
    //   // { text: '', sender: "bot", profile: avatarImageUrlSelected },
    // ]);

    // console.log(convertMessages(chatHistory));

    // const botResponse = ChatBotBot.getResponse(currentMessage);
    var data = await postDataIdle(
      'https://kn1b72ftyk6i6d-6000.proxy.runpod.net/create_idle',
      {
        img_url: activeImage,
        sessionuid: sessionuid,
        chatbot: name.replaceAll(' ', ''),
        inputPrompt: inputPrompt,
        language: selectedOption,
        voice: selectedVoiceForSelectBox
      }
    );

    // console.log("DONE IDLE ANIMATION");
    // console.log(data);

    // setavatarVideoUrl("");
    // setavatarVideoUrl(data['response']);

    // setChatHistory([
    //     ...chatHistory,
    //     { text: currentMessage, sender: "user" },
    //     { text: data['text'], sender: "bot" },
    // ]);

    // setCurrentMessage("");

  };


  const sendMessageAudio = async () => {


    setIsAudio(true);
    setIsPlaying(false);
    // audioRef.current.pause();



    // const botResponse = ChatBotBot.getResponse(currentMessage);
    var data = await postData(
      'https://xq3pl2oc187t2f-3000.proxy.runpod.net/queue',
      {
        img_url: selectedAvatarURL,
        prompt: currentMessage,
        is_audio: true,
        dc: convertMessages(chatHistory),
        voice: selectedValue,
        language: chosenLanguage,
        yawScale: yawScale,
        headMovement: headMovement,
        pitchScale: pitchScale,
        rollScale: rollScale,
        inputPrompt: inputPrompt,
        superenhance: superenhance,
        answer: resultRef.current
      }
    );


    // setavatarVideoUrl("");
    // setavatarVideoUrl(data['response']);

    // setChatHistory([
    //     ...chatHistory,
    //     { text: currentMessage, sender: "user" },
    //     { text: data['text'], sender: "bot" },
    // ]);

    // setCurrentMessage("");
  };

  // const avatarVideoUrl = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";

  const onSelectImage = (image) => {
    setActiveImage(image);
    // setAvatarImageUrl(image);
  };
  const retry = () => {
    setChatHistory([]);
    // Add your retry logic here
  };

  const onHandleChangeSwitch = (e) => {
    setFaceOrAudioToggle(e.target.checked);
  };

  const onSetImageForPlaying = () => {

    setShow3(true);


  };
  const handleTabChange = (selectedKey) => {
    console.log('Selected tab:', selectedKey);
    // You can add more logic here based on the selected tab
  };


  const aisettings = {
    infinite: true,
    // autoplay: true,
    autoplaySpeed: 2500,
    arrows: true,
    dots: false,
    slidesToShow: 6,
    margin: 10,
    centerPadding: 20,
    useTransform: true,
    scroll: 'smooth',
    responsive: [
      {
        breakpoint: 1430,
        settings: {
          slidesToShow: 4,
          // slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
          // slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          // slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
          // slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          // slidesToScroll: 1,
        },
      },
    ],
  };

  const onSelectFiles = (event) => {
    let images = [];

    for (let i = 0; i < event.target.files.length; i++)
    {
      images.push(URL.createObjectURL(event.target.files[i]));
    }

    setSelectedFiles([...selectedFiles, ...event.target.files]);
    setImagePreviews([...imagePreviews, ...images]);
  };

  const onRemoveSelectedImage = (index) => {
    let images = [...selectedFiles];
    let previews = [...imagePreviews];
    images.splice(index, 1);
    previews.splice(index, 1);
    setSelectedFiles(images);
    setImagePreviews(previews);
  };


  // const [selectedFiles, setSelectedFiles] = useState([]);
  // const [imagePreviews, setImagePreviews] = useState([]);
  const [activateScreenshot2Code, setScreenshot2Page] = useState(false);
  const [chatbotWorkAs, setChatbotWorkAs] = useState('chat');
  const [referenceImages, setReferenceImages] = useState([]);

  function fileToDataURL(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  const onActivatingScreenshot2code = (event) => {
    // handle the event here
    const allImagefiles = [...event.target.files];
    let images = [];

    for (let i = 0; i < allImagefiles.length; i++)
    {
      images.push(URL.createObjectURL(allImagefiles[i]));
    }

    setImagePreviews([...imagePreviews, ...images]);
    setSelectedFiles([...selectedFiles, ...event.target.files]);

    Promise.all(allImagefiles.map((file) => fileToDataURL(file)))
      .then((dataUrls) => {
        setReferenceImages(dataUrls.map((dataUrl) => dataUrl));
      })
      .catch((error) => {
        toast.error('Error reading files' + error);
        console.error('Error reading files:', error);
      });


    setScreenshot2Page(true);
  };
  const [settings, setSettings] = useState(
    {
      openAiApiKey: null,
      screenshotOneApiKey: null,
      isImageGenerationEnabled: true,
      editorTheme: 'espresso',
      isTermOfServiceAccepted: false,
    },
    'setting'
  );
  const [generatedCode, setGeneratedCode] = useState('');
  const [executionConsole, setExecutionConsole] = useState([]);
  const [appState, setAppState] = useState('INITIAL');
  const [codeGenerationHistory, setCodeGenerationHistory] = useState([]);
  const wsRef = useRef(null);

  function doGenerateCode(params) {
    setExecutionConsole([]);
    setCurrentMessage('');
    setImagePreviews([]);
    setAppState('CODING');

    // Merge settings with params
    const updatedParams = { ...params.params, ...settings };

    generateCode(
      wsRef,
      updatedParams,
      (token) => {
        // setGeneratedCode((prev) => prev + token)
        setIsRunning(true);
        setIsTyping(false);
        setResultt((prev) => prev + token);
      },
      (code) => {
        setIsRunning(false);
        setResultt('');
        setChatHistory([
          ...params.newChatHistory,
          {
            text: '',
            code: code,
            sender: 'system',
            profile: activeImage, //
            audioUrl: '', //
            // images: [],
          },
        ]);
        // setIsRunning(true);
        // setIsTyping(false);
        // setResultt(code)
      },
      (line) => setExecutionConsole((prev) => [...prev, line]),
      () => setAppState('CODE_READY')
    );
  }

  const makeRequestForDesign = () => {
    // Loop through the array in reverse order
    let filteredObj = chatHistory[chatHistory.length - 1]?.code ? chatHistory[chatHistory.length - 1] : {};
    let index = chatHistory[chatHistory.length - 1]?.code ? chatHistory.length - 1 : null;

    let updatedChatHistory = [...codeGenerationHistory];
    if (index)
    {
      updatedChatHistory.push(filteredObj?.code);
      updatedChatHistory.push(currentMessage);
    }
    setResultt('');
    if (Object.keys(filteredObj).length !== 0)
    {
      console.log({ updatedChatHistory });
      setCodeGenerationHistory([...updatedChatHistory]);
      const newChatHistory = [...chatHistory];
      newChatHistory[index - 1].text = currentMessage,
      newChatHistory.splice(index, 1);

      setChatHistory(newChatHistory);
      // if (imagePreviews.length > 0)
      // {
      doGenerateCode({
        params: {
          generationType: 'update',
          image: referenceImages[0],
          history: updatedChatHistory,
        },
        newChatHistory: newChatHistory,
      });
      // }
    } else
    {
      if (currentMessage !== '')
      {
        setCodeGenerationHistory([currentMessage]);
      }
      const newChatHistory = [
        ...chatHistory,
        {
          text: currentMessage,
          sender: 'user',
          profile: userData?.profile_picture ?? '/images/profile.png',
          audioUrl: '',
          screenShot: referenceImages[0],
        },
      ];

      // const newChatHistory = [...chatHistory];
      // newChatHistory[chatHistory.length - 1].text = currentMessage,
      setChatHistory(newChatHistory);
      if (imagePreviews.length > 0)
      {
        doGenerateCode({
          params: {
            generationType: 'create',
            image: referenceImages[0],
          },
          newChatHistory: newChatHistory,
        });
      }
    }
  };

  const onHandleChangeChatbotGeneration = (event) => {
    setChatbotWorkAs(event.target.value);
  };

  return (
    <div className="main-content App chat-app">
      <>
        {/* <Tabs
          defaultActiveKey="profile"
          id="justify-tab-example"
          className="mb-3"
          // justify
          onSelect={handleTabChange}
        >
          <Tab eventKey="home" title="Home">
            Tab content for Home
          </Tab>
          <Tab eventKey="profile" title="Profile">
            Tab content for Profile
          </Tab>
          <Tab eventKey="longer-tab" title="Loooonger Tab">
            Tab content for Loooonger Tab
          </Tab>
          <Tab eventKey="contact" title="Contact" disabled>
            Tab content for Contact
          </Tab>
        </Tabs> */}
        <div className="mobile-toggle-Switch toggle-Switch">
          <span className={faceOrAudioToggle && 'disable'}>
            <lord-icon
              src="https://cdn.lordicon.com/fdlndzao.json"
              trigger="loop"
              colors={isThemeDark ? 'outline:#121331,primary:#b26836,secondary:#fff,tertiary:#4bb3fd,quaternary:#f9c9c0' : 'outline:#121331,primary:#b26836,secondary:#000,tertiary:#4bb3fd,quaternary:#f9c9c0'}
            ></lord-icon>
            Face
          </span>
          <Form.Check
            type="switch"
            id="custom-switch"
            onChange={onHandleChangeSwitch}
          />
          <span className={!faceOrAudioToggle && 'disable'}>
            <lord-icon
              src="https://cdn.lordicon.com/euoeggon.json"
              trigger="loop"
              colors={isThemeDark ? 'outline:#fff' : 'outline:#000'}
            ></lord-icon>{' '}
            Audio
          </span>
        </div>
        <Row className={showFullPage ? 'chatbox-height justify-content-center' : 'createChatContent'}>
          <Col lg={7} style={{ 'display': showFullPage ? 'block' : 'none' }}>
            <div className="input-container-clippath desktop-version">
              <div className="input-container">
                <div className="chatheader">
                  <Button variant="" className="Advanced-main-wrap text-white" onClick={handleShow}>
                    <i>
                      <lord-icon src="https://cdn.lordicon.com/exlyjeeh.json" trigger="hover" colors="outline:#131431,primary:#36e899" ></lord-icon>
                    </i>
                    <span>Advanced Settings</span>
                  </Button>
                  {isCreating ? <Button variant="" className="Advanced-main-wrap text-white" onClick={handleShow2}>
                    <i><lord-icon src="https://cdn.lordicon.com/gdbnaaef.json" trigger="hover" colors="outline:#121331,primary:#ffffff,secondary:#36e899"></lord-icon></i>
                    <span>Upload AI Companion</span>
                  </Button> : (<div></div>)}


                  {/* {isCreating ? (<div className="Advanced-main-wrap" onClick={handleShow2} style={{ 'margin-left': '10px' }}>
                    <p style={{ 'font-size': '13px' }}>Upload Chatbot</p>
                  </div>) : (<div></div>)} */}




                  <div className="toggle-Switch">
                    <span className={faceOrAudioToggle && 'disable'}>
                      <lord-icon
                        src="https://cdn.lordicon.com/euoeggon.json"
                        trigger="loop"
                        colors={isThemeDark ? 'outline:#fff' : 'outline:#000'}
                      ></lord-icon>
                      Audio
                    </span>

                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      onChange={onHandleChangeSwitch}
                    />
                    <span className={faceOrAudioToggle && 'disable'}>
                      <lord-icon
                        src="https://cdn.lordicon.com/fdlndzao.json"
                        trigger="loop"
                        colors={isThemeDark ? 'outline:#121331,primary:#b26836,secondary:#fff,tertiary:#4bb3fd,quaternary:#f9c9c0' : 'outline:#121331,primary:#b26836,secondary:#000,tertiary:#4bb3fd,quaternary:#f9c9c0'}
                      ></lord-icon>
                      Face
                    </span>
                  </div>
                  {chatHistory.length > 0 &&
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          Reset your chat
                        </Tooltip>
                      }
                    >
                      <Button variant="" className="Chat-reset" onClick={retry}>
                        <Icon icon="icon-park-outline:undo" />
                      </Button>
                    </OverlayTrigger>

                  }
                </div>
                <div className="chatfield" ref={chatFieldRef}>


                  {chatHistory.map((msg, i) => (
                    <p
                      key={i}
                      className={`chat-messagebox ${msg.sender === 'system'
                        ? 'message-end'
                        : 'message-start'
                      }`}
                    >
                      <i>
                        <img style={{ width: '50px' }} src={msg.profile} />
                      </i>

                      {/* <LinearProgress /> */}


                      {msg.sender === 'system' ? (
                        <div className="msgbox">

                          {msg.audioUrl !== '' && (
                            <div className="audio-player">
                              {/* <audio ref={videoRef} controls>
                                <source src={msg.audioUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                              </audio> */}
                              <AudioPlayer autoPlay src={msg.audioUrl} />

                              <hr />
                            </div>
                          )}

                          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            {
                              msg.images && Array.isArray(msg.images) ?
                                (msg.images.length === 0 ?
                                  // Render a loading indicator when there are no images
                                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {renderLoadingIndicators()}
                                  </div>

                                  :
                                  // Map through images if there are any
                                  msg.images.map((status, index) => (
                                    <div key={index} onClick={() => openModal(msg.images[index])}>

                                      <img
                                        src={msg.images[index]}
                                        alt={`Generated ${index}`}
                                        style={{ width: '125px', height: '125px', margin: '5px', borderRadius: '5%', }}
                                      />

                                    </div>
                                  ))
                                ) : null // Render nothing if msg.images is not an array
                            }
                          </div>

                          <p>


                            {/* <WindupChildren> */}
                            {parse(msg.text)}


                            {/* <em>{"wild"}</em>
                            {"stuff with text!"} */}
                            {/* </WindupChildren> */}
                          </p>

                          {msg.code ? (
                            <div className="chat-messagebox message-end code-msg-box">
                              <Tab.Container id="left-tabs-example" defaultActiveKey="desktop">
                                <Nav variant="pills" className="custom-navs code-navs">
                                  <Nav.Item>
                                    <Nav.Link eventKey="desktop">Desktop</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="mobile">Mobile</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="code">Code</Nav.Link>
                                  </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                  <Tab.Pane eventKey="desktop"><Preview code={msg.code} device="desktop" /></Tab.Pane>
                                  <Tab.Pane eventKey="mobile"><div className="Mobile-ViewChatCode">
                                    <Preview code={msg.code} device="mobile" /></div></Tab.Pane>
                                  <Tab.Pane eventKey="code">
                                    <div className="relative">
                                      <CodeMirror
                                        code={msg.code}
                                        editorTheme={settings.editorTheme}
                                        onCodeChange={setGeneratedCode}
                                      />
                                      {/* <span
                                      title="Copy Code"
                                      className="flex items-center justify-center w-10 h-10 text-gray-500 hover:bg-gray-100 cursor-pointer rounded-lg text-sm p-2.5 absolute top-[20px] right-[20px]"
                                      onClick={doCopyCode}
                                    >
                                      <FaCopy />
                                    </span> */}
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </Tab.Container>
                            </div>
                          ) : ''}

                        </div>

                      ) : (
                        <div className="msgbox usermsgbox codeMsgbox">
                          <p dangerouslySetInnerHTML={{ __html: msg.text }} />
                          {msg.screenShot ? (
                            <div
                              className={cx('code-img', {
                                'scanning relative': i === chatHistory.length - 1 && appState === 'CODING',
                              })}
                            >
                              <img
                                src={msg.screenShot}
                                alt="Reference"
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      )}
                    </p>
                  ))}
                  {isRunning && !isTyping ? <>{chatbotWorkAs === 'app' ? (
                    <div className="chat-messagebox message-end code-msg-box">
                      <Tab.Container id="left-tabs-example" defaultActiveKey="desktop">
                        <Nav variant="pills" className="custom-navs code-navs">
                          <Nav.Item>
                            <Nav.Link eventKey="desktop">Desktop</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="mobile">Mobile</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="code">Code</Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="desktop"><Preview code={resultt} device="desktop" /></Tab.Pane>
                          <Tab.Pane eventKey="mobile"><div className="Mobile-ViewChatCode">
                            <Preview code={resultt} device="mobile" /></div></Tab.Pane>
                          <Tab.Pane eventKey="code">
                            <div className="relative">
                              <CodeMirror
                                code={resultt}
                                editorTheme={settings.editorTheme}
                                onCodeChange={setGeneratedCode}
                              />
                              {/* <span
                                title="Copy Code"
                                className="flex items-center justify-center w-10 h-10 text-gray-500 hover:bg-gray-100 cursor-pointer rounded-lg text-sm p-2.5 absolute top-[20px] right-[20px]"
                                onClick={doCopyCode}
                              >
                                <FaCopy />
                              </span> */}
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  ) : (<p className={'chat-messagebox message-end'}>
                    <i><img style={{ width: '50px' }} src={selectedAvatarURL}></img></i>
                    <div className='msgbox'>
                      {/* <Typewriter words={resultt.split(' ')}></Typewriter> */}
                      <p>{parse(resultt)}</p>

                    </div>
                  </p>)}</> : ''}


                  {isTyping ? (
                    <div style={{ 'text-align': 'right' }}>
                      <div style={{ display: 'inline-block' }}>
                        {' '}
                        <img
                          src={
                            'https://www.dropbox.com/s/syx8lrzq6juz0gu/Message-1s-38px.gif?dl=1'
                          }
                        ></img>
                      </div>
                      <i>
                        <img
                          src={selectedAvatarURL}
                          style={{
                            width: '50px',
                            'border-radius': '40px',
                            'margin-right': '16px',
                          }}
                        />
                      </i>{' '}
                    </div>
                  ) : null}
                </div>
                {chatHistory.length === 0 && <ul className="defalut-prompt">
                  <li>
                    <Button variant="" onClick={() => onSendDirectMessage('What are your top 5 facts for travelling space in a swimsuit?')}>
                      <h6>What are your top 5 facts <span>for travelling space in a swimsuit</span> </h6>
                      <i><Icon icon="ic:round-send" /></i>
                    </Button>
                  </li>
                  <li>
                    <Button variant="" onClick={() => onSendDirectMessage('Suggest some names for my cafe-by-day, bar-by-night business')}>
                      <h6>Suggest some names <span>for my cafe-by-day, bar-by-night business</span> </h6>
                      <i><Icon icon="ic:round-send" /></i>
                    </Button>
                  </li>
                  <li>
                    <Button variant="" onClick={() => onSendDirectMessage('Compare marketing strategies for sunglasses for Gen Z and Millennials')}>
                      <h6>Compare marketing strategies <span>for sunglasses for Gen Z and Millennials</span> </h6>
                      <i><Icon icon="ic:round-send" /></i>
                    </Button>
                  </li>
                  <li>
                    <Button variant="" onClick={() => onSendDirectMessage('Come up with concepts for a retro-style arcade game')}>
                      <h6>Come up with concepts <span>for a retro-style arcade game</span> </h6>
                      <i><Icon icon="ic:round-send" /></i>
                    </Button>
                  </li>
                </ul>}
                <div className="chat-input-box chatbox-mobile-wrap">
                  <div className="chat-input-boxmain">
                    <label htmlFor="fileUploadtext" className="fileUploadtext">
                      <input type="file" id="fileUploadtext" onChange={onActivatingScreenshot2code} onClick={(event) => {
                        event.target.value = null;
                      }} multiple />
                      <span><Icon icon="fluent:image-add-24-filled" /></span>
                    </label>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        id="dropdown-basic"
                        className="ChatCategoryDropDown"
                      >
                        <Icon icon="heroicons:adjustments-horizontal" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="ChatCategoryDropDownMain">
                        <div className="ChatCategoryDropDownWrap">
                          <label htmlFor="CcategoryOne" className="ChatCategoryItemList">
                            <input type="radio" id="CcategoryOne" name="image-generation" value='image' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === 'image'} />
                            <div className="ChatCategoryItem">
                              <i><Icon icon="fluent:draw-image-24-regular" /></i>
                              <p>  Image generation</p>
                            </div>
                          </label>
                          <label htmlFor="CcategoryTwo" className="ChatCategoryItemList">
                            <input type="radio" id="CcategoryTwo" name="image-generation" value='chat' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === 'chat'} />
                            <div className="ChatCategoryItem">
                              <i><Icon icon="fluent:chat-12-regular" /></i>
                              <p>Text and chatting</p>
                            </div>
                          </label>
                          <label htmlFor="CcategoryThree" className="ChatCategoryItemList">
                            <input type="radio" id="CcategoryThree" name="image-generation" value='app' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === 'app'} />
                            <div className="ChatCategoryItem">
                              <i><Icon icon="fluent:tetris-app-20-regular" /></i>
                              <p>Image to App</p>
                            </div>
                          </label>
                          <label htmlFor="CcategoryFour" className="ChatCategoryItemList ">
                            <input type="checkbox" id="CcategoryFour" name="image-generation" value='vision' onChange={handleModelChange} checked={modelsCheckbox.vision} />
                            <div className="ChatCategoryItem">
                              <i><Icon icon="tabler:device-vision-pro" /></i>
                              <p>Vision power</p>
                              <small>Pro</small>
                            </div>
                          </label>
                          <label htmlFor="CcategoryFive" className="ChatCategoryItemList ">
                            <input type="checkbox" id="CcategoryFive" name="image-generation" value='gpt' onChange={handleModelChange} checked={modelsCheckbox.gpt} />
                            <div className="ChatCategoryItem">
                              <i><Icon icon="arcticons:openai-chatgpt" /></i>
                              <p>GPT4</p>
                              <small>Pro</small>
                            </div>
                          </label>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="textareaDesgin">

                      <textarea
                        placeholder="Send your message"
                        // rows={4}
                        ref={textAreaRef}
                        // style={{height: textareaHeight > 100 ? 'auto' : `${ textareaHeight }px`}}
                        value={currentMessage}
                        onChange={(e) => onHandleCurrentMessageChange(e)}
                        disabled={isListening}
                        // onKeyDown={(e) => handleKeyUp(e)}
                        onKeyPress={(e) => {
                          // handleKeyUp(e);
                          if (!authToken) {
                            dispatch(showPopUpAction(true));
                            return;
                          }
                          if (chatbotWorkAs === 'app') {
                            if (e.key === 'Enter') {
                              makeRequestForDesign();
                            }
                          } else
                          {
                            if (activeImage !== 'https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/dark-img-upload.png?alt=media&token=474bcca1-fd06-44d8-8c2e-2898651dbb60&_gl=1*11hdrzi*_ga*NDI5MDg0OTczLjE2ODI0Njk1NzQ.*_ga_CW55HF8NVT*MTY5NjI1MjY2MS41LjEuMTY5NjI1MjY4Ni4zNS4wLjA' && activeImage !== 'https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/chat-default-image.png?alt=media&token=936b0f65-f711-4153-a1d9-0f1250ad1411')
                            {
                              if (e.key === 'Enter' && !faceOrAudioToggle)
                              {
                                e.preventDefault();
                                setIsTyping(true);
                                streamTextGeneration();
                              }
                              else if (e.key === 'Enter' && !faceOrAudioToggle == false)
                              {
                                e.preventDefault();
                                setIsTyping(true);
                                streamTextGeneration();
                              }
                            }
                          }

                        }}
                      />
                      <div className="textareaImgWrap">
                        {imagePreviews && imagePreviews.map((img, index) => (
                          <div className="textareaImg">
                            <Image src={img} key={index} alt="" />
                            <Button className="btn-close-img" variant="" type="button" onClick={() => onRemoveSelectedImage(index)}><Icon icon="ic:round-close" /></Button>
                          </div>
                        ))}
                      </div>
                    </div>
                    <Button variant="" className="record-btn" onClick={startListening}>{isListening ? <Icon icon="ic:round-pause" /> : <Icon icon="ic:outline-mic" />}</Button>
                  </div>
                  <Button variant="" className=" btn-mobile-reset" onClick={retry}>
                    <Icon icon="icon-park:redo" />
                  </Button>
                  {/* {!currentMessage ? (
                    <> */}

                  <Button
                    variant=""
                    className="chatbot-send-button btn-message"
                    onClick={() => {
                      if (!authToken) {
                        dispatch(showPopUpAction(true));
                        return;
                      }
                      if (chatbotWorkAs === 'app') {
                        makeRequestForDesign();
                      } else
                      {
                        setIsTyping(true);
                        streamTextGeneration();
                      }
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <lord-icon
                        src="https://cdn.lordicon.com/wfmfwkbb.json"
                        trigger="loop"
                        colors="primary:#eee"
                      ></lord-icon>
                    ) : (
                      <span>
                        <Icon icon="ic:round-send" />
                      </span>
                    )}
                  </Button>
                  {/* </>
                  ) :
                    !faceOrAudioToggle ? (
                      <Button
                        variant=""
                        type="submit"
                        className="chatbot-send-button btn-gradient btn-message"
                        onClick={() => { setIsTyping(true); streamTextGeneration(); }}
                        disabled={isLoading || activeImage === ""}
                      >
                        {isLoading ? (
                          <lord-icon
                            src="https://cdn.lordicon.com/wfmfwkbb.json"
                            trigger="loop"
                            colors="primary:#eee"
                          ></lord-icon>
                        ) : (
                          <span>
                            <Icon icon="ic:round-send" />
                          </span>
                        )}
                      </Button>
                    ) : (
                      <Button
                        variant=""
                        className="chatbot-send-button btn-gradient btn-message"
                        onClick={() => { setIsTyping(true); streamTextGeneration(); }}
                        disabled={isLoading || generatedImages.length === 0}
                      >
                        {isLoading ? (
                          <lord-icon
                            src="https://cdn.lordicon.com/wfmfwkbb.json"
                            trigger="loop"
                            colors="primary:#eee"
                          ></lord-icon>
                        ) : (
                          <span>
                            <Icon icon="ic:round-send" />
                          </span>
                        )}
                      </Button>
                    )} */}

                </div>
              </div>
            </div>

            <div className="input-container-clippath mobile-version">
              <div className="chatfield">


                {chatHistory.map((msg, i) => (
                  <p
                    key={i}
                    className={`chat-messagebox ${msg.sender === 'system'
                      ? 'message-end'
                      : 'message-start'
                    }`}
                  >
                    <i>
                      <img style={{ width: '50px' }} src={msg.profile} />
                    </i>
                    {msg.sender === 'system' ? (
                      <div className="msgbox">
                        {audioUrl !== '' && (
                          <div className="audio-player">
                            {/* <audio ref={videoRef} controls>
                                <source src={msg.audioUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                              </audio> */}
                            <AudioPlayer src={audioUrl} />
                            <hr />
                          </div>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                          {
                            msg.images && Array.isArray(msg.images) ?
                              (msg.images.length === 0 ?
                                // Render a loading indicator when there are no images
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                  {renderLoadingIndicators()}
                                </div>

                                :
                                // Map through images if there are any
                                msg.images.map((status, index) => (
                                  <div key={index} onClick={() => openModal(msg.images[index])}>

                                    <img
                                      src={msg.images[index]}
                                      alt={`Generated ${index}`}
                                      style={{ width: '125px', height: '125px', margin: '5px', borderRadius: '5%', }}
                                    />

                                  </div>
                                ))
                              ) : null // Render nothing if msg.images is not an array
                          }
                        </div>
                        <p>
                          {/* <WindupChildren> */}
                          {parse(msg.text)}

                          {/* <em>{"wild"}</em>
                            {"stuff with text!"} */}
                          {/* </WindupChildren> */}
                        </p>
                      </div>
                    ) : (
                      <div className="msgbox usermsgbox">
                        <p dangerouslySetInnerHTML={{ __html: msg.text }} />
                        {msg.screenShot ? (
                          <div
                            className={cx({
                              'scanning relative': i === chatHistory.length - 1 && appState === 'CODING',
                            })}
                          >
                            <img
                              src={msg.screenShot}
                              alt="Reference"
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                  </p>
                ))}
                {isRunning && !isTyping ? <>{chatbotWorkAs === 'app' ? (
                  <div className="chat-messagebox message-end code-msg-box">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="desktop">
                      <Nav variant="pills" className="custom-navs code-navs">
                        <Nav.Item>
                          <Nav.Link eventKey="desktop">Desktop</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="mobile">Mobile</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="code">Code</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="desktop"><Preview code={resultt} device="desktop" /></Tab.Pane>
                        <Tab.Pane eventKey="mobile"><div className="Mobile-ViewChatCode">
                          <Preview code={resultt} device="mobile" /></div></Tab.Pane>
                        <Tab.Pane eventKey="code">
                          <div className="relative">
                            <CodeMirror
                              code={resultt}
                              editorTheme={settings.editorTheme}
                              onCodeChange={setGeneratedCode}
                            />
                            {/* <span
                                title="Copy Code"
                                className="flex items-center justify-center w-10 h-10 text-gray-500 hover:bg-gray-100 cursor-pointer rounded-lg text-sm p-2.5 absolute top-[20px] right-[20px]"
                                onClick={doCopyCode}
                              >
                                <FaCopy />
                              </span> */}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                ) : (<p className={'chat-messagebox message-end'}>
                  <i><img style={{ width: '50px' }} src={selectedAvatarURL}></img></i>
                  <div className='msgbox'>
                    {/* <Typewriter words={resultt.split(' ')}></Typewriter> */}
                    <p>{parse(resultt)}</p>

                  </div>
                </p>)}</> : ''}


                {isTyping ? (
                  <div style={{ 'text-align': 'right' }}>
                    <div style={{ display: 'inline-block' }}>
                      {' '}
                      <img
                        src={
                          'https://www.dropbox.com/s/syx8lrzq6juz0gu/Message-1s-38px.gif?dl=1'
                        }
                      ></img>
                    </div>
                    <i>
                      <img
                        src={selectedAvatarURL}
                        style={{
                          width: '50px',
                          'border-radius': '40px',
                          'margin-right': '16px',
                        }}
                      />
                    </i>{' '}
                  </div>
                ) : null}
              </div>
              <div className="chat-input-box chatbox-mobile-wrap">
                <div className="mobile-flex-chatbox">
                  <label htmlFor="fileUploadtext" className="fileUploadtext">
                    <input type="file" id="fileUploadtext" onChange={onSelectFiles} multiple />
                    <span><Icon icon="fluent:image-add-24-filled" /></span>
                  </label>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      id="dropdown-basic"
                      className="ChatCategoryDropDown"
                    >
                      <Icon icon="heroicons:adjustments-horizontal" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="ChatCategoryDropDownMain">
                      <div className="ChatCategoryDropDownWrap">
                        <label htmlFor="CcategoryOne" className="ChatCategoryItemList">
                          <input type="radio" id="CcategoryOne" name="image-generation" value='image' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === 'image'} />
                          <div className="ChatCategoryItem">
                            <i><Icon icon="fluent:draw-image-24-regular" /></i>
                            <p>  Image generation</p>
                          </div>
                        </label>
                        <label htmlFor="CcategoryTwo" className="ChatCategoryItemList">
                          <input type="radio" id="CcategoryTwo" name="image-generation" value='chat' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === 'chat'} />
                          <div className="ChatCategoryItem">
                            <i><Icon icon="fluent:chat-12-regular" /></i>
                            <p>Text and chatting</p>
                          </div>
                        </label>
                        <label htmlFor="CcategoryThree" className="ChatCategoryItemList">
                          <input type="radio" id="CcategoryThree" name="image-generation" value='app' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === 'app'} />
                          <div className="ChatCategoryItem">
                            <i><Icon icon="fluent:tetris-app-20-regular" /></i>
                            <p>Image to App</p>
                          </div>
                        </label>
                        <label htmlFor="CcategoryFour" className="ChatCategoryItemList">
                          <input type="radio" id="CcategoryFour" name="image-generation" value='vision' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === 'vision'} />
                          <div className="ChatCategoryItem">
                            <i><Icon icon="tabler:device-vision-pro" /></i>
                            <p>Vision power</p>
                          </div>
                        </label>
                        <label htmlFor="CcategoryFive" className="ChatCategoryItemList">
                          <input type="radio" id="CcategoryFive" name="image-generation" value='gpt' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === 'gpt'} />
                          <div className="ChatCategoryItem">
                            <i><Icon icon="arcticons:openai-chatgpt" /></i>
                            <p>GPT4</p>
                          </div>
                        </label>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="textareaDesgin">

                    <textarea
                      placeholder="Send your message"
                      // rows={4}
                      ref={textAreaRefMobile}
                      // style={{height: textareaHeight > 100 ? 'auto' : `${ textareaHeight }px`}}
                      value={currentMessage}
                      onChange={(e) => onHandleCurrentMessageChange(e)}
                      disabled={isListening}
                      // onKeyDown={(e) => handleKeyUp(e)}
                      onKeyPress={(e) => {
                        // handleKeyUp(e);
                        if (!authToken) {
                          dispatch(showPopUpAction(true));
                          return;
                        }
                        if (chatbotWorkAs === 'app') {
                          if (e.key === 'Enter') {
                            makeRequestForDesign();
                          }
                        } else
                        {
                          if (activeImage !== 'https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/dark-img-upload.png?alt=media&token=474bcca1-fd06-44d8-8c2e-2898651dbb60&_gl=1*11hdrzi*_ga*NDI5MDg0OTczLjE2ODI0Njk1NzQ.*_ga_CW55HF8NVT*MTY5NjI1MjY2MS41LjEuMTY5NjI1MjY4Ni4zNS4wLjA' && activeImage !== 'https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/chat-default-image.png?alt=media&token=936b0f65-f711-4153-a1d9-0f1250ad1411')
                          {
                            if (e.key === 'Enter' && !faceOrAudioToggle)
                            {
                              e.preventDefault();
                              setIsTyping(true);
                              streamTextGeneration();
                            }
                            else if (e.key === 'Enter' && !faceOrAudioToggle == false)
                            {
                              e.preventDefault();
                              setIsTyping(true);
                              streamTextGeneration();
                            }
                          }
                        }

                      }}
                    />
                    <div className="textareaImgWrap">
                      {imagePreviews && imagePreviews.map((img, index) => (
                        <div className="textareaImg">
                          <Image src={img} key={index} alt="" />
                          <Button className="btn-close-img" variant="" type="button" onClick={() => onRemoveSelectedImage(index)}><Icon icon="ic:round-close" /></Button>
                        </div>
                      ))}
                    </div>
                  </div>
                  <Button variant="" className="Advanced-main-wrap mobile-send-setting" onClick={handleShow}>
                    <i> <lord-icon src="https://cdn.lordicon.com/exlyjeeh.json" trigger="hover" colors="outline:#131431,primary:#36e899" ></lord-icon> </i>
                  </Button>
                  {isCreating ? <Button variant="" className="Advanced-main-wrap mobile-send-setting" onClick={handleShow2}>
                    <i><lord-icon src="https://cdn.lordicon.com/gdbnaaef.json" trigger="hover" colors="outline:#121331,primary:#ffffff,secondary:#36e899"></lord-icon></i>
                  </Button> : (<div></div>)}
                </div>
                {!currentMessage ? (
                  <Button variant="" className="mobile-btn record-btn" onClick={startListening}>{isListening ? <Icon icon="ic:round-pause" /> : <Icon icon="ic:outline-mic" />}</Button>
                ) : isListening ? (
                  <Button variant="" className="mobile-btn record-btn" onClick={startListening}>{isListening ? <Icon icon="ic:round-pause" /> : <Icon icon="ic:outline-mic" />}</Button>
                ) :
                  !faceOrAudioToggle ? (
                    <Button
                      variant=""
                      type="submit"
                      className="chatbot-send-button btn-message mobile-btn"
                      onClick={() => {
                        if (!authToken) {
                          dispatch(showPopUpAction(true));
                          return;
                        }
                        if (chatbotWorkAs === 'app') {
                          makeRequestForDesign();
                        } else
                        {
                          streamTextGeneration();
                        }
                      }}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <lord-icon
                          src="https://cdn.lordicon.com/wfmfwkbb.json"
                          trigger="loop"
                          colors="primary:#eee"
                        ></lord-icon>
                      ) : (
                        <span>
                          <Icon icon="ic:round-send" />
                        </span>
                      )}
                    </Button>
                  ) : (
                    <Button
                      variant=""
                      className="chatbot-send-button mobile-btn btn-message"
                      onClick={() => {
                        if (!authToken) {
                          dispatch(showPopUpAction(true));
                          return;
                        }
                        if (chatbotWorkAs === 'app') {
                          makeRequestForDesign();
                        } else
                        {
                          streamTextGeneration();
                        }
                      }}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <lord-icon
                          src="https://cdn.lordicon.com/wfmfwkbb.json"
                          trigger="loop"
                          colors="primary:#eee"
                        ></lord-icon>
                      ) : (
                        <span>
                          <Icon icon="ic:round-send" />
                        </span>
                      )}
                    </Button>
                  )}
              </div>
            </div>
          </Col>
          <Col xl={showFullPage ? 5 : 5} lg={showFullPage ? 6 : 6} style={{ 'display': showFullPage ? 'none' : 'block' }} className="CreateAICompanion">
            {isIntroLoading ? (
              <></>
            ) : (
              <div className="AiAvtarCard">
                <div className="AiAvtarImgCard">
                  <h5 className="factoryListTitle">Create AI Companion</h5>
                  <hr />
                  <div className="FactorySettingTextbox">
                    <h6 className="label-title">AI Character Builder</h6>
                    <textarea id="description" placeholder="Explain what kind of AI agent you want to create. just describe its purpose and skill ( ex: You are a marketing guru who knows all growth hacking strategies or you are a tool that helps recognize all plants with their descriptions, and how to use them, or you are a great image generator that creates great anime characters" value={inputPrompt}
                      onChange={handleInputPromptChange} />
                  </div>
                  <hr />
                  <h6 className="label-title">Name of Chatbot</h6>
                  <div className="UploadPopup">
                    {/* <div className="profile-row-unique"> */}
                    {/* <img
                            src={activeImage}
                            alt="profile-pic"
                            className="profile-row-picture"
                            style={{ borderRadius: activeImage === "https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/chat-default-image.png?alt=media&token=936b0f65-f711-4153-a1d9-0f1250ad1411" ? '0px' : '12px' }}
                          /> */}
                    {/* </div> */}

                    <div className="name-description-block">
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                        className="profile-row-textbox mb-2"
                      />
                      <textarea
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                        className="profile-row-textbox mb-0"
                      />
                    </div>
                  </div>
                  <hr />
                  <h6 className="label-title">Voice & Chat Parameters</h6>
                  <div className="options-flex2">
                    <div className="Voice-main-wrap z-3">
                      <Form.Label>Language : </Form.Label>
                      <div className="Voices-Wrap">
                        <Select
                          placeholder="Select Option"
                          value={selectedOption}
                          className="LanguageSelect"
                          options={languages}
                          onChange={handleLanguageChange}
                          styles={customStyles2}
                          getOptionLabel={(e) => (
                            <div className="d-flex align-items-center">
                              {/* {e.icon} */}
                              <span className="ms-2">{e.value}</span>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="Voice-main-wrap z-2">
                      <Form.Label>Voice :</Form.Label>
                      <div className="Voices-Wrap">
                        <Select
                          placeholder="Select Option"
                          value={selectedVoiceForSelectBox}
                          className="LanguageSelect userSelect"
                          options={voices}
                          onChange={handleCategoryChange}
                          styles={customStyles}
                          // menuPortalTarget={document.body}
                          // menuPosition={'fixed'}
                          getOptionLabel={(e) => (
                            <div><span style={{ marginLeft: 10 }}>{e.name}</span></div>
                          )}
                        />
                      </div>
                    </div>
                    {/* <div className="Voice-main-wrap z-1">
                      <Form.Label>Model  : </Form.Label>
                      <div className="Voices-Wrap">
                        <Select
                          placeholder="Select Option"

                          value={selectedModelOption}
                          className="LanguageSelect"
                          options={models}
                          onChange={handleModelChange}
                          styles={customStyles2}
                          getOptionLabel={(e) => (
                            <div className="d-flex align-items-center">
                              <span className="ms-2">{e.text}</span>
                            </div>
                          )}
                        />
                      </div>
                    </div> */}
                    <div className="Model-Main-Wrap">
                      <h6 className="label-title">Model </h6>
                      <div className="ChatCategoryDropDownWrap">
                        <label htmlFor="CcategoryOne" className="ChatCategoryItemList">
                          <input type="checkbox" id="CcategoryOne" name="image-generation" value='image' onChange={handleModelChange} checked={modelsCheckbox.image} />
                          <div className="ChatCategoryItem">
                            <i><Icon icon="fluent:draw-image-24-regular" /></i>
                            <p>  Image generation</p>
                          </div>
                        </label>
                        <label htmlFor="CcategoryTwo" className="ChatCategoryItemList">
                          <input type="checkbox" id="CcategoryTwo" name="image-generation" value='chat' onChange={handleModelChange} checked={modelsCheckbox.chat} />
                          <div className="ChatCategoryItem">
                            <i><Icon icon="fluent:chat-12-regular" /></i>
                            <p>Text and chatting</p>
                          </div>
                        </label>
                        <label htmlFor="CcategoryThree" className="ChatCategoryItemList">
                          <input type="checkbox" id="CcategoryThree" name="image-generation" value='app' onChange={handleModelChange} checked={modelsCheckbox.app} />
                          <div className="ChatCategoryItem">
                            <i><Icon icon="fluent:tetris-app-20-regular" /></i>
                            <p>Image to App</p>
                          </div>
                        </label>
                        <label htmlFor="CcategoryFour" className="ChatCategoryItemList ">
                          <input type="checkbox" id="CcategoryFour" name="image-generation" value='vision' onChange={handleModelChange} checked={modelsCheckbox.vision} />
                          <div className="ChatCategoryItem">
                            <i><Icon icon="tabler:device-vision-pro" /></i>
                            <p>Vision power</p>
                            <small>Pro</small>
                          </div>
                        </label>
                        <label htmlFor="CcategoryFive" className="ChatCategoryItemList ">
                          <input type="checkbox" id="CcategoryFive" name="image-generation" value='gpt' onChange={handleModelChange} checked={modelsCheckbox.gpt} />
                          <div className="ChatCategoryItem">
                            <i><Icon icon="arcticons:openai-chatgpt" /></i>
                            <p>GPT4</p>
                            <small>Pro</small>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <Button variant="" disabled={!isDannyTrejoPicture} onClick={onSaveChatBot} className="btn-white mt-4" >Upload Chatbot</Button>
                </div>
              </div>
            )}
          </Col>
          <Col xl={showFullPage ? 5 : 5} lg={showFullPage ? 5 : 6}>
            {hasGenerated ? (
              <div className="AiAvtarCard AiAvtarCard3">

                <div className="AiAvtarImgCard">
                  {/* {isAudio ? (
                      <>
                        <video ref={videoRef} controls>
                          <source src={avatarVideoUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </>
                    ) : (
                      <div className="AiAvtarvideoCard">
                        <video ref={videoRef} controls>
                          <source src={avatarVideoUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        <Button
                          className="chatbot-send-button btn-gradient"
                          onClick={() => {
                            setHasGenerating(false);
                          }}
                        >
                          Regenerate Head
                        </Button>
                      </div>
                    )} */}

                  {isVideo ? (
                    <div className="AiAvtarvideoCard">
                      <div className="AiAvtarvideoCardround">
                        <div style={{ borderRadius: '15px', marginBottom: '15px', height: 'calc(100% - 115px)' }}>
                          <div className="video-container h-100">
                            <img src={activeImage}></img>
                            <video ref={videoRef2} ></video>
                            <video ref={videoRef3} ></video>
                          </div>
                        </div>
                      </div>
                      {/* {isProgressing ? (<div style={{ 'width': '100%', 'paddingBottom': '12px' }}>
                        <BorderLinearProgress />
                      </div>) : null} */}
                      {/* <video ref={videoRef} controls>
                          <source src={avatarVideoUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video> */}
                      <Link to="/Chatbots"
                        className="chatbot-send-button btn-gradient mb-btn-send"
                        style={{ 'margin': '0 auto', 'width': '100%', 'textAlign': 'center' }}
                        // onClick={() => {
                        //   setIsPlayingImage(false);
                        //   setHasGenerating(false);
                        //   if (location.state.voice == "Danny Trejo")
                        //   {
                        //     console.log("DANNY");
                        //     setMessage("professional headshot of danny trejo as a cowboy");
                        //   }

                      // }}
                      >
                        Regenerate Head
                      </Link>
                    </div>
                  ) : (

                    <div className="AiAvtarvideoCard">

                      <Image src={activeImage} />
                      {/* <video ref={videoRef} controls>
                          <source src={avatarVideoUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video> */}
                      <Link to="/CreateChatbot"
                        className="chatbot-send-button btn-gradient mb-btn-send"
                        style={{ 'margin': '0 auto', 'width': '100%', 'textAlign': 'center' }}
                        // onClick={() => {
                        //   setIsPlayingImage(false);
                        //   setHasGenerating(false);
                        //   if (location.state.voice == "Danny Trejo")
                        //   {
                        //     console.log("DANNY");
                        //     setMessage("professional headshot of danny trejo as a cowboy");
                        //   }

                      // }}
                      >
                        Regenerate Head
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            ) : avatarImageUrlSelected == '' ? (
              <div className="AiAvtarCard AiAvtarContent4" style={{ 'display': visible ? 'none' : 'inline-block' }}>
                <div className="AiAvtarImgCard">
                  <div className={`AiAvtarContent  ${generatedImages.length > 0 && isRegenerated ? 'AiAvtarContent2' : ''}`}>

                    <div className="ChatBotCreatetitle">
                      <h6 style={{ 'display': !showFullPage ? 'block' : 'none' }}>Create AI Companion Avatar</h6>
                      <p style={{ 'fontSize': '12px', 'textAlign': 'center', 'color': '#fff8' }}>Make sure image is a clear headshot portrait. Works best with realistic looking images with a clear face.</p>
                    </div>
                    <input
                      ref={fileInputRef}
                      type="file"
                      style={{ display: 'none' }}
                      accept=".png, .jpg, .jpeg"
                      onChange={handleFileChange}
                    />

                    {isGeneratingImage || activeImage === '' ? (
                      <Col lg={5}></Col>
                    ) : (
                      <>
                        <i
                          className={`ch-70 ${isPlayingImage ? 'AibigImage' : 'AiMainImg'
                          }`}
                        >
                          <img
                            src={activeImage}
                            alt="Clickable preview"
                            style={{ cursor: 'pointer', borderRadius: activeImage === 'https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/chat-default-image.png?alt=media&token=936b0f65-f711-4153-a1d9-0f1250ad1411' ? '0px' : '12px' }}
                            onClick={handleImageClick}
                          />
                          {/* {!isPlayingImage && isAvatarGenerated && (
                            <Link
                              className="mainImagebutton"
                              to="#"
                              onClick={onSetImageForPlaying}
                              data-tooltip-id="button-tooltip"
                            >
                              <lord-icon
                                src="https://cdn.lordicon.com/mjieolyn.json"
                                trigger="hover"
                                colors="outline:#36e899,primary:#36e899,secondary:#ffffff"
                              ></lord-icon>
                              <ReactTooltip
                                id="button-tooltip"
                                place="top"
                                className="tooltip"
                                content="Make AI Come-to-life"
                              />
                            </Link>
                          )}
                          {isDannyTrejoPicture && (
                            <Link
                              className="mainImagebutton"
                              to="#"
                              onClick={onSetImageForPlaying}
                              data-tooltip-id="button-tooltip"
                            >
                              <lord-icon
                                src="https://cdn.lordicon.com/mjieolyn.json"
                                trigger="hover"
                                colors="outline:#36e899,primary:#36e899,secondary:#ffffff"
                              ></lord-icon>
                              <ReactTooltip
                                id="button-tooltip"
                                place="top"
                                className="tooltip"
                                content="Make AI Come-to-life"
                              />
                            </Link>
                          )} */}
                        </i>
                        {/* {!isAvatarGenerated && <div className="ch-20  AIgenImgMain">
                          <ImageSlider {...aisettings}>
                            {imageUrls.map((url, index) => (
                              <div key={index}>
                                <Link className="AIgenImg" to="#" onClick={() => {
                                  setActiveImage(url);
                                  // setGeneratedImages([url]);
                                  setIsDannyTrejoPicture(true);
                                }}>
                                  <Image className="rounded" src={url} alt="" />
                                </Link>
                              </div>
                            ))}
                          </ImageSlider>
                        </div>} */}
                        {isPlayingImage && (
                          <Button
                            type="button"
                            className="chatbot-send-button btn-gradient d-block w-100 mb-btn-send"
                            onClick={onSetImageForPlaying}
                          >
                            Return
                          </Button>
                        )}
                      </>
                    )}

                    {isGeneratingImage ? (
                      <div className="cloneprogressClass w-100 h-100">
                        {/* <img
                                  className=""
                                  src={selectedAvatarURL}
                                  alt="Avatar"
                                /> */}
                        <div className="progressClass">
                          <CircularProgressbarWithChildren
                            styles={{
                              path: { stroke: '#36e899' },
                              trail: { stroke: '#ffffff14' },
                            }}
                            className="ProgressMainInfo"
                            value={countDown}
                          >
                            <div className="ProgressInfo">
                              <img
                                src="/images/big-logo-icon.png"
                                alt="doge"
                              />
                              <strong>{countDown}%</strong>
                            </div>
                          </CircularProgressbarWithChildren>
                        </div>
                      </div>
                    ) : (
                      <>
                        {generatedImages.length > 0 && <div
                          id="generatedImages"
                          className={`ch-20 TextAiImgWrap mt-0 ${generatedImages.length <= 10
                            ? `image-${generatedImages.length}`
                            : 'image-default'
                          } ${isPlayingImage ? 'd-none' : 'd-flex'}`}
                        >
                          {generatedImages.length > 0 &&
                            generatedImages?.map((image, index) => (
                              <figure className="AIGenerated-img" key={index}>
                                <a
                                  className="AI_Img"
                                  // data-fancybox="AIGeneratedImg"
                                  // href={image.imageUrl}
                                  onClick={() => {
                                    onSelectImage(image.imageUrl);
                                    setIsDannyTrejoPicture(true);
                                  }}
                                >
                                  <img
                                    className="rounded"
                                    src={image.imageUrl}
                                    alt={''}
                                  />
                                </a>

                                {/* <label
                                        className="ImgCheckBox"
                                        htmlFor={image.imageUrl}
                                      >
                                        <input
                                          type="checkbox"
                                          id={image.imageUrl}
                                          value={image.imageUrl}
                                          checked={image.isChecked}
                                          onClick={() => onSelectImages(image, index)}
                                        />
                                        <span>
                                          <Icon icon="mdi:tick" />
                                        </span>
                                      </label> */}
                                {/* <Button
                                        type="button"
                                        variant=""
                                        disabled={!image.isChecked}
                                        onClick={onShowTokenize}
                                        className="btn btn-white BtnTokenize"
                                      >
                                        Tokenize
                                      </Button> */}
                              </figure>
                            ))}
                        </div>}
                        {/* {hasGeneratedImage ? (
                                <div className="text-center mt-3">
                                  {selectedImage.length > 1 ? (
                                    <Link
                                      to="#"
                                      onClick={onShowTokenize}
                                      className="btn btn-white"
                                    >
                                      Tokenize Collection
                                    </Link>
                                  ) : (
                                    <Link
                                      to="#"
                                      onClick={onSelectAllImage}
                                      className="btn btn-white"
                                    >
                                      Select All
                                    </Link>
                                  )}
                                </div>
                              ) : null} */}
                      </>
                    )}

                    {!isPlayingImage && (
                      <div className="chat-input-box ch-10">
                        <input
                          placeholder="Imagine and generate your AI companion"
                          type="text"
                          value={message}
                          onChange={handlePromptChange}
                        />
                        <Button
                          className="chatbot-send-button btn-gradient"
                          onClick={fetchGeneration}
                        >
                          {isGeneratingImage ? 'Loading...' : 'Generate'}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (

              <img className="" src={avatarImageUrlSelected} alt="Avatar" />
            )}
          </Col>

        </Row>
      </>
      {/* )} */}
      <Modal show={show} onHide={handleClose} centered size="lg" className="FactoryListPopup advancePopup">
        <Modal.Body>
          <Link
            to="#"
            className="CloseBtn"
            onClick={handleClose}
          >
            <Icon icon="ion:close-circle" color="white" />
          </Link>
          <h5 className="factoryListTitle">Choose Parameters</h5>
          <p className="FactorySettingText">Customize your AI assistant in whatever way you want!</p>
          <hr />
          <h6 className="label-title">Voice & Chat Parameters</h6>
          <div className="options-flex">
            <div className="Voice-main-wrap z-3">
              <Form.Label>Language  : </Form.Label>
              <div className="Voices-Wrap">
                <Select
                  placeholder="Select Option"
                  value={selectedOption}
                  className="LanguageSelect"
                  options={languages}
                  onChange={handleLanguageChange}
                  styles={customStyles2}
                  getOptionLabel={(e) => (
                    <div className="d-flex align-items-center">
                      {/* {e.icon} */}
                      <span className="ms-2" style={{ fontSize: 13 }}>{e.value}</span>
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="Voice-main-wrap z-2">
              <Form.Label>Voice :</Form.Label>
              <div className="Voices-Wrap">
                <Select
                  placeholder="Select Option"
                  value={selectedVoiceForSelectBox}
                  className="LanguageSelect userSelect"
                  options={voices}
                  onChange={handleCategoryChange}
                  styles={customStyles}
                  // menuPortalTarget={document.body}
                  // menuPosition={'fixed'}
                  getOptionLabel={(e) => (
                    <div><span style={{ marginLeft: 10, fontSize: 13 }}>{e.name}</span></div>
                  )}
                />
              </div>
            </div>

            <div className="Voice-main-wrap z-1">
              <Form.Label>Model  : </Form.Label>
              <div className="Voices-Wrap">
                <Select
                  placeholder="Select Option"
                  value={selectedModelOption}
                  className="LanguageSelect"
                  options={models}
                  onChange={handleModelChange}
                  styles={customStyles2}
                  getOptionLabel={(e) => (
                    <div className="d-flex align-items-center">
                      <span className="ms-2" style={{ fontSize: 13 }}>{e.text}</span>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="FactorySettingTextbox">
            <h6 className="label-title">AI Character Builder</h6>
            <textarea id="description" placeholder="Explain what type of chatbot your are.. (ex: You are a motivational chatbot that is supportive...)" value={inputPrompt}
              onChange={handleInputPromptChange} />
          </div>
          <div>
            {/* Step 3 and 5: Create an input element of type checkbox and bind the state variable to the checked attribute */}
            <Form.Check // prettier-ignore
              type="switch"
              name="superenhance"
              checked={superenhance}
              onChange={handleCheckboxChange}
              id="Superenhance"
              label="Superenhance"
            />
          </div>
          <hr />
          <h6 className="label-title">Talking Head Parameters</h6>

          <div className="TalkingWrap">
            <div className="Talking50">
              <h6>Expression Scale ({headMovement})</h6>
              <Slider
                min={1}
                max={10}
                defaultValue={headMovement}
                onChange={(e) => setHeadMovement(e)}
              />
              <div className="DropProgressWrapInfo">
                <p>Low Expression</p>
                <p>High Expression</p>
              </div>
            </div>
            <div className="Talking50">
              <h6>Yaw ({yawScale})</h6>
              <Slider
                min={0}
                max={20}
                defaultValue={yawScale}
                onChange={(e) => setYaw(e)}
              />
              <div className="DropProgressWrapInfo">
                <p>No Yaw Change</p>
                <p>High Yaw Change</p>
              </div>
            </div>
            <div className="Talking50">
              <h6>Pitch ({pitchScale})</h6>
              <Slider
                min={0}
                max={20}
                defaultValue={pitchScale}
                onChange={(e) => setPitchScale(e)}
              />
              <div className="DropProgressWrapInfo">
                <p>No Pitch Change</p>
                <p>High Pitch Change</p>
              </div>
            </div>
            <div className="Talking50">
              <h6>Roll ({rollScale})</h6>
              <Slider
                min={0}
                max={20}
                defaultValue={rollScale}
                onChange={(e) => setRollScale(e)}
              />
              <div className="DropProgressWrapInfo">
                <p>No Roll Change</p>
                <p>High Roll Change</p>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>
      <Modal show={show3} onHide={handleClose3} centered size="lg" className="FactoryListPopup advancePopup">
        {/* <Modal.Header >
          <Modal.Title className="center-title" style={{ 'font-weight': 'bold' }}>Create AI Companion</Modal.Title>
          <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose3}></button>

        </Modal.Header> */}
        <Modal.Body>
          <Link
            to="#"
            className="CloseBtn"
            onClick={handleClose3}
          >
            <Icon icon="ion:close-circle" color="white" />
          </Link>
          <h5 className="factoryListTitle">Create AI Companion</h5>
          <hr />
          {isIntroLoading ? (
            <div className="options-flex" >
              {/* <img
                                  className=""
                                  src={selectedAvatarURL}
                                  alt="Avatar"
                                /> */}
              <div className="progressClass" style={{ 'textAlign': 'center', 'marginRight': 'auto', 'marginLeft': 'auto', 'paddingTop': '22px', 'paddingBottom': '10px', }}>
                <CircularProgressbarWithChildren
                  styles={{
                    path: { stroke: '#36e899' },
                    trail: { stroke: '#ffffff14' },
                  }}
                  className="ProgressMainInfo"
                  value={countDown}
                >
                  <div className="ProgressInfo">
                    <img
                      src="/images/big-logo-icon.png"
                      alt="doge"
                    />
                    <strong>{countDown}%</strong>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          ) : (
            <>
              <h6 className="label-title">Name of Chatbot</h6>
              <div className="UploadPopup">
                {/* <div className="profile-row-unique"> */}
                {/* <img
                  src={activeImage}
                  alt="profile-pic"
                  className="profile-row-picture"
                  style={{ borderRadius: activeImage === "https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/chat-default-image.png?alt=media&token=936b0f65-f711-4153-a1d9-0f1250ad1411" ? '0px' : '12px' }}
                /> */}
                {/* </div> */}
                <div className="name-description-block">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    className="profile-row-textbox"
                  />
                  {/* <textarea
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Description"
                  className="profile-row-textbox"
                /> */}
                </div>
              </div>
              <br></br>

              <h6 className="label-title">Voice & Chat Parameters</h6>
              <div className="options-flex">
                <div className="Voice-main-wrap z-3">
                  <Form.Label>Language : </Form.Label>
                  <div className="Voices-Wrap">
                    <Select
                      placeholder="Select Option"
                      value={selectedOption}
                      className="LanguageSelect"
                      options={languages}
                      onChange={handleLanguageChange}
                      styles={customStyles2}
                      getOptionLabel={(e) => (
                        <div className="d-flex align-items-center">
                          {/* {e.icon} */}
                          <span className="ms-2">{e.value}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="Voice-main-wrap z-2">
                  <Form.Label>Voice :</Form.Label>
                  <div className="Voices-Wrap">
                    <Select
                      placeholder="Select Option"
                      value={selectedVoiceForSelectBox}
                      className="LanguageSelect userSelect"
                      options={voices}
                      onChange={handleCategoryChange}
                      styles={customStyles}
                      // menuPortalTarget={document.body}
                      // menuPosition={'fixed'}
                      getOptionLabel={(e) => (
                        <div><span style={{ marginLeft: 10 }}>{e.name}</span></div>
                      )}
                    />
                  </div>
                </div>
                <div className="Voice-main-wrap z-1">
                  <Form.Label>Model  : </Form.Label>
                  <div className="Voices-Wrap">
                    <Select
                      placeholder="Select Option"

                      value={selectedModelOption}
                      className="LanguageSelect"
                      options={models}
                      onChange={handleModelChange}
                      styles={customStyles2}
                      getOptionLabel={(e) => (
                        <div className="d-flex align-items-center">
                          <span className="ms-2">{e.text}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="FactorySettingTextbox">
                <h6 className="label-title">AI Character Builder</h6>
                <textarea id="description" placeholder="Explain what type of chatbot your want to create.. (ex: You are a motivational chatbot that is supportive...)" value={inputPrompt}
                  onChange={handleInputPromptChange} />
              </div>
            </>

          )}
        </Modal.Body>
        <hr className="mt-0" />
        <div className="btn-flex-modal">
          <Button variant="" onClick={handleClose3} className="btn-border-white">Cancel</Button>
          <Button variant="" onClick={onSaveChatBot} className="btn-white">Save</Button>
        </div>
      </Modal>
      <Modal show={show2} onHide={handleClose2} centered size="lg" className="FactoryListPopup advancePopup">
        <Modal.Header >
          <Modal.Title className="center-title" >Upload AI Companion</Modal.Title>
          <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose2}></button>

        </Modal.Header>
        {isIntroLoading ? (
          <Modal.Body>



            <div className="options-flex" >
              {/* <img
                                  className=""
                                  src={selectedAvatarURL}
                                  alt="Avatar"
                                /> */}
              <div className="progressClass" style={{ 'textAlign': 'center', 'marginRight': 'auto', 'marginLeft': 'auto', 'paddingTop': '22px', 'paddingBottom': '10px', }}>
                <CircularProgressbarWithChildren
                  styles={{
                    path: { stroke: '#36e899' },
                    trail: { stroke: '#ffffff14' },
                  }}
                  className="ProgressMainInfo"
                  value={countDown}
                >
                  <div className="ProgressInfo">
                    <img
                      src="/images/big-logo-icon.png"
                      alt="doge"
                    />
                    <strong>{countDown}%</strong>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>



          </Modal.Body>
        ) : (
          <Modal.Body>



            <h6 className="label-title">Name of Chatbot</h6>
            <div className="UploadPopup">
              {/* <div className="profile-row-unique"> */}
              {/* <img
                  src={activeImage}
                  alt="profile-pic"
                  className="profile-row-picture"
                  style={{ borderRadius: activeImage === "https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/chat-default-image.png?alt=media&token=936b0f65-f711-4153-a1d9-0f1250ad1411" ? '0px' : '12px' }}
                /> */}
              {/* </div> */}

              <div className="name-description-block">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  className="profile-row-textbox"
                />
                <textarea
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Description"
                  className="profile-row-textbox"
                />

              </div>
            </div>
            <br></br>

            <h6 className="label-title">Voice & Chat Parameters</h6>
            <div className="options-flex">
              <div className="Voice-main-wrap z-3">
                <Form.Label>Language : </Form.Label>
                <div className="Voices-Wrap">
                  <Select
                    placeholder="Select Option"
                    value={selectedOption}
                    className="LanguageSelect"
                    options={languages}
                    onChange={handleLanguageChange}
                    styles={customStyles2}
                    getOptionLabel={(e) => (
                      <div className="d-flex align-items-center">
                        {/* {e.icon} */}
                        <span className="ms-2">{e.value}</span>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="Voice-main-wrap z-2">
                <Form.Label>Voice :</Form.Label>
                <div className="Voices-Wrap">
                  <Select
                    placeholder="Select Option"
                    value={selectedVoiceForSelectBox}
                    className="LanguageSelect userSelect"
                    options={voices}
                    onChange={handleCategoryChange}
                    styles={customStyles}
                    // menuPortalTarget={document.body}
                    // menuPosition={'fixed'}
                    getOptionLabel={(e) => (
                      <div><span style={{ marginLeft: 10 }}>{e.name}</span></div>
                    )}
                  />
                </div>
              </div>

              <div className="Voice-main-wrap z-1">
                <Form.Label>Model  : </Form.Label>
                <div className="Voices-Wrap">
                  <Select
                    placeholder="Select Option"

                    value={selectedModelOption}
                    className="LanguageSelect"
                    options={models}
                    onChange={handleModelChange}
                    styles={customStyles2}
                    getOptionLabel={(e) => (
                      <div className="d-flex align-items-center">
                        <span className="ms-2">{e.text}</span>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="FactorySettingTextbox">
              <h6 className="label-title">AI Character Builder</h6>
              <textarea id="description" placeholder="You are a chatbot that is motivational..." value={inputPrompt}
                onChange={handleInputPromptChange} />
            </div>
            <br></br>
            <Button
              variant=""
              className="chatbot-send-button btn-gradient "
              onClick={uploadData}

            >
              {
                isUploading ? <span>
                  Uploading...
                </span> : <span>
                  Upload
                </span>
              }
            </Button>


          </Modal.Body>
        )}

      </Modal>
      <div className={` overlay ${visible ? 'visible' : 'hidden'}`}>
        {/* <p>Loading Character...</p> */}
        <div className="loader-wrap"> <CircularProgressbarWithChildren
          styles={{
            path: { stroke: '#36e899' },
            trail: { stroke: '#ffffff14' },
          }}
          className="ProgressMainInfo"
          value={countDown}
        >
          <div className="ProgressInfo">

            <img src="/images/big-logo-icon.png" alt="doge" />
            <div style={{ 'textAlign': 'center' }}>
              <strong>{countDown}%</strong>
            </div>

          </div>
        </CircularProgressbarWithChildren>
        <p>{texts[index]}</p>
        </div>
      </div>
      <PopupModal
        className="popupShowImage"
        show={isModalOpen}
        handleClose={closeModal}
      >
        <div className="popupModalImage">
          <Image src={selectedImageUrl} alt="" />
        </div>
      </PopupModal>
    </div >
  );
}

export default CreateChatBot;
// if (sessionuid != "")
// {
//   console.log(sessionuid);
//   console.log(numGenerated);
// try
// {
//   const response = await fetch('https://i5z2sbz6n8ylzb-5000.proxy.runpod.net/sessions/get_vid', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       "sessionuid": sessionuid,
//     }),
//   });

//   // const response = await fetch(`https://i5z2sbz6n8ylzb-5000.proxy.runpod.net/sessions/${sessionuid}/videos/has_videos`);
//   const data = await response.json();
//   console.log(data);

// if (data['total_vids'] > 0)
// {
//   setUrl(data['video']);
// } else
// {
//   setUrl("https://i5z2sbz6n8ylzb-5000.proxy.runpod.net/hls/3s.m3u8");
// }

// } catch (error)
// {
//   console.error('Error:', error);
// }

// }
