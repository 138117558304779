import React, { useEffect, useMemo, useState } from 'react';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import axios from 'axios';
import Products from "../../Models/Products";
import "./ProductsDetails.css";

export const ProductSection: React.FC<{ shouldFetchProducts: boolean, setShouldFetchProducts: React.Dispatch<React.SetStateAction<boolean>> }> = ({ shouldFetchProducts, setShouldFetchProducts,setSelectedProduct, setActiveSection }) => {
    const [productData, setProductData] = useState<Products[]>([]);
    const [productTotalResults, setProductTotalResults] = useState(0);
    const [productCurrentPage, setProductCurrentPage] = useState(1);
    const [productShouldFetch, setProductShouldFetch] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);  // Nuevo estado para verificar si hay más datos

    const itemsPerPage = 20; // Number of items per page

    useEffect(() => {
        if (productShouldFetch || shouldFetchProducts) {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
            };

            const fetchProductData = async () => {
                const offset = (productCurrentPage - 1) * itemsPerPage;
                try {
                    const response = await axios.get(
                        `${import.meta.env.VITE_SERVER_ADDRESS}getAllProducts?offset=${offset}&limit=${itemsPerPage}`,
                        config
                    );
                    if (response.data.length < itemsPerPage) {
                        setHasMoreData(false);  // No more data to load
                    }
                    setProductData(response.data);
                } catch (err) {
                    console.error("An error occurred while fetching products:", err);
                }
            };

            fetchProductData().catch(err => {
                console.error("An error occurred:", err);
            });

            setProductShouldFetch(false);  // Reset flag for this component
            setShouldFetchProducts(false);  // Reset flag passed from parent (AdminPanel)
        }
    }, [productShouldFetch, productCurrentPage, shouldFetchProducts]);

    const handleProductClick = (id) => {
        setSelectedProduct(id);
        setActiveSection('ProductDetails');
    };

    const productColumns = useMemo(
        () => [
            { Header: 'ID', accessor: 'id' },
            { Header: 'Name', accessor: 'Name' },
            { Header: 'Description', accessor: 'Description' },
            { Header: 'Category', accessor: 'Category' },
            { Header: 'Sub-Category', accessor: 'SubCategory' },
            { Header: 'Price', accessor: 'Price' },
            { Header: 'Discount', accessor: 'Discount' },
            // {
            //     Header: 'Stripe ID',
            //     accessor: 'StripeId',
            //     Cell: ({ value }) => (value ? 'Set' : 'Not Set'), // Render 'Set' or 'Not Set' based on the value
            // },
            // {
            //     Header: 'Stripe Recurrent ID',
            //     accessor: 'StripeRecurrentId',
            //     Cell: ({ value }) => (value ? 'Set' : 'Not Set'),
            // },
            // {
            //     Header: 'Test Stripe ID',
            //     accessor: 'TestStripeId',
            //     Cell: ({ value }) => (value ? 'Set' : 'Not Set'),
            // },
            // {
            //     Header: 'Test Stripe Recurrent ID',
            //     accessor: 'TestStripeRecurrentId',
            //     Cell: ({ value }) => (value ? 'Set' : 'Not Set'),
            // },
            { Header: 'Level', accessor: 'Level' },
            { Header: 'Product ID', accessor: 'ProductId' },
            { Header: 'Orbox Price', accessor: 'OrboxPrice' },
            { Header: 'Orbox Rewarded', accessor: 'OrboxRewarded' },
            { Header: 'Orbox Premium Rewarded', accessor: 'OrboxPremiumRewarded' },
            { Header: 'Min Sub Level', accessor: 'MinSubLevel' },
            {
                Header: 'Public Sale',
                accessor: 'PublicSale',
                Cell: ({ value }) => (value ? 'Yes' : 'No'), // Check value and return 'Yes' or 'No'
            },
            { Header: 'Uses', accessor: 'Uses' },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns: productColumns, data: productData }, useGlobalFilter, useSortBy);

    const handleDeleteClick = async (productId) => {
        const userConfirmed = window.confirm(`Do you want to delete the product with ID: ${productId}?`);
        if (userConfirmed) {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
                };
                const response = await axios.delete(`${import.meta.env.VITE_SERVER_ADDRESS}deleteProduct?productId=${productId}`, config);
                if (response.status === 200) {
                    console.log("Product deleted successfully"); // Debug line
                    setProductShouldFetch(true); // Refresh the table
                }
            } catch (error) {
                console.error("An error occurred:", error);
            }
        }
    };

    return (
        <div>
            {/* Pagination controls */}
            <div className="pagination-controls">
                <button onClick={() => {setProductCurrentPage(1); setProductShouldFetch(true);}} disabled={productCurrentPage === 1}>
                    First
                </button>
                <button onClick={() => {setProductCurrentPage(prev => Math.max(prev - 1, 1)); setProductShouldFetch(true);}} disabled={productCurrentPage === 1}>
                    Previous
                </button>
                <span>
                Page {productCurrentPage} of {productTotalResults > 0 ? Math.ceil(productTotalResults / itemsPerPage) : 1}
            </span>
                <button onClick={() => {setProductCurrentPage(prev => Math.min(prev + 1, Math.ceil(productTotalResults / itemsPerPage))); setProductShouldFetch(true);}}
                        disabled={productCurrentPage === Math.ceil(productTotalResults / itemsPerPage) || productTotalResults === 0}>
                    Next
                </button>
                <button onClick={() => {setProductCurrentPage(Math.ceil(productTotalResults / itemsPerPage)); setProductShouldFetch(true);}}
                        disabled={productCurrentPage === Math.ceil(productTotalResults / itemsPerPage) || productTotalResults === 0}>
                    Last
                </button>
            </div>

            <div className="add-button-container">
                <button onClick={() => { setSelectedProduct(null); setActiveSection('ProductDetails'); }}>
                    Add New Product
                </button>
            </div>

            {/* Product Table */}
            <div className="factoryItemsWrap">
                <div className="table-container">
                    <div className="table-scroll">
                        <table {...getTableProps()}>
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span>
                                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                </span>
                                        </th>
                                    ))}
                                    <th>Edit</th> {/* Add header for Edit button */}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            );
                                        })}
                                        <td>
                                            <button onClick={() => handleProductClick(row.original.id)}>Edit</button>
                                            {/* Add Delete button */}
                                            <button onClick={() => handleDeleteClick(row.original.id)}>Delete</button>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
