import { useEffect, useState } from "react";
import { Button, ProgressBar } from "react-bootstrap";

import "./StepTwoForm.css";

const StepTwoForm = (props) =>
{
  const [orbData, setOrbData] = useState({
    socialToken: {},
    aboutCommunity:'',
  })
  const [imagePreview, setImagePreview] = useState('')
  useEffect(() =>
  {
    if(props?.data?.orbData)
    {
      setOrbData(props.data.orbData)
    }
  }, [props.data.orbData])
  const handleFileUpload = (event) =>
  {
    setOrbData({ ...orbData, socialToken: event.target.files[0]})
    setImagePreview(URL.createObjectURL(event.target.files[0]))
  }

  const onHandleChange = (event) =>
  {
    setOrbData({ ...orbData, aboutCommunity: event.target.value })
  }

  const onNextStep = () =>
  {
    const data = {
      ...props.data,
      orbData: orbData
    }
    props.nextStep(data);
  }
  const onPrevStep = () =>
  {
    props.removeFormData('communityOrbData');
    props.previousStep(props.data);
  }

  return (
    <div className="wizardFormMain StepTwoForm">
      <h3>{props.data.communityOrbData.name}</h3>
      <div className="Progressbar-wrap">
        <p>Step 02</p>
        <ProgressBar now={40} />
        <p>
          <span>02</span>/04
        </p>
      </div>
      <h4>Your social token</h4>
      <div className="formGroup drag-photo">
        <label htmlFor="imageUpload" className="drag-photo-img">
          <input type="file" htmlFor="imageUpload" id="imageUpload" onChange={handleFileUpload}/>
          <div className="imageUpload">
            <p>Drag & Drop File <br /> Or <br/> <span>Browse Files</span></p>
          </div>
        </label>
        <i>
          Upload file for your community Orb. <br /> This will also be the
          visual of your sNFT
        </i>
        <img src={imagePreview} alt={""}></img>
      </div>
      <caption>
        The social token is a membership token with superpowers. It empowers you
        to earn money by enabling people to buy it to join your community and
        earn a piece of it. It also enables you to grow your social capital,
        level up, and earn more.
      </caption>
      <div className="formGroup">
        <label>What is your community and World about?</label>
        <textarea placeholder="Describe your community and world" onChange={onHandleChange}></textarea>
      </div>
      <caption>
      Describe to people what your community Orb is about. One thing that you love and that can rally people around it
      </caption>
      <div className="wizardBtn-wrap">
        <Button
          variant=""
          className="btn-gradient"
          onClick={onPrevStep}
        >
          Back
        </Button>
        <Button variant="" className="btn-gradient" onClick={onNextStep}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default StepTwoForm;