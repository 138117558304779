import React from "react";
import ProfileBanner from "../../Components/ProfileBanner/ProfileBanner";
import "./MainProfilePage.css";
import ProfileUserAssets from "./InternalComponents/ProfileUserAssets/ProfileUserAssets";

function MainProfilePage() {
    return (
        <div className="main-content">
            <ProfileBanner isPublicProfile={false} />
            <ProfileUserAssets isPublicProfile={false} />
        </div>
    );
}

export default MainProfilePage;
