import React, { useState } from "react";
import { Image, Row, Col, Form, } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./FactoryPopup.css";
import {SetAchievement} from "../AchievementComponents/AchievementsHandler";
import {AchievementList} from "../../Utils/Constants";
import {useSelector} from "react-redux";
import {userDataSelector} from "../../Redux/Slices/userDataSlice";
import { Icon } from "@iconify/react";
import Slider from "rc-slider";

function FactoryPopup({ images, factoryData }) {
  const navigate = useNavigate();
  const [, setGuidanceScale] = useState(7);
  const [, setSteps] = useState(30);
  const [, setNumImage] = useState(4);
  const [timerRate, ] = useState(1000);
  const userData = useSelector(userDataSelector);


  const [, setIsGenerating] = useState(false);
  const [, setHasGenerating] = useState(false);
  const [, setIsNetworkImage] = useState(false);
  const [, setUploaded] = useState(false);
  const [orbData, setOrbData] = useState({
    socialToken: {},
    aboutCommunity: "",
  });
  const [, setImagePreview] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [baseModel, setBaseModel] = useState("Stable diffusion 2.1");

  const handleEmailChange = (event) =>
  {
    setUserEmail(event.target.value);
  };

  // eslint-disable-next-line no-unused-vars
  const handleFileUpload = (event) =>
  {
    setUploaded(true);
    setIsNetworkImage(false);
    setOrbData({ ...orbData, socialToken: event.target.files[0] });
    setImagePreview(URL.createObjectURL(event.target.files[0]));
  };

  // eslint-disable-next-line no-unused-vars
  const handleGuidanceScale = (value) =>
  {
    setGuidanceScale(value);
  };
  // eslint-disable-next-line no-unused-vars
  const handleSteps = (value) =>
  {
    setSteps(value);
  };
  // eslint-disable-next-line no-unused-vars
  const handleNumImage = (value) =>
  {
    setNumImage(value);
  };
  const [, setGeneratedImages] = useState([]);
  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(false);

  const [learningRate, setLearningRate] = useState("3e-6");
  const [textEncoderlearningRate, setTextLearningRate] = useState("1e-6");
  const [lr_scheduler, setlrscheduler] = useState("linear");
  const [trainingSteps, setTrainingSteps] = useState(2500);
  const [textEncodertrainingSteps, setEncoderTrainingSteps] = useState(750);
  const [offsetNoise, setOffsetNoise] = useState(false);
  const [steroids_mode, setSteroidsMode] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const learningRateOptions = ["1e-6", "1e-5", '1e-4', '1e-3'];
  const textEncoderLearningRate = ["3e-6", "1e-6", "1e-5", '1e-4', '1e-3'];
  const lr_schedulers = ["linear", "constant", "polynomial"];


  const [user_category, setCategory] = useState("General Purpose");
  const [photoResulation, setPhotoResulation] = useState("512 X 512");

  const handleCategoryChange = (e) =>
  {
    setCategory(e.target.value);
  };
  const handlePhotoResolutionChange = (e) =>
  {
    setPhotoResulation(e.target.value);
  };

  React.useEffect(() =>
  {
    if (userData != null)
    {
      setUserEmail(userData['email']);
    }

    let timerId;

    if (runTimer)
    {
      setCountDown(0);
      timerId = setInterval(() =>
      {
        setCountDown((countDown) => countDown + 1);
      }, timerRate);
    }
    else
    {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer, timerRate, userData]);

  React.useEffect(() =>
  {
    if (countDown > 100 && runTimer)
    {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const fetchGeneration = async () =>
  {
    SetAchievement(AchievementList.CreateFactory, 1, userData.Role);
    setIsGenerating((t) => true);
    setRunTimer((t) => true);

    let formData = new FormData();
    formData.append("data", "");
    let username;
    let userPicture;
    if (userData != null)
    {
      username = userData['name'];
      userPicture = userData['profile_picture'];
    }
    else
    {
      username = "anonymous";
      userPicture = null;
    }
      await fetch(`https://v67eh52pl2zw5f-3000.proxy.runpod.net/dreambooth`, {
        method: "POST",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          Accept: "application/json",
          'Connection': 'keep-alive',
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          inputImages: images,
          category: user_category,
          email: userEmail,
          factoryTitle: factoryData['factoryTitle'],
          factoryDesc: factoryData['factoryDesc'],
          learningRate: learningRate,
          textEncoderlearningRate: textEncoderlearningRate,
          learningRateScheduler: lr_scheduler,
          offsetNoise: offsetNoise,
          textEncoderSteps: textEncodertrainingSteps,
          trainingSteps: trainingSteps,
          creator: username,
          profile_picture: userPicture,
        })
      })
        .then((response) => response.json())
        .then((data) => {
          setGeneratedImages(data);
          setRunTimer((t) => false);
          setCountDown(0);
          setIsGenerating((t) => false);
          setHasGenerating(true);
          navigate(`/Factory/${factoryData.factoryTitle}`)
        });
  }

  const onHandleBaseModelChange = (event) =>
  {
    setBaseModel(event.target.value);
  }
  return (
    <div className="FactoryPopupMain">
      <div className="FactoryPopupTitle">
        <ul>
          {images.map((img, index) => (
            <li key={index}>
              <Image src={img} />
            </li>
          ))}
        </ul>
        <h6>
          {factoryData.factoryTitle}{" "}
          <p>
            {factoryData.factoryDesc}
          </p>
        </h6>
      </div>
      <hr />
      <Row>
        <Col lg={6}>
          <div className="formGroup">
            <label>Category</label>
            <select
              name="Category"
              value={user_category}
              onChange={handleCategoryChange}
            >
              <option value="General Purpose">General Purpose</option>
              <option value="Characters">Characters</option>

              <option value="Environments">Environments</option>
              <option value="Buildings">Buildings</option>
              <option value="Game Items & Collectibles">
                Game Items & Collectibles
              </option>
              <option value="Props">Props</option>
              <option value="Natural Elements">Natural Elements</option>
              <option value="Fashion and Wearables">
                Fashion and Wearables
              </option>
              <option value="Game Maps">Game Maps</option>
              <option value="Pixel Art">Pixel Art</option>
              <option value="Vehicles">Vehicles</option>
              <option value="Weapons">Weapons</option>
              <option value="Concept Art/Illustration">
                Concept Art/Illustration
              </option>
              <option value="UI Components and GUI">
                UI Components and GUI
              </option>
              <option value="Photography">Photography</option>
              <option value="Blueprints">Blueprints</option>
            </select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="formGroup">
            <label>Base Model</label>
            <input
              placeholder="Enter Base Model"
              name="baseModel"
              value={baseModel}
              disabled
              onChange={onHandleBaseModelChange}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="formGroup">
            <label>Photo Resolution</label>
            <select
              name="photoResolution"
              value={photoResulation}
              onChange={handlePhotoResolutionChange}
            >
              <option value="512 X 512">512 X 512</option>
              <option value="768 X 768">768 X 768</option>
            </select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="formGroup">
            <label>Email</label>
            {userData != null ? (<input
              placeholder="Enter email"
              value={userData['email']}
              name="email"
              onChange={handleEmailChange}
            />) : (<input
              placeholder="Enter email"
              name="email"
              onChange={handleEmailChange}
            />)}
          </div>
        </Col>
      </Row>
      
      <Link className="showAdvancedbtn mt-2" to="#" onClick={() => setShowAdvanced(!showAdvanced)}>
        {showAdvanced ? "Hide" : "Show"} Advanced Options 
        {showAdvanced ?  <Icon
         icon="iconamoon:arrow-up-2" />: <Icon icon="iconamoon:arrow-down-2" />} 

      </Link>
      
      {showAdvanced && (
       <Row className="mt-3">
        <Col lg={4}>
          <div className="formGroup">
            <label >Learning Rate Scheduler </label>
            <select
              value={lr_scheduler}
              onChange={(e) => setlrscheduler(e.target.value)}>
              {lr_schedulers.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col lg={4}>
          <div className="formGroup">
            <label >Text Encoder Learning Rate </label>
            <select
              value={textEncoderlearningRate}
              onChange={(e) => setTextLearningRate(e.target.value)}>

              {textEncoderLearningRate.map((rate) => (
                <option key={rate} value={rate}>
                  {rate}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col lg={4}>
          <div className="formGroup">
            <label >Learning Rate </label>
            <select
              value={learningRate}
              onChange={(e) => setLearningRate(e.target.value)}>

              {learningRateOptions.map((rate) => (
                <option key={rate} value={rate}>
                  {rate}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="formGroup">
            <label htmlFor="">Text Encoder Training Steps </label>
            <Slider              
              min={0}
              max={2000}
              value={textEncodertrainingSteps}
              onChange={(e) => setEncoderTrainingSteps(Number(e))}
            />
            <p className="mt-1 mb-0">{textEncodertrainingSteps}</p>
          </div>
        </Col>
        <Col lg={6}>
          <div className="formGroup">
            <label htmlFor="">Training Steps </label>
            <Slider              
              min={1000}
              max={8000}
              value={trainingSteps}
              onChange={(e) => setTrainingSteps(Number(e))}
            />
            <p className="mt-1 mb-0">{trainingSteps}</p>
          </div>
        </Col>
        <Col lg={12}>
          <div className="d-flex" style={{gap : '15px'}}> 
            <Form.Check 
              type="checkbox"
              id="OffsetNoise"
              label="Offset Noise"
              checked={offsetNoise}
              onChange={() => setOffsetNoise(!offsetNoise)}
            />
            <Form.Check 
              type="checkbox"
              id="SteroidsMode"
              label="Steroids Mode"
              style={{ color: 'yellow' }}
              checked={steroids_mode}
              onChange={() => setSteroidsMode(!offsetNoise)}
            />
          </div>
        </Col>
       </Row>
      )}

      <hr />
        <div className="text-end">
        <Link to="/FactoryWaitPage" onClick={fetchGeneration} className="btn btn-gradient">
          Generate Factory
        </Link>
        </div>
    </div>
  );
}

export default FactoryPopup;
