import axios from "axios";
import React from 'react'
import * as ReactDOM from 'react-dom/client';
import {AchievementPopUp} from "../PopupModal/HeaderPopups/AchievementPopUp";

// This method add 1 to the achievement user ID and give the experience to the user in case the achievement is completed
export function SetAchievement(id: number, addingValue: number = 1, role: string = "user")
{
    console.log("Setting achievement...")
    if(role === "admin")
    {
        SetAchievementServerConnection(id, String(addingValue));
    }
}

// This is to adward the achievements
function AwardAchievement(achId : number)
{
    const root = ReactDOM.createRoot(document.getElementById('popupAchievement')!);
    const popUpParams = {root:root, id: achId};
    const element = <AchievementPopUp {...popUpParams} />;
    root.render(element);
}

// The id for friend achievement is 1 that's why we don't need to introduce here.
// In the backend we control in case the user doesn't exist because the link is something editable by the user.
export function AddAchievementToInvitationSenderUser(token: string) {
    console.log("Validating invitation...");
    const user = parseInt(localStorage.getItem("invitation"), 10);
   // localStorage.removeItem("invitation");
    const config = { headers: { Authorization: `Bearer ${token}` } };
    axios.post(import.meta.env.VITE_SERVER_ADDRESS + "validateInvitation", { user: user }, config);
}

function SetAchievementServerConnection(id: Number, value: String)
{
    const config = {headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }};
    axios.get(import.meta.env.VITE_SERVER_ADDRESS + "setAchievements?id=" + id + "&add=" + value, config)
        .then((response) => {

            if(response.data == "levelUp"){
                AwardAchievement(id)
            }
            console.log(response.data)
        })
        .catch((error) => {
            console.log(error);
        });
}

