import "./AdminPanelPage.css";
import axios from "axios";
import React, { useMemo, useState, useEffect } from "react";
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import PopupShareContent from "../../Components/PopupModal/HeaderPopups/PopupShareContent";
import {Button} from "react-bootstrap";
import {RegisterOrboxUse, ValidateOrboxUse} from "../../Utils/Utils";
import {updateUserData, userDataSelector} from "../../Redux/Slices/userDataSlice";
import {useDispatch, useSelector} from "react-redux";
import UserDetail from "../../Components/UserData/UserData";
import {ProductSection} from "../../Components/AdminPanelComponents/ProductsDetails";
import {UserLookupSection} from "../../Components/AdminPanelComponents/UserLookupSection";
import ProductDetail from "../../Components/AdminPanelComponents/ProductsData";
import AchievementDetail from "../../Components/AdminPanelComponents/AchievementDetail";
import {AchievementSection} from "../../Components/AdminPanelComponents/AchievementSection";

function AdminPanel() {

    const [orboxPrices, setOrboxPrices] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [activeSection, setActiveSection] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [shouldFetchProducts, setShouldFetchProducts] = useState(false);
    const [shouldFetchAchievements, setShouldFetchAchievements] = useState(false);
    const [selectedAchievement, setSelectedAchievement] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);


    const userData = useSelector(userDataSelector);
    const dispatch = useDispatch();

    if (localStorage.getItem("oauthToken") !== "") {
        if(userData.Role != "admin")
        {
            // Redirect to home page
            window.location.href = "/";
            return null;
        }
    }
    else
    {
        window.location.href = "/";
        return null;
    }

    useEffect(() => {
        fetchOrboxPricesForCategory("CoCreation").catch(err => {
            console.error("An error occurred:", err);
        });
    }, []);


    const fetchOrboxPricesForCategory = async (category) => {
        try {
            const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getPriceCategory?category=${category}`);
            setOrboxPrices(response.data);
        } catch (error) {
            console.error("Failed to fetch orbox prices:", error);
        }
    };

    const onHandleUseOrbox = async (productId) => {
        setIsProcessing(true);
        const response = await RegisterOrboxUse(productId,false);
        console.log(response);

        if(response !== "InvalidTransaction") {
            // Update userData with the new values
            const updatedUserData = {
                ...userData,
                Orbox: parseInt(response.Orbox, 10),
                PremiumOrbox: parseInt(response.PremiumOrbox, 10)
            };
            dispatch(updateUserData(updatedUserData));
            console.log("ValidTransaction");
        } else {
            console.log("InvalidTransaction");
        }
        setIsProcessing(false);
    };

    const onHandleValidateOrbox = async (productId) => {
        setIsProcessing(true);
        await ValidateOrboxUse(productId);
        setIsProcessing(false);
    };

    const getPriceForProduct = (productId) => {
        const product = orboxPrices.find(p => p.product_id === productId);
        return product ? product.price : "Loading";
    };

    return (
        <div className="main-content">
            <div className="section-menu">
                <button onClick={() => setActiveSection('testFeatures')}>Test Features</button>
                <button onClick={() => {
                    setActiveSection('userLookup');
                    setFirstLoad(true);
                }}>User Lookup</button>
                <button onClick={() => {
                    setActiveSection('products');
                    setShouldFetchProducts(true);
                }}>Products</button>
                <button onClick={() => {
                    setActiveSection('achievements');
                    setShouldFetchAchievements(true);
                }}>Achievements</button>
            </div>
            {activeSection === 'testFeatures' && (
                <div className="FactoryTitles">
                    <h2>Control Panel</h2>
                    <Button onClick={() => onHandleUseOrbox(9)} disabled={isProcessing}>CoCreate Image</Button>
                    <Button onClick={() => onHandleValidateOrbox(9)} disabled={isProcessing}>Validate Transaction</Button>
                    <p>Price: {getPriceForProduct(9)} Orbox</p>
                    <Button onClick={() => onHandleUseOrbox(10)} disabled={isProcessing}>Complete Image</Button>
                    <p>Price: {getPriceForProduct(10)} Orbox</p>
                    <p>
                        <PopupShareContent
                            className="btn btn-white btn-invite"
                            inviteFriend={true}
                            icon={
                                <lord-icon src="https://cdn.lordicon.com/liqllijy.json" trigger="loop" colors="outline:#121331,primary:#e1dae5" ></lord-icon>
                            }
                            buttonText="Invite Friend"
                        />
                    </p>
                </div>
            )}
            {activeSection === 'userLookup' && (
                <UserLookupSection
                    firstLoad={firstLoad}
                    setFirstLoad={setFirstLoad}
                    setSelectedUser={setSelectedUser}
                    setActiveSection={setActiveSection} />
            )}
            {activeSection === 'UserDetails' && (
                <UserDetail
                    userId={selectedUser}
                    goBack={() => {
                        setActiveSection('userLookup');
                        setFirstLoad(true);  // This will trigger a re-fetch of the data
                    }}
                />
            )}

            {activeSection === 'ProductDetails' && (
                <ProductDetail
                    productId={selectedProduct}
                    goBack={() => setActiveSection('products')}
                    setShouldFetchProducts={setShouldFetchProducts}
                />
            )}
            {activeSection === 'products' &&
                <ProductSection
                    shouldFetchProducts={shouldFetchProducts}
                    setShouldFetchProducts={setShouldFetchProducts}
                    setSelectedProduct={setSelectedProduct}
                    setActiveSection={setActiveSection}
                />}
            {activeSection === 'achievements' &&
                <AchievementSection
                    shouldFetchAchievements={shouldFetchAchievements}
                    setShouldFetchAchievements={setShouldFetchAchievements}
                    setSelectedAchievement={setSelectedAchievement}
                    setActiveSection={setActiveSection}
                />}
            {activeSection === 'achievementDetails' && (
                <AchievementDetail
                    achievementId={selectedAchievement}
                    goBack={() => setActiveSection('achievements')}
                    setShouldFetchAchievements={setShouldFetchAchievements}
                />
            )}
        </div>
    );
}

export default AdminPanel;
