import ReactConfetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import "./CongratulationPage.css"; // Make sure to import your CSS file
import React, {useEffect} from "react";
import {CheckPendingTransaction} from "../../Utils/Utils";

function CongratulationPage() {
    const { width, height } = useWindowSize();

    // Extract URL redirections as constants
    const homePageURL = "/";
    const transactionURL = "/?transaction";

    useEffect(() =>
    {
        CheckPendingTransaction().then(r=>window.location.href = homePageURL).catch(r =>window.location.href = transactionURL);
    }, []);

    return (
        <div className='main-content Congratulation-content'>
            <ReactConfetti
                width={width}
                height={height}
            />
            <div className='Congratulation-info'>
                <h6>Congratulation</h6>
                <p>Your purchase has been processed. Wait here, you will be redirected shortly.</p>
            </div>
        </div>
    );
}

export default CongratulationPage;
