import React, {useState} from "react";
import {Button} from "react-bootstrap";
import {Icon} from "@iconify/react";
import PopupModal from "../../PopupModal/PopupModal";
import {Link} from "react-router-dom";
import {CancelSub} from "../../../Utils/Utils";

type HandleCloseFunction = () => void;

export const CancelSubscriptionPopUp = ({ show, handleClose }: { show: boolean; handleClose: HandleCloseFunction }) =>
{
    const [cancelingSubscription, setCancelingSubscription] = useState(false);

    const CancelSubscription = async () =>
    {
        setCancelingSubscription(true);
        await CancelSub().then(async () => {
            window.location.reload();
        }).catch( () => {
            setCancelingSubscription(false);
            show = false;
        });
    };

    return (
        <PopupModal show={show} handleClose={{handleClose}} className={undefined} backdrop={undefined}>
            {cancelingSubscription ?  <h3 className="text-white"> Canceling Subscription</h3> :
                <><Link to="#" className="CloseBtn" onClick={handleClose}>
                    <Icon icon="ion:close-circle" color="white"/>
                </Link>
                <div>
                    <h3 className="text-white"> Are you sure you want to cancel your subscription?</h3>
                    <p></p>
                    <hr/>
                    <div className="text-center">
                        <Button
                            variant=""
                            type="button"
                            className="btn-gradient"
                            onClick={handleClose}>
                             No
                        </Button>
                        <Button
                            variant=""
                            type="button"
                            className="btn-gradient"
                            onClick={CancelSubscription}>
                             Yes
                        </Button>
                    </div>
                </div>
                </>
            }
        </PopupModal>
    );
};