import { Alchemy, Network } from "alchemy-sdk";
import { useEffect, useState } from "react";
import axios from "axios";
import NFTCard from '../NFTCard/NFTCard';
import NFTCardLZ from '../NFTCard/NFTCardLZ';
import './TokenizeAssets.css';
import {useAccount} from "wagmi";


function TokenizeAssets() 
{
    const [tokenURIs, setTokenURIs] = useState([]);
    const [omniDATA, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [, setError] = useState(null);

    const config = {
        apiKey: import.meta.env.VITE_REACT_ALCHEMY_ID,
        network: Network.MATIC_MUMBAI,
      };
    new Alchemy(config);
    // eslint-disable-next-line no-unused-vars
    const { address, isConnecting, isDisconnected } = useAccount()

    // contract calls



    useEffect(() =>
    {
        const fetchDataFromAPI = async () =>
        {
            setLoading(true); // Set loading to true before making the API call
            try 
            {
                const response = await axios.get(`https://5qay1k2tmaxmqf-6000.proxy.runpod.net/get_nfts_by_user/${address}`);
                if (response.status === 200)
                {
                   
                    setData(response.data);
                    return response.data
                }
                else
                {
                    
                    setError('Error fetching data: ' + response.statusText);
                }
            }
            catch (error)
            {
                setError('Error fetching data: ' + error);
            }
            finally
            {
                setLoading(false); // Set loading to false after the API call is complete

            }
        };

        const getLZData = async () => {
            // Get all NFTs
            const response = await fetchDataFromAPI();
         
            const ipfsDataPromises = [];
        
            for (let token of Object.values(response.nfts)) {
                const promise = axios.get(token.tokenURI)
                    .then(response => ({
                        contractAddress: token.contract_address,
                        chainID: token.chainID,
                        data: response.data,
                        onchain: token
                    }))
                    .catch(error => {
                        console.error("Error fetching tokenURI:", token.tokenURI, error);
                        return null;  // or return some default data
                    });
        
                ipfsDataPromises.push(promise);
            }
        
            try {
                const ipfsData = await Promise.all(ipfsDataPromises);
                const filteredData = ipfsData.filter(item => item !== null); // remove nulls (or defaults) from the result
             
                setTokenURIs(filteredData);
            } catch (error) {
                console.error("Error executing Promise.all:", error);
            }
        };
        
        getLZData().then(r => {});

    }, [address]);

  return (
        <>
            <div className="AiAssetsImgWrap">
                
                    {
                        loading ? (
                        <div className="FactoryTitles">
                            <h2 style={{ fontSize: '16px' }}>Loading your NFTs... 🔄</h2>
                        </div>
                        ) : (
                        !isDisconnected && address ? (
                            tokenURIs.length > 0 ? (
                            <>
                                {tokenURIs.slice().reverse().map((tokenData, index) => (
                                <figure className="AiAssets-img" key={index}>
                                    {
                                     tokenData.onchain.islZ ? <NFTCardLZ nft={tokenData} /> : <NFTCard nft={tokenData} />
                                    }

                                </figure>
                                ))}
                            </>
                            ) : (
                            <div className="FactoryTitles w-100 my-5">
                                <p className="mb-0" style={{ fontSize: '16px' }}>
                                    <strong>No NFTs found for this address. 😢</strong>
                                </p>
                            </div>
                            )
                        ) : (
                            <div className="FactoryTitles w-100 my-5">
                                <p className="mb-0" style={{ fontSize: '16px' }}><strong>Please connect your wallet to view your NFTs 😁</strong></p>
                            </div>
                        )
                        )
                    }
                            
            </div>
        </>

  );
}

export default TokenizeAssets;

