class Subscription {
    id: number;
    UserId: number;
    SubscriptionLevel: number;
    SubscriptionExpDate: string;
    RemainingSubMonths: number;
    DirectDebitProvider: string;
    CreatedAt: string;
    Recurrent: boolean;

    constructor(
        id: number = -1,
        userId: number = -1,
        subscriptionLevel: number = -1,
        subscriptionExpDate: string = "",
        remainingSubMonths: number = 0,
        directDebitProvider: string = "",
        createdAt: string = "",
        recurrent: boolean = false,
    ) {
        this.id = id;
        this.UserId = userId;
        this.SubscriptionLevel = subscriptionLevel;
        this.SubscriptionExpDate = subscriptionExpDate;
        this.RemainingSubMonths = remainingSubMonths;
        this.DirectDebitProvider = directDebitProvider;
        this.CreatedAt = createdAt;
        this.Recurrent = recurrent;
    }
}

export default Subscription;