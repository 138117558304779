import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Image, Row, Modal } from "react-bootstrap";
import { Icon } from "@iconify/react";
import Slider from "rc-slider";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import Switch from "react-switch";
import "react-circular-progressbar/dist/styles.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import axios from "axios";

import {
    collection,
    onSnapshot,
    updateDoc,
    increment,
} from "@firebase/firestore";
import PopupModal from "../../Components/PopupModal/PopupModal";
import AudioCard from "../../Components/AudioCard/audiocard";
import ImagePopup from "../../Components/ImagePopup/ImagePopup";
import TokenizeForm from "../../Components/TokenizeForm/TokenizeForm";
import { Fancybox } from "@fancyapps/ui";
import { doc, getDoc } from "firebase/firestore";
import styled, { keyframes } from "styled-components";
import { categoryList } from "../../Utils/Constants";
import { getFirestoreInstance, RegisterOrboxUse, SetTimerRates } from "../../Utils/Utils";
import Select from "react-select";
import "./FactoryDetailPage.css";
import PopupShareContent from "../../Components/PopupModal/HeaderPopups/PopupShareContent";
import { useDispatch, useSelector } from "react-redux";
import { showPopUpAction } from "../../Redux/Slices/loginPopUpSlice";
import { updateUserData, userDataSelector } from "../../Redux/Slices/userDataSlice";

Fancybox.bind("[data-fancybox=\"gallery\"]", {
    //
});
Fancybox.bind("[data-fancybox=\"AIGeneratedImg\"]", {
    //
});

// TODO: Move style codes to a Stylesheet

const fadeUpAndOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

// eslint-disable-next-line no-unused-vars
const LikeCircle = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const LikeAnimation = styled.div`
  position: absolute;
  animation: ${fadeUpAndOut} 1s ease-out forwards;
  opacity: 0;
`;

const FactoryDetailPage = ({ setShowLoginPopup }) => {
    const [selectedImageUrl, setSelectedImageUrl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();

    const openModal = (imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [guidanceScale, setGuidanceScale] = useState(7);
    const [steps, setSteps] = useState(30);
    const [showTokenNize, setShowTokenNize] = useState(false);
    const [isDownloadingModel, setDownloadingModel] = useState(false);
    const [numImage, setNumImage] = useState(4);
    const [factoryList, setFactoryList] = useState([]);
    const [modelUid, setModelUid] = useState("");
    const authToken = localStorage.getItem("oauthToken");
    const [isGenerating, setIsGenerating] = useState(false);
    const [hasGenerated, setHasGenerating] = useState(false);
    const [message, setMessage] = useState("");
    const [generatedImages, setGeneratedImages] = useState([]);
    const [generatedSongs, setGeneratedSongs] = useState([]);
    const [selectedImage, setSelectedImage] = useState([]);
    const searchParam = useParams();
    const qty = searchParam.uid;
    const [showImagePopup, setShowImagePopup] = useState(false);
    const [ShowFactoryPopup, SetshowFactoryPopup] = useState(false);
    const [selectedImageDetails, setSelectedImageDetails] = useState({});
    const [imgSrc, setImgSrc] = useState("");
    const [factoryName, setFactoryName] = useState("");
    const [factoryDesc, setFactoryDesc] = useState("");
    const [profile_picture, setProfile] = useState("");
    const [creator, setCreator] = useState("");
    const [recentGenerations, setRecentGenerations] = useState([]);
    const [recentSongGenerations, setRecentSongGenerations] = useState([]);
    const [likes, setLikes] = useState("");
    const [numGeneration, setNums] = useState("");
    const [category, setCategory] = useState("");
    const [, setIsPopupClosed] = useState(false);
    const [loading] = useState(false);
    const [newFactoryLoading, setNewFactoryLoading] = useState(true);
    const [loadingMessage] = useState("");
    const userData = useSelector(userDataSelector);
    const [showAnimation, setShowAnimation] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [orboxPrices, setOrboxPrices] = useState([]);

    const [allCategories, setAllCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [filteredFactory, setFilteredFactory] = useState([]);
    const [displayedFactoryIndex, setDisplayedFactoryIndex] = useState(null);

    const [queueId, setQueueId] = useState(null);
    const [, setQueuePosition] = useState(null);
    const [, setResult] = useState(null);

    const [show, setShow] = useState(false);
    const [error, setError] = useState("");

    const [modelUrl, setModelURL] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectedImages, setSelectedImages] = useState([]);
    const [addedPrompt, setAddedPrompt] = useState("");

    const handleImageClick = (image) => {
        if (selectedImages.includes(image.url)) {
            setSelectedImages(selectedImages.filter((item) => item !== image.url));
            setAddedPrompt(addedPrompt.replace(image.prompt, ""));
        } else {
            setSelectedImages([...selectedImages, image.url]);
            setAddedPrompt((addedPrompt + " " + image.prompt).split(/\s+/).join(" "));
        }
    };

    const [privateMode, setPrivateMode] = useState(false);

    const handleToggle = () => {
        setPrivateMode(!privateMode);
    };

    const downloadModel = () => {
        window.open(modelUrl);
    };

    const imageList = [
        {
            url: "https://storage.googleapis.com/generativeartbucket1/eezyl//20230608-153327-2.webp",
            title: "Digital Art",
            prompt:
                "protogemb, rzminjourney, WPAP, InkPunk768, cartoonish_doll, rzviceb6",
        },
        {
            url: "https://storage.googleapis.com/generativeartbucket1/eezyl//20230610-070302-2.webp",
            title: "Digital Art 2",
            prompt: "0xroyalty-2000, protogemb, rzminjourney",
        },
        {
            url: "https://storage.googleapis.com/generativeartbucket1/eezyl//20230610-070534-1.webp",
            title: "Cyberpunk",
            prompt:
                "Ross Tran, artgerm, InkPunk768, cyberpunk rock, dark dystopian, by Mike Campau",
        },
        {
            url: "https://storage.googleapis.com/generativeartbucket1/eezyl//20230610-065801-1.webp",
            title: "Anime",
            prompt: "SDA768",
        },
        {
            url: "https://storage.googleapis.com/generativeartbucket1/eezyl//20230610-070005-1.webp",
            title: "GTA",
            prompt: "GTA768, protogemb, cartoonish_doll, midj",
        },
        {
            url: "https://storage.googleapis.com/generativeartbucket1/eezyl//20230610-070145-0.webp",
            title: "InkPunk",
            prompt: "artgerm, InkPunk768",
        },
        {
            url: "https://storage.googleapis.com/generativeartbucket1/eezyl//20230610-070809-1.webp",
            title: "Fantasy",
            prompt: "by Ross Tran, dgtlv2, fantasy, protogemb",
        },
    ];

    const navigate = useNavigate();

    useEffect(() => {
        setAllCategories(categoryList);
    }, []);

    const onHandleSelectCategory = (selectedOptions) => {
        const selectedCat = selectedOptions.map((option) => option.value);
        const catArray = allCategories.map((category) => ({
            ...category,
            checked: selectedCat.includes(category.title),
        }));

        const filteredData = factoryList.filter((doc) =>
            selectedCat.includes(doc.category)
        );

        setFilteredFactory(filteredData);
        setSelectedCategory(selectedCat);
        setAllCategories(catArray);
    };

    useEffect(() => {
        if (modelUid === "") {
            if (qty != null) {
                setModelUid(qty);
            } else {
                window.location.href = "https://www.orbofi.com/Factory";
            }
        }
    }, [modelUid, qty]);

    const db = getFirestoreInstance();

    const docRef = doc(collection(db, "models"), qty);

    const DownloadIcon = ({ size = 24, color = "black" }) => (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="7 10 12 15 17 10"></polyline>
            <line x1="12" y1="15" x2="12" y2="3"></line>
        </svg>
    );

    const handleLike = async () => {
        try {
            await updateDoc(docRef, { likes: increment(1) });

            setShowAnimation(true);
            setTimeout(() => setShowAnimation(false), 1000);

            // Update likes state after successful Firebase operation
            setLikes((likes) => likes + 1);
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };
    useEffect(() => {
        if (qty) {
            const colRef = collection(db, "models");
            setModelUid(qty);
            setRecentGenerations([]);
            const unsubListener = onSnapshot(colRef, {
                next: (snapshot) => {
                    const lst = snapshot.docs.map((doc) => ({
                        img: doc.data()["model_photo_url"],
                        uid: doc.data()["model_id"],
                        numGeneration: doc.data()["numGeneration"],
                        usage: doc.data()["usage"],
                        creator: doc.data()["creator"],
                        model_desc: doc.data()["model_desc"],
                        model_name: doc.data()["model_name"],
                        creation_date: doc.data()["creation_date"]["seconds"],
                        likes: doc.data()["likes"],
                        category: doc.data()["category"],
                        profile_picture: doc.data()["profile_picture"],
                        model_url: doc.data()["model_url"],
                        recent_generations: [],
                    }));
                    lst.forEach((element) => {
                        if (element.uid === qty) {
                            setFactoryName(element["model_name"]);
                            setFactoryDesc(element["model_desc"]);
                            setLikes(element["likes"]);
                            setCreator(element["creator"]);
                            setNums(element["usage"]);
                            setCategory(element["category"]);
                            setImgSrc(element["img"][0]);
                            setProfile(element["profile_picture"]);
                            setModelURL(element["model_url"]);

                            if (element.uid === "orbofi-theory") {
                                setGuidanceScale(5);
                            }
                        }
                    });
                },
                error: (err) => {
                    console.error(err);
                    // TODO: handle error (signed out? no permission? no connection?)
                },
            });
            return unsubListener;
        }
    }, [db, qty]);

    useEffect(() => {
        if (qty === "text-to-music") {
            const colRef = doc(db, "FactoryRecents", qty);
            setModelUid(qty);
            setRecentGenerations([]);
            const unsubListener = onSnapshot(colRef, {
                next: (snapshot) => {
                    setRecentSongGenerations(
                        snapshot.data()["recent_generations"].reverse()
                    );
                },
                error: (err) => {
                    console.error(err);
                    // TODO: handle error (signed out? no permission? no connection?)
                },
            });
            return unsubListener;
        } else if (qty === "text-to-animation") {
            const colRef = doc(db, "FactoryRecents", qty);
            setModelUid(qty);
            setRecentGenerations([]);
            const unsubListener = onSnapshot(colRef, {
                next: (snapshot) => {
                    const data = snapshot.data()["recent_generations"].reverse();
                    const gens = data.slice(0, 7);
                    setRecentGenerations(gens);
                },
                error: (err) => {
                    console.error(err);
                    // TODO: handle error (signed out? no permission? no connection?)
                },
            });
            return unsubListener;
        } else {
            const colRef = doc(db, "FactoryRecents", qty);
            setModelUid(qty);
            const unsubListener = onSnapshot(colRef, {
                next: (snapshot) => {
                    setRecentGenerations(snapshot.data()["recent_generations"].reverse());
                },
                error: (err) => {
                    console.error(err);
                    // TODO: handle error (signed out? no permission? no connection?)
                },
            });
            return unsubListener;
        }
    }, [db, qty]);

    useEffect(() => {
        if (qty) {
            setModelUid(qty);
            setGeneratedImages([]);
            setHasGenerating(false);
        }
    }, [qty]);

    const onCloseModal = () => {
        setShowTokenNize(false);
        setIsPopupClosed(true);
    };

    const onCloseImageModal = () => {
        setShowImagePopup(false);
        setSelectedImageDetails({});
    };

    // eslint-disable-next-line no-unused-vars
    const onHandleSubmit = (data) => { };

    // eslint-disable-next-line no-unused-vars
    const onShowImagePopup = (image) => {
        setShowImagePopup(true);
        setSelectedImageDetails(image);
    };

    const handlePromptChange = (event) => {
        setMessage(event.target.value);
    };

    const [countDown, setCountDown] = React.useState(0);
    const [runTimer, setRunTimer] = React.useState(false);
    const [timerRate, setTimerRate] = useState(4000);

    React.useEffect(() => {
        let timerId;

        if (runTimer) {
            setCountDown(0);
            timerId = setInterval(() => {
                setCountDown((countDown) => countDown + 1);
            }, timerRate);
        } else {
            clearInterval(timerId);
        }

        return () => clearInterval(timerId);
    }, [runTimer, timerRate]);

    React.useEffect(() => {
        if (countDown > 100 && runTimer) {
            setRunTimer(false);
            setCountDown(0);
        }
    }, [countDown, runTimer]);

    React.useEffect(() => {
        if (countDown > 100 && runTimer) {
            setRunTimer(false);
            setCountDown(0);
        }
    }, [countDown, runTimer]);

    useEffect(() => {
        if (queueId) {
            const interval = setInterval(async () => {
                try {
                    const response = await axios.get(
                        `https://yjbx86uhoesmxn-4003.proxy.runpod.net/queue_position/${queueId}`
                    ); // adjust with your API base url
                    const position = response.data.queue_position; // adjust this based on the API response
                    setQueuePosition(position);

                    if (position === null) {
                        const resultResponse = await axios.get(
                            `https://yjbx86uhoesmxn-4003.proxy.runpod.net/generated_imgs/${queueId}`
                        ); // adjust with your API base url
                        setResult(resultResponse.data.generationUrls); // adjust this based on the API response
                        clearInterval(interval);

                        let allImages = [];
                        resultResponse.data.generationUrls.map((image) => {
                            const newData = {
                                imageUrl: image,
                                isChecked: false,
                            };
                            allImages.push(newData);
                            return allImages;
                        });

                        setGeneratedImages(allImages);
                        setSelectedImage([]);

                        setRunTimer((t) => false);
                        setCountDown(0);
                        setIsGenerating((t) => false);
                        setHasGenerating(true);
                        setQueueId(null);
                    }
                } catch (error) {
                    console.error(error);
                }
            }, 5000); // Adjust this interval based on your need. For this example, it's checking every 5 seconds.

            return () => clearInterval(interval);
        }
    }, [queueId]);

    const validateOrboxForFetch = async () => {
        if (!authToken) {
            dispatch(showPopUpAction(true));
            return;
        }
        setIsProcessing(true);
        const response = await RegisterOrboxUse(9, true, numImage);

        if (response !== "InvalidTransaction") {
            const updatedUserData = {
                ...userData,
                Orbox: parseInt(response.Orbox, 10),
                PremiumOrbox: parseInt(response.PremiumOrbox, 10)
            };
            dispatch(updateUserData(updatedUserData));
            await fetchGeneration();
        } else {
            console.log("InvalidTransaction");
        }
        setIsProcessing(false);
    };

    const fetchImage = async () => {
        try {
            const response = await fetch('https://api.runpod.ai/v2/sdxl/runsync', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'XNLHZ5FP018JAYKXTFE4AJJHR32E1BR1DLUPT1VJ', // This should be stored securely
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    input: {
                        prompt: message + " " + modelUid,
                        num_inference_steps: 25,
                        refiner_inference_steps: 50,
                        width: 1024,
                        height: 1024,
                        guidance_scale: 8.5,
                        strength: 0.8,
                        seed: null,
                        num_images: 1
                    }
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            return data.output.images[0]; // Extracting the first image from the response
        } catch (error) {
            console.error('Error in fetchImage:', error);
            return null; // Return null in case of an error
        }
    };

    const fetchImage2 = async () => {
        try {
            console.log("ORBOFI THEORY CALL");
            const response = await fetch('https://api.runpod.ai/v2/nty5zc4anzr6ic/runsync', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'XNLHZ5FP018JAYKXTFE4AJJHR32E1BR1DLUPT1VJ', // This should be stored securely
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    input: {
                        prompt: message,
                        steps: 25,
                        // refiner_inference_steps: 50,
                        width: 768,
                        height: 768,
                        cfg_scale: 2.5,
                        // strength: 0.8,
                        // seed: null,
                        // num_images: 1
                    }
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log(data['output'][0]);
            return data['output'][0]; // Extracting the first image from the response
        } catch (error) {
            console.error('Error in fetchImage:', error);
            return null; // Return null in case of an error
        }
    };

    const fetchGeneration = async () => {
        const controller = new AbortController();
        const signal = controller.signal;

        setGeneratedSongs([]);
        setGeneratedImages([]);

        setTimeout(() => {
            controller.abort();
        }, 120000); // 2 minutes (120000 milliseconds) timeout



        if (authToken) {
            let numImages = [4, 5, 6];
            let timerRates = [3500, 3700, 4000, 10300];
            SetTimerRates(numImage, numImages, setTimerRate, timerRates);


            setIsGenerating((t) => true);
            setDownloadingModel(false);

            setHasGenerating((t) => false);
            setRunTimer((t) => true);
            var allImages = [];
            var prompts = [message, message, message, message];
            // Promise.all(prompts.map((prompt, i) => {
            //     const postData = {
            //         model: "dall-e-3",
            //         prompt: prompt,
            //         n: 1,
            //         size: "1024x1024"
            //     };

            //     return axios.post("https://api.openai.com/v1/images/generations", postData, {
            //         headers: {
            //             "Content-Type": "application/json",
            //             "Authorization": "Bearer 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"
            //         }
            //     })
            //         .then(response => {
            //             // Process each response
            //             console.log(response.data.data[0].url);
            //             // let allImages = [];
            //             // data.map((image) => {
            //             const newData = {
            //                 imageUrl: response.data.data[0].url,
            //                 isChecked: false,
            //             };
            //             allImages.push(newData);
            //             // });


            //             // allImages.push(response.data.data[0].url);
            //             // images.push(response.data.data[0].url);
            //             // setImagesStatus(prevStatus => {
            //             //   const newStatus = [...prevStatus];
            //             //   newStatus[i] = "loaded";
            //             //   return newStatus;
            //             // });
            //         })
            //         .catch(error => {
            //             // Handle errors
            //             console.error("Error generating image for prompt:", prompt, error);
            //             // setIsLoading(false);
            //         });
            // }))
            console.log("CALLING API");
            var promises;
            if(modelUid =="orbofi-theory"){
                promises = Array(4).fill().map(() => fetchImage2());
            }
            else {
                promises = Array(4).fill().map(() => fetchImage());
            }
            

            const results = await Promise.all(promises);
            console.log(results); // Array of responses
            // const extractedImages = results.map(result => result.output.images[0]);


            // .then(async (data) => {
            //     // let allImages = [];
            results.map((image) => {
                const newData = {
                    imageUrl: image,
                    isChecked: false,
                };
                allImages.push(newData);
                return allImages;
            });
            // console.log(allImages);
            setGeneratedImages(allImages);
            setSelectedImage([]);

            setRunTimer((t) => false);
            setCountDown(0);
            setIsGenerating((t) => false);
            setHasGenerating(true);
            // })
            //     .catch((error) => {
            //         setError(error.message);
            //         handleShow();
            //         setRunTimer((t) => false);
            //         setCountDown(0);
            //         setIsGenerating((t) => false);
            //         setHasGenerating(false);
            //     });
            // eslint-disable-next-line no-unused-vars
            const { timeout = 8000 } = options;

            let prompt = message + addedPrompt;
            const result = prompt.split(/\s+/).join(" ");

            // await fetch(
            //     `https://yjbx86uhoesmxn-3000.proxy.runpod.net/factory_inference?model_uid=${modelUid}&prompt=${result}&cgs=${guidanceScale}&steps=${steps}&n_imgs=${numImage}&email=${userData?.Email
            //     }&privateMode=${privateMode === false ? 0 : 1}`,
            //     {
            //         headers: {
            //             connection: "keep-alive",
            //         },
            //         keepalive: true,
            //         timeout: 8000000,
            //     }
            // )
            //     .then((response) => response.json())
            //     .then(async (data) => {
            //         let allImages = [];
            //         data.map((image) => {
            //             const newData = {
            //                 imageUrl: image,
            //                 isChecked: false,
            //             };
            //             allImages.push(newData);
            //             return allImages;
            //         });
            //         setGeneratedImages(allImages);
            //         setSelectedImage([]);

            //         setRunTimer((t) => false);
            //         setCountDown(0);
            //         setIsGenerating((t) => false);
            //         setHasGenerating(true);
            //     })
            // .catch((error) => {
            //     setError(error.message);
            //     handleShow();
            //     setRunTimer((t) => false);
            //     setCountDown(0);
            //     setIsGenerating((t) => false);
            //     setHasGenerating(false);
            // });
        } else {
            dispatch(showPopUpAction(true));
        }
        return () => {
            controller.abort();
        };
    };

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    color: "rgba(255, 255, 255, 0.1)",
                },
                ticks: {
                    color: "white", // Change the color of the x-axis labels
                },
            },
            y: {
                grid: {
                    color: "rgba(255, 255, 255, 0.1)",
                },
                ticks: {
                    color: "white", // Change the color of the x-axis labels
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
    };

    const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    const data = {
        labels,
        datasets: [
            {
                label: "",
                data: [10, 20, 30, 40, 20, 30, 50],
                barPercentage: 0.2,
                backgroundColor: "#36E899",
            },
        ],
    };

    useEffect(() => {
        document.body.classList.add("FactoryPage");
    }, []);

    const onSelectImages = (image, index) => {
        let allImage = selectedImage;
        let updateGeneratedImage = [...generatedImages];
        if (image.isChecked) {
            updateGeneratedImage[index].isChecked = false;
            const imgIndex = allImage.indexOf(image.imageUrl);
            if (index > -1) {
                allImage.splice(imgIndex, 1);
            }
        } else {
            updateGeneratedImage[index].isChecked = true;
            allImage.push(image.imageUrl);
        }
        setGeneratedImages(updateGeneratedImage);
        setSelectedImage(allImage);
    };
    const onShowTokenize = () => {
        if (authToken) {
            setShowTokenNize(true);
        } else {
            dispatch(showPopUpAction(true));
        }
    };

    const onSelectAllImage = () => {
        let updateGeneratedImage = [...generatedImages];
        let allImage = [];
        updateGeneratedImage.map((image) => {
            allImage.push(image.imageUrl);
            return (image.isChecked = true);
        });
        setGeneratedImages(updateGeneratedImage);
        setSelectedImage(allImage);
    };

    useEffect(() => {
        setNewFactoryLoading(true);
        const colRef = collection(db, "models");
        onSnapshot(colRef, {
            next: (snapshot) => {
                snapshot.docs.forEach((doc) => { });
                const lst = snapshot.docs.map((doc) => {
                    return {
                        img: doc.data()["model_photo_url"][0],
                        uid: doc.data()["model_id"],
                        numGeneration: doc.data()["numGeneration"],
                        creator: doc.data()["creator"],
                        model_desc: doc.data()["model_desc"],
                        model_name: doc.data()["model_name"],
                        creation_date: doc.data()["creation_date"]["seconds"],
                        likes: doc.data()["likes"],
                        category: doc.data()["category"],
                    };
                });
                const sorted = lst.sort((a, b) =>
                    a.numGeneration < b.numGeneration ? 1 : -1
                );
                const checkIndex = sorted.findIndex((item) => item.uid === qty);
                if (displayedFactoryIndex >= 0) {
                    setDisplayedFactoryIndex(checkIndex);
                }
                setFactoryList(sorted);
                setNewFactoryLoading(false);
            },
            error: (err) => {
                // TODO: handle error (signed out? no permission? no connection?)
            },
        });
    }, [db, displayedFactoryIndex, qty]);

    const onChangeFactory = (uid, facIndex) => {
        setDisplayedFactoryIndex(facIndex);
        navigate("/FactoryView/" + uid);
    };

    useEffect(() => {
        fetchOrboxPricesForCategory("CreateImage").catch(err => {
            console.error("An error occurred:", err);
        });
    }, []);

    const fetchOrboxPricesForCategory = async (category) => {
        try {
            const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getPriceCategory?category=${category}`);
            setOrboxPrices(response.data);
        } catch (error) {
            console.error("Failed to fetch orbox prices:", error);
        }
    };

    const getPriceForProduct = (productId) => {
        const product = orboxPrices.find(p => p.product_id === productId);
        return product ? product.price : "Loading";
    };

    const onGoPrevFactory = (facIndex) => {
        const gotoIndex = facIndex - 1;
        setNewFactoryLoading(true);
        navigate("/FactoryView/" + factoryList[gotoIndex].uid);
        setDisplayedFactoryIndex(gotoIndex);
        setTimeout(() => {
            setNewFactoryLoading(false);
        }, 1000);
    };

    const onGoNextFactory = (facIndex) => {
        const gotoIndex = facIndex + 1;
        setNewFactoryLoading(true);
        navigate("/FactoryView/" + factoryList[gotoIndex].uid);
        setDisplayedFactoryIndex(gotoIndex);
        setTimeout(() => {
            setNewFactoryLoading(false);
        }, 1000);
    };
    const categoryOptions = categoryList.map((category) => ({
        value: category.title,
        label: category.title,
    }));
    return !newFactoryLoading ? (
        <div className="Factory-content">
            <Row className="FactoryRow">
                <Col lg={3}>
                    <div className="FdLeftMain">
                        <div className="BoxShadowMain BoxShadowMainHeader">
                            <div className="FdLeftHeader FDShap01">
                                <div className="FDShapInner01">
                                    <h3 onClick={() => SetshowFactoryPopup(true)}>
                                        {factoryName}
                                        <lord-icon
                                            src="https://cdn.lordicon.com/cdkjzgmd.json"
                                            trigger="loop"
                                            colors="primary:#3A3E5C,secondary:#36e899"
                                        ></lord-icon>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="BoxShadowMain BoxShadowMainProfile">
                            <div className="FdLeftProfile FDShap01">
                                <div className="FDShapInner01">
                                    <div className="FdLeftProfileImg">
                                        <Image src={imgSrc} />

                                        <p className="FactoryShare">
                                            <PopupShareContent
                                                popupTitle="Share this AI model"
                                                icon={
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/liqllijy.json"
                                                        trigger="hover"
                                                        colors="outline:#121331,primary:#ffffff"
                                                    ></lord-icon>
                                                }
                                                link={window.location.href}
                                            />
                                        </p>
                                    </div>
                                    <p>{factoryDesc}</p>
                                </div>
                            </div>
                        </div>
                        <div className="BoxShadowMain BoxShadowMainUser BoxShadowMainUser2">
                            <div className="FdLeftUser FDShap02">
                                <div className="FDShapInner02">
                                    <div className="FdLeftUserImg">
                                        <Image src={profile_picture} />
                                    </div>
                                    <h6>
                                        {creator}
                                        <span>Creator</span>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <Button
                            variant=""
                            className="btn btn-green btn-polygn btn mt-3 btn-mobile-hide"
                            onClick={() => onGoPrevFactory(displayedFactoryIndex)}
                        >
                            Prev
                        </Button>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="FdCenterMain">
                        <div className="BoxShadowMain BoxShadowCenterHeader">
                            <div className="FdCenterHeader FDShap01">
                                <div className="FDShapInner01">
                                    <ul className="CenterHeaderInfo">
                                        <li>
                                            <span>
                                                <lord-icon
                                                    src="https://cdn.lordicon.com/lgjuecfm.json"
                                                    colors="primary:#ffffff,secondary:#36e899"
                                                    trigger="loop"
                                                ></lord-icon>
                                            </span>
                                            <h6>{category}</h6>
                                        </li>
                                        <li>
                                            <div
                                                className="heart-Flexwrap"
                                                style={{ display: "flex", alignItems: "center" }}
                                            >
                                                {" "}
                                                {showAnimation && <LikeAnimation>+1</LikeAnimation>}
                                                <button className={"like-button"} onClick={handleLike}>
                                                    ❤
                                                </button>
                                                <div
                                                    style={{ marginBottom: "10px", marginRight: "9px" }}
                                                >
                                                    <h6 className="likes-count">
                                                        {likes} {likes === 1 ? "Like" : "Likes"}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div
                                                className="switchFlex"
                                                style={{ display: "flex", alignItems: "center" }}
                                            >
                                                <div
                                                    className="switchFlexOrder"
                                                    style={{ marginRight: "8px", MaxWidth: "178px" }}
                                                >
                                                    <h6>Private Mode: {privateMode ? "ON" : "OFF"}</h6>
                                                </div>

                                                <Switch
                                                    className="switchBox"
                                                    onChange={handleToggle}
                                                    checked={privateMode}
                                                    offColor="#ccc"
                                                    onColor="#36e899"
                                                    height={21}
                                                    width={48}
                                                    handleDiameter={25}
                                                />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="BoxShadowMain BoxShadowCenterGenratedImg">
                            <div className="FdGenRatedImg FDShap01 FDShap04">
                                <div className="FDShapInner01">
                                    {isGenerating ? (
                                        <div className="progressDiv progressDiv02">
                                            <div className="progressClass">
                                                <CircularProgressbarWithChildren
                                                    styles={{
                                                        path: { stroke: "#36e899" },
                                                        trail: { stroke: "#ffffff14" },
                                                    }}
                                                    className="ProgressMainInfo"
                                                    value={countDown}
                                                >
                                                    <div className="ProgressInfo">
                                                        <img src="/images/big-logo-icon.png" alt="doge" />
                                                        <strong>{countDown}%</strong>
                                                    </div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                        </div>
                                    ) : null}
                                    {isDownloadingModel ? (
                                        <div className="progressDiv progressDiv02">
                                            <div className="progressClass2">
                                                Model is downloading for the first time.. (Stay on Page.
                                                It Will Take 2 minutes)
                                            </div>
                                        </div>
                                    ) : null}
                                    {isGenerating ? null : (
                                        <>
                                            <div
                                                id="generatedImages"
                                                className={`TextAiImgWrap mt-0 ${generatedImages.length <= 10
                                                    ? `image-${generatedImages.length}`
                                                    : "image-default"
                                                    }`}
                                            >
                                                {generatedImages.length > 0 &&
                                                    generatedImages?.map((image, index) => (
                                                        <figure className="AIGenerated-img" key={index}>
                                                            <a
                                                                className="AI_Img"
                                                                data-fancybox="AIGeneratedImg"
                                                                href={image.imageUrl}
                                                            >
                                                                <img
                                                                    className="rounded"
                                                                    src={image.imageUrl}
                                                                    alt={""}
                                                                />
                                                            </a>
                                                            <ul className="AIGenerated-buttons">
                                                                <li></li>
                                                                <li></li>
                                                                <li>
                                                                    <Link target="_blank" to={image.imageUrl}>
                                                                        <lord-icon
                                                                            trigger="hover"
                                                                            src="/images/icons/download.json"
                                                                        ></lord-icon>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#">
                                                                        <lord-icon
                                                                            trigger="click"
                                                                            src="/images/icons/star.json"
                                                                        ></lord-icon>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to={"/ChatBot?url=" + image.imageUrl}>
                                                                        <lord-icon
                                                                            trigger="hover"
                                                                            src="/images/icons/people02.json"
                                                                        ></lord-icon>
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                            <label
                                                                className="ImgCheckBox"
                                                                htmlFor={image.imageUrl}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    id={image.imageUrl}
                                                                    value={image.imageUrl}
                                                                    checked={image.isChecked}
                                                                    onClick={() => onSelectImages(image, index)}
                                                                />
                                                                <span>
                                                                    <Icon icon="mdi:tick" />
                                                                </span>
                                                            </label>
                                                            <Button
                                                                type="button"
                                                                variant=""
                                                                disabled={!image.isChecked}
                                                                onClick={onShowTokenize}
                                                                className="btn btn-white BtnTokenize"
                                                            >
                                                                Tokenize
                                                            </Button>
                                                        </figure>
                                                    ))}
                                                {generatedSongs.length > 0 &&
                                                    generatedSongs?.map((image, index) => (
                                                        <div className="AudioCardGenrate" key={index}>
                                                            <figure className="AIGenerated-img" key={index}>
                                                                <AudioCard
                                                                    songUrl={image.generated_mp3}
                                                                    songName={image.prompt}
                                                                    backgroundImage={image.generated_thumbnail}
                                                                ></AudioCard>
                                                            </figure>
                                                        </div>
                                                    ))}
                                            </div>
                                            {hasGenerated ? (
                                                <div className="text-center mt-3">
                                                    {selectedImage.length > 1 ? (
                                                        <Link
                                                            to="#"
                                                            onClick={onShowTokenize}
                                                            className="btn btn-white"
                                                        >
                                                            Tokenize Collection
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            to="#"
                                                            onClick={onSelectAllImage}
                                                            className="btn btn-white"
                                                        >
                                                            Select All
                                                        </Link>
                                                    )}
                                                </div>
                                            ) : null}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="BoxShadowMain BoxShadowMainTextArea mb-0">
                            <div className="FdLeftTextArea FDShap02">
                                <div className="FDShapInner02">
                                    <div className="FdLeftTextAreaInfo">
                                        <textarea
                                            className="generateTextArea"
                                            rows="3"
                                            placeholder="Describe your Image"
                                            value={message}
                                            onChange={handlePromptChange}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant=""
                                    id="dropdown-basic"
                                    className="TextBoXDropDownBtn PolygnBtn"
                                >
                                    <Icon icon="heroicons:adjustments-horizontal" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="TextBoXDropDown">
                                    <div className="TextDropWrap">
                                        <div className="TextDropArea">
                                            <textarea
                                                className="negativeTextArea"
                                                rows="2"
                                                placeholder="Negative prompt"
                                            ></textarea>
                                        </div>
                                        <div className="TextImgRange">
                                            <div className="DropProgress-wrap">
                                                <h6>Guidance Scale ({guidanceScale})</h6>
                                                <Slider
                                                    min={3}
                                                    max={20}
                                                    defaultValue={guidanceScale}
                                                    onChange={(e) => setGuidanceScale(e)}
                                                />
                                                <div className="DropProgressWrapInfo">
                                                    <p>Prioritize creativity</p>
                                                    <p>Prioritize prompt</p>
                                                </div>
                                            </div>
                                            <div className="DropProgress-wrap">
                                                <h6>Steps ({steps})</h6>
                                                <Slider
                                                    min={10}
                                                    max={50}
                                                    defaultValue={steps}
                                                    onChange={(e) => setSteps(e)}
                                                />
                                                <div className="DropProgressWrapInfo">
                                                    <p>Better speed</p>
                                                    <p>Better quality</p>
                                                </div>
                                            </div>
                                            <div className="DropProgress-wrap">
                                                <h6>Number of Images ({numImage})</h6>
                                                <Slider
                                                    min={1}
                                                    max={16}
                                                    defaultValue={numImage}
                                                    onChange={(e) => setNumImage(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="AspectRatioWrap">
                                            <h6>Aspect Ratio</h6>
                                            <p>Non-square aspect ratios may produce artifacts.</p>
                                            <ul className="AspectRatioUl">
                                                <li>
                                                    <label
                                                        htmlFor="AspectRatio01"
                                                        className="AspectRadioWrap"
                                                    >
                                                        <input
                                                            type="radio"
                                                            name="AspectRatio"
                                                            id="AspectRatio01"
                                                        />
                                                        <div className="RatioWrap">
                                                            <div className="RatioWrapBox Square">
                                                                <span></span>
                                                            </div>
                                                            <h6>Square (1:1)</h6>
                                                        </div>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label
                                                        htmlFor="AspectRatio02"
                                                        className="AspectRadioWrap"
                                                    >
                                                        <input
                                                            type="radio"
                                                            name="AspectRatio"
                                                            id="AspectRatio02"
                                                        />
                                                        <div className="RatioWrap">
                                                            <div className="RatioWrapBox Landscape">
                                                                <span></span>
                                                            </div>
                                                            <h6>Landscape (4:3)</h6>
                                                        </div>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label
                                                        htmlFor="AspectRatio03"
                                                        className="AspectRadioWrap"
                                                    >
                                                        <input
                                                            type="radio"
                                                            name="AspectRatio"
                                                            id="AspectRatio03"
                                                        />
                                                        <div className="RatioWrap">
                                                            <div className="RatioWrapBox Desktop">
                                                                <span></span>
                                                            </div>
                                                            <h6>Desktop (16:9)</h6>
                                                        </div>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label
                                                        htmlFor="AspectRatio04"
                                                        className="AspectRadioWrap"
                                                    >
                                                        <input
                                                            type="radio"
                                                            name="AspectRatio"
                                                            id="AspectRatio04"
                                                        />
                                                        <div className="RatioWrap">
                                                            <div className="RatioWrapBox Portrait">
                                                                <span></span>
                                                            </div>
                                                            <h6>Portrait (3:4)</h6>
                                                        </div>
                                                    </label>
                                                </li>
                                                <li>
                                                    <label
                                                        htmlFor="AspectRatio05"
                                                        className="AspectRadioWrap"
                                                    >
                                                        <input
                                                            type="radio"
                                                            name="AspectRatio"
                                                            id="AspectRatio05"
                                                        />
                                                        <div className="RatioWrap">
                                                            <div className="RatioWrapBox Mobile">
                                                                <span></span>
                                                            </div>
                                                            <h6>Mobile (9:16)</h6>
                                                        </div>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                            {qty === "danny-trejo-ai" ? (
                                <Dropdown>
                                    <Dropdown.Toggle
                                        variant=""
                                        id="dropdown-basic"
                                        className="TextBoXDropDownBtn PolygnBtn2"
                                    >
                                        <Icon icon="heroicons:paint-brush" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="TextBoXDropDown">
                                        <div
                                            style={{
                                                display: "grid",
                                                gridTemplateColumns: "repeat(5, 1fr)",
                                                gap: "10px",
                                            }}
                                        >
                                            {imageList.map((image, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        border: "1px solid #ddd",
                                                        borderRadius: "4px",
                                                        textAlign: "center",
                                                        backgroundColor: selectedImages.includes(image.url)
                                                            ? "orange"
                                                            : "white",
                                                    }}
                                                    onClick={() => handleImageClick(image)}
                                                >
                                                    <img
                                                        src={image.url}
                                                        alt={image.title}
                                                        style={{ width: "100%" }}
                                                    />
                                                    <p
                                                        style={{
                                                            fontSize: "15px",
                                                            fontWeight: "bold",
                                                            color: "black",
                                                        }}
                                                    >
                                                        {image.title}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            ) : (
                                <></>
                            )}
                        </div>

                        <Button
                            variant=""
                            onClick={validateOrboxForFetch}
                            className="btn btn-green btn-polygn"
                            disabled={message === "" || isProcessing}
                        >
                            Generate &nbsp; | &nbsp; {getPriceForProduct(9)} Orbox/Image
                        </Button>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="FdrightMain">
                        <div className="BoxShadowMain BoxShadowMainChart">
                            <div className="FdRightChart FDShap01">
                                <div className="FDShapInner01">
                                    <h6>Number of Assets Generated</h6>
                                    <h4>{numGeneration * 4}</h4>
                                    <Bar options={options} data={data} />
                                </div>
                            </div>
                        </div>
                        <div className="BoxShadowMain BoxShadowMainChart">
                            <div className="FdRightChart FDShap01 PerformanceChart">
                                <div className="FDShapInner01 ">
                                    <h6 className="text-center">Performance</h6>
                                    <ul className="PerformanceChartWrap">
                                        <li>
                                            <span style={{ fontSize: "17px" }}>25/min</span>
                                            <p>Production Rate</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="BoxShadowMain BoxShadowMainPeople">
                            <div className="FdRightPeople FDShap02">
                                <div className="FDShapInner02">
                                    <div className="PeopleFactoryMain">
                                        <h4>
                                            <span>Number of People who generated</span>{" "}
                                            {Math.round(numGeneration)}{" "}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button
                            variant=""
                            className="btn btn-green btn-polygn btn mt-3 btn-mobile-hide"
                            onClick={() => onGoNextFactory(displayedFactoryIndex)}
                        >
                            Next
                        </Button>
                    </div>
                </Col>
            </Row>
            <div className="BtnMobileWrapShow">
                <Button
                    variant=""
                    className="btn btn-green btn-polygn btn mt-3"
                    onClick={() => onGoPrevFactory(displayedFactoryIndex)}
                >
                    Prev
                </Button>
                <Button
                    variant=""
                    className="btn btn-green btn-polygn btn mt-3"
                    onClick={() => onGoNextFactory(displayedFactoryIndex)}
                >
                    Next
                </Button>
            </div>

            <div className="infinite-scroll-component mt-5 pt-2">
                {
                    recentGenerations.length > 0 ? (
                        recentGenerations.map((image, index) => (
                            <div
                                className="AIGenerated-img"
                                key={index}
                                onClick={() => openModal(image)}
                            >
                                <img src={image} alt="" className="img-fluid" />
                            </div>
                        ))
                    ) : recentSongGenerations.length > 0 ? (
                        recentSongGenerations.map((image, index) => (
                            <AudioCard
                                songUrl={image.generated_mp3}
                                songName={image.prompt}
                                backgroundImage={image.generated_thumbnail}
                                key={index}
                            />
                        ))
                    ) : (
                        <div className="grid-container">
                            {/* Fetching Factories... */}
                            <lord-icon
                                src="https://cdn.lordicon.com/zuhwwskw.json"
                                colors="primary:#36e899,secondary:#ffffff"
                                trigger="loop"
                            ></lord-icon>
                        </div>
                    )}

                <PopupModal
                    className="popupShowImage"
                    show={isModalOpen}
                    handleClose={closeModal}
                >
                    <div className="popupModalImage">
                        <Image src={selectedImageUrl} alt="" />
                    </div>
                </PopupModal>
            </div>

            <PopupModal
                show={showTokenNize}
                handleClose={onCloseModal}
                className="Tokenize_Modal"
            >
                <Link to="#" className="CloseBtn" onClick={onCloseModal}>
                    <Icon icon="ion:close-circle" color="white" />
                </Link>
                {loading ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <lord-icon
                            trigger="loop"
                            src="/images/icons/loader.json"
                        ></lord-icon>

                        <h3 style={{ color: "#36E899 ", marginLeft: "20px" }}>
                            {loadingMessage}
                        </h3>
                    </div>
                ) : (
                    <TokenizeForm selectedImages={selectedImage} />
                )}
            </PopupModal>
            <PopupModal
                show={showImagePopup}
                handleClose={onCloseImageModal}
                className="Image_Modal"
            >
                <Link to="#" className="CloseBtn" onClick={onCloseImageModal}>
                    <Icon icon="ion:close-circle" color="white" />
                </Link>
                <ImagePopup imageInfo={selectedImageDetails} />
            </PopupModal>

            <PopupModal
                show={ShowFactoryPopup}
                handleClose={() => SetshowFactoryPopup(false)}
                className="FactoryListPopup"
            >
                <Link
                    to="#"
                    className="CloseBtn"
                    onClick={() => SetshowFactoryPopup(false)}
                >
                    <Icon icon="ion:close-circle" color="white" />
                </Link>
                <h5 className="factoryListTitle">Choose AI Factory</h5>
                <hr />
                <ul className="categoryList categoryListNoWrap">
                    <div className="categoryBoxList">
                        <Select
                            placeholder="Selct Category"
                            options={categoryOptions}
                            closeMenuOnSelect={false}
                            onChange={onHandleSelectCategory}
                            isMulti
                        />
                    </div>
                </ul>
                {selectedCategory.length > 0 ? (
                    <ul className="factoryList">
                        {filteredFactory.map((doc, index) => (
                            <li onClick={() => SetshowFactoryPopup(false)} key={index}>
                                <Link to={"/FactoryView/" + doc.uid}>
                                    <span>
                                        <Image src={doc.img} />
                                    </span>
                                    <h6>
                                        {doc.model_name}
                                        <i>{doc.model_desc}</i>
                                    </h6>
                                </Link>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <ul className="factoryList">
                        {factoryList.map((doc, index) => (
                            <li onClick={() => SetshowFactoryPopup(false)} key={index}>
                                <Button
                                    variant=""
                                    onClick={() => onChangeFactory(doc.uid, index)}
                                >
                                    <span>
                                        <Image src={doc.img} />
                                    </span>
                                    <h6>
                                        {doc.model_name}
                                        <i>{doc.model_desc}</i>
                                    </h6>
                                </Button>
                            </li>
                        ))}
                    </ul>
                )}
            </PopupModal>
            <Modal
                show={show}
                onHide={handleClose}
                centered
                dialogClassName="modal-90w"
                className="dark-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{error}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    ) : (
        <div className="loader-container">
            <div className="spinner">
                <lord-icon
                    src="https://cdn.lordicon.com/zuhwwskw.json"
                    colors="primary:#36e899,secondary:#ffffff"
                    trigger="loop"
                ></lord-icon>
            </div>
        </div>
    );
};

export default FactoryDetailPage;
