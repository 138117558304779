import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Image, Row, Modal, Form } from "react-bootstrap";
import AIOrbABI from "../../Contracts/AITokenization/AIOrb.json";
import OmniAIOrb from "../../Contracts/AITokenization/OmniAIOrb.json";
import MantaAIOrb from "../../Contracts/AITokenization/MantaAIOrb.json";
import useDebouncedReadFromAIContract from "../../Contracts/wagmi/readContract.js";
import { ORBOFI_BASE_IPFS_URL } from "../../Contracts/ipfs.js";
import axios from "axios";
import { ethers } from "ethers";
import { useWaitForTransaction, useAccount, useContractWrite, useContractEvent, useNetwork, useContractRead } from "wagmi";
import  { getOmniData, addOmniMintData } from "../../Components/TokenizeForm/apiFunctions"
import { Icon } from "@iconify/react";
import Confetti from "react-confetti";
import debounce from "lodash/debounce";
import "./AIImageMintPage.css";

const sampleQuantity = 1;



function AIImageMintPage(props) {
  // eslint-disable-next-line no-unused-vars
  const { collectionName, chainId, contractAddress, lZ } = useParams();
  const [IPFSData, setIPFSData] = useState([]);
  const [omniData, setOmniData] = useState([]);
  const [activeButton, setActiveButton] = useState();
  const [dynamicContractAddress, setDynamicContractAddress] = useState();
  const [dynamicChainId, setDynamicChainId] = useState();
  const [showModal, setShowModal] = useState();
  const [confetti, setConfetti] = useState(false);
  const [tokenID, setTokenID] = useState(0);
  const [isTokenIDUpdated, setIsTokenIDUpdated] = useState(false);


  // eslint-disable-next-line no-unused-vars
  const { address, isConnecting, isDisconnected } = useAccount()
  const { chain } = useNetwork();

  const baseHookArgs = [
    { address: dynamicContractAddress ? dynamicContractAddress :contractAddress, functionName: "name", chainId: dynamicChainId ? dynamicChainId : chainId ? Number(chainId) : null, isLZ: lZ ? true : false },
    { address: dynamicContractAddress ? dynamicContractAddress :contractAddress, functionName: "symbol", chainId: dynamicChainId ? dynamicChainId : chainId ? Number(chainId) : null, isLZ: lZ ? true : false},
    { address: dynamicContractAddress ? dynamicContractAddress :contractAddress, functionName: "pricePerUnit", chainId: dynamicChainId ? dynamicChainId : chainId ? Number(chainId) : null, isLZ: lZ ? true : false},
    { address: dynamicContractAddress ? dynamicContractAddress :contractAddress, functionName: lZ ? "maxMintId": "maxSupply", chainId: dynamicChainId ? dynamicChainId : chainId ? Number(chainId) : null, isLZ: lZ ? true : false},
    { address: dynamicContractAddress ? dynamicContractAddress :contractAddress, functionName: lZ ? "nextMintId" : "totalSupply", chainId: dynamicChainId ? dynamicChainId : chainId ? Number(chainId) : null, isLZ: lZ ? true : false},
  ];

  // New state variables for hook arguments
  const [hookArgs, setHookArgs] = useState(
    !lZ
      ? [
          {
            address: dynamicContractAddress
              ? dynamicContractAddress
              : contractAddress,
            functionName: "tokenURI",
            args: [0],
            chainId: dynamicChainId ? dynamicChainId : Number(chainId),
          },
          ...baseHookArgs,
        ]
      : [...baseHookArgs]
  );
  
  // Use tokenURI data only if lZ is false
  let tokenURIWagmi, contractURIError, contractURIIsLoading;
  if (!lZ) {
    ({
      data: tokenURIWagmi,
      error: contractURIError,
      isLoading: contractURIIsLoading,
    } = useDebouncedReadFromAIContract(hookArgs[0]));
  }

    const { data: contractNameWagmi, error1, isLoading1 } = useDebouncedReadFromAIContract(hookArgs[lZ ? 0 : 1]);
    const { data: contractSymbolWagmi, error2, isLoading2 } = useDebouncedReadFromAIContract(hookArgs[lZ ? 1 : 2]);
    const { data: contractPriceWagmi, } = useDebouncedReadFromAIContract(hookArgs[lZ ? 2 : 3]);
    const { data: maxSupplyWagmi, } = useDebouncedReadFromAIContract(hookArgs[lZ ? 3 : 4]);
    const { data: totalSupplyWagmi, } = useDebouncedReadFromAIContract(hookArgs[lZ ? 4 : 5]);

  const debouncedFetchData = debounce(() => {
    setHookArgs(
      !lZ
        ? [
            {
              address: dynamicContractAddress
                ? dynamicContractAddress
                : contractAddress,
              functionName: "tokenURI",
              args: [0],
              chainId: Number(chainId),
            },
            ...baseHookArgs,
          ]
        : [...baseHookArgs]
    );
  }, 500); // 500ms delay between

  useEffect(() => {
    debouncedFetchData();
  }, [
    dynamicContractAddress ? dynamicContractAddress : contractAddress,
    dynamicChainId ? dynamicChainId : chainId,
    debouncedFetchData,
  ]);

  const eventSignature = "Transfer(address, address, uint256)";
  const eventSignatureHash = ethers.utils.id(eventSignature);
  const { data: normalMint,  error, isError,  write } = useContractWrite({
    mode: 'recklesslyUnprepared',
    address: dynamicContractAddress ? dynamicContractAddress :contractAddress,
    abi: lZ ? OmniAIOrb.abi : chainId == 169 ? MantaAIOrb.abi :AIOrbABI.abi,
    functionName: "mint",
    args: [address, sampleQuantity], // the UI widget that allows user to input the quantity
    overrides: {
      value: contractPriceWagmi?.mul(sampleQuantity),
    },

  })

  const { data: omniDATATx,  error: omniError,  write: omniMint } = useContractWrite({
    mode: 'recklesslyUnprepared',
    address: dynamicContractAddress ? dynamicContractAddress :contractAddress,
    abi:  lZ ? OmniAIOrb.abi : chainId == 169 ? MantaAIOrb.abi :AIOrbABI.abi,
    functionName: 'mint',
    args: [address], // the UI widget that allows user to input the quantity
    overrides: {
      value: contractPriceWagmi,
    },

  })
 


  const { isLoadingSrc, isSuccessSrc } = useWaitForTransaction({
    hash: omniDATATx?.hash,
  
    async onSuccess(omniDATATx) {
      const user_data = {
        "address": address,
        "srcContractAddress": omniData.srcContractAddress,
        "destContractAddress": omniData.destContractAddress,
        "contract_address": dynamicContractAddress ? dynamicContractAddress :contractAddress,
        "tokenID": tokenID,
        "tokenURI": `${ORBOFI_BASE_IPFS_URL}${omniData.tokenURI}${tokenID}`,
        "chainID": chainId,
        "islZ": true,
      };

      addOmniMintData(user_data).then(r => {
  
      });

      
    }
  });


  let value;
  useContractEvent({
    address: dynamicContractAddress ? dynamicContractAddress : contractAddress,
    abi: lZ ? OmniAIOrb.abi : chainId == 169 ? MantaAIOrb.abi :AIOrbABI.abi,
    eventName: "Transfer",
    listener(node, label, owner) {
      value = parseInt(owner._hex, 16);
      setTokenID(value);
      setIsTokenIDUpdated(true);
    },
  });
  

  const MintNFT = async () =>
  {
    write?.()
   
    if (isError)
    {
      alert(error.data.message)
    }
  };

  // a hacky way to get the baseURI
  // you can make the baseURI public and read it directly from the contract but
  // testing purposes will do it this way for now
  function removeLastZero(str) {
    if (str.endsWith("0")) {
      return str.slice(0, -1); // removes the last character from the string
    } else {
      return str;
    }
  }

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: normalMint?.hash,
    onSuccess(normalMint)
    {

      setShowModal(true);
      setConfetti(true);
    
    }
  })

  useEffect(() => {
    if (isSuccess && isTokenIDUpdated && tokenID !== 0 && !lZ) {
      const user_data = {
        "address": address,
        "contract_address": dynamicContractAddress ? dynamicContractAddress :contractAddress,
        "tokenID": tokenID,
        "tokenURI": `${ORBOFI_BASE_IPFS_URL}/${removeLastZero(tokenURIWagmi)}${tokenID}`,
        "collection_name": contractNameWagmi,
        "chainID": chainId,
        "islZ": false,
      };
     
      addOmniMintData(user_data).then(r => {});
    }
  }, [
    address,
    chainId,
    dynamicContractAddress ? dynamicContractAddress : contractAddress,
    contractNameWagmi,
    isSuccess,
    isTokenIDUpdated,
    tokenID,
  ]);


  const handleCloseModal = () =>
  {
    setShowModal(false);
    setConfetti(false);
  };

  useEffect(() => {
    async function fetchDataFromIPFS() {
      if (tokenURIWagmi || lZ) {
        // if lZ exists this means that the contract were made from the layer zero
        // architecture and the tokenURI is the baseURI
        let baseURI;
        // this will keep track of the total supply of the omni contracts
        // as they are main token supply is split into multiple contracts (src and dest)
        let totalSupplyForOmniContracts;
        if (lZ) {
          const data = await getOmniData(
            dynamicContractAddress ? dynamicContractAddress : contractAddress
          );
          

          // The total supply is split between src and dest.
          // To get the total supply, pick the second element from the dest list.
          totalSupplyForOmniContracts = data.destTotalSupply[1];
          setOmniData(data);
          baseURI = `ipfs${data.tokenURI}`;
        } else {
          baseURI = removeLastZero(tokenURIWagmi);
        }

        const max_supply = totalSupplyForOmniContracts? totalSupplyForOmniContracts : maxSupplyWagmi?.toNumber();
    
        const imagePromises = [];

        for (let i = 0; i <= max_supply - 1; i++) {
          const token_uri = `${ORBOFI_BASE_IPFS_URL}/${baseURI}${i}`;

          imagePromises.push(axios.get(token_uri));
        }

        try {
          const responseArray = await Promise.all(imagePromises);
          const images = responseArray.map((response) => response.data);

          setIPFSData(images);
        } catch (error) {
          console.error("Error fetching IPFS data:", error);
        }
      }
    }
      fetchDataFromIPFS().then(r => {
        
      });
  }, [tokenURIWagmi || lZ, contractNameWagmi, IPFSData, maxSupplyWagmi, omniDATATx]);

  const handlePolygonClick = () => {
    setDynamicContractAddress(contractAddress);
    setDynamicChainId(137);
    setActiveButton("polygon");
    };
    
    const handleBNBClick = () => {
    setDynamicContractAddress(omniData.destContractAddress);
    setDynamicChainId(56);
    setActiveButton("bnb");
    };

   // 11155420
  //  console.log( IPFSData[0]?.chainId)
  return (
    <div className="main-content AIGeneratedPage">
     
     {
        lZ && (
          <div className="btn-mint-wrap">
            <Button
              variant=""
              className={`btn-border-white ${activeButton === "polygon" ? "btn-white active" : ""}`}
              onClick={handlePolygonClick}
            >
              <Image src="/images/icons/Polygon.svg" />
              <span>Polygon</span>
            </Button>
            <Button
              variant=""
              className={`btn-border-white ${activeButton === "bnb" ? "btn-white active" : ""}`}
              onClick={handleBNBClick}
            >
              <Image src="/images/icons/BNBChain.svg" />
              <span>BNB</span>
            </Button>
          </div>
        )
}

      <div className="AIImageBox default-box mb-5">
        <Row>
          <Col lg={6}>
            {tokenURIWagmi && (
              <div className="AIImageBoxImg">
                <Image
                  src={
                    totalSupplyWagmi?.toNumber() === maxSupplyWagmi?.toNumber()
                      ? IPFSData[totalSupplyWagmi?.toNumber() - 1]?.image
                      : IPFSData[totalSupplyWagmi?.toNumber()]?.image
                  }
                />
              </div>
            )}

            {lZ && (
              <div className="AIImageBoxImg">
                <Image
                  src={
                    totalSupplyWagmi?.toNumber() === maxSupplyWagmi?.toNumber()
                      ? IPFSData[totalSupplyWagmi?.toNumber() - 1]?.image
                      : IPFSData[totalSupplyWagmi?.toNumber()]?.image
                  }
                />
              </div>
            )}
          </Col>
          <Col lg={6}>
            <div
              className={`AIImageBoxInfo ${
                isLoading ? "flip-animation blur" : ""
              }`}
            >
              <h2 className="mb-0" style={{ color: "white" }}>
                
                {contractNameWagmi} ({contractSymbolWagmi})
              </h2>
              <hr />
              <ul>
                <li>
                  Total Supply :
                  <span>
                    
                    {totalSupplyWagmi?.toNumber()} /
                    {maxSupplyWagmi?.toNumber()}
                  </span>
                </li>
                {contractPriceWagmi && (
                  <li>
                    Price :
                    <span>
                      {parseInt(contractPriceWagmi?.toString()) / 10 ** 18}
                      {dynamicChainId
                        ? dynamicChainId === 56
                          ? "BNB"
                          : dynamicChainId === 137
                          ? "MATIC"
                          : null
                        : IPFSData[0]?.chainId === 56
                        ? " BNB"
                        : IPFSData[0]?.chainId === 137
                        ? " MATIC"
                        : IPFSData[0]?.chainId === 169 || 84532 || 11155420
                        ? " ETH":
                        IPFSData[0]?.chainId === 1351057110
                        ? " SFUEL":
                        IPFSData[0]?.chainId === 2525
                        ? " INJ":
                        
                        null
                        }
                    </span>
                  </li>
                )}
                {!lZ && (
                  <li>
                    Royalty : <span>{IPFSData[0]?.royalty} %</span>
                  </li>
                )}

                {/* <li>
                  Tokenized on the blockchain :
                  <span className="TokenizeRadioWrap">
                      <Form.Check
                        inline
                        label="Yes"
                        name="TokenizeRadioWrap"
                        type='radio'
                        id="TokenizeYes"
                        defaultChecked={true}
                      />
                       <Form.Check
                        inline
                        label="No"
                        name="TokenizeRadioWrap"
                        type='radio'
                        id="TokenizeNo"
                      />
                  </span>
                </li> */}
                <li>
                  Chain:
                  {dynamicChainId ? (
                    dynamicChainId === 56 ? (
                      <i><Image src="/images/icons/BNBChain.svg" /></i>
                    ) : dynamicChainId === 137 ? (
                      <i><Image src="/images/icons/Polygon.svg" /></i>
                    ) : null
                    ) : IPFSData[0]?.chainId === 56 ? (
                      <i><Image src="/images/icons/BNBChain.svg" /></i>
                    ) : IPFSData[0]?.chainId === 137 ? (
                      <i><Image src="/images/icons/Polygon.svg" /></i>
                    )  : IPFSData[0]?.chainId === 169 ? (
                      <i><Image src="/images/icons/manta.svg" /></i>
                    ) : IPFSData[0]?.chainId === 1351057110 ? (
                      <h6> Skale </h6>
                    ) :
                    IPFSData[0]?.chainId === 8453 ? (
                      <i><Image src="/images/icons/base-logo-in-blue.svg" /></i>
                    ):
                    IPFSData[0]?.chainId === 11155420 ? (
                      <i><Image src="/images/icons/optimism.svg" /></i>
                    ):
                    IPFSData[0]?.chainId === 421614 ? (
                      <i><Image src="/images/icons/arbitrum.svg" /></i>
                    ):
                    IPFSData[0]?.chainId === 2525 ? (
                      // <i><Image src="/images/icons/injective.svg" /></i>
                      <h6>Injective</h6>
                    ):
                    IPFSData[0]?.chainId === 1116 ? (
                      <h6>CoreDao</h6>
                    ):
                    null
                    
                    }
                </li>
                <li>
                      Contract Address : <span>{`${dynamicContractAddress ? dynamicContractAddress?.substring(0, 6) : contractAddress?.substring(0, 6)}...${dynamicContractAddress ? dynamicContractAddress.substring(dynamicContractAddress.length - 4) : contractAddress?.substring(contractAddress?.length - 4)}`}</span>
                      {
                        dynamicChainId
                          ? dynamicChainId === 56
                            ? <a href={`https://bscscan.com/address/${dynamicContractAddress ? dynamicContractAddress : contractAddress}`} target="_blank" style={{color: "#f0b90b"}} rel="noreferrer">
                                <Icon icon="ri:share-box-fill" />
                              </a>
                            : dynamicChainId === 137
                            ? <a href={`https://polygonscan.com/address/${dynamicContractAddress ? dynamicContractAddress : contractAddress}`} target="_blank" style={{color: "#8247e5"}} rel="noreferrer">
                                <Icon icon="ri:share-box-fill" />
                              </a>
                            : null
                          : IPFSData[0]?.chainId === 56
                          ? <a href={`https://bscscan.com/address/${dynamicContractAddress ? dynamicContractAddress : contractAddress}`} target="_blank" style={{color: "#f0b90b"}} rel="noreferrer">
                              <Icon icon="ri:share-box-fill" />
                            </a>
                          : IPFSData[0]?.chainId === 137
                          ? <a href={`https://polygonscan.com/address/${dynamicContractAddress ? dynamicContractAddress : contractAddress}`} target="_blank" style={{color: "#8247e5"}} rel="noreferrer">
                              <Icon icon="ri:share-box-fill" />
                            </a>
                          : IPFSData[0]?.chainId === 169
                          ? <a href={`https://pacific-explorer.manta.network/address/${dynamicContractAddress ? dynamicContractAddress : contractAddress}`} target="_blank" style={{color: "#8247e5"}} rel="noreferrer">
                          <Icon icon="ri:share-box-fill" />
                        </a> : 
                        IPFSData[0]?.chainId === 1351057110
                          ? <a href={`https://staging-fast-active-bellatrix.explorer.staging-v3.skalenodes.com/address/${dynamicContractAddress ? dynamicContractAddress : contractAddress}`} target="_blank" style={{color: "#8247e5"}} rel="noreferrer">
                          <Icon icon="ri:share-box-fill" />
                        </a> :
                         IPFSData[0]?.chainId === 8453
                         ? <a href={`https://basescan.org/address/${dynamicContractAddress ? dynamicContractAddress : contractAddress}`} target="_blank" style={{color: "#8247e5"}} rel="noreferrer">
                         <Icon icon="ri:share-box-fill" />
                       </a> :
                         IPFSData[0]?.chainId === 11155420
                         ? <a href={`https://sepolia-optimism.etherscan.io/address/${dynamicContractAddress ? dynamicContractAddress : contractAddress}`} target="_blank" style={{color: "#8247e5"}} rel="noreferrer">
                         <Icon icon="ri:share-box-fill" />
                       </a> :
                        IPFSData[0]?.chainId === 421614
                        ? <a href={`https://sepolia.arbiscan.io/address/${dynamicContractAddress ? dynamicContractAddress : contractAddress}`} target="_blank" style={{color: "#8247e5"}} rel="noreferrer">
                        <Icon icon="ri:share-box-fill" />
                      </a> :
                       IPFSData[0]?.chainId === 2525
                       ? <a href={` https://inevm.calderaexplorer.xyz/address/${dynamicContractAddress ? dynamicContractAddress : contractAddress}`} target="_blank" style={{color: "#8247e5"}} rel="noreferrer">
                       <Icon icon="ri:share-box-fill" />
                     </a> :
                      IPFSData[0]?.chainId === 1116
                      ? <a href={` https://scan.coredao.org/address/${dynamicContractAddress ? dynamicContractAddress : contractAddress}`} target="_blank" style={{color: "#8247e5"}} rel="noreferrer">
                      <Icon icon="ri:share-box-fill" />
                    </a> :
                        null
                      }
                  </li>
                 
              </ul>
              <div className="AIBtnWrap">
              
                <Button className="btn btn-gradient w-100"  onClick={ lZ ? omniMint :  MintNFT} disabled={isDisconnected ||  totalSupplyWagmi?.toNumber() === maxSupplyWagmi?.toNumber() } >
                {isLoading ? "Minting..." : "Mint"}   
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>

        <div className="MintImgWrap">
        {IPFSData?.map((item, index) => {
          const adjustedIndex = omniData? index + 1 : index; // Adjusting the index to start from 1
          return (
            <div className="MintImg" key={index}>
              <Image src={item.image} />
              {/* {lZ === undefined && adjustedIndex <= totalSupplyWagmi?.toNumber() ? <h6>Minted</h6> : null} */}
              { omniData && lZ &&(
                <>
                  {omniData?.srcTotalSupply.includes(adjustedIndex) && (
                    <span><Image src="/images/icons/Polygon.svg" /></span>
                  )}
                  {omniData?.destTotalSupply.includes(adjustedIndex) && (
                    <span><Image src="/images/icons/BNBChain.svg" /></span>
                  )}
                </>
              )}    
            </div>
          );
        })}
      </div>
    

      {confetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={500}
        />
      )}

      <Modal show={showModal} onHide={handleCloseModal} centered className="FactoryListPopup">
        <Modal.Body>
          <h5 className="factoryListTitle">Congratulations!</h5>
          <Link to="#" className="CloseBtn" onClick={() => handleCloseModal(false)}>
            <Icon icon="ion:close-circle" color="white" />
          </Link>
          <hr />
          <div className="NFTModal">
            <p>Congrats, you have successfully minted an NFT!</p>
            <Image src={IPFSData[tokenID]?.image} className="image-fit" />
            {chainId === "169" ? (
              <a
                className="btn btn-white"
                href={`https://element.market/assets/manta-pacific/${contractAddress}/${tokenID}`}
                target="_blank"
                rel="noreferrer"
              >
                Check on ELEMENT
              </a>
            ) : (
                <a
                  className="btn btn-white"
                  href={`https://opensea.io/assets/${
                    dynamicChainId
                      ? dynamicChainId === 137
                        ? "matic"
                        : dynamicChainId === 56
                        ? "bsc"
                        : "unknown"
                      : chainId
                      ? chainId === "137"
                        ? "matic"
                        : chainId === "56"
                        ? "bsc"
                        : "unknown"
                      : "unknown"
                  }/${dynamicContractAddress || contractAddress}/${tokenID}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Check on OPENSEA
                </a>
              )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AIImageMintPage;
