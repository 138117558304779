import React from "react";
import { Image } from "react-bootstrap";



interface DataProps {
    // picture: string;
    Name: string;
    Provider: string;
    Status: string;
    CreatedAt: string;
}

interface ComponentProps {
    data: DataProps[];

}


const ProfileProductCard = (props: ComponentProps) => {
    const { data } = props;
    return (
        <table className="Orbofi-Table">
            <thead>
                <tr>
                    <th>No.</th>
                    <th>Date</th>
                    <th>Product Description</th>
                    <th>Payment Method</th>
                    <th>Status</th>
                    <th>Price</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                    <tr key={index}>
                        <td><p>{index + 1}</p></td>
                        <td><p>{item.CreatedAt}</p></td>
                        <td><p>{item.Name}</p></td>
                        <td><p>{item.Provider}</p></td>
                        <td><p>{item.Status}</p></td>
                        <td><p>25$</p></td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ProfileProductCard;