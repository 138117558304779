import { Link } from "react-router-dom";
import FactoryCard from "../../Components/FactoryCard/FactoryCard";
import FactoryCardAnimation from "../../Components/FactoryCardAnimation/FactoryCardAnimation";
import React, { useEffect, useState } from "react";

import {
    collection,
    onSnapshot,
    query,
    where,
    orderBy, startAt, endAt,
} from "@firebase/firestore";
import FactoryCardMusic from "../../Components/FactoryCardMusic/FactoryCardMusic";
import { categoryList } from "../../Utils/Constants";
import "./FactoryPage.css";
import FactoryFilter from "../../Components/FactoryFilter/FactoryFilter";
import {getFirestoreInstance} from "../../Utils/Utils";

function FactoryPage() {
    const [factoryList, setFactoryList] = useState([]);
    const [, setImgSrc] = useState("");
    const [allCategories, setAllCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [filteredFactory, setFilteredFactory] = useState([]);
    const [mode, setMode] = useState("Orbofi");

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setAllCategories(categoryList);
        setLoading(true);
    }, []);

    const onHandleSelectCategory = (selectedOptions, filterMode) => {

        if (filterMode === "filter") {
            const selectedCat = selectedOptions;
            const catArray = allCategories.map((category) => ({
                ...category,
                checked: selectedCat.includes(category.title),
            }));

            const filteredData = factoryList.filter((doc) =>
                selectedCat.includes(doc.category)
            );

            setAllCategories(catArray);
            setSelectedCategory(selectedCat);
            setFilteredFactory(filteredData);
        } else {
            setAllCategories(categoryList);
            setSelectedCategory([]);
            setFilteredFactory([]);
        }
    };

    const onSelectCategory = (cat) => {
        const catArray = allCategories.map((category) => {
            if (category.title === cat) {
                if (category.checked) {
                    category.checked = false;
                } else[(category.checked = true)];
            }
            return category;
        });

        setAllCategories(catArray);
    };

    const db = getFirestoreInstance();
    useEffect(() => {
        const colRef = collection(db, "models");
        const queryRef = query(colRef, where("numGeneration", ">", 70000));
        const unsubListener = onSnapshot(queryRef, {
            next: (snapshot) => {
                snapshot.docs.forEach((doc) => {
                    setImgSrc(doc.data()["model_photo_url"]);
                });
                const lst = snapshot.docs.map((doc) => ({
                    img: doc.data()["model_photo_url"],
                    uid: doc.data()["model_id"],
                    numGeneration: doc.data()["numGeneration"],
                    creator: doc.data()["creator"],
                    model_desc: doc.data()["model_desc"],
                    model_name: doc.data()["model_name"],
                    creation_date: doc.data()["creation_date"]["seconds"],
                    likes: doc.data()["likes"],
                    category: doc.data()["category"],
                }));

                setFactoryList(
                    lst.sort((a, b) => (a.numGeneration < b.numGeneration ? 1 : -1))
                );
            },
            error: (err) => {
                console.error(err);
                // TODO: handle error (signed out? no permission? no connection?)
            },
        });
        // setLoading(false);

        return unsubListener;
    }, [db]);

    const handleButtonClick = (selectedMode) => {
        setMode(selectedMode);
        setFactoryList([]);
        setLoading(true);
        // Call any other function here. For example:
        if (selectedMode === "Orbofi") {
            const colRef = collection(db, "models");
            const queryRef = query(colRef, where("numGeneration", ">", 70000));
            onSnapshot(queryRef, {
                next: (snapshot) => {
                    snapshot.docs.forEach((doc) => {
                        setImgSrc(doc.data()["model_photo_url"]);
                    });
                    const lst = snapshot.docs.map((doc) => ({
                        img: doc.data()["model_photo_url"],
                        uid: doc.data()["model_id"],
                        numGeneration: doc.data()["numGeneration"],
                        creator: doc.data()["creator"],
                        model_desc: doc.data()["model_desc"],
                        model_name: doc.data()["model_name"],
                        creation_date: doc.data()["creation_date"]["seconds"],
                        likes: doc.data()["likes"],
                        category: doc.data()["category"],
                    }));

                    setFactoryList(
                        lst.sort((a, b) => (a.numGeneration < b.numGeneration ? 1 : -1))
                    );
                },
                error: (err) => {
                    console.error(err);
                    // TODO: handle error (signed out? no permission? no connection?)
                },
            });
        } else if (selectedMode === "Uploaded") {
            const colRef = collection(db, "models");
            const queryRef = query(colRef, where("isUploaded", "==", true));
            onSnapshot(queryRef, {
                next: (snapshot) => {
                    snapshot.docs.forEach((doc) => {
                        setImgSrc(doc.data()["model_photo_url"]);
                    });
                    const lst = snapshot.docs.map((doc) => ({
                        img: doc.data()["model_photo_url"],
                        uid: doc.data()["model_id"],
                        numGeneration: doc.data()["numGeneration"],
                        creator: doc.data()["creator"],
                        model_desc: doc.data()["model_desc"],
                        model_name: doc.data()["model_name"],
                        creation_date: doc.data()["creation_date"]["seconds"],
                        likes: doc.data()["likes"],
                        category: doc.data()["category"],
                    }));

                    setFactoryList(
                        lst.sort((a, b) => (a.numGeneration < b.numGeneration ? 1 : -1))
                    );
                },
                error: (err) => {
                    console.error(err);
                    // TODO: handle error (signed out? no permission? no connection?)
                },
            });
        } else {
            const colRef = collection(db, "models");
            const queryRef = query(colRef, where("numGeneration", "<", 50000));
            onSnapshot(queryRef, {
                next: (snapshot) => {
                    snapshot.docs.forEach((doc) => {
                        setImgSrc(doc.data()["model_photo_url"]);
                    });
                    const lst = snapshot.docs.map((doc) => ({
                        img: doc.data()["model_photo_url"],
                        uid: doc.data()["model_id"],
                        numGeneration: doc.data()["numGeneration"],
                        creator: doc.data()["creator"],
                        model_desc: doc.data()["model_desc"],
                        model_name: doc.data()["model_name"],
                        creation_date: doc.data()["creation_date"]["seconds"],
                        likes: doc.data()["likes"],
                        category: doc.data()["category"],
                    }));

                    setFactoryList(
                        lst.sort((a, b) => (a.numGeneration < b.numGeneration ? 1 : -1))
                    );
                },
                error: (err) => {
                    console.error(err);
                    // TODO: handle error (signed out? no permission? no connection?)
                },
            });
        }
        setLoading(true);
    };

    const handleSearch = (selectedMode) => {
        setFactoryList([]);
        setLoading(true);
        // // Call any other function here. For example:
        // // if (selectedMode === "Orbofi") {


        const colRef = collection(db, "models");
        const start = selectedMode;
        const end = selectedMode + "\uf8ff"; // '\uf8ff' is a very high Unicode point, so it will select all strings that start with 'Orbofi'
        const queryRef = query(colRef, orderBy("model_name"), startAt(start), endAt(end));

        onSnapshot(queryRef, {
            next: (snapshot) => {
                snapshot.docs.forEach((doc) => {
                    setImgSrc(doc.data()["model_photo_url"]);
                });
                const lst = snapshot.docs.map((doc) => ({
                    img: doc.data()["model_photo_url"],
                    uid: doc.data()["model_id"],
                    numGeneration: doc.data()["numGeneration"],
                    creator: doc.data()["creator"],
                    model_desc: doc.data()["model_desc"],
                    model_name: doc.data()["model_name"],
                    creation_date: doc.data()["creation_date"]["seconds"],
                    likes: doc.data()["likes"],
                    category: doc.data()["category"],
                }));
                setFactoryList(
                    lst.sort((a, b) => (a.numGeneration < b.numGeneration ? 1 : -1))
                );
            }
        });
        setLoading(false);
    };

    const categoryOptions = categoryList.map((category) => ({
        value: category.title,
        label: category.title,
    }));
    return (
        <div className="main-content home-page">
            <div className="FactoryTitles Factory-bg">
                <h2>Create your own AI factory for any kind of game and media asset</h2>
                <p>
          Create a virtual factory, aka: AI model, that produces hundreds or
          even thousands of style-consistent and high-quality assets in minutes,
          use them for games and media. If you wish, Earn Crypto by having
          people using your factory
                </p>
                <div className="btn-factory-wrap fw-50">
                    <Link to="/Createfactory" className="btn btn-white">
            Create your AI model
                    </Link>
                    <Link to="/CreateAiModel" className="btn btn-white">
            Upload an AI model
                    </Link>
                </div>
            </div>
            <FactoryFilter
                categoryList={allCategories}
                handleButtonClick={handleButtonClick}
                mode={mode}
                onHandleSelectCategory={onHandleSelectCategory}
                onSelectCategory={onSelectCategory}
                selectedCategory={selectedCategory}
                handleSearch={handleSearch}
            />

            <div className="factoryItemsWrap factoryItemsGrid">
                {selectedCategory.length > 0 ? (
                    <>
                        {filteredFactory.map((doc) => {
                            return doc.uid === "text-to-animation" ? (
                                <FactoryCardAnimation
                                    key={doc.img}
                                    imgSrc={doc.img}
                                    model_uid={doc.uid}
                                    creator={doc.creator}
                                    model_desc={doc.model_desc}
                                    model_name={doc.model_name}
                                    creation_date={doc.creation_date}
                                    numGeneration={doc.numGeneration}
                                    likes={doc.likes}
                                    category={doc.category}
                                />
                            ) : (
                                <FactoryCard
                                    key={doc.img}
                                    imgSrc={doc.img}
                                    model_uid={doc.uid}
                                    creator={doc.creator}
                                    model_desc={doc.model_desc}
                                    model_name={doc.model_name}
                                    creation_date={doc.creation_date}
                                    numGeneration={doc.numGeneration}
                                    likes={doc.likes}
                                    category={doc.category}
                                />
                            );
                        })}
                    </>
                ) : factoryList.length > 0 ? (
                    <>
                        {factoryList.map((doc) =>
                            doc.uid === "text-to-animation" ? (
                                <FactoryCardAnimation
                                    key={doc.img}
                                    imgSrc={doc.img}
                                    model_uid={doc.uid}
                                    creator={doc.creator}
                                    model_desc={doc.model_desc}
                                    model_name={doc.model_name}
                                    creation_date={doc.creation_date}
                                    numGeneration={doc.numGeneration}
                                    likes={doc.likes}
                                    category={doc.category}
                                />
                            ) : doc.uid === "text-to-music" ? (
                                <FactoryCardMusic
                                    key={doc.img}
                                    imgSrc={doc.img}
                                    model_uid={doc.uid}
                                    creator={doc.creator}
                                    model_desc={doc.model_desc}
                                    model_name={doc.model_name}
                                    creation_date={doc.creation_date}
                                    numGeneration={doc.numGeneration}
                                    likes={doc.likes}
                                    category={doc.category}
                                />
                            ) : (
                                <FactoryCard
                                    key={doc.img}
                                    imgSrc={doc.img}
                                    model_uid={doc.uid}
                                    creator={doc.creator}
                                    model_desc={doc.model_desc}
                                    model_name={doc.model_name}
                                    creation_date={doc.creation_date}
                                    numGeneration={doc.numGeneration}
                                    likes={doc.likes}
                                    category={doc.category}
                                />
                            )
                        )}
                    </>
                ) : !isLoading && factoryList.length == 0 ? (<>
                    <div className="grid-container" style={{ height: "500px" }}>
            😢 No Factories Found 😢
                        {/* <lord-icon
              src="https://cdn.lordicon.com/zuhwwskw.json"
              colors="primary:#36e899,secondary:#ffffff"
              trigger="loop"
            ></lord-icon> */}
                    </div>
                </>) : (
                    <>
                        <div className="grid-container" style={{ height: "500px" }}>
                            <div className="text-center"> 
                                <p>Fetching Factories...</p>
                                <lord-icon
                                    src="https://cdn.lordicon.com/zuhwwskw.json"
                                    colors="primary:#36e899,secondary:#ffffff"
                                    trigger="loop"
                                ></lord-icon>
                            </div>
                        </div>
                    </>
                )
                }
            </div >
        </div >
    );
}

export default FactoryPage;
