import "./CreateCommunityInfo.css";

function CreateCommunityInfo() {
  return (
    <div className="CreateCommunityInfo">
      <h2>Create your new home on the internet, and a whole new world now with AI and Web3 superpowers.</h2>
      <p>
        A Community Orb is your personal or community world that has its own
        metaverse space, its own social token, and its own gamified universe
        full of crazy and friendly creatures
      </p>
      <hr />
      <ul>
        <li>
          <lord-icon
            trigger="loop"
            src="/images/icons/crowdfunding.json"
          ></lord-icon>
          <div>
            <h6>A world that pays you, as well as your friends, members, or followers.</h6>
            <small>
              Every Community Orb gets its own social token ,sNFT, that acts as
              a membership token with super powers. Earn digital currency by
              growing your Orb and enabling people to get your social token,
              which also empowers you to expand your social capital, level-up,
              and earm more
            </small>
          </div>
        </li>
        <li>
          <lord-icon
            trigger="loop"
            src="/images/icons/controller-pad.json"
          ></lord-icon>
          <div>
            <h6> Metaverse community, avatars, and god-mode AI-superpowers</h6>
            <small>
            get your own 3D open world as your new home, for yourself, your community, your audience and followers, and group of friends. This is just like turning your Reddit, discords servers, facebook/whatsapp/telegram groups into 3D virtual worlds. Build your own metaverse community with God mode AI superpowers, and enable people to express themselves with avatars. chat together,share content, trade, Play, explore, trade, give hugs, or even shoot at each other, you make the rules
            </small>
          </div>
        </li>
        <li>
          <lord-icon
            trigger="loop"
            src="/images/icons/home-safety.json"
          ></lord-icon>
          <div>
            <h6>
            Your own world, with your people, forever yours.
            </h6>
            <small>
            Your World, its content, people, its economy, and all the assets living on it, are powered and secured by Blockchain, and will remain yours forever. You control it from end to end.
            </small>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default CreateCommunityInfo;
