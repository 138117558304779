import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import FactoryPopup from "../../Components/FactoryPopup/FactoryPopup";
import PopupModal from "../../Components/PopupModal/PopupModal";
import React from "react";
import Dropzone from "react-dropzone";
import "react-dropzone-uploader/dist/styles.css";
import { toast } from "react-hot-toast";
import { onGettingAllAiGeneratedImages } from "../../Components/AIGeneratedImage/action";
import { useDropzone } from "react-dropzone";
import InfiniteScroll from "react-infinite-scroll-component";
import "./CreateFactory.css";
import "../../Components/Txt2Img/Txt2Img.css";
import { categoryList } from "../../Utils/Constants";
import {useDispatch} from "react-redux";
import {showPopUpAction} from "../../Redux/Slices/loginPopUpSlice";

function CreateFactory() {
    const [selectedImageUrl, setSelectedImageUrl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();

    const openModal = (imageUrl) => 
    {
        setSelectedImageUrl(imageUrl);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [selectImageVisible, setSelectImageVisible] = useState(false);
    const [showImagePopup, setShowImagePopup] = useState(false);
    const [imgsSrc, setImgsSrc] = useState([]);
    const [selectedFromList, setSelectedFromList] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [allImages, setAllImages] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [searchPrompt, setSearchPrompt] = useState("");
    const itemsPerPage = 30;
    const [records, setrecords] = useState(itemsPerPage);
    const [hasMore] = useState(true);
    const [page, setPage] = useState(0);

    const [learningRate] = useState("3e-6");
    const [textEncoderlearningRate] = useState("1e-6");
    const [lr_scheduler] = useState("linear");
    const [trainingSteps] = useState(2500);
    const [textEncodertrainingSteps] = useState(750);
    const [offsetNoise] = useState(false);
    const [steroids_mode] = useState(false);

    const [factoryData, setFactoryData] = useState({
        factoryTitle: "",
        factoryDesc: "",
        factoryCategories: [],
        learningRate: "",
        textEncoderlearningRate: "",
        learningRateScheduler: "",
        offsetNoise: false,
        steroids_mode: false,
        textEncoderSteps: 750,
        trainingSteps: 2500,
    });
    const [, setIsImageUploadProcess] = useState(false);
    const [allAiGeneratedImages, setAllAiGeneratedImages] = useState([]);
    const [, setIsLoading] = useState(true);
    const authToken = localStorage.getItem("oauthToken");
    const goToSelectImage = () => {
        if (authToken) {
            if (factoryData.factoryTitle !== "" && factoryData.factoryDesc !== "") {
                setSelectImageVisible(true);
                const pageNo = 0;
                setFactoryData({
                    ...factoryData,
                    factoryCategories: "test",
                    learningRate: learningRate,
                    textEncoderlearningRate: textEncoderlearningRate,
                    learningRateScheduler: lr_scheduler,
                    offsetNoise: offsetNoise,
                    steroids_mode: steroids_mode,
                    textEncoderSteps: textEncodertrainingSteps,
                    trainingSteps: trainingSteps,
                });
                setSearchPrompt(factoryData.factoryTitle);
                setSearchString(factoryData.factoryTitle);
                onGetRelatedImages(factoryData.factoryTitle, pageNo, []);
            } else {
                toast.error("Fill all the details first");
            }
        } else {
            dispatch(showPopUpAction(true));
        }
    };

    const onGetRelatedImages = (prompt, pageNo, allImages) => {
        setIsLoading(true);
        onGettingAllAiGeneratedImages(prompt, pageNo).then((resp) => {
            if (resp.status === 200) {
                const changedRes = resp.data.map((r) => {
                    return { ...r, isChecked: false };
                });
                let images = [...allImages, ...changedRes];
                setAllAiGeneratedImages(images);
                setrecords(images.length);
                setIsLoading(false);
            }
        });
    };

    const loadMore = () => {
        const prompt = searchPrompt;
        const pageNo = page + 1;
        setPage(pageNo);
        onGetRelatedImages(prompt, pageNo, allAiGeneratedImages);
    };

    useEffect(() => {
        setAllCategories(categoryList);
    }, []);

    const onShowImagePopup = () => {
        const checkLength = [...selectedFromList, ...imgsSrc];
        const reqLength = 10;

        if (checkLength.length < reqLength) {
            toast.error("Please Select at least 10 images!");
        } else {
            if (imgsSrc.length > 0 || selectedFromList.length > 0) {
                setAllImages(selectedFromList);
                imgsSrc.map((img) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(img);
                    reader.onload = () => {
                        setAllImages((prev) => [...prev, reader.result]);
                    };
                    reader.onerror = () => {};
                    return null;
                });
                setShowImagePopup(true);
            }
        }
    };
    const onCloseImageModal = () => {
        setShowImagePopup(false);
    };

    const onDrop = (acceptedFiles) => {
        acceptedFiles.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })
        );
        imgsSrc.map((img) => {
            const reader = new FileReader();
            reader.readAsDataURL(img);
            reader.onload = () => {
                setAllImages((prev) => [...prev, reader.result]);
            };
            reader.onerror = () => {};
            return null;
        });
        let uploadedImage = [...imgsSrc, ...acceptedFiles];
        setImgsSrc(uploadedImage);
    };
    useDropzone();

    // eslint-disable-next-line no-unused-vars
    const handleChangeStatus = ({ meta, remove }, status) => {
        let data = [...imgsSrc];
        if (status === "done") {
            data.push(meta.previewUrl);
            setImgsSrc(data);
            setIsImageUploadProcess(false);
        } else if (status === "aborted") {
            setIsImageUploadProcess(true);
        } else if (status === "removed") {
            const findIndex = data.findIndex(meta.previewUrl);
            data.splice(findIndex);
            setImgsSrc(data);
            setIsImageUploadProcess(true);
        } else {
            setIsImageUploadProcess(true);
        }
    };

    // receives array of files that are done uploading when submit button is clicked
    // eslint-disable-next-line no-unused-vars
    const handleSubmit = (files) => {
        setImgsSrc([]);
        for (const file of files) {
            const reader = new FileReader();
            reader.readAsDataURL(file.file);
            reader.onload = () => {
                setImgsSrc((imgs) => [...imgs, reader.result]);
            };
            reader.onerror = () => {};
        }
    };

    const onHandleChange = (event) => {
        const { name, value } = event.target;
        setFactoryData({ ...factoryData, [name]: value });
    };

    const onHandleSelectCategory = (category, index) => {
        let catArray = [...allCategories];
        let selectedCat = [...factoryData.factoryCategories];
        if (factoryData.factoryCategories.includes(category.title)) {
            catArray[index].checked = false;
            const catIndex = factoryData.factoryCategories.indexOf(category.title);
            selectedCat.splice(catIndex, 1);
        } else {
            for (let i = 0; i < selectedCat; i++) {
                selectedCat.splice(i);
            }
            catArray[index].checked = true;
            selectedCat.push(category.title);
        }
        setFactoryData({ ...factoryData, factoryCategories: selectedCat });
        setAllCategories(catArray);
    };

    // eslint-disable-next-line no-unused-vars
    const onHandleChangeSearch = (event) => {
        setSearchPrompt(event.target.value);
    };

    const handleSearch = () => {
        let pageNo = 0;
        setAllAiGeneratedImages([]);
        setrecords(itemsPerPage);
        setSearchPrompt(searchString);
        onGetRelatedImages(searchString, pageNo, []);
    };

    const onSelectImages = (image, index) => {
        let allImage = [...selectedFromList];
        let updatedSelected = [...allAiGeneratedImages];
        if (allImage.includes(image)) {
            updatedSelected[index].isChecked = false;
            const imgIndex = allImage.indexOf(image);
            allImage.splice(imgIndex, 1);
        } else {
            updatedSelected[index].isChecked = true;
            allImage.push(image);
        }
        setSelectedFromList(allImage);
        setAllAiGeneratedImages(updatedSelected);
    };
    const onRemoveSelectedImage = (link, index) => {
        let allImage = [...selectedFromList];
        let updatedSelected = [...allAiGeneratedImages];
        let indexOfAiImage = updatedSelected.findIndex((p) => p.url === link);
        updatedSelected[indexOfAiImage].isChecked = false;
        allImage.splice(index, 1);
        setAllAiGeneratedImages(updatedSelected);
        setSelectedFromList(allImage);
    };
    const onRemoveImage = (index) => {
        const newArr = [...imgsSrc];
        newArr.splice(index, 1);
        setImgsSrc(newArr);
    };

    const thumbs = imgsSrc.map((file, index) => (
        <div className="ImageItems" key={file.name}>
            <img src={file.preview} alt="" />
            <Button
                variant=""
                className="removeBtn"
                onClick={() => onRemoveImage(index)}
            >
                <Icon icon="ion:close-circle" />
            </Button>
        </div>
    ));

    const onHandleSearch = (event) => {
        setSearchString(event.target.value);
    };

    const onKeyPress = (event) => {
        if (event.keyCode === 13) {
            let pageNo = 0;
            setAllAiGeneratedImages([]);
            setrecords(itemsPerPage);
            setSearchPrompt(searchString);
            onGetRelatedImages(searchString, pageNo, []);
        }
    };
    return (
        <>
            <div className="main-content home-page">
                {!selectImageVisible ? (
                    <>
                        <div className="FactoryTitles">
                            <h2>Create your own AI factory for any kind of game asset</h2>
                            <p>
                Create a virtual factory, aka: AI model, that produces hundreds
                or even thousands of style-consistent and high-quality assets in
                minutes, use them for games and media. If you wish, Earn Crypto
                by having people using your factory
                            </p>
                        </div>
                        <div className="FectoryCreateInfo">
                            <div className="TextImgBox">
                                <input
                                    type="text"
                                    name="factoryTitle"
                                    placeholder="Enter Factory Title"
                                    onChange={onHandleChange}
                                />
                                <textarea
                                    className="generateTextArea"
                                    rows="3"
                                    name="factoryDesc"
                                    placeholder="Enter Factory Description"
                                    onChange={onHandleChange}
                                ></textarea>
                            </div>
                            <div className="categoryBoxList">
                                <h5>Category</h5>
                                <ul className="categoryList">
                                    {categoryList.map((category, index) => (
                                        <li className="categoryItem" key={index}>
                                            <label
                                                htmlFor="categoryItem01"
                                                onClick={() => onHandleSelectCategory(category, index)}
                                            >
                                                <input
                                                    type="checkbox"
                                                    id={`custom-checkbox-${index}`}
                                                    name="factoryCategories"
                                                    checked={category.checked}
                                                />
                                                <p>{category.title}</p>
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                                <h5 className="mt-4">Visibility</h5>
                                <ul className="FacToryInputWrap categoryList">
                                    <li>
                                        <label htmlFor="PublicFactory">
                                            <input
                                                type="radio"
                                                id="PublicFactory"
                                                name="factoryCategories"
                                            />
                                            <p>
                                                {" "}
                                                <span>
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/hbvgknxo.json"
                                                        trigger="loop"
                                                        colors="primary:#ffffff,secondary:#36e899,tertiary:#3a3347"
                                                    ></lord-icon>
                                                </span>{" "}
                        Public Factory
                                            </p>
                                        </label>
                                    </li>
                                    <li>
                                        <label htmlFor="PrivetFactory">
                                            <input
                                                type="radio"
                                                id="PrivetFactory"
                                                name="factoryCategories"
                                            />
                                            <p>
                                                <span>
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/kkpjxfpg.json"
                                                        trigger="loop"
                                                        colors="primary:#ffffff,secondary:#36e899,outline:#ffffff"
                                                    ></lord-icon>
                                                </span>
                        Private Factory
                                            </p>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <Link
                                className="btn btn-gradient mt-4"
                                to="#"
                                onClick={goToSelectImage}
                            >
                Next
                            </Link>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="FactoryTitles">
                            <h2>Upload or choose the training data</h2>
                            <p>
                Upload or choose between 5 to 50 images you want to train your
                factory on. Images should match the category of this factory and
                its quality will depend on the quality of the images you upload
                or choose.{" "}
                            </p>
                        </div>
                        <div className="FectoryCreateImgInfo">
                            <div className="FectoryImgWidth">
                                <label htmlFor="imageUpload" className="drag-photo-img d-block">
                                    <Dropzone onDrop={onDrop} minSize={10}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <h6 className="btn btn-white">
                            click to select files
                                                    </h6>
                                                    {(imgsSrc.length === 0 && selectedFromList.length === 0) && <p>Drag &apos;n&apos; drop some files here</p>}
                                                </div>
                                                <aside className="ItemsImagesWrap">
                                                    {thumbs}
                                                    {selectedFromList.map((link, index) => (
                                                        <div className="ImageItems" key={index}>
                                                            <img src={link} alt={""} />
                                                            <Button
                                                                variant=""
                                                                className="removeBtn"
                                                                onClick={() =>
                                                                    onRemoveSelectedImage(link, index)
                                                                }
                                                            >
                                                                <Icon icon="ion:close-circle" />
                                                            </Button>
                                                        </div>
                                                    ))}
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </label>
                                <br></br>

                                <br></br>
                            </div>
                            <div className="text-center">
                                <Button
                                    className="btn btn-gradient mt-4"
                                    variant=""
                                    onClick={() => onShowImagePopup()}
                                >
                  Next
                                </Button>
                            </div>
                            <br />
                            <hr />
                            <br />
                            <div className="ImgLibraryWrap">
                                <div className="ImgLibraryTitle">
                                    <h3>Choose From Library</h3>
                                    <div className="AIGeneratedSearch">
                                        <input
                                            type="search"
                                            placeholder="Search For Assets"
                                            onChange={onHandleSearch}
                                            onKeyDown={onKeyPress}
                                        />
                                        <Link to="#" onClick={handleSearch}>
                                            <Icon icon="uil:search" />
                                        </Link>
                                    </div>
                                </div>

                                <div className="ImgLibrary">
                                    <InfiniteScroll
                                        dataLength={records}
                                        next={() => loadMore()}
                                        hasMore={hasMore}
                                        loader={
                                            <div className="AiImageLoader">
                                                <lord-icon
                                                    trigger="loop"
                                                    src="/images/icons/loader.json"
                                                ></lord-icon>
                                            </div>
                                        }
                                    >
                                        {allAiGeneratedImages.length > 0 &&
                      allAiGeneratedImages.map((image, index) => (
                          <div
                              className="AIGenerated-img"
                              key={index}
                              
                          >
                              <img onClick={() => openModal(index)} src={image.url} alt="" className="img-fluid" />
                              <label className="ImgCheckBox" htmlFor={image.url}>
                                  <input
                                      type="checkbox"
                                      id={image.url}
                                      checked={image.isChecked}
                                      onClick={() => onSelectImages(image.url, index)}
                                  />
                                  <span>
                                      <Icon icon="mdi:tick" />
                                  </span>
                              </label>
                          </div>
                      ))}
                                    </InfiniteScroll>
                                </div>

                                <PopupModal
                                    className="popupShowImage"
                                    show={isModalOpen}
                                    handleClose={closeModal}
                                >
                                    {isModalOpen && (
                                        <div className="popupModalImage">
                                            <Image
                                                src={allAiGeneratedImages[selectedImageUrl].url}
                                                alt=""
                                            />
                                        </div>
                                    )}
                                </PopupModal>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <PopupModal
                show={showImagePopup}
                handleClose={onCloseImageModal}
                className="Factory_Modal FactoryListPopup"
            >
                <h5 className="factoryListTitle">Factory Model</h5>
                <Link to="#" className="CloseBtn" onClick={onCloseImageModal}>
                    <Icon icon="ion:close-circle" color="white" />
                </Link>
                <hr />
                <FactoryPopup images={allImages} factoryData={factoryData} />
            </PopupModal>
        </>
    );
}

export default CreateFactory;
