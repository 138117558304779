import { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Image, Row } from "react-bootstrap";
import "./ImagePopup.css";
import PopupShareContent from "../PopupModal/HeaderPopups/PopupShareContent";

function ImagePopup({ imageInfo }) {
  const [promptBtnText, setPromptBtnText] = useState("Copy");
  const [, setUrlBtnText] = useState("Copy");

  return (
    <div className="AIImageBox default-box">
      <Row className="justify-content-center">
        <Col lg={6}>
          <div className="AIImageBoxImg">
            <Image src={imageInfo} />
          </div>
        </Col>
        <Col lg={6} className="d-none">
          <div className="AIImageBoxInfo">
            <ul>
              <li>
                Creator : <span></span>
              </li>
              <li>
                Seed : <span></span>
              </li>
              <li>
                Height : <span></span>
              </li>
              <li>
                Tokenized on the blockchain :
                <span className="TokenizeRadioWrap">
                  <label className="TokenizeRadioBtn">
                    <input type="radio" name="TokenizeRadioWrap" />
                    <i>Yes</i>
                  </label>
                  <label className="TokenizeRadioBtn">
                    <input type="radio" name="TokenizeRadioWrap" checked />
                    <i>No</i>
                  </label>
                </span>
              </li>
              <li>
                Model : <span></span>
              </li>
            </ul>
            <div className="AIBtnWrap mt-4">
              <Link
                className="btn btn-gradient w-100"
                to="#"
                onClick={() => {
                  setPromptBtnText("Copied");
                  setTimeout(() => {
                    setPromptBtnText("Copy");
                  }, 2000);
                }}
              >
                <span>
                  <lord-icon
                    trigger="loop"
                    src="/images/icons/photo-editor.json"
                  ></lord-icon>
                </span>{" "}
                Image2Image
              </Link>
              <Link
                className="btn btn-white"
                to="#"
                onClick={() => {
                  setPromptBtnText("Copied");
                  setTimeout(() => {
                    setPromptBtnText("Copy");
                  }, 2000);
                  navigator.clipboard.writeText(imageInfo?.prompt);
                }}
              >
                <span>
                  <lord-icon
                    trigger="loop"
                    src="/images/icons/copy.json"
                  ></lord-icon>
                </span>{" "}
                {promptBtnText} Prompt
              </Link>
              <PopupShareContent
                icon={
                  <lord-icon
                    src="https://cdn.lordicon.com/liqllijy.json"
                    trigger="hover"
                    colors="outline:#121331,primary:#ffffff"
                  ></lord-icon>
                }
                link={imageInfo.url}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ImagePopup;
