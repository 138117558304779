import {ethers} from "ethers";
import {useContractWrite, useWaitForTransaction, useAccount, useNetwork} from "wagmi";
import {bsc_test_payment_contract, obi_token_eth_mainnet,eth_main_payment_contract, bsc_main_payment_contract, obi_token_bsc_mainnet} from "../../Contracts/payments/address";
import paymentContract from "../../Contracts/payments/paymentsMainABI.json";
import {toast} from "react-toastify";
import OBIBscMainTokenContract from "../../Contracts/payments/OBITokenBSCMAINABI.json";
import {Button, Image, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import Products from "../../Models/Products";
import PopupModal from "../../Components/PopupModal/PopupModal";
import { Icon } from "@iconify/react";

// TODO
// check the ABI and contract addresses if are correct
// print out the value in the approve fn to check if the function is called

interface ComponentProps {
    isModalOpen: boolean, closeModal: () => void, isTransit: boolean
}

function ObiTokenPaymentPage(props: ComponentProps)
{
    const {isModalOpen, closeModal, isTransit} = props;
    const navigate = useNavigate();
    const [obiTokenOrderData, setObitTokenOrderData] = useState(new Products());
    const [obiTokenPrice, setObitTokenPrice] = useState(0);
    const { address, isConnecting, isDisconnected, isConnected } = useAccount()
    const { chain } = useNetwork();

    console.log("chainID", chain?.id)
    console.log("disconnected", isDisconnected)
    useEffect(() =>
    {
        const config = {headers: {"Authorization": "Bearer " + localStorage.getItem("oauthToken"),},};
        axios.get(import.meta.env.VITE_SERVER_ADDRESS + "getObiTransactionInfo", config).then((resp) => {
            const orderData = resp.data as Products;
            setObitTokenOrderData(orderData);
            return resp.data as Products;
        })
            .catch();
    }, []);

    useEffect(() =>
    {
        axios("https://api.coingecko.com/api/v3/simple/price?ids=orbofi-ai&vs_currencies=usd").then((resp) => {
            setObitTokenPrice(resp.data["orbofi-ai"]["usd"]);
        })
            .catch(console.error);
    }, []);

    // const OBIAmount = ethers.utils.parseUnits("1", "ether");
    const decimals = 18;

    let OBIAmount = ethers.BigNumber.from(0); // Default to zero
    
    // Check if the Price and obiTokenPrice are valid and not zero
    if (obiTokenOrderData?.Price > 0 && obiTokenPrice > 0) {
        const calculatedAmount = obiTokenOrderData.Price / obiTokenPrice;
        
        // Check for valid, finite, and positive results
        if (!isNaN(calculatedAmount) && isFinite(calculatedAmount) && calculatedAmount > 0) {
            // Convert to a string with two decimal places
            const formattedAmount = calculatedAmount.toFixed(2);
    
            // Now safely convert to BigNumber
            OBIAmount = ethers.utils.parseUnits(formattedAmount, decimals);
        } else {
            // Handle invalid or negative results
            console.error("Invalid or negative amount calculated");
            
        }
    } else {
        console.error("Price or obiTokenPrice is invalid or zero");
        
    }
    
    console.log("the amount of OBI tokens required", OBIAmount)
    const { data: payInTokenData,  error: payInTokenError,  write: payInToken, isLoading: isTokenTransferLoading, isSuccess: isTokenTransferSucces } = useContractWrite({
        mode: "recklesslyUnprepared",
        address:chain?.id === 56 ? bsc_main_payment_contract : (chain?.id === 1 ? eth_main_payment_contract : null),
        abi:  paymentContract.abi,
        functionName: "payInToken",
        args: [chain?.id === 56 ? obi_token_bsc_mainnet : (chain?.id === 1 ? obi_token_eth_mainnet : null), OBIAmount],
        
    });

    console.log("which", chain?.id === 56 ? bsc_main_payment_contract : (chain?.id === 1 ? eth_main_payment_contract : null))

    const { isLoading:isTokenTransfer, isSuccess:isTokenTransferSuccess } = useWaitForTransaction(
        {
            hash: payInTokenData?.hash,
            onSuccess(payInTokenData)
            {
                toast.success("Tokens transferred successfully!");
                // The Payment has been done but we need to notify the backend of it to award the product
                localStorage.setItem("ObiTransactionCompleted", "Completed");
                const config = {headers: {"Authorization": "Bearer " + localStorage.getItem("oauthToken"),},};
                axios.get(import.meta.env.VITE_SERVER_ADDRESS + "completeObiTransaction", config).then((resp) => {
                    // The transaction is ready on the backend to be completed so we don't need the status data anymore
                    localStorage.removeItem("PendingObiTransaction");
                    localStorage.removeItem("ObiTransactionCompleted");
                    navigate("/Congratulation");
                    props.closeModal();
                }).catch(()=>{
                    navigate("/");
                });

            }
        });


        const { data: approveData, error: approveError, write: approveTokens, isLoading: isApproveLoading, isSuccess: isApproveSuccess } = useContractWrite({
            mode: "recklesslyUnprepared",
            address: chain?.id === 56 ? obi_token_bsc_mainnet : (chain?.id === 1 ? obi_token_eth_mainnet : null),
            abi: OBIBscMainTokenContract.abi,
            functionName: "approve",
            args: [
                chain?.id === 56 ? obi_token_bsc_mainnet : (chain?.id === 1 ? obi_token_eth_mainnet : null), 
                OBIAmount
            ],
        });
        
    const { isLoading, isSuccess } = useWaitForTransaction(
        {
            hash: approveData?.hash,

            onSuccess(approveData)
            {
                localStorage.setItem("PendingObiTransaction", approveData?.hash);
                payInToken();
                toast.success("Tokens Approved successfully!");
            },

            onError()
            {
                // Clean up in the backend
            },
        });

    return (
        <div className={`ObiTokenPayment ${isTransit ? "transitionShow" : ""}`}>
            <Link to="#" onClick={closeModal} className="BackBtn"> <i><Icon icon="ion:chevron-back-outline" /></i> Back</Link>
            <div className="CheckoutInfo">
                <i><Image src="/images/product-dummy-img.png" /></i>
                <h4><small>Subscription</small><span>{obiTokenOrderData.Name}</span></h4>
                {/* <h6><span>{obiTokenOrderData.Price} USD</span></h6> */}
                <p className="ms-auto mb-0"><span>{(obiTokenOrderData.Price / obiTokenPrice).toFixed(2)}</span> OBI Tokens</p>
            </div>
            <hr />
            {isTokenTransferLoading || isApproveLoading ? (
                <>
                    <lord-icon
                        trigger="loop"
                        src="/images/icons/ipfs.json"
                        colors="primary:#36e899,secondary:#ffffff"
                    ></lord-icon>
                    <p>
                        {isTokenTransferLoading ? "Waiting for approval to Transfer tokens...." :
                            isApproveLoading ? "Please Approve tokens  ..." :
                                isLoading ? "approving tokens..." :
                                    isTokenTransfer ? "Waiting for tokens to be transferred..." :
                                        isSuccess ? "Tokens Approved Successfully" :
                                            isTokenTransferSuccess ? "Tokens Transferred Successfully" : null}
                    </p>
                </>
            ) : (
                <>
                    <p>{isTokenTransferSuccess ? "Tokens Transferred Successfully" : null}</p>
                    {
                        (!isSuccess && !isTokenTransferSuccess && !isLoading) ? (
                            <Button
                                variant=""
                                type="button"
                                className="btn btn-green btn-confirm-payment"
                                disabled={isLoading || isTokenTransfer || isTokenTransferLoading || isApproveLoading || isDisconnected}
                                onClick={() => { approveTokens(); }}
                            >
                                {
                                    isDisconnected ? "Please connect your wallet to proceed" : 
                                    (isConnected && !(chain?.id === 56 || chain?.id === 1) ? "Please switch to BNB or ETH chain" : "Confirm Transactions")
                                }
                                                            
                            </Button>
                        ) :  <lord-icon
                            trigger="loop"
                            src="/images/icons/ipfs.json"
                            colors="primary:#36e899,secondary:#ffffff"
                        ></lord-icon>
                    }
                </>
            )}
        </div>
    );
}

export default ObiTokenPaymentPage;