import ProfileBanner from "../../Components/ProfileBanner/ProfileBanner";
import ProfileUserAssets from "../MainProfilePage/InternalComponents/ProfileUserAssets/ProfileUserAssets";
import React from "react";

function PublicProfilePage() {
    return (
        <div className="main-content">
            <ProfileBanner isPublicProfile={true} />
            <ProfileUserAssets isPublicProfile={true} />
        </div>
    );
}

export default PublicProfilePage;
