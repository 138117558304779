import { configureStore } from "@reduxjs/toolkit";
import {loginPopUpSlice} from "./Slices/loginPopUpSlice";
import {userDataSlice} from "./Slices/userDataSlice";
// import {changeUserDataPopUpSlice} from "./Slices/changeUserDataPopUpSlice";
import {themeSlice} from "./Slices/themeSlice";
import {subscriptionDataSlice} from "./Slices/subscriptionDataSlice";

export const store = configureStore({
    reducer: {
        loginPopUpState: loginPopUpSlice.reducer,
        userData: userDataSlice.reducer,
        // changeUserDataPopUpState: changeUserDataPopUpSlice.reducer,
        themeState: themeSlice.reducer,
        subscriptionData: subscriptionDataSlice.reducer,
    }
});
