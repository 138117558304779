import React, { useEffect, useState } from 'react';
import Achievements from "../../Models/AchievementLevel";
import axios from 'axios';

function AchievementDetail({achievementId, goBack, setShouldFetchAchievements}) {
    const [achievementData, setAchievementData] = useState(new Achievements());
    const [isEditMode, setIsEditMode] = useState(true);

    const fetchAchievementById = async (achievementId) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
            };
            const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getAchievementById?achievementId=${achievementId}`, config);
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    useEffect(() => {
        if (achievementId) {
            setIsEditMode(true);  // Edit mode
            const fetchData = async () => {
                const fetchedData = await fetchAchievementById(achievementId);
                if (fetchedData) {
                    setAchievementData(fetchedData);
                }
            };
            fetchData().catch(err => {
                console.error("An error occurred:", err);
            });
        } else {
            setIsEditMode(false);  // Create mode
            setAchievementData(new Achievements());
        }
    }, [achievementId]);

    const updateAchievement = async (updatedAchievement) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
            };
            const response = await axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}updateAchievement`, updatedAchievement, config);
            if (response.status === 200) {
                console.log("Achievement updated successfully");  // Debug line
                return response.data;  // Make sure to return data
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const handleAction = () => {
        if (isEditMode) {
            handleUpdate();  // Existing logic for update
        } else {
            handleCreate();  // New logic for create
        }
    };


    const handleUpdate = () => {
        const updatedAchievement = {
            ...achievementData
        };

        updateAchievement(updatedAchievement).then(response => {
            console.log("Achievement updated:", response);  // Debug line
            setShouldFetchAchievements(true);
            goBack();
        }).catch(err => {
            console.error("An error occurred while updating achievement:", err);
        });
    };

    const createAchievement = async (newAchievement) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
            };
            const response = await axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}createAchievement`, newAchievement, config);
            if (response.status === 200) {
                console.log("Achievement created successfully");  // Debug line
                return response.data;  // Make sure to return data
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const handleCreate = () => {
        const newAchievement = {
            ...achievementData
        };

        createAchievement(newAchievement).then(response => {
            console.log("Achievement created:", response);  // Debug line
            setShouldFetchAchievements(true);
            goBack();
        }).catch(err => {
            console.error("An error occurred while creating achievement:", err);
        });
    };


    const handleGoBack = () => {
        goBack();
        setShouldFetchAchievements(true);
    };


    return (
        <div className="form-container">
            {achievementData ? (
                <form>
                    <div className="form-input">
                        <label className="form-label">Name:</label>
                        <input type="text" value={achievementData.Name} onChange={(e) => setAchievementData({...achievementData, name: e.target.value})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Description:</label>
                        <input type="text" value={achievementData.Description} onChange={(e) => setAchievementData({...achievementData, description: e.target.value})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Achievement ID:</label>
                        <input type="number" value={achievementData.IdAchievement} onChange={(e) => setAchievementData({...achievementData, level: parseInt(e.target.value, 10)})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Level:</label>
                        <input type="number" value={achievementData.Level} onChange={(e) => setAchievementData({...achievementData, level: parseInt(e.target.value, 10)})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Experience:</label>
                        <input type="number" value={achievementData.Experience} onChange={(e) => setAchievementData({...achievementData, experience: parseInt(e.target.value, 10)})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Next Level ID:</label>
                        <input type="number" value={achievementData.Next} onChange={(e) => setAchievementData({...achievementData, next: parseInt(e.target.value, 10)})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Orbox:</label>
                        <input type="number" value={achievementData.Orbox} onChange={(e) => setAchievementData({...achievementData, orbox: parseInt(e.target.value, 10)})} />
                    </div>
                    <div className="button-container">
                        <button type="button" className="form-button" onClick={handleAction}>
                            {isEditMode ? 'Change' : 'Create'}
                        </button>
                    </div>
                </form>
            ) : (
                'Loading...'
            )}
        </div>
    );
}

export default AchievementDetail;