import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import "./LeftSidebar.css";
import { ProfilePicture } from "../ProfilePicture/ProfilePicture";
import React from "react";
import { userDataSelector } from "../../Redux/Slices/userDataSlice";

function LeftSidebar() {
    const userData = useSelector(userDataSelector);
    const location = useLocation();

    return (
        <>
            <ul className="left-sidebar">
                {/* <li className="left_Profile">
                    <Link to={userData ? `/profile/${userData?.Name}` : "/"} className="mb-2 LeftProfileIco">
                        <ProfilePicture />
                    </Link>
                </li> */}
                <li>
                    <Link to="/">
                        <i className="ico-01">
                            <lord-icon
                                trigger="hover"
                                src="/images/icons/home.json"
                            ></lord-icon>
                        </i>
                        <p>Home</p>
                    </Link>
                </li>
                <li>
                    <Link to="/ChatBots">
                        <i className="ico-01">
                            <lord-icon
                                trigger="hover"
                                src="/images/icons/explore.json"
                            ></lord-icon>
                        </i>
                        {/* <p>Explore</p> */}
                        <p>AI <span>Chatbots</span></p>
                    </Link>
                </li>
                <li className="plus-add">
                    <Link to="/CreateChatbot">
                        <i className="ico-01">
                            <lord-icon
                                trigger="hover"
                                src="/images/icons/plus.json"
                            ></lord-icon>
                        </i>
                        <p>Create  <span>AI Agent</span></p>
                    </Link>
                </li>
                <li>
                    <Link to="/Factory">
                        <i className="ico-01">
                            <lord-icon
                                trigger="hover"
                                src="/images/icons/gallery.json"
                            ></lord-icon>
                        </i>
                        <p>AI Factories</p>
                    </Link>
                </li>
                <li>
                    <Link to="/AIGeneratedImage">
                        <i className="ico-01 ico-Images">
                        <lord-icon src="https://cdn.lordicon.com/bzqvamqv.json" trigger="hover" colors="primary:#08a88a,secondary:#4bb3fd,tertiary:#f24c00,quaternary:#ffffff,quinary:#f9c9c0,senary:#ffc738"></lord-icon>
                        </i>
                        <p>AI <span>Image</span></p>
                    </Link>
                </li>
                {/* <li className="left_Profile">
                    <a target="_blank" className="mt-2 metaverseIcon" rel="noreferrer">
                        <i className="ico-01">
                            <lord-icon
                                trigger="loop"
                                src="/images/icons/metaverse.json"
                            ></lord-icon>
                        </i>
                        <p>metaverse</p>
                    </a>
                </li> */}
            </ul>
            {location.pathname.toLowerCase().split("/")[1] !== "chatbot" && location.pathname.toLowerCase().split("/")[1] !== "createchatbot" &&
                <ul className="mobile-nav-bar left-sidebar">
                    <li className="left_Profile">
                        <Link to={userData ? `/profile/${userData?.Name}` : "/"} className="mb-2 LeftProfileIco">
                            <ProfilePicture />
                        </Link>
                    </li>
                    <li>
                        <Link to="/">
                            <i className="ico-01">
                                <lord-icon
                                    trigger="hover"
                                    src="/images/icons/home.json"
                                ></lord-icon>
                            </i>
                            <p>Home</p>
                        </Link>
                    </li>
                    <li>
                        <Link to="/ChatBots">
                            <i className="ico-01">
                                <lord-icon
                                    trigger="hover"
                                    src="/images/icons/explore.json"
                                ></lord-icon>
                            </i>
                            <p>Chatbots</p>
                        </Link>
                    </li>
                    <li className="plus-add">
                        <Link to="/CreateChatbot">
                            <i className="ico-01">
                                <lord-icon
                                    trigger="hover"
                                    src="/images/icons/plus.json"
                                ></lord-icon>
                            </i>
                            <p>Create  <span>AI Agent</span></p>
                        </Link>
                    </li>
                    <li>
                        <Link to="/Factory">
                            <i className="ico-01">
                                <lord-icon
                                    trigger="hover"
                                    src="/images/icons/gallery.json"
                                ></lord-icon>
                            </i>
                            <p>AI Factories</p>
                        </Link>
                    </li>
                    <li>
                        <Link to="/AIGeneratedImage">
                            <i className="ico-01 ico-Images">
                            <lord-icon src="https://cdn.lordicon.com/bzqvamqv.json" trigger="hover" colors="primary:#08a88a,secondary:#4bb3fd,tertiary:#f24c00,quaternary:#ffffff,quinary:#f9c9c0,senary:#ffc738"></lord-icon>
                            </i>
                            <p>Image</p>
                        </Link>
                    </li>
                    {/* <li className="left_Profile">
                        <a target="_blank" className="mt-2 metaverseIcon" rel="noreferrer">
                            <i className="ico-01">
                                <lord-icon
                                    trigger="loop"
                                    src="/images/icons/metaverse.json"
                                ></lord-icon>
                            </i>
                            <p>metaverse</p>
                        </a>
                    </li> */}
                </ul>
            }
        </>
    );
}

export default LeftSidebar;
