import React, {useEffect, useState} from "react";
import Products from "../../../../Models/Products";
import ProfileProductCard from "./ProfileProductCard";
import axios from "axios";

function MyProducts()
{
    const [productsData, setProductsData] = useState<Array<Products>>([]);

    useEffect(() =>
    {
        const config = {headers: {"Authorization": "Bearer " + localStorage.getItem("oauthToken"),},};
        axios.get(import.meta.env.VITE_SERVER_ADDRESS + "getUserProducts", config).then((resp) =>
        {
            const productsReturned = resp.data as Products[];
            const premiumProducts = productsReturned.filter((product) => product.Category !== "Orbox" && product.Category !== "Subscription");
            setProductsData(premiumProducts);
        });
    }, []);
    
    return(
        <div className="orbofi-table-wrap">
                {productsData?.length > 0 ? (
                    <>
                            <ProfileProductCard data={productsData}/>                        

                    </>
                ) : (
                    <>
                        {
                            <div className="FactoryTitles">
                                <h2 style={{fontSize: "16px"}}>No products purchased yet</h2>
                            </div>
                        }
                    </>
                )}
        </div>
    );
}

export default MyProducts;