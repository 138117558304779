import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { useSpring, animated } from 'react-spring'
import "./FactoryCardMusic.css";
import AudioCard from "../AudioCard/audiocard";

const calc = (x, y) => [-(y - window.innerHeight / 2) / 40, (x - window.innerWidth / 2) / 40, 1.1]
const trans = (x, y, s) => `perspective(1000px) rotateX(${x}deg) rotateY(${y}deg)`
function FactoryCardMusic({ imgSrc, model_uid, creator, model_desc, creation_date, numGeneration, model_name, likes, category }) {

  const [props, set] = useSpring({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } })

  return (
    <>
      <animated.div className="AiFactoryShap card" onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
        onMouseLeave={() => set({ xys: [0, 0, 1] })}
        style={{ transform: props.xys.interpolate(trans) }} key={model_uid}>
        <figure className="AIGenerated-img">
          <div className="FWrap">
            <AudioCard songUrl="https://storage.googleapis.com/factories_orbofi/outputs/music/20230517-073843-1-audio_file.mp3" songName="♪ In the jungle, the mighty jungle, the lion barks tonight ♪" backgroundImage="https://storage.googleapis.com/factories_orbofi/outputs/music/20230517-073843-1-thumbnail.png"></AudioCard>
            <p>{category}</p>
          </div>
          <div className="FInfo">
            <h6>{model_name}</h6>

            <p>{model_desc}</p>
            <ul>
              <li><span><Icon icon="entypo:images" color="white" /></span></li>
              <li><span><Icon icon="mdi:user-circle" color="white" /></span>{creator}</li>
            </ul>
            <Link to={"/FactoryView/" + model_uid} className="btn btn-gradient" state={{ model_uid: model_uid, creator: creator, model_desc: model_desc, creation_date: creation_date, model_name: model_name, likes: likes, imgSrc: imgSrc }}>Generate From This Factory</Link>
          </div>
        </figure>
      </animated.div>
      <animated.div
        className="card"
        onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
        onMouseLeave={() => set({ xys: [0, 0, 1] })}
        style={{ transform: props.xys.interpolate(trans) }}
      />
    </>
  );
}

export default FactoryCardMusic;

