import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter} from "react-router-dom";
//import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import ScrollToTop from "./Utils/ScrollToTop";
import "./index.css";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import {store} from "./Redux/store";

import achievements_es from "./Texts/es/achievements.json";
import achievements_en from "./Texts/en/achievements.json";

i18next.init({
    interpolation:
        {
            escapeValue: false
        },
    lng: "en",
    resources:
        {
            es:
            {
                achievements: achievements_es,
            },
            en:
            {
                achievements: achievements_en,
            },
        },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <I18nextProvider i18n={i18next}>
        <Provider store={store}>
            {/*<PersistGate loading={<div>Loading...</div>} persistor={persistor}	>*/}
            <BrowserRouter>
                <ScrollToTop />
                <App />
            </BrowserRouter>
            {/*</PersistGate>*/}
        </Provider>
    </I18nextProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();