import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import User from "../../Models/User";

export const UserLookupSection = ({ firstLoad, setFirstLoad, setSelectedUser, setActiveSection }) => {
    const [data, setData] = useState<User[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchField, setSearchField] = useState("name");
    const [totalResults, setTotalResults] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [shouldFetch, setShouldFetch] = useState(false);
    const [operator, setOperator] = useState("=");
    const itemsPerPage = 20;

    // Function to handle clicking a user name
    const handleUserClick = (userId) => {
        setSelectedUser(userId);
        setActiveSection('UserDetails');
    };

    // Your existing useEffect
    useEffect(() => {
        if (shouldFetch || firstLoad) {
            setShouldFetch(false);
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
            };

            const fetchData = async () => {
                const offset = (currentPage - 1) * itemsPerPage;
                const limit = itemsPerPage;
                const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getUsers?field=${searchField}&term=${searchTerm}&operator=${operator}&offset=${offset}&limit=${limit}`, config);
                const { users, total } = response.data;
                setData(users);
                if(currentPage == 1)
                {
                    setTotalResults(total);
                }
            };

            fetchData()
                .then(() => {
                    if (firstLoad) {
                        setFirstLoad(false);
                    }
                })
                .catch(err => {
                    console.error("An error occurred:", err);
                });

        }
    }, [shouldFetch, currentPage, firstLoad]);

    const handleSearch = () => {
        if (searchTerm !== "") {
            setCurrentPage(1);  // Reset the current page to 1
            setShouldFetch(true);  // Trigger data fetching
        }
    };

    const columns = useMemo(
        () => [
            { Header: 'Name', accessor: 'Name' },
            { Header: 'User ID', accessor: 'UserId' },
            { Header: 'Email', accessor: 'Email' },
            { Header: 'Role', accessor: 'Role' },
            { Header: 'Orbox', accessor: 'Orbox' },
            { Header: 'Premium Orbox', accessor: 'PremiumOrbox' },
            { Header: 'Profile Picture', accessor: 'ProfilePicture' },
            { Header: 'Platform', accessor: 'Platform' },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
    } = useTable({ columns, data }, useGlobalFilter, useSortBy);

    const { globalFilter } = state;

    return (
        <div>
            <div className="search-container">
                <select onChange={(e) => {
                    setSearchField(e.target.value);
                    if (e.target.value === "Orbox" || e.target.value === "PremiumOrbox") {
                        setOperator(">");  // Set the default operator
                    }
                }}>
                    <option value="name">Name</option>
                    <option value="user_id">User ID</option>
                    <option value="email">Email</option>
                    <option value="role">Role</option>
                    <option value="orbox">Orbox</option>
                    <option value="premium_orbox">Premium Orbox</option>
                    <option value="profile_picture">Profile Picture</option>
                    <option value="platform">Platform</option>
                </select>
                {(searchField === "Orbox" || searchField === "PremiumOrbox") && (
                    <select onChange={(e) => setOperator(e.target.value)}>
                        <option value=">">More than</option>
                        <option value="<">Less than</option>
                    </select>
                )}
                <input
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    placeholder="Search term..."
                />
                <div className="search-container">
                    <button onClick={handleSearch}>Search</button>
                </div>
                <span>Results: {totalResults}</span> {/* Asumiendo que tienes un estado llamado `totalResults` */}
            </div>
            <div className="factoryItemsWrap">
                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
              </span>
                                </th>
                            ))}
                            <th>Edit</th> {/* Add header for Edit button */}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    );
                                })}
                                <td>
                                    <button onClick={() => handleUserClick(row.original.UserId)}>Edit</button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            <div className="pagination-controls">
                <div className="pagination-controls">
                    <button onClick={() => {setCurrentPage(1); setShouldFetch(true);}} disabled={currentPage === 1}>
                        First
                    </button>
                    <button onClick={() => {setCurrentPage(prev => Math.max(prev - 1, 1)); setShouldFetch(true);}} disabled={currentPage === 1}>
                        Previous
                    </button>
                    <span>
                        Page {currentPage} of {totalResults > 0 ? Math.ceil(totalResults / itemsPerPage) : 1}
                    </span>
                    <button onClick={() => {setCurrentPage(prev => Math.min(prev + 1, Math.ceil(totalResults / itemsPerPage))); setShouldFetch(true);}}
                            disabled={currentPage === Math.ceil(totalResults / itemsPerPage) || totalResults === 0}>
                        Next
                    </button>
                    <button onClick={() => {setCurrentPage(Math.ceil(totalResults / itemsPerPage)); setShouldFetch(true);}}
                            disabled={currentPage === Math.ceil(totalResults / itemsPerPage) || totalResults === 0}>
                        Last
                    </button>
                </div>
            </div>
        </div>

    );
}