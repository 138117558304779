import React, { useEffect, useState } from "react";
import axios from "axios";
import User from "../../Models/User";
import "./UserData.css";
import OrdersFiat from "../../Models/OrdersFiat";
import Subscription from "../../Models/Subscription";
import Invitations from "../../Models/invitations";

type UserData = {
    orders: OrdersFiat[]
    referred: Invitations[]
    subscription: Subscription
    user: User
}

function UserDetail({userId, goBack } : {userId: number, goBack: ()=>void})
{
    const [userData, setUserData] = useState(new User());
    const [userOrders, setUserOrders] = useState<OrdersFiat[]>([]); // State to store orders// State to store
    const [userSubscription, setUserSubscription] = useState<Subscription>(new Subscription());
    const [userReferred, setUserReferred] = useState<Invitations[]>([]);

    useEffect(() =>
    {
        const fetchData = async () =>
        {
            const fetchedData = await fetchUserDataById(userId);
            if (fetchedData)
            {
                setUserData(fetchedData.user);
                setUserOrders(fetchedData.orders || []);
                if (fetchedData.subscription && fetchedData.subscription.id !== 0)
                {
                    setUserSubscription(fetchedData.subscription);
                }
                if (fetchedData.referred)
                {
                    const referrals = Array.isArray(fetchedData.referred) ? fetchedData.referred : [fetchedData.referred];
                    const validReferrals = referrals.filter(referral => referral.id !== 0);
                    setUserReferred(validReferrals);
                }
            }
        };

        fetchData().catch(err => {
            console.error("An error occurred:", err);
        });
    }, [userId]);

    const handleUpdate = () => {
        const updatedUser = {
            ...userData
        };

        UpdateUser(updatedUser).then(response => {
            console.log("User updated:", response);  // Debug line
        }).catch(err => {
            console.error("An error occurred while updating user:", err);
        });
    };

    useEffect(() => {
        console.log("Referred users updated:", userReferred);
    }, [userReferred]);


    return (
        <div className="form-container">
            {userData ? (
                <form>
                    <div className="form-input">
                        <label className="form-label">Name:</label>
                        <input type="text" value={userData.Name} onChange={(e) => setUserData({...userData, Name: e.target.value})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Email:</label>
                        <input type="text" value={userData.Email} onChange={(e) => setUserData({...userData, Email: e.target.value})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Role:</label>
                        <select value={userData.Role} onChange={(e) => setUserData({...userData, Role: e.target.value})}>
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                            <option value="betatester">Beta Tester</option>
                            <option value="csteam">Customer Service Team</option>
                        </select>
                    </div>
                    <div className="form-input">
                        <label className="form-label">Orbox:</label>
                        <input type="number" value={userData.Orbox} onChange={(e) => setUserData({...userData, Orbox: parseInt(e.target.value)})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Premium Orbox:</label>
                        <input type="number" value={userData.PremiumOrbox} onChange={(e) => setUserData({...userData, PremiumOrbox: parseInt(e.target.value)})} />
                    </div>
                    <div className="button-container"> {/* New button container */}
                        <button type="button" className="form-button" onClick={handleUpdate}>Change</button>
                        <button type="button" className="form-button" onClick={goBack}>Go Back</button>
                    </div>

                    <h3>Users Referred</h3>

                    <table>
                        <thead>
                            <tr>
                                <th>User ID</th>
                                <th>Referral date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userReferred.map((referred) => (
                                <tr key={referred.id}>
                                    <td>{referred.ReferedUserId}</td>
                                    <td>{referred.CreatedAt?.toString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <h3>Fiat Orders made by the user</h3>

                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Product Name</th>
                                <th>Level</th>
                                <th>Price</th>
                                <th>Provider</th>
                                <th>Status</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userOrders.map((order, index) => (
                                <tr key={index}>
                                    <td>{order.id}</td>
                                    <td>{order.productName}</td>
                                    <td>{order.level}</td>
                                    <td>{order.price}</td>
                                    <td>{order.provider}</td>
                                    <td>{order.status}</td>
                                    <td>{order.quantity}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <h3>User Current subscription</h3>

                    <table> {/* New table for subscription */}
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Level</th>
                                <th>Exp Date</th>
                                <th>Remaining Months</th>
                                <th>Provider</th>
                                <th>Recurrent</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userSubscription && userSubscription.id !== -1 ? (
                                <tr>
                                    <td>{userSubscription.id}</td>
                                    <td>{userSubscription.SubscriptionLevel}</td>
                                    <td>{userSubscription.SubscriptionExpDate}</td>
                                    <td>{userSubscription.RemainingSubMonths}</td>
                                    <td>{userSubscription.DirectDebitProvider}</td>
                                    <td>{userSubscription.Recurrent ? "Yes" : "No"}</td>
                                </tr>
                            ) : (
                                <tr>
                                    <td colSpan={6}>Not Subscribed</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                </form>

            ) : (
                "Loading..."
            )}
        </div>
    );
}

export default UserDetail;

export async function fetchUserById (userId : number) : Promise<User>
{
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
    };
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getUserById?userId=${userId}`, config);
    if (response.status === 200)
    {
        return response.data as User;
    }
    else
    {
        throw new Error("User Not Retrieved Correctly");
    }
}

async function fetchUserDataById (userId : number) : Promise<UserData | undefined>
{
    try
    {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
        };
        const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getUserDataById?userId=${userId}`, config);
        if (response.status === 200)
        {
            return response.data as UserData;
        }
    }
    catch (error)
    {
        console.error("An error occurred:", error);
    }
}

async function UpdateUser (updatedUser : User) : Promise<string | undefined>
{
    try
    {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
        };
        const response = await axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}updateUser`, updatedUser, config);
        if (response.status === 200)
        {
            console.log("User updated successfully");  // Debug line
            return response.data;  // Make sure to return data
        }
    }
    catch (error)
    {
        console.error("An error occurred:", error);
    }
}