// Chat.js
import React, { useState, useEffect, useRef, useCallback } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import { Icon } from "@iconify/react";
// import { ChatBot } from "./ChatBot";
import { Col, Row, Modal, Button, Image, Form, Tooltip, OverlayTrigger, Nav, Dropdown } from "react-bootstrap";
import { Typewriter } from "react-simple-typewriter";
import { Link, useNavigate } from "react-router-dom";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./ChatBot.css";
import { toast } from "react-hot-toast";
import Select from "react-select";
import parse from "html-react-parser";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { WindupChildren } from "windups";
import Slider from "rc-slider";
import { useLocation } from "react-router-dom";
import { getStorage } from "firebase/storage";
import { getFirestore, setDoc } from "@firebase/firestore";
import { doc, collection, getDocs, getDoc } from "firebase/firestore";
import firebase from "firebase/compat/app";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { v4 as uuidv4 } from "uuid";
import { SSE } from "sse";
import { getFirestoreInstance, RegisterOrboxUse } from "../../Utils/Utils";
import { styled } from "@mui/material/styles";

import PopupModal from "../../Components/PopupModal/PopupModal";
import CircularProgress from "@mui/joy/CircularProgress";
import {
    ref,
    uploadBytesResumable,
    getDownloadURL
} from "firebase/storage";
import { initializeApp } from "firebase/app";
import ImageSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { themeSelector } from "../../Redux/Slices/themeSlice";
import { useDispatch, useSelector } from "react-redux";
import useAutosizeTextArea from "../../Utils/useAutosizeTextArea";
import Hls from "hls.js";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { useParams } from "react-router-dom";
import { updateUserData, userDataSelector } from "../../Redux/Slices/userDataSlice";
import { ProductList } from "../../Utils/Constants";
import { generateCode } from "./generateCode";
import cx from "classnames";
import Preview from "./Preview";
import CodeMirror from "./CodeMirror";
import {subDataSelector} from "../../Redux/Slices/subscriptionDataSlice";
import {ProfilePicture} from "../../Components/ProfilePicture/ProfilePicture";
import { showPopUpAction } from "../../Redux/Slices/loginPopUpSlice";
import html2canvas from "html2canvas";
const LordIcon = ({ src, trigger, style }) => {
    useEffect(() => {
        // Check if the script is already loaded
        const scriptAlreadyAdded = document.querySelector("script[src=\"https://cdn.lordicon.com/lordicon-1.2.0.js\"]");

        const script = document.createElement("script");
        script.src = "https://cdn.lordicon.com/lordicon-1.2.0.js";
        script.async = true;

        if (!scriptAlreadyAdded)
        {
            document.body.appendChild(script);
        }

        return () => {
            if (!scriptAlreadyAdded)
            {
                document.body.removeChild(script);
            }
        };
    }, []);

    return (
        <lord-icon
            src={src}
            trigger={trigger}
            colors="primary:#ffffff,secondary:#08a88a"
            stroke="bold"
            style={style}
        ></lord-icon>
    );
};




const customStyles2 = {
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: "#333",  // Change this to your desired color
    }),
    menuPortal: base => ({ ...base, zIndex: 9993 })
};

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: "#333",  // Change this to your desired color
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
};

var numSentence = 0;
var isResponseWaiting = false;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === "light" ? "#36e899" : "#36e899",
    },
}));

function ChatBot(props) {
    const userData = useSelector(userDataSelector);
    const [chatHistory, setChatHistory] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const [currentMessage, setCurrentMessage] = useState("");
    const [textareaHeight, setTextareaHeight] = useState(50);
    const [avatarVideoUrl, setavatarVideoUrl] = useState("");
    const audioRef = useRef("");
    const authToken = localStorage.getItem("oauthToken");
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        console.log("CHAT HISTORY");
        // console.log(chatHistory);
    }, [chatHistory]);

    const [generatedImageUrlsChatbot, setGeneratedImageUrlsChatbot] = useState([]);
    const [numImagesCreated, setNumImagesCreated] = useState(0);
    const [isProgressing, setIsProgressing] = useState(false);
    const [isIfreamOpen, setIsIfreamOpen] = useState(false);
    const [ifreamCode, setIfreamCode] = useState("");


    const [totalDocs, setTotalDocs] = useState(0);

    const navigate = useNavigate();

    const chatFieldRef = useRef(null);

    // Function to scroll to the bottom
    const scrollToBottom = () => {
        const chatField = chatFieldRef.current;
        if (chatField)
        {
            chatField.scrollTop = chatField.scrollHeight;
        }
    };

    const [show2, setShow2] = useState(false);
    const [isOutputted, setisOutputted] = useState(true);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [isPlaying, setIsPlaying] = useState(false);
    const [isPlayingImage, setIsPlayingImage] = useState(false);

    const [premiumVoices, setIsPremiumVoices] = useState({});
    const [speechGenData, setSpeechGenData] = useState({});

    const [yawScale, setYaw] = useState(0);
    const [headMovement, setHeadMovement] = useState(4);
    const [pitchScale, setPitchScale] = useState(0);
    const [rollScale, setRollScale] = useState(0);
    const [inputPrompt, setInputPrompt] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [isLanguageModal, setIsLanguageModal] = useState(false);
    const [isVoiceModal, setIsVoiceModal] = useState(false);
    const [isChatModal, setIsChatModal] = useState(false);

    const [chatbotName, setChatbotName] = useState("");
    const [descriptionChatbot, setDescriptionChatbot] = useState("");
    const [createdBy, setIsCreated] = useState("");
    const [userLink, setUserLink] = useState("");



    const [isCreating, setIsCreating] = useState(false);
    const isThemeDark = useSelector(themeSelector);
    // console.log({ isThemeDark })
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const handleCloseLanguageModal = () => setIsLanguageModal(false);
    const handleCloseVoiceModal = () => setIsVoiceModal(false);
    const handleCloseChatModal = () => setIsChatModal(false);

    const togglePiP = async () => {
        try
        {
            // Exiting PiP if it's already active
            if (document.pictureInPictureElement)
            {
                await document.exitPictureInPicture();
            } else if (videoRef2.current)
            {
                // Request PiP for videoRef2
                await videoRef2.current.requestPictureInPicture();

                // When videoRef2 ends, play videoRef3 in PiP
                videoRef2.current.onended = async () => {
                    if (document.pictureInPictureElement && videoRef3.current)
                    {
                        await videoRef3.current.requestPictureInPicture();
                    }
                };

                // When videoRef3 ends, play videoRef2 in PiP again
                videoRef3.current.onended = async () => {
                    if (document.pictureInPictureElement && videoRef2.current)
                    {
                        await videoRef2.current.requestPictureInPicture();
                    }
                };
            }
        } catch (error)
        {
            console.error("Error: Couldn't enter Picture-in-Picture mode", error);
        }
    };

    function convertToNumberedList(str) {
        // Use a regular expression to split the string on the digit-dot-space pattern
        let items = str.split(/\d\.\s/);

        // Remove the first element if it's empty (which happens if the string starts with a number-dot-space)
        if (items[0] === "")
        {
            items.shift();
        }

        // Wrap each item in <li> tags and join them into a single string
        let listItems = items.map(item => `<li>${item.trim()}</li>`).join("");

        // Wrap the list items in <ol> tags to create an ordered list
        // return `<ol>${listItems}</ol>`;
        return str;
    }

    const [videoTimerId, setVideoTimerId] = useState(null);

    function getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const [progress, setProgress] = useState(0);


    const { username } = useParams();
    useEffect(() => {
        const fetchUserData = async () => {
            const docRef = doc(db, "ChatBots", username); // Adjust collection name and document ID as needed
            const docSnap = await getDoc(docRef);

            if (docSnap.exists())
            {
                const userData = docSnap.data();

                setHasGenerating(true);
                setIsVideo(true);

                setActiveImage(userData.cover_image);
                setGeneratedImages([userData.inputImage]);
                setIsRegenerated(true);
                setInputPrompt(userData.inputPrompt);


                var idleAnimList = userData.generated_intro_vid.split("/");
                var idleAnim = idleAnimList[idleAnimList.length - 1];
                setIdleAnimation(idleAnim);
                setUrl(`${userData.generated_hello_video.replaceAll("xq3pl2oc187t2f", "kn1b72ftyk6i6d")}?backup=${idleAnim}`);
                setUrl2(`${userData.generated_intro_vid.replaceAll("xq3pl2oc187t2f", "kn1b72ftyk6i6d")}?backup=${idleAnim}`);
                if (userData.generated_image == null)
                {
                    setisCreateImageRec(false);
                } else
                {
                    setisCreateImageRec(true);
                    setUrl3(`${userData.generated_image.replaceAll("xq3pl2oc187t2f", "kn1b72ftyk6i6d")}?backup=${idleAnim}`);
                    setUrl4(`${userData.generated_image_finish}?backup=${idleAnim}`);
                }


                setSelectedVoiceForSelectBox(userData.voice);
                setSelectedValue(userData.voice.value);

                setChatbotName(userData.name);
                if (userData.created_by !== "Orbofi" && userData.created_by !== "Premium")
                {
                    const data = userData.created_by;
                    axios.post(import.meta.env.VITE_SERVER_ADDRESS + "getUserNameByEmail", data)
                        .then((resp) => {
                            setIsCreated(resp.data);
                            const url = "../user/" + resp.data;
                            setUserLink(url);
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
                else
                {
                    setUserLink("#");
                    setIsCreated(userData.created_by);
                }
                setDescriptionChatbot(userData.description);

            } else
            {
                console.log("No such document!");
            }
        };
        // console.log("CHATBOT DATA1");
        fetchUserData();
    }, [username]);



    const callEndpoint = async () => {
        try
        {
            const response = await fetch("https://kn1b72ftyk6i6d-5000.proxy.runpod.net/sessions/get_vid", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "sessionuid": sessionuid,
                    "numGenerated": numGenerated
                }),
            });

            // Handle the response if necessary...
            const data = await response.json();

        } catch (error)
        {
            console.error("Failed to fetch data:", error);
        }
    };

    const startVideoTimer = () => {
        if (videoTimerId)
        {
            // A timer is already running, do not start another.
            return;
        }

        const id = setInterval(callEndpoint, 1000); // Call every 5 seconds
        setVideoTimerId(id);
    };

    const stopVideoTimer = () => {
        if (videoTimerId)
        {
            clearInterval(videoTimerId);
            setVideoTimerId(null);
        }
    };

    // new streaming values
    const [isIntroLoading, setIsIntroLoading] = useState(false);
    const [url, setUrl] = useState("");
    const [url2, setUrl2] = useState("");
    const [url3, setUrl3] = useState("");
    const [url4, setUrl4] = useState("");
    const [idleAnimationDefault, setIdleAnimation] = useState("");
    const [sessionuid, setSessionUid] = useState("");
    const [isRunning, setIsRunning] = useState(false);
    const [isCreateImageRec, setisCreateImageRec] = useState(false);
    const [coverImage, setCoverImage] = useState("");

    const [currIndex, setCurrIndex] = useState(0);
    const [resultingData, setResultingData] = useState([]);

    const [userPlayed, setIsUserPlayed] = useState(false);

    const [visible, setVisible] = useState(true);
    const [selectedImageUrl, setSelectedImageUrl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [imagesStatus, setImagesStatus] = useState([]);



    const openModal = (imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const closeIframeModal = () => {
        setIsIfreamOpen(false);
        setIfreamCode("");
    };



    useEffect(() => {
        // setShow2(true);
        const timer3 = setTimeout(() => {
            // setVisible(false);
        }, 2000);  // Set a timer to hide overlay after 2 seconds

        return () => clearTimeout(timer3);  // Clear timer on component unmount
    }, []);  // Empty dependency array means this useEffect runs once, similar to componentDidMount

    const [countDown2, setCountDown2] = useState(0);

    useEffect(() => {
        let intervalId;

        intervalId = setInterval(() => {
            setCountDown2(prevCount => Math.min(prevCount + 1, 100));  // Increment by 2
        }, 200);

        const speedUpTimer = setTimeout(() => {
            clearInterval(intervalId);  // Clear the initial slow interval

            // Start a faster interval of 50ms after the first second
            intervalId = setInterval(() => {
                setCountDown2(prevCount => Math.min(prevCount + 10, 100));  // Increment by 10
            }, 180);
        }, 400);

        const timer2 = setTimeout(() => {
            // setVisible(false);
            setCountDown2(100);
            clearInterval(intervalId);  // Clear the interval when hiding the overlay
        }, 2000);

        return () => {
            clearTimeout(timer2);
            clearInterval(intervalId);  // Clear the interval on component unmount
        };
    }, []);

    useEffect(() => {
        const sessionId = uuidv4();  // Generate a unique session ID
        setSessionUid(sessionId);
        try
        {
            fetch("https://kn1b72ftyk6i6d-5000.proxy.runpod.net/sessions", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    key: sessionId,
                    value: {
                        "videos": []
                    },
                }),
            });


        } catch (error)
        {
            console.error("Error:", error);
        }
    }, []);

    function splitEveryFourWords(text) {
        let words = text.split(" ");
        let result = [];

        for (let i = 0; i < words.length; i += 6)
        {
            result.push(words.slice(i, i + 6).join(" ") + ".");
        }

        return result.join(" ");
    }

    function endsWithNumber(str) {
        const lastChar = str.slice(-1);
        return !isNaN(lastChar) && lastChar !== " ";
    }

    const videoRef2 = useRef(null);
    const videoRef3 = useRef(null);

    var numGenerated = 0;
    var hasGeneratedSequence = false;
    const [intervalId, setIntervalId] = useState(null);
    var idleAnimation = "https://kn1b72ftyk6i6d-5000.proxy.runpod.net/hls/3s.m3u8";

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Set up the event listener for window resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const playVideo = (video, url, autoplay = true) => {
        try
        {
            if (Hls.isSupported())
            {
                const hls = new Hls();
                hls.loadSource(url);
                hls.attachMedia(video);
                if (autoplay)
                {
                    hls.on(Hls.Events.MANIFEST_PARSED, () => {
                        video.play().then(() => {
                            setVisible(false);
                        }).catch(e => {
                            console.log("error occured");
                            // setVisible(true);
                        });
                    });
                }
                return hls;  // Return the hls instance for cleanup
            } else if (video.canPlayType("application/vnd.apple.mpegurl"))
            {
                video.src = url;
                if (autoplay)
                {
                    video.addEventListener("loadedmetadata", () => {
                        video.play();
                    });
                }
            }
        } catch (e)
        {
            // setVisible(true);
            // print('fewfe')
            console.error(e);
        }
    };

    useEffect(() => {
        if (sessionuid !== "")
        {
            console.log("sessionUid:", sessionuid);
        }
    }, [sessionuid]);

    useEffect(() => {
        // console.log()
    });

    const handlePlayVideo = () => {
        if (videoRef.current)
        {
            videoRef.current.play();
        }
        setVisible(false); // Or any other state change you need
        setIsUserPlayed(true);
    };
    //
    function extractTextAfterList(htmlString) {
        // Create a new DOMParser instance
        const parser = new DOMParser();

        // Parse the HTML string
        const doc = parser.parseFromString(htmlString, "text/html");

        // Find the <ol> element
        const list = doc.querySelector("ol");

        // Get the next sibling's text content
        let textAfterList = "";
        if (list && list.nextSibling)
        {
            // Extract the text content, trimming whitespace
            textAfterList = list.nextSibling.textContent.trim();
        }

        return textAfterList;
    }

    useEffect(() => {
        // const video = videoRef2.current;
        // let hls = playVideo(video, url, false);  // Load and play the first video
        if (sessionuid !== "" && url !== "")
        {
            setTimeout(() => {

                const video = videoRef2.current;
                const video2 = videoRef3.current;

                idleAnimation = url;

                if (!video || !video2)
                {
                    console.error("Video is null");
                    return;
                }

                video.style.display = "block";  // Show video1
                video2.style.display = "none";  // Hide video2

                let hls = playVideo(video, url);  // Load and play the first video
                let hls2 = playVideo(video2, url2, false);  // Preload the second video without playing

                // setVisible(false);

                const handleVideo1Ended = async () => {

                    setIsVideo1(false);
                    idleAnimation = url2;

                    if (numSentence == numGenerated && hasGeneratedSequence)
                    {
                        numGenerated = 0;
                        numSentence = 0;
                        hasGeneratedSequence = false;
                        setIsLoading(false);
                        setisOutputted(true);
                    }


                    var tmp_url = idleAnimation;

                    video.style.display = "none";  // Hide video1
                    video2.style.display = "block";  // Show video2

                    if (hls)
                    {
                        hls.destroy();  // Optionally destroy the hls instance for video1
                    }

                    try
                    {
                        const response = await fetch("https://kn1b72ftyk6i6d-5000.proxy.runpod.net/sessions/get_vid", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                "sessionuid": sessionuid,
                                "numGenerated": numGenerated
                            }),
                        });

                        // const response = await fetch(`https://i5z2sbz6n8ylzb-5000.proxy.runpod.net/sessions/${sessionuid}/videos/has_videos`);
                        const data = await response.json();
                        if (data["total_vids"] > 0)
                        {

                            // setNumGenerated(numGenerated + 1);
                            numGenerated += 1;
                            hasGeneratedSequence = true;
                            tmp_url = `${data["video"]}?backup=${idleAnimationDefault}`;


                            hls2 = playVideo(video2, tmp_url, false);
                            hls = playVideo(video, idleAnimation, false);
                            setIsProgressing(false);

                            video2.play();  // Play the preloaded second video

                        } else
                        {
                            tmp_url = idleAnimation;
                            hls = playVideo(video, tmp_url, false);

                            video2.play();  // Play the preloaded second video
                        }
                    } catch (error)
                    {
                        console.error("Error:", error);
                        tmp_url = idleAnimation;
                        hls = playVideo(video, tmp_url, false);

                        video2.play();  // Play the preloaded second video
                    }



                };


                const handleVideo2Ended = async () => {
                    setIsVideo1(true);

                    if (numSentence == numGenerated && hasGeneratedSequence)
                    {
                        numGenerated = 0;
                        numSentence = 0;
                        hasGeneratedSequence = false;
                        setIsLoading(false);
                        setisOutputted(true);
                    }


                    var tmp_url = idleAnimation;

                    video.style.display = "block";  // Hide video1
                    video2.style.display = "none";  // Show video2

                    if (hls2)
                    {
                        hls2.destroy();  // Optionally destroy the hls instance for video1
                    }

                    try
                    {
                        const response = await fetch("https://kn1b72ftyk6i6d-5000.proxy.runpod.net/sessions/get_vid", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                "sessionuid": sessionuid,
                                "numGenerated": numGenerated
                            }),
                        });

                        // const response = await fetch(`https://i5z2sbz6n8ylzb-5000.proxy.runpod.net/sessions/${sessionuid}/videos/has_videos`);
                        const data = await response.json();
                        if (data["total_vids"] > 0)
                        {

                            numGenerated += 1;
                            hasGeneratedSequence = true;
                            tmp_url = `${data["video"]}?backup=${idleAnimationDefault}`;
                            // hls2 = playVideo(video2, urls[0], false);
                            hls = playVideo(video, tmp_url, false);
                            hls2 = playVideo(video2, idleAnimation, false);
                            setIsProgressing(false);

                            video.play();  // Play the preloaded second video

                        } else
                        {
                            <div className=""></div>;
                            tmp_url = idleAnimation;
                            // hls2 = playVideo(video2, urls[0], false);
                            hls2 = playVideo(video2, tmp_url, false);

                            video.play();  // Play the preloaded second video
                        }
                    } catch (error)
                    {
                        console.error("Error:", error);
                        tmp_url = idleAnimation;
                        // hls2 = playVideo(video2, urls[0], false);
                        hls2 = playVideo(video2, tmp_url, false);

                        video.play();  // Play the preloaded second video
                    }


                };

                // console.log("Attaching event listener");
                video.addEventListener("ended", handleVideo1Ended);
                video2.addEventListener("ended", handleVideo2Ended);

                return () => {
                    video.removeEventListener("ended", handleVideo1Ended);
                    if (hls2)
                    {
                        hls2.destroy();  // Cleanup for the second video
                    }
                    // ... other cleanup ...
                };
            }, 0);
        }
    }, [sessionuid, url, url2, idleAnimationDefault, userPlayed]);

    // }, [url]);  // re-run this useEffect block whenever 'src' prop changes

    const deleteSession = async () => {
        if (!sessionuid)
        {
            console.error("Session ID is required");
            return;
        }

        try
        {
            const response = await fetch(`https://kn1b72ftyk6i6d-5000.proxy.runpod.net/sessions/${sessionuid}`, {
                method: "DELETE",
            });

            if (response.ok)
            {
                console.log("Session deleted successfully");
            } else
            {
                console.error("Failed to delete session:", response.statusText);
            }
        } catch (error)
        {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        // Cleanup function to clear the timer when the component unmounts
        return () => {
            if (intervalId)
            {
                deleteSession();
                clearInterval(intervalId);
            }
        };
    }, [intervalId]);


    const handleClick = () => {
        setShow(prevValue => !prevValue); // This toggles the boolean value
    };

    // Initialize Firebase
    const db = getFirestoreInstance();



    const handleInputPromptChange = (e) => {
        setInputPrompt(e.target.value);
    };

    // useEffect(() => {
    //   console.log(props);
    // })


    const location = useLocation();



    const fileInputRef = useRef(null);



    const [transcript, setTranscript] = useState("");
    const [isListening, setIsListening] = useState(false);
    const inputRef = useRef(null);

    const [audioUrl, setAudioUrl] = useState("");

    const startListening = () => {
        // setCurrentMessage("");
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

        if (SpeechRecognition)
        {
            const recognition = new SpeechRecognition();
            recognition.interimResults = true;

            recognition.onresult = (event) => {
                let interimTranscript = "";
                for (let i = event.resultIndex; i < event.results.length; i++)
                {
                    const transcriptionSegment = event.results[i][0].transcript;
                    if (event.results[i].isFinal)
                    {
                        // setCurrentMessage((prev) => prev + transcriptionSegment);
                    } else
                    {
                        // console.log(transcriptionSegment);
                        interimTranscript += transcriptionSegment;
                        setCurrentMessage(interimTranscript);
                    }
                }
                inputRef.current.value = transcript + interimTranscript;
            };

            recognition.onstart = () => {
                setIsListening(true);
            };

            recognition.onend = () => {
                setIsListening(false);
            };

            recognition.start();
        } else
        {
            toast.error("Speech Recognition API not supported in this browser.");
        }
    };



    const videoRef = useRef(null);
    const [countDown, setCountDown] = React.useState(0);
    const [runTimer, setRunTimer] = React.useState(false);
    const [isAudio, setIsAudio] = React.useState(false);
    const [isVideo, setIsVideo] = React.useState(true);
    const [timerRate, setTimerRate] = useState(2000);

    useEffect(() => {
        if (avatarVideoUrl && videoRef.current)
        {

            videoRef.current.addEventListener("loadedmetadata", () => {
                videoRef.current.play();
            });

            videoRef.current.load();  // Ensure the new source is loaded
        }
    }, [avatarVideoUrl]);


    const [isGenerating, setIsGenerating] = useState(false);
    const [hasGenerated, setHasGenerating] = useState(true);
    const [isRegenerated, setIsRegenerated] = useState(false);

    const [isGeneratingImage, setIsGeneratingImage] = useState(false);
    const [hasGeneratedImage, setHasGeneratingImage] = useState(false);

    const [superenhance, setSuperenhance] = useState(false);

    const handleCheckboxChange = (event) => {
        setSuperenhance(event.target.checked);
    };


    const [generatedImages, setGeneratedImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState([]);
    const [message, setMessage] = useState(
        "realistic portrait of a professional young man, real life, high quality, colored"
    );
    const [faceOrAudioToggle, setFaceOrAudioToggle] = useState(false);
    const [artGenMode, setArtGenMode] = useState(false);

    const handlePromptChange = (event) => {
        setMessage(event.target.value);
    };

    const [show, setShow] = useState(false);
    const [sliderValue1, setSliderValue1] = useState(50);
    const [sliderValue2, setSliderValue2] = useState(50);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSliderChange1 = (e) => setSliderValue1(e.target.value);
    const handleSliderChange2 = (e) => setSliderValue2(e.target.value);


    const [voices, setVoices] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState(null);

    let [resultt, setResultt] = useState("");

    const resultRef = useRef();

    useEffect(() => {
        resultRef.current = resultt;
    }, [resultt]);


    var models = [
        {
            value: 1,
            text: "Llama2",
            icon: <Icon icon="emojione:flag-for-united-states" />,
        },
        {
            value: 2,
            text: "ChatGPT",
            icon: <Icon icon="emojione:flag-for-spain" />,
        },

    ];
    var codeType = [
        {
            value: "html_tailwind",
            text: "HTML + Tailwind",
            icon: <Icon icon="emojione:flag-for-united-states" />,
        },
        {
            value: "ionic_tailwind",
            text: "Ionic + Tailwind",
            icon: <Icon icon="emojione:flag-for-spain" />,
        },
        {
            value: "react_tailwind",
            text: "React + Tailwind",
            icon: <Icon icon="emojione:flag-for-spain" />,
        },
        {
            value: "generic",
            text: "generic",
            icon: <Icon icon="emojione:flag-for-spain" />,
        }


    ];
    const [chosenModel, setChosenModel] = useState("Llama2");
    const [chosenCodeType, setChosenCodeType] = useState("Llama2");

    const [languages, setLanguages] = useState([]);
    useEffect(() => {

        // console.log("languages state has changed:", languages);


    }, [languages]);

    const [chosenLanguage, setSelectedLanguage] = useState("English (US)");

    const [selectedModelOption, setSelectedModelOption] = useState({
        value: 1,
        text: "Llama2",
        icon: <Icon icon="emojione:flag-for-united-states" />,
    });
    const [selectedCodeTypeOption, setSelectedCodeTypeOption] = useState({
        value: "html_tailwind",
        text: "HTML + Tailwind",
        icon: <Icon icon="emojione:flag-for-united-states" />,
    });
    const [selectedOption, setSelectedOption] = useState({ "value": "English (US)", "label": "English (US)", });

    function replaceDannyTrejo(str) {
        return str.replace(/Danny Trejo|danny trejo/g, "<lora:danny2500:1.3>");
    }


    const [selectedValue, setSelectedValue] = useState("Angel");
    const [selectedSample, setSelectedSample] = useState("");
    const [selectedVoiceForSelectBox, setSelectedVoiceForSelectBox] = useState();
    const audioRef2 = useRef(null);

    const handleVoiceChange = (event) => {
        setSelectedVoice(event.target.value);

        setIsPlaying(false);
        if (audioRef.current)
        {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    };

    const playPreview = () => {
        if (audioRef2.current)
        {
            audioRef2.current.play();
        }
    };

    const pausePreview = () => {
        if (audioRef2.current)
        {
            audioRef2.current.pause();
        }
    };

    const selectedVoiceSample = voices.find(
        (voice) => voice.value === selectedVoice
    )?.sample;

    useEffect(() => {

        const fetchData = async () => {
            await fetch("https://speechgen.io/index.php?r=api/voices")
                .then(response => {
                    // Check if the request was successful
                    if (response.status === 200)
                    {
                        return response.json();
                    } else
                    {
                        throw new Error(`Failed: ${response.statusText}`);
                    }
                })
                .then(data => {

                    const newList = data;

                    //set languages
                    setSpeechGenData(newList);
                    const languages_temp = Object.keys(newList);
                    const options = languages_temp.map(language => ({ value: language, label: language }));
                    setLanguages(options);

                    //set voices
                    var newArray = data["English (US)"].map((item) => {
                        return {
                            value: item.voice,
                            name: item.voice,
                            language: "English (US)",
                            // ... any additional keys you want
                        };
                    });

                    newArray = [{
                        "value": "Fast",
                        "name": "Fast",
                        "language": "Fast",
                    }, ...newArray];

                    setVoices(newArray);

                    try
                    {

                    } catch (e)
                    {
                        setIsCreating(false);
                    }

                })
                .catch(error => {
                    console.log(error);
                });




        };

        fetchData();


    }, []); // empty dependency array means this useEffect runs once after initial render

    const [avatarImageUrlSelected, setAvatarImageUrl] = useState("");
    const [isAvatarGenerated, setIsAvatarGenerated] = useState(false);
    const [selectedAvatarURL, setselectedAvatarURL] = useState("");


    const handleCategoryChange = (event) => {
        const selectedVoiceName = event.name;
        const selectedVoice = voices.find(
            (voice) => voice.name === selectedVoiceName
        );

        setIsPlaying(false);

        if (selectedVoice)
        {
            setSelectedValue(selectedVoice.value);
            // setSelectedSample(selectedVoice.sample);
        }
        setSelectedVoiceForSelectBox(event);
        // your logic here
    };

    const handleModelChange = (event) => {
        const selectedLanguage = event.text;

        setChosenModel(selectedLanguage);
        setSelectedModelOption(event);
        // your logic here
    };
    const handleCodeTypeChange = (event) => {
        const selectedCodeType = event.value;


        setSettings((prev) => ({ ...prev, generatedCodeConfig: selectedCodeType }));
        setChosenCodeType(selectedCodeType);
        setSelectedCodeTypeOption(event);
        // your logic here
    };

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.value;
        setSelectedLanguage(selectedLanguage);

        // setSelectedLanguage(selectedLanguage);
        setSelectedOption({ "value": selectedLanguage, "name": selectedLanguage, "language": selectedLanguage, });

        // console.log(speechGenData[event.value]);

        const newArray = speechGenData[event.value].map((item) => {
            return {
                value: item.voice,
                name: item.voice,
                language: selectedLanguage,
                // ... any additional keys you want
            };
        });

        setVoices(newArray);


        setSelectedVoiceForSelectBox(newArray[0]);
        setSelectedValue(newArray[0].value);
        setSelectedOption({ "value": selectedLanguage, "name": selectedLanguage, "language": selectedLanguage, });
        // your logic here
    };

    const [activeImage, setActiveImage] = useState("");



    React.useEffect(() => {
        let timerId;

        if (runTimer)
        {
            setCountDown(0);
            timerId = setInterval(() => {
                setCountDown((countDown) => countDown + 1);
            }, timerRate);
        } else
        {
            clearInterval(timerId);
        }

        return () => clearInterval(timerId);
    }, [runTimer, timerRate]);

    React.useEffect(() => {
        if (countDown > 100 && runTimer)
        {
            setRunTimer(false);
            setCountDown(0);
        }
    }, [countDown, runTimer]);

    React.useEffect(() => {
        if (countDown > 100 && runTimer)
        {
            setRunTimer(false);
            setCountDown(0);
        }
    }, [countDown, runTimer]);

    const convertMessages = (messages) => {
        const formattedMessages = messages.map((message) => ({
            role: message.sender,
            content: message.text,
        }));

        // if (messages.length < 2) {
        formattedMessages.unshift({
            role: "system",
            content: `You are an Orbofi A.I. Assistant created by the engineers at orbofi. You are able to act as any character given the characteristics that the user inputs. Here is the characteristics you should replicate: ${inputPrompt}. Don't make your sentences too long. Conversation begins:\n`
        });
        // }

        return formattedMessages;
    };

    const convertMessagesForImageGeneration = (messages) => {
        const formattedMessages = messages.map((message) => ({
            role: message.sender,
            content: message.text,
        }));

        // if (messages.length < 2) {
        formattedMessages.unshift({
            role: "system",
            content: `You are an Orbofi A.I. Assistant created by the engineers at orbofi. You are able to act as any character given the characteristics that the user inputs. Here is the characteristics you should replicate: ${inputPrompt}. When the user asks to generate images, you will create 4 image generation prompts of whatever they ask. These 4 prompts will be fed into an AI image generator, Make sure to style the prompts with <ol>, <li> to make sure your response gets processed correctly.. After the ordered list, add a catchphrase commenting on the generated prompts in past tense, make it funny, catchy and less than 20 words! Conversation begins:\n`
        });
        // }

        return formattedMessages;
    };

    // Send Data to Talking Head API to generate talking face
    const postData = async (url = "", data = {}) => {
        let now = new Date();
        // setIsTyping(true);
        // setIsGenerating((t) => true);
        setIsLoading(true);

        // setRunTimer((t) => true);

        await fetch(url, {
            method: "POST",
            body: JSON.stringify({
                data,
                // add all other data here
            }),
            headers: {
                "content-type": "application/json",
            },
        })
            .then((response) => response.json())
            .then(async (data) => {
            })
            .catch((error) => {
                setIsTyping(false);
                setIsLoading(false);
            });
    };



    const postDataIdle = async (url = "", data = {}) => {
        let now = new Date();

        await fetch(url, {
            method: "POST",
            body: JSON.stringify({
                data,
                // add all other data here
            }),
            headers: {
                "content-type": "application/json",
            },
        })
            .then((response) => response.json())
            .then(async (data) => {
                // console.log("DATA");
                // console.log("DONE IDLE ANIMATION");
                setCountDown(100);
                setIsGenerating(false);
                setIsGeneratingImage((t) => false);
                setRunTimer((t) => false);
                setIsPlayingImage(true);
                setHasGenerating(true);
                setIsVideo(true);
                setUrl(`https://kn1b72ftyk6i6d-5000.proxy.runpod.net/hls/${data["text"]}?backup=${idleAnimationDefault}`);
                setUrl2(`https://kn1b72ftyk6i6d-5000.proxy.runpod.net/hls/${data["text"]}?backup=${idleAnimationDefault}`);
                // setUrl3(`https://xq3pl2oc187t2f-5000.proxy.runpod.net/hls/${data['text']}?backup=${idleAnimationDefault}`);
                // setUrl2(`https://xq3pl2oc187t2f-5000.proxy.runpod.net/hls/${data['text']}?backup=${idleAnimationDefault}`);

                // setU
                // return data;
                // setQueueId(data["queue_id"]);
            })
            .catch((error) => {
                toast.error(error.message);
                // setRunTimer((t) => false);
                // setCountDown(0);
                // setIsGenerating((t) => false);
                // setHasGenerating(false);
                // setIsTyping(false);
                // setIsLoading(false);
            });
    };


    const onSendDirectMessage = (message) => {
        setCurrentMessage(message);
        // sendMessageHead();
    };

    function replaceHtmlCharsWithDot(inputString) {
        // Replace HTML tags with a dot
        let result = inputString.replace(/<\/?[^>]+(>|$)/g, ".");

        // Replace HTML entities with a dot
        result = result.replace(/&[^;\s]+;/g, ".");

        return result;
    }

    function replaceSpecialCharsWithDot(inputString) {
        // Replace specified characters with a dot
        return inputString.replace(/[!:\?]/g, ".");
    }
    const dispatch = useDispatch();
    const userData2 = useSelector(userDataSelector);
    let quantityProducts = 1;
    const [timerRunning, setTimerRunning] = useState(false);
    const [isVideo1, setIsVideo1] = useState(true);
    const intervalRef = useRef(null); // To keep track of the interval ID

    // useEffect(() => {
    //   if (timerRunning)
    //   {
    //     intervalRef.current = setInterval(async () => {

    //       const response = await fetch('https://lzc6smy1cj2us5-5000.proxy.runpod.net/sessions/get_vid', {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({
    //           "sessionuid": sessionuid,
    //           'numGenerated': numGenerated
    //         }),
    //       });
    //       const data = await response.json();
    //       console.log(data);

    //       if (data['total_vids'] > 0)
    //       {

    //         // setNumGenerated(numGenerated + 1);
    //         var tmp_url = idleAnimation;

    //         tmp_url = `${data['video']}?backup=${idleAnimationDefault}`;


    //         if (isVideo1)
    //         {
    //           console.log("IS VIDEO 1");
    //           setUrl2(tmp_url);

    //           numGenerated += 1;
    //           setTimerRunning(false);
    //         } else
    //         {
    //           console.log("IS NOT VIDEO 1");
    //           setUrl(tmp_url);
    //           numGenerated += 1;
    //           setTimerRunning(false);

    //         }
    //         console.log("VIDEO IS GENERATED AND READY TO PLAY");
    //       } else
    //       {
    //         tmp_url = idleAnimation;
    //       }
    //     }, 1000);
    //   }

    //   return () => {
    //     if (intervalRef.current) clearInterval(intervalRef.current);
    //   };
    // }, [timerRunning, isVideo1]);


    function splitSentenceAt4thLastSpace(sentence) {
        // Check if the sentence length is more than 50 characters
        if (sentence.length > 50)
        {
            // Initialize splitIndex
            let splitIndex = -1;

            // Find the last 4th space before the 50th character
            for (let i = 0, spaceCount = 0; i < 50; i++)
            {
                if (sentence[i] === " ")
                {
                    spaceCount++;
                    if (spaceCount === 4)
                    {
                        splitIndex = i;
                        break;
                    }
                }
            }

            // If there's no 4th space, split at the 50th character
            if (splitIndex === -1) splitIndex = 50;

            // Split the sentence into two parts
            let part1 = sentence.substring(0, splitIndex);
            let part2 = sentence.substring(splitIndex + 1);

            return [part1, part2];
        } else
        {
            // If the sentence is less than or equal to 50 characters, return it as is
            return [sentence];
        }
    }

    function containsImageRequest(text) {
        var sentences = text.toLowerCase().split(/[.!?]+/); // Split text into sentences
        return sentences.some(sentence => {
            return (sentence.includes("create") && sentence.includes("image")) ||
                (sentence.includes("generate") && sentence.includes("image")) ||
                (sentence.includes("make") && sentence.includes("it")) ||
                (sentence.includes("make") && sentence.includes("image")) ||
                (sentence.includes("make") && sentence.includes("them")) ||
                (sentence.includes("make") && sentence.includes("him")) ||
                (sentence.includes("make") && sentence.includes("her")) ||
                (sentence.includes("create") && sentence.includes("an")) ||
                (sentence.includes("images"));
        });
    }

    function extractListItems(htmlString) {
        // Regular expression to match the numbered items
        const regex = /<li>(.*?)<\/li>/g;

        // Initialize an empty array to hold the extracted items
        const extractedItems = [];

        let match;
        // Use a while loop to find all matches
        while ((match = regex.exec(htmlString)) !== null)
        {
            // The first capturing group in the regex contains the content of the <li> element
            extractedItems.push(match[1].trim());
        }

        return extractedItems;
    }

    // Function to render four loading indicators
    const renderLoadingIndicators = () => {
        let indicators = [];
        for (let i = 0; i < 4; i++)
        {
            indicators.push(
                <div key={i} style={{
                    backgroundColor: "#38418b", // This sets a dark background color
                    borderRadius: "5%", // This makes the border circular
                    width: "125px", height: "125px", margin: "5px",
                    display: "flex", // Use flex to center the CircularProgress
                    justifyContent: "center", // Center horizontally
                    alignItems: "center" // Center vertically
                }}>
                    <CircularProgress />
                </div>
            );
        }
        return indicators;
    };

    const streamTextGeneration = async () => {
        if (currentMessage.length < 1)
        {
            toast.error("Make sure input text is greater than 1 character", {
                icon: "👏",
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                    position: "bottom-center",
                    // index: '1'
                },
            });
            return;

        }
        const AUDIO_ORBOX_QUANTITY = 1;
        const VIDEO_QUANTITY_MULTIPLIER = 4;

        //True means audio false means Face
        if (faceOrAudioToggle)
        {
            quantityProducts = AUDIO_ORBOX_QUANTITY;
        }
        else
        {
            quantityProducts = AUDIO_ORBOX_QUANTITY * VIDEO_QUANTITY_MULTIPLIER;
        }


        const response = await RegisterOrboxUse(ProductList.ChatBot, false, quantityProducts);
        // console.log(response);


        if (response !== "InvalidTransaction")
        {
            // Update userData with the new values
            const updatedUserData = {
                ...userData2,
                Orbox: parseInt(response.Orbox, 10),
                PremiumOrbox: parseInt(response.PremiumOrbox, 10)
            };
            dispatch(updateUserData(updatedUserData));
            console.log("ValidTransaction");
        } else
        {
            console.log("InvalidTransaction");
        }

        numSentence = 0;
        setIsTyping(true);
        setIsLoading(true);
        setIsProgressing(true);
        setIsGenerating((t) => true);
        setCurrentMessage("");
        setSelectedFiles([]);
        setImagePreviews([]);
        // console.log("STREAMING");
        setTimerRunning(true);
        scrollToBottom();

        if (containsImageRequest(currentMessage))
        {
            // console.log("User wants to Generate Image.");
            setselectedAvatarURL(activeImage);

            setResultt("");

            // if (currentMessage.trim() !== "")
            // {

            const newChatHistory = [
                ...chatHistory,
                {
                    text: currentMessage,
                    sender: "user",
                    profile: userData?.ProfilePicture ?? "/images/profile.png",
                    audioUrl: "",
                },
            ];
            setChatHistory(newChatHistory);

            // console.log(convertMessagesForImageGeneration(newChatHistory));
            let url = "https://api.openai.com/v1/chat/completions";
            let tmp = {
                model: "gpt-4",
                messages: convertMessagesForImageGeneration(newChatHistory),
                temperature: 0.75,
                top_p: 0.95,
                // max_tokens: 100,
                stream: true,
                // n: 1,
            };

            let source = new SSE(url, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer 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",
                },
                method: "POST",
                payload: JSON.stringify(tmp),
            });
            source.addEventListener("message", (e) => {
                setIsRunning(true);
                scrollToBottom();


                if (e.data != "[DONE]")
                {

                    setIsTyping(false);
                    // setIsTyping(false);
                    let payload = JSON.parse(e.data);
                    // console.log(payload['choices']);
                    let text = payload.choices[0].delta["content"];
                    if (text != null)
                    {
                        // console.log("Text: " + text);
                        resultRef.current = resultRef.current + text;
                        setResultt(resultRef.current);


                    }

                } else
                {
                    // setResultt(resultRef.current);
                    source.close();
                }

            });
            setGeneratedImageUrlsChatbot([]);
            setImagesStatus(["loading", "loading", "loading", "loading"]);
            let hasRun = false;
            source.addEventListener("readystatechange", async (e) => {
                if (hasRun) return;
                if (e.readyState >= 2)
                {
                    hasRun = true;
                    // console.log("COMPLETED");
                    setIsTyping(false);
                    isResponseWaiting = true;

                    setIsRunning(false);


                    setChatHistory([
                        ...newChatHistory,
                        {
                            text: resultRef.current,
                            sender: "system",
                            profile: activeImage, //
                            audioUrl: "", //
                            images: [],
                        },
                    ]);

                    var prompts = extractListItems(resultRef.current);
                    var images = [];
                    // console.log("Generating Images...");
                    // console.log(prompts);
                    if (setisCreateImageRec)
                    {
                        setUrl(url3);
                    }

                    var stringToSay = extractTextAfterList(resultRef.current);

                    var data = {
                        img_url: activeImage,
                        prompt: currentMessage,
                        is_audio: false,
                        dc: convertMessages(chatHistory),
                        voice: selectedValue,
                        language: chosenLanguage,
                        yawScale: yawScale,
                        headMovement: headMovement,
                        pitchScale: pitchScale,
                        rollScale: rollScale,
                        inputPrompt: inputPrompt,
                        superenhance: superenhance,
                        answer: stringToSay.replaceAll("\"", ""),
                        sessionuid: sessionuid,
                        numSentence: numSentence
                    };


                    // console.log("CALLING OPENAI");
                    // setIsRunning(true);
                    Promise.all([
                        // This part handles the array of API requests
                        Promise.all(prompts.map((prompt, i) => {
                            const postData = {
                                model: "dall-e-3",
                                prompt: prompt,
                                n: 1,
                                size: "1024x1024"
                            };

                            return axios.post("https://api.openai.com/v1/images/generations", postData, {
                                headers: {
                                    "Content-Type": "application/json",
                                    "Authorization": "Bearer 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"
                                }
                            })
                                .then(response => {
                                    // Process each response
                                    // console.log(response.data.data[0].url);
                                    images.push(response.data.data[0].url);
                                    setImagesStatus(prevStatus => {
                                        const newStatus = [...prevStatus];
                                        newStatus[i] = "loaded";
                                        return newStatus;
                                    });
                                })
                                .catch(error => {
                                    // Handle errors
                                    console.error("Error generating image for prompt:", prompt, error);
                                    setIsLoading(false);
                                });
                        })),

                        // This is your other API request
                        fetch("https://kn1b72ftyk6i6d-6000.proxy.runpod.net/talking_voice_clone", {
                            method: "POST",
                            body: JSON.stringify({
                                data, // Add your data here
                            }),
                            headers: {
                                "content-type": "application/json",
                            },
                        })
                            .then(response => response.json())
                            .then(async data => {
                                // Process this response
                                // console.log(data);
                                const response = await fetch("https://kn1b72ftyk6i6d-5000.proxy.runpod.net/sessions/get_vid", {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        "sessionuid": sessionuid,
                                        "numGenerated": numGenerated
                                    }),
                                });

                                // const response = await fetch(`https://i5z2sbz6n8ylzb-5000.proxy.runpod.net/sessions/${sessionuid}/videos/has_videos`);
                                const data2 = await response.json();
                                return data2["video"];
                            })
                            .catch(error => {
                                // Handle errors
                                setIsTyping(false);
                                setIsLoading(false);
                            })
                    ])
                        .then(async ([imageResponses, voiceCloneResponse]) => {
                            // This part runs after all promises have resolved
                            // console.log("OUTPUT DATA");
                            // console.log(voiceCloneResponse);
                            // console.log(imageResponses);
                            setChatHistory([
                                ...newChatHistory,
                                {
                                    text: resultRef.current,
                                    sender: "system",
                                    profile: activeImage, //
                                    audioUrl: "", //
                                    images: images.slice(0, 4),
                                },
                            ]);


                            setIsRunning(false);
                            setIsProgressing(false);
                            setIsLoading(false);
                            setUrl(voiceCloneResponse);
                        })
                        .catch(error => {
                            // Handle any error from the entire Promise.all
                            console.error("Error in Promise.all:", error);
                            setIsLoading(false);
                        });

                }
            });
            source.stream();
        }
        else
        {

            setselectedAvatarURL(activeImage);

            setResultt("");



            const newChatHistory = [
                ...chatHistory,
                {
                    text: currentMessage,
                    sender: "user",
                    profile: userData?.ProfilePicture ?? "/images/profile.png",
                    audioUrl: "",
                },
            ];
            setChatHistory(newChatHistory);

            // console.log(convertMessages(newChatHistory));
            let url = "https://api.openai.com/v1/chat/completions";
            let tmp = {
                model: "gpt-4",
                messages: convertMessages(newChatHistory),
                temperature: 0.75,
                top_p: 0.95,
                // max_tokens: 100,
                stream: true,
                // n: 1,
            };


            let source = new SSE(url, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer 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",
                },
                method: "POST",
                payload: JSON.stringify(tmp),
            });


            var numGenerated = 0;
            // var t = ""
            // if (faceOrAudioToggle)
            // {
            //     source.addEventListener("message", (e) => {
            //         setIsRunning(true);
            //         scrollToBottom();


            //         if (e.data != "[DONE]")
            //         {

            //             setIsTyping(false);
            //             // setIsTyping(false);
            //             let payload = JSON.parse(e.data);
            //             // console.log(payload['choices']);
            //             let text = payload.choices[0].delta["content"];
            //             if (text != null)
            //             {
            //                 // console.log("Text: " + text);
            //                 resultRef.current = resultRef.current + text;
            //                 setResultt(resultRef.current);

            //                 var textTosay = "";
            //                 textTosay = textTosay.replaceAll("!!!", ".");
            //                 textTosay = textTosay.replaceAll("!!", ".");
            //                 textTosay = textTosay.replaceAll("...", ".");
            //                 textTosay = textTosay.replaceAll(":", " ");
            //                 textTosay = textTosay.replaceAll(",", " ");
            //                 textTosay = textTosay.replaceAll("'", " ");
            //                 textTosay = textTosay.replaceAll("-", " ");
            //                 textTosay = textTosay.replaceAll("?!!", ".");
            //                 textTosay = textTosay.replaceAll("?!", ".");
            //                 textTosay = textTosay.replaceAll("!", ".");
            //                 textTosay = textTosay.replaceAll("?!!!", ".");
            //                 textTosay = textTosay.replaceAll("A.I.", "A I ");
            //                 textTosay = textTosay.replaceAll("1.", "1");
            //                 textTosay = textTosay.replaceAll("2.", "2");
            //                 textTosay = textTosay.replaceAll("3.", "3");
            //                 textTosay = textTosay.replaceAll("4.", "4");
            //                 textTosay = textTosay.replaceAll("5.", "5");
            //                 textTosay = textTosay.replaceAll("6.", "6");
            //                 textTosay = textTosay.replaceAll("7.", "7");
            //                 textTosay = textTosay.replaceAll("8.", "8");
            //                 textTosay = textTosay.replaceAll("9.", "9");
            //                 textTosay = textTosay.replaceAll("10.", "10");
            //                 textTosay = textTosay.replaceAll("\"", "");
            //                 textTosay = textTosay.replaceAll(".com", " com");
            //                 textTosay = textTosay.replace(/"/g, " ");

            //                 textTosay = replaceHtmlCharsWithDot(resultRef.current);
            //                 textTosay = replaceSpecialCharsWithDot(textTosay);

            //                 let sentences = textTosay.split(".");
            //                 // console.log("SPLIT SENTENCES 2 SAY");
            //                 // console.log(sentences);

            //                 if (sentences.length > numGenerated)
            //                 {
            //                     // console.log("TALKING HEAD ACTIVATED");

            //                     numGenerated += 1;
            //                     sentences = sentences.filter(element => element !== "");
            //                     sentences = sentences.filter(element => element !== " ");
            //                     let lastSentence = sentences[numGenerated - 2];
            //                     // console.log("NUM GENERATED");
            //                     // console.log(numGenerated);
            //                     // console.log("TOTAL SENTENCES");
            //                     // console.log(sentences.length);
            //                     // console.log(lastSentence);
            //                     if (lastSentence.length > 5)
            //                     {
            //                         // console.log(`SENDING SENTENCE: ${lastSentence}`);
            //                         postData(
            //                             "https://kn1b72ftyk6i6d-6000.proxy.runpod.net/talking_voice_clone",
            //                             {
            //                                 img_url: activeImage,
            //                                 prompt: currentMessage,
            //                                 is_audio: false,
            //                                 dc: convertMessages(chatHistory),
            //                                 voice: selectedValue,
            //                                 language: chosenLanguage,
            //                                 yawScale: yawScale,
            //                                 headMovement: headMovement,
            //                                 pitchScale: pitchScale,
            //                                 rollScale: rollScale,
            //                                 inputPrompt: inputPrompt,
            //                                 superenhance: superenhance,
            //                                 answer: lastSentence,
            //                                 sessionuid: sessionuid,
            //                                 numSentence: numSentence
            //                             }
            //                         );
            //                         if (numSentence == 0)
            //                         {
            //                             setisOutputted(false);
            //                         }
            //                         numSentence++;
            //                         // console.log("Increasing Number of Sentences...");
            //                     }
            //                 }


            //                 // }

            //                 // if (textTosay.endsWith("."))
            //                 // {
            //                 //     let sentences = textTosay.split(".");
            //                 //     console.log("SPLIT SENTENCES ARE: ");
            //                 // sentences = sentences.filter(element => element !== "");
            //                 // sentences = sentences.filter(element => element !== " ");

            //                 //     console.log(sentences);
            //                 //     let lastSentence = sentences[sentences.length - 1];

            //                 //     console.log("LAST SENTENCE TO GENERATE IS: ");
            //                 //     console.log(lastSentence);

            //                 // if (lastSentence.length > 5)
            //                 // {
            //                 //     console.log(`SENDING SENTENCE: ${lastSentence}`);
            //                 //     postData(
            //                 //         "https://xq3pl2oc187t2f-6000.proxy.runpod.net/talking_voice_clone",
            //                 //         {
            //                 //             img_url: activeImage,
            //                 //             prompt: currentMessage,
            //                 //             is_audio: false,
            //                 //             dc: convertMessages(chatHistory),
            //                 //             voice: selectedValue,
            //                 //             language: chosenLanguage,
            //                 //             yawScale: yawScale,
            //                 //             headMovement: headMovement,
            //                 //             pitchScale: pitchScale,
            //                 //             rollScale: rollScale,
            //                 //             inputPrompt: inputPrompt,
            //                 //             superenhance: superenhance,
            //                 //             answer: lastSentence,
            //                 //             sessionuid: sessionuid,
            //                 //             numSentence: numSentence
            //                 //         }
            //                 //     );
            //                 //     if (numSentence == 0)
            //                 //     {
            //                 //         setisOutputted(false);
            //                 //     }
            //                 //     numSentence++;
            //                 //     console.log("Increasing Number of Sentences...");
            //                 // }
            //                 //     // Make a non-blocking API call



            //                 // }

            //             }

            //         } else
            //         {
            //             // setResultt(resultRef.current);
            //             source.close();
            //         }

            //     });

            //     source.addEventListener("readystatechange", (e) => {
            //         if (e.readyState >= 2)
            //         {
            //             // console.log("COMPLETED");
            //             // setIsTyping(false);
            //             isResponseWaiting = true;

            //             setIsRunning(false);

            //             setChatHistory([
            //                 ...newChatHistory,
            //                 {
            //                     text: resultRef.current,
            //                     sender: "system",
            //                     profile: activeImage, //
            //                     audioUrl: "", //
            //                 },
            //             ]);

            //         }
            //     });
            //     source.stream();
            // }
            // else
            // {
                // console.log("Audio Generation");
            source.addEventListener("message", (e) => {
                setIsRunning(true);
                setIsTyping(false);
                scrollToBottom();
                if (e.data != "[DONE]")
                {
                    setIsTyping(false);
                    // setIsTyping(false);
                    let payload = JSON.parse(e.data);
                    // console.log(payload['choices']);
                    let text = payload.choices[0].delta["content"];
                    if (text != null)
                    {
                        // console.log("Text: " + text);
                        resultRef.current = resultRef.current + text;
                        // console.log(resultRef.current);
                        setResultt(resultRef.current);

                        var textTosay = "";
                        textTosay = replaceHtmlCharsWithDot(resultRef.current);
                        textTosay = replaceSpecialCharsWithDot(textTosay);
                        // console.log("TEXT 2 SAY");
                        // console.log(textTosay);


                    }

                } else
                {
                    source.close();
                }
            });

            var ran = false;

            source.addEventListener("readystatechange", async (e) => {
                if (e.readyState >= 2 && ran == false)
                {
                    ran = true;
                    // console.log("COMPLETED");

                    // setIsTyping(false);
                    isResponseWaiting = true;



                    var input_data = {
                        prompt: replaceSpecialCharsWithDot(currentMessage),
                        is_audio: false,
                        dc: convertMessages(chatHistory),
                        voice: selectedValue,
                        language: chosenLanguage,
                        yawScale: yawScale,
                        headMovement: headMovement,
                        pitchScale: pitchScale,
                        rollScale: rollScale,
                        inputPrompt: inputPrompt,
                        superenhance: superenhance,
                        answer: resultRef.current,
                        sessionuid: sessionuid,
                    };
                    await fetch("https://kn1b72ftyk6i6d-6000.proxy.runpod.net/audio_generation", {
                        method: "POST",
                        body: JSON.stringify(input_data // add all other data here
                        ),
                        headers: {
                            "content-type": "application/json",
                        },
                    })
                        .then((response) => response.json())
                        .then(async (data) => {
                            // console.log(data);
                            setIsRunning(false);
                            setIsProgressing(false);

                            setChatHistory([
                                ...newChatHistory,
                                {
                                    text: resultRef.current,
                                    sender: "system",
                                    profile: activeImage, //
                                    audioUrl: data["audio"], //
                                },
                            ]);
                            setIsLoading(false);
                            // return data;

                            // setQueueId(data["queue_id"]);
                        })
                        .catch((error) => {
                            toast.error(error.message);
                            setIsTyping(false);
                            setIsLoading(false);
                        });




                }
            });
            source.stream();
            // console.log('test')
        }

        // }
    };
    //
    // console.log({ chatHistory })


    const sendMessageHeadOnPlay = async () => {
        // setIsAudio(false);
        // setIsTyping(true);

        if (superenhance)
        {
            setTimerRate(2000);
        } else
        {
            setTimerRate(800);
        }
        setIsPlaying(false);
        setCountDown(0);

        // if (currentMessage.length < 2)
        // {
        //   // setError(error.message);
        //   // handleShow();
        //   setRunTimer((t) => false);
        //   setCountDown(0);
        //   setIsGenerating((t) => false);
        //   setHasGenerating(false);
        //   toast.error("Make sure input text is greater than 3 characters", {
        //     icon: "👏",
        //     style: {
        //       borderRadius: "10px",
        //       background: "#333",
        //       color: "#fff",
        //       position: "bottom-center",
        //       // index: '1'
        //     },
        //   });
        //   return;
        //   // setError("Make sure input text is greater than 3 characters");

        //   // handleShow();
        // }
        // audioRef.current.pause();

        if (
            activeImage ===
            "https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/chat-default-image.png?alt=media&token=936b0f65-f711-4153-a1d9-0f1250ad1411"
        )
        {
            setRunTimer((t) => false);
            setCountDown(0);
            setIsGenerating((t) => false);
            setHasGenerating(false);
            toast.error("Please Generate an Image", {
                icon: "👏",
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                },
            });
            return;
        }

        // if (avatarImageUrlSelected == "") {
        setselectedAvatarURL(activeImage);
        // } else {
        // setselectedAvatarURL(avatarImageUrlSelected);
        // }


        // setChatHistory([]);
        const introChatHistory = [];
        setCountDown(0);

        setIsGeneratingImage((t) => true);

        // setHasGeneratingImage((t) => false);
        setRunTimer((t) => true);

        // const botResponse = ChatBotBot.getResponse(currentMessage);
        var data = await postDataIdle(
            "https://kn1b72ftyk6i6d-4000.proxy.runpod.net/create_idle",
            {
                img_url: activeImage,
                sessionuid: sessionuid,
                chatbot: location.state.name.replaceAll(" ", "")
            }
        );
        // console.log("DONE IDLE ANIMATION");
        // console.log(data);

        // setavatarVideoUrl("");
        // setavatarVideoUrl(data['response']);

        // setChatHistory([
        //     ...chatHistory,
        //     { text: currentMessage, sender: "user" },
        //     { text: data['text'], sender: "bot" },
        // ]);

        // setCurrentMessage("");

    };


    const sendMessageAudio = async () => {


        setIsAudio(true);
        setIsPlaying(false);
        // audioRef.current.pause();


        // const botResponse = ChatBotBot.getResponse(currentMessage);
        var data = await postData(
            "https://2ook38ir4ea9ub-3000.proxy.runpod.net/queue",
            {
                img_url: selectedAvatarURL,
                prompt: currentMessage,
                is_audio: true,
                dc: convertMessages(chatHistory),
                voice: selectedValue,
                language: chosenLanguage,
                yawScale: yawScale,
                headMovement: headMovement,
                pitchScale: pitchScale,
                rollScale: rollScale,
                inputPrompt: inputPrompt,
                superenhance: superenhance,
                answer: resultRef.current
            }
        );
        // setavatarVideoUrl("");onHandleChangeSwitch
        // setavatarVideoUrl(data['response']);

        // setChatHistory([
        //     ...chatHistory,
        //     { text: currentMessage, sender: "user" },
        //     { text: data['text'], sender: "bot" },
        // ]);

        // setCurrentMessage("");
    };

    // const avatarVideoUrl = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";

    const onSelectImage = (image) => {
        setActiveImage(image);
        // setAvatarImageUrl(image);
    };
    const retry = () => {
        setChatHistory([]);
        // Add your retry logic here
    };

    const onHandleChangeSwitch = (e) => {
        setFaceOrAudioToggle(e.target.checked);
        // setFaceOrAudioToggle(true);
    };

    const onHandleChangeSwitchArtMode = (e) => {
        setArtGenMode(e.target.checked);
    };

    const aisettings = {
        infinite: true,
        // autoplay: true,
        autoplaySpeed: 2500,
        arrows: true,
        dots: false,
        slidesToShow: 6,
        margin: 10,
        centerPadding: 20,
        useTransform: true,
        scroll: "smooth",
        responsive: [
            {
                breakpoint: 1430,
                settings: {
                    slidesToShow: 4,
                    // slidesToScroll: 3,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                    // slidesToScroll: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    // slidesToScroll: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 4,
                    // slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    // slidesToScroll: 1,
                },
            },
        ],
    };

    const onHandleCurrentMessageChange = (event) => {
        setCurrentMessage(event.target.value);
    };

    const textAreaRef = useRef(null);
    const textAreaRefMobile = useRef(null);

    useAutosizeTextArea(textAreaRef.current, currentMessage);
    useAutosizeTextArea(textAreaRefMobile.current, currentMessage);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [activateScreenshot2Code, setScreenshot2Page] = useState(false);
    const [chatbotWorkAs, setChatbotWorkAs] = useState("chat");
    const [referenceImages, setReferenceImages] = useState([]);
    const subData = useSelector(subDataSelector);

    function fileToDataURL(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    }

    const onActivatingScreenshot2code = (event) => {
        // handle the event here
        const allImagefiles = [...event.target.files];
        let images = [];

        for (let i = 0; i < allImagefiles.length; i++)
        {
            images.push(URL.createObjectURL(allImagefiles[i]));
        }
        setImagePreviews([...imagePreviews, ...images]);
        setSelectedFiles([...selectedFiles, ...event.target.files]);

        Promise.all(allImagefiles.map((file) => fileToDataURL(file)))
            .then((dataUrls) => {
                setReferenceImages(dataUrls.map((dataUrl) => dataUrl));
            })
            .catch((error) => {
                toast.error("Error reading files" + error);
                console.error("Error reading files:", error);
            });


        setScreenshot2Page(true);
    };
    const [settings, setSettings] = useState(
        {
            openAiApiKey: null,
            openAiBaseURL: null,
            screenshotOneApiKey: null,
            isImageGenerationEnabled: true,
            editorTheme: "cobalt",
            generatedCodeConfig: "html_tailwind",
            // Only relevant for hosted version
            isTermOfServiceAccepted: false,
            accessCode: null,
        },
        "setting"
    );
    const [generatedCode, setGeneratedCode] = useState("");
    const [executionConsole, setExecutionConsole] = useState([]);
    const [appState, setAppState] = useState("INITIAL");
    const [codeGenerationHistory, setCodeGenerationHistory] = useState([]);
    const [appHistory, setAppHistory] = useState([]);
    const [currentVersion, setCurrentVersion] = useState(null);
    const [selectedHistory, setSelectedHistory] = useState(null);
    const wsRef = useRef(null);

    console.log({ appHistory });

    const onShowCode = (code, historyIndex) => {
        setSelectedHistory(historyIndex);
        let index;
        const filter = chatHistory.filter((chat, i) => {
            if (chat.code)
            {
                index = i;
                return code;
            }
        });
        const history = [...chatHistory];
        history[index].code = code.code;
        console.log({ index, filter, chatHistory, history });
        setChatHistory(history);
    };
    function doGenerateCode(params) {
        setExecutionConsole([]);
        setCurrentMessage("");
        setImagePreviews([]);
        setAppState("CODING");

        // Merge settings with params
        const updatedParams = { ...params.params, ...settings };

        // console.log({ params })
        generateCode(
            wsRef,
            updatedParams,
            (token) => {
                // setGeneratedCode((prev) => prev + token)
                setIsRunning(true);
                setIsTyping(false);
                setResultt((prev) => prev + token);
            },
            (code) => {
                setIsRunning(false);
                setResultt("");
                setChatHistory([
                    ...params.newChatHistory,
                    {
                        text: "",
                        code: code,
                        sender: "system",
                        profile: activeImage, //
                        audioUrl: "", //
                        // images: [],
                    },
                ]);
                console.log({ params });
                if (params.params.generationType === "create")
                {
                    setAppHistory([
                        {
                            type: "ai_create",
                            parentIndex: null,
                            code,
                            inputs: { image_url: referenceImages[0] },
                        },
                    ]);
                    setCurrentVersion(0);
                    setSelectedHistory(0);
                } else
                {
                    setAppHistory((prev) => {
                        // Validate parent version
                        if (params.parentVersion === null)
                        {
                            toast.error(
                                "No parent version set. Contact support or open a Github issue."
                            );
                            return prev;
                        }

                        const newHistory = [
                            ...prev,
                            {
                                type: "ai_edit",
                                parentIndex: params.parentVersion,
                                code,
                                inputs: {
                                    prompt: currentMessage,
                                },
                            },
                        ];
                        setCurrentVersion(newHistory.length - 1);
                        return newHistory;
                    });
                    setSelectedHistory((prev) => prev + 1);
                }
                // setIsRunning(true);
                // setIsTyping(false);
                // setResultt(code)
            },
            (line) => setExecutionConsole((prev) => [...prev, line]),
            () => setAppState("CODE_READY")
        );
    }
    const takeScreenshot = async () => {
        const iframeElement = document.querySelector("#preview-desktop");
        if (!iframeElement || !iframeElement.contentWindow || !iframeElement.contentWindow.document.body) {
            return "";
        }
    
        try {
            // Using html2canvas to take a screenshot
            const canvas = await html2canvas(iframeElement.contentWindow.document.body);
            return canvas.toDataURL("image/png"); // Returns a base64 encoded string of the image
        } catch (error) {
            console.error("Error taking screenshot:", error);
            return "";
        }
    };
    
    const makeRequestForDesign = async () => {
        // Loop through the array in reverse order
        let filteredObj = chatHistory[chatHistory.length - 1]?.code ? chatHistory[chatHistory.length - 1] : {};
        let index = chatHistory[chatHistory.length - 1]?.code ? chatHistory.length - 1 : null;


        let updatedChatHistory = [...codeGenerationHistory];
        if (index)
        {
            updatedChatHistory.push(filteredObj?.code);
            updatedChatHistory.push(currentMessage);
        }

        setResultt("");
        if (Object.keys(filteredObj).length !== 0)
        {
            setCodeGenerationHistory([...updatedChatHistory]);
            const newChatHistory = [...chatHistory];
            newChatHistory[index - 1].text = currentMessage,
                newChatHistory.splice(index, 1);

            setChatHistory(newChatHistory);
            // if (imagePreviews.length > 0)
            // {
            const resultImage = await takeScreenshot();
            doGenerateCode({
                
                params: {
                    generationType: "update",
                    image: referenceImages[0],
                    resultImage: resultImage,
                    history: updatedChatHistory,
                },
                parentVersion: currentVersion,
                newChatHistory: newChatHistory,
            });
            // }
        } else
        {
            if (currentMessage !== "")
            {
                setCodeGenerationHistory([currentMessage]);
            }
            const newChatHistory = [
                ...chatHistory,
                {
                    text: currentMessage,
                    sender: "user",
                    profile: userData?.ProfilePicture ?? "/images/profile.png",
                    audioUrl: "",
                    screenShot: referenceImages[0],
                },
            ];

            // const newChatHistory = [...chatHistory];
            // newChatHistory[chatHistory.length - 1].text = currentMessage,
            setChatHistory(newChatHistory);
            if (imagePreviews.length > 0)
            {
                doGenerateCode({
                    params: {
                        generationType: "create",
                        image: referenceImages[0],
                    },
                    currentVersion,
                    newChatHistory: newChatHistory,
                });
            }
        }
    };


    const onSelectFiles = (event) => {
        let images = [];

        for (let i = 0; i < event.target.files.length; i++)
        {
            images.push(URL.createObjectURL(event.target.files[i]));
        }

        setSelectedFiles([...selectedFiles, ...event.target.files]);
        setImagePreviews([...imagePreviews, ...images]);
    };

    const onRemoveSelectedImage = (index) => {
        let images = [...selectedFiles];
        let previews = [...imagePreviews];
        images.splice(index, 1);
        previews.splice(index, 1);
        setSelectedFiles(images);
        setImagePreviews(previews);
    };

    const onHandleChangeChatbotGeneration = (event) => {

        const id = event.target.id;

        if (id === "CcategoryFour" || id === "CcategoryFive")
        {
            // Check if subscription level is less than 2
            if (subData.SubscriptionLevel < 2)
            {
                // Redirect to the pricing page
                window.open("../pricing", "_blank");
            } else
            {
                setChatbotWorkAs(event.target.value);
            }
        } else
        {
            setChatbotWorkAs(event.target.value);
        }


    };
    const onDisplayIframe = (value) => {
        setIsIfreamOpen(true);
        setIfreamCode(value);
    };


    return (
        <div className="main-content App chat-app">
            <>

                {/* <Tabs
          defaultActiveKey="profile"
          id="justify-tab-example"
          className="mb-3"
          // justify
          onSelect={handleTabChange}
        >
          <Tab eventKey="home" title="Home">
            Tab content for Home
          </Tab>
          <Tab eventKey="profile" title="Profile">
            Tab content for Profile
          </Tab>
          <Tab eventKey="longer-tab" title="Loooonger Tab">
            Tab content for Loooonger Tab
          </Tab>
          <Tab eventKey="contact" title="Contact" disabled>
            Tab content for Contact
          </Tab>
        </Tabs> */}
                <div className="mobile-toggle-Switch toggle-Switch">
                    <span className={faceOrAudioToggle && "disable"}>
                        <lord-icon
                            src="https://cdn.lordicon.com/fdlndzao.json"
                            trigger="loop"
                            colors={isThemeDark ? "outline:#121331,primary:#b26836,secondary:#fff,tertiary:#4bb3fd,quaternary:#f9c9c0" : "outline:#121331,primary:#b26836,secondary:#000,tertiary:#4bb3fd,quaternary:#f9c9c0"}
                        ></lord-icon>
                        Face
                    </span>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        onChange={onHandleChangeSwitch}
                    />
                    <span className={!faceOrAudioToggle && "disable"}>
                        <lord-icon
                            src="https://cdn.lordicon.com/euoeggon.json"
                            trigger="loop"
                            colors={isThemeDark ? "outline:#fff" : "outline:#000"}
                        ></lord-icon>{" "}
                        Audio
                    </span>
                </div>

                <Row className="chatbox-height">
                    <Col lg={7}>
                        <div className="input-container-clippath desktop-version">
                            <div className="input-container">
                                <div className="chatheader">
                                    <Button variant="" className="Advanced-main-wrap text-white" onClick={handleShow}>
                                        <i>
                                            <lord-icon src="https://cdn.lordicon.com/exlyjeeh.json" trigger="hover" colors="outline:#131431,primary:#36e899" ></lord-icon>
                                        </i>
                                        <span>Advanced Settings</span>
                                    </Button>
                                    {isCreating ? <Button variant="" className="Advanced-main-wrap text-white" onClick={handleShow2}>
                                        <i><lord-icon src="https://cdn.lordicon.com/gdbnaaef.json" trigger="hover" colors="outline:#121331,primary:#ffffff,secondary:#36e899"></lord-icon></i>
                                        <span>Upload AI Companion</span>
                                    </Button> : (<div></div>)}


                                    <div className="toggle-Switch">
                                        <span className={faceOrAudioToggle && "disable"}>
                                            <lord-icon
                                                src="https://cdn.lordicon.com/euoeggon.json"
                                                trigger="loop"
                                                colors={isThemeDark ? "outline:#fff" : "outline:#000"}
                                            ></lord-icon>
                                            Audio
                                        </span>

                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            onChange={onHandleChangeSwitch}
                                        />
                                        <span className={"disable"}>
                                            <lord-icon
                                                src="https://cdn.lordicon.com/fdlndzao.json"
                                                trigger="loop"
                                                colors={isThemeDark ? "outline:#121331,primary:#b26836,secondary:#fff,tertiary:#4bb3fd,quaternary:#f9c9c0" : "outline:#121331,primary:#b26836,secondary:#000,tertiary:#4bb3fd,quaternary:#f9c9c0"}
                                            ></lord-icon>
                                            Face
                                        </span>
                                    </div>
                                    {chatHistory.length > 0 &&
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip placement="bottom">
                                                    Reset your chat
                                                </Tooltip>
                                            }
                                        >
                                            <Button variant="" className="Chat-reset" onClick={retry}>
                                                <Icon icon="icon-park-outline:undo" />
                                            </Button>
                                        </OverlayTrigger>

                                    }

                                </div>
                                <div className="chatfield" ref={chatFieldRef}>
                                    {chatHistory.map((msg, i) => (
                                        <p
                                            key={i}
                                            className={cx("chat-messagebox", {
                                                "message-end": msg.sender === "system",
                                                "message-start": msg.sender === "user",
                                                "message-CodeWrap": msg.code
                                            })}
                                        >
                                            <i>
                                                {msg.sender === "user" ?
                                                    <ProfilePicture style={{ width: "50px" }} />
                                                    :
                                                    <img style={{ width: "50px" }} src={msg.profile} />}

                                            </i>



                                            {msg.sender === "system" ? (
                                                <div className="msgbox">

                                                    {msg.audioUrl !== "" && (
                                                        <div className="audio-player">

                                                            <AudioPlayer autoPlay src={msg.audioUrl} />

                                                            <hr />
                                                        </div>
                                                    )}

                                                    {msg.images && <div style={{ display: "flex", justifyContent: "space-around" }}>
                                                        {
                                                            msg.images && Array.isArray(msg.images) ?
                                                                (msg.images.length === 0 ?
                                                                    // Render a loading indicator when there are no images
                                                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                                        {renderLoadingIndicators()}
                                                                    </div>

                                                                    :
                                                                    // Map through images if there are any
                                                                    msg.images.map((status, index) => (
                                                                        <div key={index} onClick={() => openModal(msg.images[index])}>

                                                                            <img
                                                                                src={msg.images[index]}
                                                                                alt={`Generated ${index}`}
                                                                                style={{ width: "125px", height: "125px", margin: "5px", borderRadius: "5%", }}
                                                                            />

                                                                        </div>
                                                                    ))
                                                                ) : null // Render nothing if msg.images is not an array
                                                        }
                                                    </div>}

                                                    {msg.text && <p>
                                                        {parse(msg.text)}


                                                        {/* <em>{"wild"}</em>
                            {"stuff with text!"} */}
                                                        {/* </WindupChildren> */}
                                                    </p>}

                                                    {msg.code ? (
                                                        <div className="chat-messagebox message-end code-msg-box">
                                                            <div className="ChatAppHistoryMainWrap">
                                                                <h6 className="ChatAppHistoryTitle">Versions :</h6>
                                                                <ul className="ChatAppHistory">
                                                                    {appHistory.map((code, index) => (
                                                                        <li onClick={() => onShowCode(code, index)} className={`${index === selectedHistory ? "active" : ""}`} key={`${code.type}-${index}`}>{code.type === "ai_create" ? "Create" : code.inputs.prompt}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                            <Tab.Container id="left-tabs-example" defaultActiveKey="desktop">
                                                                <Nav variant="pills" className="custom-navs code-navs">
                                                                    <Nav.Item>
                                                                        <Nav.Link eventKey="desktop">Desktop</Nav.Link>
                                                                    </Nav.Item>
                                                                    <Nav.Item>
                                                                        <Nav.Link eventKey="mobile">Mobile</Nav.Link>
                                                                    </Nav.Item>
                                                                    <Nav.Item>
                                                                        <Nav.Link eventKey="code">Code</Nav.Link>
                                                                    </Nav.Item>
                                                                    <Link className="ChatPopupBtn" onClick={() => onDisplayIframe(msg.code)} to="#"><Icon icon="lets-icons:full" /></Link>
                                                                </Nav>

                                                                <Tab.Content>
                                                                    <Tab.Pane eventKey="desktop"><Preview code={msg.code} device="desktop" /></Tab.Pane>
                                                                    <Tab.Pane eventKey="mobile"><div className="Mobile-ViewChatCode">
                                                                        <Preview code={msg.code} device="mobile" /></div></Tab.Pane>
                                                                    <Tab.Pane eventKey="code">
                                                                        <div className="relative">
                                                                            <CodeMirror
                                                                                code={msg.code}
                                                                                editorTheme={settings.editorTheme}
                                                                                onCodeChange={setGeneratedCode}
                                                                            />
                                                                            {/* <span
                                      title="Copy Code"
                                      className="flex items-center justify-center w-10 h-10 text-gray-500 hover:bg-gray-100 cursor-pointer rounded-lg text-sm p-2.5 absolute top-[20px] right-[20px]"
                                      onClick={doCopyCode}
                                    >
                                      <FaCopy />
                                    </span> */}
                                                                        </div>
                                                                    </Tab.Pane>
                                                                </Tab.Content>
                                                            </Tab.Container>
                                                        </div>
                                                    ) : ""}

                                                </div>

                                            ) : (
                                                <div className="msgbox usermsgbox codeMsgbox">
                                                    <p dangerouslySetInnerHTML={{ __html: msg.text }} />
                                                    {msg.screenShot ? (
                                                        <div
                                                            className={cx("code-img", {
                                                                "scanning relative": i === chatHistory.length - 1 && appState === "CODING",
                                                            })}
                                                        >
                                                            <img
                                                                src={msg.screenShot}
                                                                alt="Reference"
                                                            />
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            )}
                                        </p>
                                    ))}
                                    <PopupModal
                                        className="CodePopup"
                                        show={isIfreamOpen}
                                        handleClose={closeIframeModal}
                                    >
                                        <Link to="#" className="CloseBtn" onClick={closeIframeModal}>
                                            <Icon icon="ion:close-circle" color="white" />
                                        </Link>
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="desktop">
                                            <Nav variant="pills" className="custom-navs code-navs">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="desktop">Desktop</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="mobile">Mobile</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="code">Code</Nav.Link>
                                                </Nav.Item>
                                            </Nav>

                                            <Tab.Content>
                                                <Tab.Pane eventKey="desktop"><Preview code={ifreamCode} device="desktop" /></Tab.Pane>
                                                <Tab.Pane eventKey="mobile"><div className="Mobile-ViewChatCode">
                                                    <Preview code={ifreamCode} device="mobile" /></div></Tab.Pane>
                                                <Tab.Pane eventKey="code">
                                                    <div className="relative">
                                                        <CodeMirror
                                                            code={ifreamCode}
                                                            editorTheme={settings.editorTheme}
                                                            onCodeChange={setGeneratedCode}
                                                        />
                                                        {/* <span
                                      title="Copy Code"
                                      className="flex items-center justify-center w-10 h-10 text-gray-500 hover:bg-gray-100 cursor-pointer rounded-lg text-sm p-2.5 absolute top-[20px] right-[20px]"
                                      onClick={doCopyCode}
                                    >
                                      <FaCopy />
                                    </span> */}
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </PopupModal>
                                    {isRunning && !isTyping ? <>{chatbotWorkAs === "app" ? (
                                        <div className="chat-messagebox message-end code-msg-box">
                                            <Tab.Container id="left-tabs-example" defaultActiveKey="desktop">
                                                <Nav variant="pills" className="custom-navs code-navs">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="desktop">Desktop</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="mobile">Mobile</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="code">Code</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="desktop"><Preview code={resultt} device="desktop" /></Tab.Pane>
                                                    <Tab.Pane eventKey="mobile"><div className="Mobile-ViewChatCode">
                                                        <Preview code={resultt} device="mobile" /></div></Tab.Pane>
                                                    <Tab.Pane eventKey="code">
                                                        <div className="relative">
                                                            <CodeMirror
                                                                code={resultt}
                                                                editorTheme={settings.editorTheme}
                                                                onCodeChange={setGeneratedCode}
                                                            />
                                                            {/* <span
                                title="Copy Code"
                                className="flex items-center justify-center w-10 h-10 text-gray-500 hover:bg-gray-100 cursor-pointer rounded-lg text-sm p-2.5 absolute top-[20px] right-[20px]"
                                onClick={doCopyCode}
                              >
                                <FaCopy />
                              </span> */}
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </div>
                                    ) : (<p className={"chat-messagebox message-end"}>
                                        <i><img style={{ width: "50px" }} src={selectedAvatarURL}></img></i>
                                        <div className='msgbox'>
                                            {/* <Typewriter words={resultt.split(' ')}></Typewriter> */}
                                            <p>{parse(resultt)}</p>

                                        </div>
                                    </p>)}</> : ""}

                                    {isTyping ? (
                                        <div style={{ "text-align": "right" }}>
                                            <div style={{ display: "inline-block" }}>
                                                {" "}
                                                <img
                                                    src={
                                                        "https://www.dropbox.com/s/syx8lrzq6juz0gu/Message-1s-38px.gif?dl=1"
                                                    }
                                                ></img>
                                            </div>
                                            <i>
                                                <img
                                                    src={selectedAvatarURL}
                                                    style={{
                                                        width: "50px",
                                                        "border-radius": "40px",
                                                        "margin-right": "16px",
                                                    }}
                                                />
                                            </i>{" "}
                                        </div>
                                    ) : null}

                                </div>
                                {chatHistory.length === 0 && <ul className="defalut-prompt">
                                    <li>
                                        <Button variant="" onClick={() => onSendDirectMessage("What are your top 5 facts for travelling space in a swimsuit?")}>
                                            <h6>What are your top 5 facts <span>for travelling space in a swimsuit</span> </h6>
                                            <i><Icon icon="ic:round-send" /></i>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button variant="" onClick={() => onSendDirectMessage("Suggest some names for my cafe-by-day, bar-by-night business")}>
                                            <h6>Suggest some names <span>for my cafe-by-day, bar-by-night business</span> </h6>
                                            <i><Icon icon="ic:round-send" /></i>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button variant="" onClick={() => onSendDirectMessage("Compare marketing strategies for sunglasses for Gen Z and Millennials")}>
                                            <h6>Compare marketing strategies <span>for sunglasses for Gen Z and Millennials</span> </h6>
                                            <i><Icon icon="ic:round-send" /></i>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button variant="" onClick={() => onSendDirectMessage("Come up with concepts for a retro-style arcade game")}>
                                            <h6>Come up with concepts <span>for a retro-style arcade game</span> </h6>
                                            <i><Icon icon="ic:round-send" /></i>
                                        </Button>
                                    </li>
                                </ul>}
                                <div className="chat-input-box chatbox-mobile-wrap">
                                    <div className="chat-input-boxmain">
                                        <label htmlFor="fileUploadtext" className="fileUploadtext">
                                            <input type="file" id="fileUploadtext" onChange={onActivatingScreenshot2code} onClick={(event) => {
                                                event.target.value = null;
                                            }} multiple />
                                            <span><Icon icon="fluent:image-add-24-filled" /></span>
                                        </label>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant=""
                                                id="dropdown-basic"
                                                className="ChatCategoryDropDown"
                                            >
                                                <Icon icon="heroicons:adjustments-horizontal" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="ChatCategoryDropDownMain">
                                                <div className="ChatCategoryDropDownWrap">
                                                    <label htmlFor="CcategoryOne" className="ChatCategoryItemList">
                                                        <input type="radio" id="CcategoryOne" name="image-generation" value='image' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === "image"} />
                                                        <div className="ChatCategoryItem">
                                                            <i><Icon icon="fluent:draw-image-24-regular" /></i>
                                                            <p>  Image generation</p>
                                                        </div>
                                                    </label>
                                                    <label htmlFor="CcategoryTwo" className="ChatCategoryItemList">
                                                        <input type="radio" id="CcategoryTwo" name="image-generation" value='chat' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === "chat"} />
                                                        <div className="ChatCategoryItem">
                                                            <i><Icon icon="fluent:chat-12-regular" /></i>
                                                            <p>Text and chatting</p>
                                                        </div>
                                                    </label>
                                                    <label htmlFor="CcategoryThree" className="ChatCategoryItemList">
                                                        <input type="radio" id="CcategoryThree" name="image-generation" value='app' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === "app"} />
                                                        <div className="ChatCategoryItem">
                                                            <i><Icon icon="fluent:tetris-app-20-regular" /></i>
                                                            <p>Image to App</p>
                                                        </div>
                                                    </label>
                                                    <label htmlFor="CcategoryFour" className="ChatCategoryItemList ">
                                                        <input type="checkbox" id="CcategoryFour" name="image-generation" value='vision' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === "vision"} />
                                                        <div className="ChatCategoryItem">
                                                            <i><Icon icon="tabler:device-vision-pro" /></i>
                                                            <p>Vision power</p>
                                                            <small>Pro</small>
                                                        </div>
                                                    </label>
                                                    <label htmlFor="CcategoryFive" className="ChatCategoryItemList ">
                                                        <input type="checkbox" id="CcategoryFive" name="image-generation" value='gpt' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === "gpt"} />
                                                        <div className="ChatCategoryItem">
                                                            <i><Icon icon="arcticons:openai-chatgpt" /></i>
                                                            <p>GPT4</p>
                                                            <small>Pro</small>
                                                        </div>
                                                    </label>
                                                    {chatbotWorkAs === "app" && <Select
                                                        placeholder="Select Option"
                                                        value={selectedCodeTypeOption}
                                                        className="LanguageSelect ChatOptionSelect"
                                                        options={codeType}
                                                        onChange={handleCodeTypeChange}
                                                        styles={customStyles2}
                                                        getOptionLabel={(e) => (
                                                            <div className="d-flex align-items-center">
                                                                <span className="ms-2" style={{ fontSize: 13 }}>{e.text}</span>
                                                            </div>
                                                        )}
                                                    />}
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <div className="textareaDesgin">

                                            <textarea
                                                placeholder="Send your message"
                                                // rows={4}
                                                ref={textAreaRef}
                                                // style={{height: textareaHeight > 100 ? 'auto' : `${textareaHeight}px`}}
                                                value={currentMessage}
                                                onChange={(e) => onHandleCurrentMessageChange(e)}
                                                disabled={isListening}
                                                // onKeyDown={(e) => handleKeyUp(e)}
                                                onKeyPress={(e) => {
                                                    // handleKeyUp(e);
                                                    if (!authToken) {
                                                        dispatch(showPopUpAction(true));
                                                        return;
                                                    }
                                                    if (chatbotWorkAs === "app")
                                                    {
                                                        if (e.key === "Enter")
                                                        {
                                                            makeRequestForDesign();
                                                        }
                                                    } else
                                                    {
                                                        if (activeImage !== "https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/dark-img-upload.png?alt=media&token=474bcca1-fd06-44d8-8c2e-2898651dbb60&_gl=1*11hdrzi*_ga*NDI5MDg0OTczLjE2ODI0Njk1NzQ.*_ga_CW55HF8NVT*MTY5NjI1MjY2MS41LjEuMTY5NjI1MjY4Ni4zNS4wLjA" && activeImage !== "https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/chat-default-image.png?alt=media&token=936b0f65-f711-4153-a1d9-0f1250ad1411")
                                                        {
                                                            if (e.key === "Enter" && !faceOrAudioToggle)
                                                            {
                                                                e.preventDefault();
                                                                setIsTyping(true);
                                                                streamTextGeneration();
                                                            }
                                                            else if (e.key === "Enter" && !faceOrAudioToggle == false)
                                                            {
                                                                e.preventDefault();
                                                                setIsTyping(true);
                                                                streamTextGeneration();
                                                            }
                                                        }
                                                    }

                                                }}
                                            />
                                            <div className="textareaImgWrap">
                                                {imagePreviews && imagePreviews.map((img, index) => (
                                                    <div className="textareaImg" key={index}>
                                                        <Image src={img} key={index} alt="" />
                                                        <Button className="btn-close-img" variant="" type="button" onClick={() => onRemoveSelectedImage(index)}><Icon icon="ic:round-close" /></Button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <Button variant="" className="record-btn" onClick={startListening}>{isListening ? <Icon icon="ic:round-pause" /> : <Icon icon="ic:outline-mic" />}</Button>
                                    </div>
                                    <Button variant="" className=" btn-mobile-reset" onClick={retry}>
                                        <Icon icon="icon-park:redo" />
                                    </Button>

                                    <Button
                                        variant=""
                                        className="chatbot-send-button btn-message"
                                        onClick={() => {
                                            if (!authToken) {
                                                dispatch(showPopUpAction(true));
                                                return;
                                            }
                                            if (chatbotWorkAs === "app")
                                            {
                                                makeRequestForDesign();
                                            } else
                                            {
                                                setIsTyping(true);
                                                streamTextGeneration();
                                            }
                                        }}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <lord-icon
                                                src="https://cdn.lordicon.com/wfmfwkbb.json"
                                                trigger="loop"
                                                colors="primary:#eee"
                                            ></lord-icon>
                                        ) : (
                                            <span>
                                                <Icon icon="ic:round-send" />
                                            </span>
                                        )}
                                    </Button>
                                    {/* {console.log({ isLoading, generatedImages, activeImage })} */}
                                    {/* </>
                  ) :
                    !faceOrAudioToggle ? (
                      <Button
                        variant=""
                        type="submit"
                        className="chatbot-send-button btn-gradient btn-message"
                        onClick={() => { setIsTyping(true); streamTextGeneration(); }}
                        disabled={isLoading || activeImage === ""}
                      >
                        {isLoading ? (
                          <lord-icon
                            src="https://cdn.lordicon.com/wfmfwkbb.json"
                            trigger="loop"
                            colors="primary:#eee"
                          ></lord-icon>
                        ) : (
                          <span>
                            <Icon icon="ic:round-send" />
                          </span>
                        )}
                      </Button>
                    ) : (
                      <Button
                        variant=""
                        className="chatbot-send-button btn-gradient btn-message"
                        onClick={() => { setIsTyping(true); streamTextGeneration(); }}
                        disabled={isLoading || generatedImages.length === 0}
                      >
                        {isLoading ? (
                          <lord-icon
                            src="https://cdn.lordicon.com/wfmfwkbb.json"
                            trigger="loop"
                            colors="primary:#eee"
                          ></lord-icon>
                        ) : (
                          <span>
                            <Icon icon="ic:round-send" />
                          </span>
                        )}
                      </Button>
                    )} */}

                                </div>
                            </div>
                        </div>
                        <div className="input-container-clippath mobile-version">
                            <div className="chatfield">

                                {chatHistory.map((msg, i) => (
                                    <p
                                        key={i}
                                        className={cx("chat-messagebox", {
                                            "message-end": msg.sender === "system",
                                            "message-start": msg.sender === "user",
                                            "": msg.code
                                        })}
                                    >
                                        <i>
                                            {msg.sender === "user" ?
                                                <ProfilePicture style={{ width: "50px" }} />
                                                :
                                                <img style={{ width: "50px" }} src={msg.profile} />}
                                        </i>
                                        {msg.sender === "system" ? (
                                            <div className="msgbox">
                                                {audioUrl !== "" && (
                                                    <div className="audio-player">
                                                        {/* <audio ref={videoRef} controls>
                                <source src={msg.audioUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                              </audio> */}
                                                        <AudioPlayer src={audioUrl} />
                                                        <hr />
                                                    </div>
                                                )}

                                                {msg.images && <div style={{ display: "flex", justifyContent: "space-around" }}>
                                                    {
                                                        msg.images && Array.isArray(msg.images) ?
                                                            (msg.images.length === 0 ?
                                                                // Render a loading indicator when there are no images
                                                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                                    {renderLoadingIndicators()}
                                                                </div>
                                                                :
                                                                // Map through images if there are any
                                                                msg.images.map((status, index) => (
                                                                    <div key={index} onClick={() => openModal(msg.images[index])}>

                                                                        <img
                                                                            src={msg.images[index]}
                                                                            alt={`Generated ${index}`}
                                                                            style={{ width: "125px", height: "125px", margin: "5px", borderRadius: "5%", }}
                                                                        />

                                                                    </div>
                                                                ))
                                                            ) : null // Render nothing if msg.images is not an array
                                                    }
                                                </div>}

                                                {msg.text ? <p>
                                                    {/* <WindupChildren> */}
                                                    {parse(msg.text)}
                                                    {/* {resultt} */}

                                                    {/* <em>{"wild"}</em>
                            {"stuff with text!"} */}
                                                    {/* </WindupChildren> */}
                                                </p> : ""}
                                                {msg.code ? (
                                                    <div className="chat-messagebox message-end code-msg-box">
                                                        <Tab.Container id="left-tabs-example" defaultActiveKey="desktop">
                                                            <Nav variant="pills" className="custom-navs code-navs">
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="desktop">Desktop</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="mobile">Mobile</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="code">Code</Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                            <Tab.Content>
                                                                <Tab.Pane eventKey="desktop"><Preview code={msg.code} device="desktop" /></Tab.Pane>
                                                                <Tab.Pane eventKey="mobile"><div className="Mobile-ViewChatCode">
                                                                    <Preview code={msg.code} device="mobile" /></div></Tab.Pane>
                                                                <Tab.Pane eventKey="code">
                                                                    <div className="relative">
                                                                        <CodeMirror
                                                                            code={msg.code}
                                                                            editorTheme={settings.editorTheme}
                                                                            onCodeChange={setGeneratedCode}
                                                                        />
                                                                        {/* <span
                                    title="Copy Code"
                                    className="flex items-center justify-center w-10 h-10 text-gray-500 hover:bg-gray-100 cursor-pointer rounded-lg text-sm p-2.5 absolute top-[20px] right-[20px]"
                                    onClick={doCopyCode}
                                  >
                                    <FaCopy />
                                  </span> */}
                                                                    </div>
                                                                </Tab.Pane>
                                                            </Tab.Content>
                                                        </Tab.Container>
                                                    </div>
                                                ) : ""}
                                            </div>
                                        ) : (
                                            <div className="msgbox usermsgbox" style={{ "padding": "10px" }}>

                                                <p dangerouslySetInnerHTML={{ __html: msg.text }} />
                                                {msg.screenShot ? (
                                                    <div
                                                        className={cx({
                                                            "scanning relative": i === chatHistory.length - 1 && appState === "CODING",
                                                        })}
                                                    >
                                                        <img
                                                            src={msg.screenShot}
                                                            alt="Reference"
                                                        />
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        )}
                                    </p>
                                ))}
                                {isRunning && !isTyping && <> {
                                    chatbotWorkAs === "app" ? (
                                        <div className="chat-messagebox message-end code-msg-box">
                                            <Tab.Container id="left-tabs-example" defaultActiveKey="desktop">
                                                <Nav variant="pills" className="">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="desktop">Desktop</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="mobile">Mobile</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="code">Code</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="desktop"><Preview code={resultt} device="desktop" /></Tab.Pane>
                                                    <Tab.Pane eventKey="mobile"><Preview code={resultt} device="mobile" /></Tab.Pane>
                                                    <Tab.Pane eventKey="code">
                                                        <div className="relative">
                                                            <CodeMirror
                                                                code={resultt}
                                                                editorTheme={settings.editorTheme}
                                                                onCodeChange={setGeneratedCode}
                                                            />
                                                            {/* <span
                            title="Copy Code"
                            className="flex items-center justify-center w-10 h-10 text-gray-500 hover:bg-gray-100 cursor-pointer rounded-lg text-sm p-2.5 absolute top-[20px] right-[20px]"
                            onClick={doCopyCode}
                          >
                            <FaCopy />
                          </span> */}
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </div>
                                    ) : (
                                        <p className={"chat-messagebox message-end"}>
                                            <i><img style={{ width: "50px" }} src={selectedAvatarURL}></img></i>
                                            <div className='msgbox'>
                                                <p>{parse(resultt)}</p>

                                            </div>
                                        </p>
                                    )}
                                </>}


                                {isTyping ? (
                                    <div style={{ "text-align": "right" }}>
                                        <div style={{ display: "inline-block" }}>
                                            {" "}
                                            <img
                                                src={
                                                    "https://www.dropbox.com/s/syx8lrzq6juz0gu/Message-1s-38px.gif?dl=1"
                                                }
                                            ></img>
                                        </div>
                                        <i>
                                            <img
                                                src={selectedAvatarURL}
                                                style={{
                                                    width: "50px",
                                                    "border-radius": "40px",
                                                    "margin-right": "16px",
                                                }}
                                            />
                                        </i>{" "}
                                    </div>
                                ) : null}
                            </div>
                            <div className="chat-input-box chatbox-mobile-wrap">
                                <div className="mobile-flex-chatbox">
                                    <label htmlFor="fileUploadtext" className="fileUploadtext">
                                        <input type="file" id="fileUploadtext" onChange={onSelectFiles} multiple />
                                        <span><Icon icon="fluent:image-add-24-filled" /></span>
                                    </label>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            variant=""
                                            id="dropdown-basic"
                                            className="ChatCategoryDropDown"
                                        >
                                            <Icon icon="heroicons:adjustments-horizontal" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="ChatCategoryDropDownMain">
                                            <div className="ChatCategoryDropDownWrap">
                                                <label htmlFor="CcategoryOne" className="ChatCategoryItemList">
                                                    <input type="radio" id="CcategoryOne" name="image-generation" value='image' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === "image"} />
                                                    <div className="ChatCategoryItem">
                                                        <i><Icon icon="fluent:draw-image-24-regular" /></i>
                                                        <p>  Image generation</p>
                                                    </div>
                                                </label>
                                                <label htmlFor="CcategoryTwo" className="ChatCategoryItemList">
                                                    <input type="radio" id="CcategoryTwo" name="image-generation" value='chat' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === "chat"} />
                                                    <div className="ChatCategoryItem">
                                                        <i><Icon icon="fluent:chat-12-regular" /></i>
                                                        <p>Text and chatting</p>
                                                    </div>
                                                </label>
                                                <label htmlFor="CcategoryThree" className="ChatCategoryItemList">
                                                    <input type="radio" id="CcategoryThree" name="image-generation" value='app' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === "app"} />
                                                    <div className="ChatCategoryItem">
                                                        <i><Icon icon="fluent:tetris-app-20-regular" /></i>
                                                        <p>Image to App</p>
                                                    </div>
                                                </label>
                                                <label htmlFor="CcategoryFour" className="ChatCategoryItemList">
                                                    <input type="radio" id="CcategoryFour" name="image-generation" value='vision' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === "vision"} />
                                                    <div className="ChatCategoryItem">
                                                        <i><Icon icon="tabler:device-vision-pro" /></i>
                                                        <p>Vision power</p>
                                                    </div>
                                                </label>
                                                <label htmlFor="CcategoryFive" className="ChatCategoryItemList">
                                                    <input type="radio" id="CcategoryFive" name="image-generation" value='gpt' onChange={onHandleChangeChatbotGeneration} checked={chatbotWorkAs === "gpt"} />
                                                    <div className="ChatCategoryItem">
                                                        <i><Icon icon="arcticons:openai-chatgpt" /></i>
                                                        <p>GPT4</p>
                                                    </div>
                                                </label>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <div className="textareaDesgin">

                                        <textarea
                                            placeholder="Send your message"
                                            // rows={4}
                                            ref={textAreaRefMobile}
                                            // style={{height: textareaHeight > 100 ? 'auto' : `${textareaHeight}px`}}
                                            value={currentMessage}
                                            onChange={(e) => onHandleCurrentMessageChange(e)}
                                            disabled={isListening}
                                            // onKeyDown={(e) => handleKeyUp(e)}
                                            onKeyPress={(e) => {
                                                // handleKeyUp(e);     
                                                if (!authToken) {
                                                    dispatch(showPopUpAction(true));
                                                    return;
                                                }
                                                if (chatbotWorkAs === "app")
                                                {
                                                    if (e.key === "Enter")
                                                    {
                                                        makeRequestForDesign();
                                                    }
                                                } else
                                                {
                                                    if (activeImage !== "https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/dark-img-upload.png?alt=media&token=474bcca1-fd06-44d8-8c2e-2898651dbb60&_gl=1*11hdrzi*_ga*NDI5MDg0OTczLjE2ODI0Njk1NzQ.*_ga_CW55HF8NVT*MTY5NjI1MjY2MS41LjEuMTY5NjI1MjY4Ni4zNS4wLjA" && activeImage !== "https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/chat-default-image.png?alt=media&token=936b0f65-f711-4153-a1d9-0f1250ad1411")
                                                    {
                                                        if (e.key === "Enter" && !faceOrAudioToggle)
                                                        {
                                                            e.preventDefault();
                                                            setIsTyping(true);
                                                            streamTextGeneration();
                                                        }
                                                        else if (e.key === "Enter" && !faceOrAudioToggle == false)
                                                        {
                                                            e.preventDefault();
                                                            setIsTyping(true);
                                                            streamTextGeneration();
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                        <div className="textareaImgWrap">
                                            {imagePreviews && imagePreviews.map((img, index) => (
                                                <div className="textareaImg">
                                                    <Image src={img} key={index} alt="" />
                                                    <Button className="btn-close-img" variant="" type="button" onClick={() => onRemoveSelectedImage(index)}><Icon icon="ic:round-close" /></Button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <Button variant="" className="Advanced-main-wrap mobile-send-setting" onClick={handleShow}>
                                        <i> <lord-icon src="https://cdn.lordicon.com/exlyjeeh.json" trigger="hover" colors="outline:#131431,primary:#36e899" ></lord-icon> </i>
                                    </Button>
                                    {isCreating ? <Button variant="" className="Advanced-main-wrap mobile-send-setting" onClick={handleShow2}>
                                        <i><lord-icon src="https://cdn.lordicon.com/gdbnaaef.json" trigger="hover" colors="outline:#121331,primary:#ffffff,secondary:#36e899"></lord-icon></i>
                                    </Button> : (<div></div>)}
                                </div>
                                {!currentMessage ? (
                                    <Button variant="" className="mobile-btn record-btn" onClick={startListening}>{isListening ? <Icon icon="ic:round-pause" /> : <Icon icon="ic:outline-mic" />}</Button>
                                ) : isListening ? (
                                    <Button variant="" className="mobile-btn record-btn" onClick={startListening}>{isListening ? <Icon icon="ic:round-pause" /> : <Icon icon="ic:outline-mic" />}</Button>
                                ) :
                                    !faceOrAudioToggle ? (
                                        <Button
                                            variant=""
                                            type="submit"
                                            className="chatbot-send-button btn-message mobile-btn"
                                            onClick={() => {
                                                if (!authToken) {
                                                    dispatch(showPopUpAction(true));
                                                    return;
                                                }
                                                if (chatbotWorkAs === "app")
                                                {
                                                    makeRequestForDesign();
                                                } else
                                                {
                                                    streamTextGeneration();
                                                }
                                            }}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? (
                                                <lord-icon
                                                    src="https://cdn.lordicon.com/wfmfwkbb.json"
                                                    trigger="loop"
                                                    colors="primary:#eee"
                                                ></lord-icon>
                                            ) : (
                                                <span>
                                                    <Icon icon="ic:round-send" />
                                                </span>
                                            )}
                                        </Button>
                                    ) : (
                                        <Button
                                            variant=""
                                            className="chatbot-send-button mobile-btn btn-message"
                                            onClick={() => {
                                                if (!authToken) {
                                                    dispatch(showPopUpAction(true));
                                                    return;
                                                }
                                                if (chatbotWorkAs === "app")
                                                {
                                                    makeRequestForDesign();
                                                } else
                                                {
                                                    streamTextGeneration();
                                                }
                                            }}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? (
                                                <lord-icon
                                                    src="https://cdn.lordicon.com/wfmfwkbb.json"
                                                    trigger="loop"
                                                    colors="primary:#eee"
                                                ></lord-icon>
                                            ) : (
                                                <span>
                                                    <Icon icon="ic:round-send" />
                                                </span>
                                            )}
                                        </Button>
                                    )}
                            </div>
                        </div>
                    </Col>

                    <Col lg={5}>
                        {hasGenerated ? (
                            <div className="AiAvtarCard">
                                <div className="AiAvtarImgCard">

                                    {isVideo ? (
                                        <div className="AiAvtarvideoCard">
                                            <div className="AiAvtarvideoCardround">

                                                <div style={{ borderRadius: "15px", marginBottom: "15px", height: "calc(100% - 115px)" }}>
                                                    <div className="video-container h-100">
                                                        <img src={activeImage}></img>
                                                        <video ref={videoRef2} ></video>
                                                        <video ref={videoRef3} ></video>
                                                        <Button variant="" className="btn-white btnTogglePiP" onClick={togglePiP}>Toggle PiP</Button>
                                                    </div>
                                                </div>
                                                {isProgressing ? (<div style={{ "width": "100%", "position": "relative", "margin": "-8px 0 5px", "zIndex": "1" }}>
                                                    <BorderLinearProgress />
                                                </div>) : null}



                                                <a href={userLink} target="_blank" rel="noopener noreferrer" className="AiAvtarvideoCardinfo">

                                                    <span><Image src={activeImage} /></span>
                                                    <div className="AiAvtarvideoCardinfos">
                                                        <h5>{chatbotName}</h5>
                                                        <h6>
                                                            <i><Icon icon="mdi:user-circle" color="white" /></i>
                                                            {createdBy}
                                                        </h6>
                                                        <p>{descriptionChatbot}</p>
                                                    </div>
                                                </a>
                                            </div>

                                        </div>
                                    ) : (
                                        <div className="AiAvtarvideoCard">
                                            <Image src={activeImage} />

                                            <Link to="/CreateChatbot"
                                                className="chatbot-send-button btn-gradient mb-btn-send"
                                                style={{ "margin": "0 auto", "width": "100%", "textAlign": "center" }}
                                            >
                                                Recreate Chatbot
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : avatarImageUrlSelected == "" ? (
                            <div className="AiAvtarCard">
                                <div className="AiAvtarImgCard">
                                    <a href={userLink} target="_blank" rel="noopener noreferrer" className="AiAvtarvideoCardinfo">
                                        <span><Image src={activeImage} /></span>
                                        <div className="AiAvtarvideoCardinfos">
                                            <h5>{chatbotName}</h5>
                                            <h6>
                                                <i><Icon icon="mdi:user-circle" color="white" /></i>
                                                {createdBy}
                                            </h6>
                                            <p>{descriptionChatbot}</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <a href={userLink} target="_blank" rel="noopener noreferrer" className="AiAvtarvideoCardinfo">
                                <span><Image src={activeImage} /></span>
                                <div className="AiAvtarvideoCardinfos">
                                    <h5>{chatbotName}</h5>
                                    <h6>
                                        <i><Icon icon="mdi:user-circle" color="white" /></i>
                                        {createdBy}
                                    </h6>
                                    <p>{descriptionChatbot}</p>
                                </div>
                            </a>
                        )}
                    </Col>

                </Row>
            </>
            {/* )} */}
            <Modal show={isLanguageModal} onHide={handleCloseLanguageModal} centered size="lg"
                className="FactoryListPopup advancePopup max-50">
                <Modal.Body>
                    <Link
                        to="#"
                        className="CloseBtn"
                        onClick={handleCloseLanguageModal}
                    >
                        <Icon icon="ion:close-circle" color="white" />
                    </Link>
                    <h5 className="factoryListTitle">Choose Language Parameters</h5>
                    <p className="FactorySettingText">Customize your AI assistant in whatever way you want!</p>
                    <hr />
                    <h6 className="label-title">Language  Parameters</h6>
                    <div className="Voice-main-wrap z-3">
                        <Form.Label>Language  : </Form.Label>
                        <div className="Voices-Wrap">
                            <Select
                                placeholder="Select Option"
                                value={selectedOption}
                                className="LanguageSelect"
                                options={languages}
                                onChange={handleLanguageChange}
                                styles={customStyles2}
                                getOptionLabel={(e) => (
                                    <div className="d-flex align-items-center">
                                        {/* {e.icon} */}
                                        <span className="ms-2" style={{ fontSize: 13 }}>{e.value}</span>
                                    </div>
                                )}
                            />
                        </div>
                    </div>

                </Modal.Body>
                <hr />
                <div className="btn-flex-modal">
                    <Button variant="" className="btn-border-white" onClick={handleClick}>Cancel</Button>
                    <Button variant="" className="btn-white" onClick={handleClick}>Save</Button>
                </div>
            </Modal>
            <Modal show={isChatModal} onHide={handleCloseChatModal} centered size="lg" className="FactoryListPopup advancePopup max-50">
                <Modal.Body>
                    <Link
                        to="#"
                        className="CloseBtn"
                        onClick={handleCloseChatModal}
                    >
                        <Icon icon="ion:close-circle" color="white" />
                    </Link>
                    <h5 className="factoryListTitle">Choose  Model Parameters</h5>
                    <p className="FactorySettingText">Customize your AI assistant in whatever way you want!</p>
                    <hr />
                    <h6 className="label-title">Model  Parameters</h6>
                    <div className="Voice-main-wrap z-1">
                        <Form.Label>Model  : </Form.Label>
                        <div className="Voices-Wrap">
                            <Select
                                placeholder="Select Option"
                                value={selectedModelOption}
                                className="LanguageSelect"
                                options={models}
                                onChange={handleModelChange}
                                styles={customStyles2}
                                getOptionLabel={(e) => (
                                    <div className="d-flex align-items-center">
                                        <span className="ms-2" style={{ fontSize: 13 }}>{e.text}</span>
                                    </div>
                                )}
                            />
                        </div>
                    </div>

                </Modal.Body>
                <hr />
                <div className="btn-flex-modal">
                    <Button variant="" className="btn-border-white" onClick={handleClick}>Cancel</Button>
                    <Button variant="" className="btn-white" onClick={handleClick}>Save</Button>
                </div>
            </Modal>
            <Modal show={isVoiceModal} onHide={handleCloseVoiceModal} centered size="lg" className="FactoryListPopup advancePopup max-50">
                <Modal.Body>
                    <Link
                        to="#"
                        className="CloseBtn"
                        onClick={handleCloseVoiceModal}
                    >
                        <Icon icon="ion:close-circle" color="white" />
                    </Link>
                    <h5 className="factoryListTitle">Choose Voice Parameters</h5>
                    <p className="FactorySettingText">Customize your AI assistant in whatever way you want!</p>
                    <hr />
                    <h6 className="label-title">Voice Parameters</h6>
                    <div className="Voice-main-wrap z-2">
                        <Form.Label>Voice :</Form.Label>
                        <div className="Voices-Wrap">
                            <Select
                                placeholder="Select Option"
                                value={selectedVoiceForSelectBox}
                                className="LanguageSelect userSelect"
                                options={voices}
                                onChange={handleCategoryChange}
                                styles={customStyles}
                                // menuPortalTarget={document.body}
                                // menuPosition={'fixed'}
                                getOptionLabel={(e) => (
                                    <div><span style={{ marginLeft: 10, fontSize: 13 }}>{e.name}</span></div>
                                )}
                            />
                        </div>
                    </div>

                </Modal.Body>
                <hr />
                <div className="btn-flex-modal">
                    <Button variant="" className="btn-border-white" onClick={handleClick}>Cancel</Button>
                    <Button variant="" className="btn-white" onClick={handleClick}>Save</Button>
                </div>
            </Modal>
            <Modal show={show} onHide={handleClose} centered size="lg" className="FactoryListPopup advancePopup">
                <Modal.Body>
                    <Link
                        to="#"
                        className="CloseBtn"
                        onClick={handleClose}
                    >
                        <Icon icon="ion:close-circle" color="white" />
                    </Link>
                    <h5 className="factoryListTitle">Choose Parameters</h5>
                    <p className="FactorySettingText">Customize your AI assistant in whatever way you want!</p>
                    <hr />
                    <h6 className="label-title">Voice & Chat Parameters</h6>
                    <div className="options-flex">
                        <div className="Voice-main-wrap z-3">
                            <Form.Label>Language  : </Form.Label>
                            <div className="Voices-Wrap">
                                <Select
                                    placeholder="Select Option"
                                    value={selectedOption}
                                    className="LanguageSelect"
                                    options={languages}
                                    onChange={handleLanguageChange}
                                    styles={customStyles2}
                                    getOptionLabel={(e) => (
                                        <div className="d-flex align-items-center">
                                            {/* {e.icon} */}
                                            <span className="ms-2" style={{ fontSize: 13 }}>{e.value}</span>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="Voice-main-wrap z-2">
                            <Form.Label>Voice :</Form.Label>
                            <div className="Voices-Wrap">
                                <Select
                                    placeholder="Select Option"
                                    value={selectedVoiceForSelectBox}
                                    className="LanguageSelect userSelect"
                                    options={voices}
                                    onChange={handleCategoryChange}
                                    styles={customStyles}
                                    getOptionLabel={(e) => (
                                        <div><span style={{ marginLeft: 10, fontSize: 13 }}>{e.name}</span></div>
                                    )}
                                />
                            </div>
                        </div>

                        <div className="Voice-main-wrap z-1">
                            <Form.Label>Model  : </Form.Label>
                            <div className="Voices-Wrap">
                                <Select
                                    placeholder="Select Option"
                                    value={selectedModelOption}
                                    className="LanguageSelect"
                                    options={models}
                                    onChange={handleModelChange}
                                    styles={customStyles2}
                                    getOptionLabel={(e) => (
                                        <div className="d-flex align-items-center">
                                            <span className="ms-2" style={{ fontSize: 13 }}>{e.text}</span>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="FactorySettingTextbox">
                        <h6 className="label-title">AI Prompt Tuner</h6>
                        <textarea id="description" placeholder="Type here..." value={inputPrompt}
                            onChange={handleInputPromptChange} />
                    </div>
                    <div>
                        <Form.Check // prettier-ignore
                            type="switch"
                            name="superenhance"
                            checked={superenhance}
                            onChange={handleCheckboxChange}
                            id="Superenhance"
                            label="Superenhance"
                        />
                    </div>
                    <hr />
                    <h6 className="label-title">Talking Head Parameters</h6>

                    <div className="TalkingWrap">
                        <div className="Talking50">
                            <h6>Expression Scale ({headMovement})</h6>
                            <Slider
                                min={1}
                                max={10}
                                defaultValue={headMovement}
                                onChange={(e) => setHeadMovement(e)}
                            />
                            <div className="DropProgressWrapInfo">
                                <p>Low Expression</p>
                                <p>High Expression</p>
                            </div>
                        </div>
                        <div className="Talking50">
                            <h6>Yaw ({yawScale})</h6>
                            <Slider
                                min={0}
                                max={20}
                                defaultValue={yawScale}
                                onChange={(e) => setYaw(e)}
                            />
                            <div className="DropProgressWrapInfo">
                                <p>No Yaw Change</p>
                                <p>High Yaw Change</p>
                            </div>
                        </div>
                        <div className="Talking50">
                            <h6>Pitch ({pitchScale})</h6>
                            <Slider
                                min={0}
                                max={20}
                                defaultValue={pitchScale}
                                onChange={(e) => setPitchScale(e)}
                            />
                            <div className="DropProgressWrapInfo">
                                <p>No Pitch Change</p>
                                <p>High Pitch Change</p>
                            </div>
                        </div>
                        <div className="Talking50">
                            <h6>Roll ({rollScale})</h6>
                            <Slider
                                min={0}
                                max={20}
                                defaultValue={rollScale}
                                onChange={(e) => setRollScale(e)}
                            />
                            <div className="DropProgressWrapInfo">
                                <p>No Roll Change</p>
                                <p>High Roll Change</p>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <hr />
                <div className="btn-flex-modal">
                    <Button variant="" className="btn-border-white" onClick={handleClick}>Cancel</Button>
                    <Button variant="" className="btn-white" onClick={handleClick}>Save</Button>
                </div>
            </Modal>
            <Modal show={show2} onHide={handleClose2} centered size="lg" className="FactoryListPopup advancePopup">
                <Modal.Header >
                    <Modal.Title className="center-title" style={{ "font-weight": "bold" }}>Upload AI Companion</Modal.Title>
                    <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose2}></button>

                </Modal.Header>
                {isIntroLoading ? (
                    <Modal.Body>



                        <div className="options-flex" >
                            {/* <img
                                  className=""
                                  src={selectedAvatarURL}
                                  alt="Avatar"
                                /> */}
                            <div className="progressClass" style={{ "textAlign": "center", "marginRight": "auto", "marginLeft": "auto", "paddingTop": "22px", "paddingBottom": "10px" }}>
                                <CircularProgressbarWithChildren
                                    styles={{
                                        path: { stroke: "#36e899" },
                                        trail: { stroke: "#ffffff14" },
                                    }}
                                    className="ProgressMainInfo"
                                    value={countDown}
                                >
                                    <div className="ProgressInfo">
                                        <img
                                            src="/images/big-logo-icon.png"
                                            alt="doge"
                                        />
                                        <strong>{countDown}%</strong>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                        </div>



                    </Modal.Body>
                ) : (
                    <Modal.Body>
                        <h6 className="label-title">Voice & Chat Parameters</h6>
                        <div className="options-flex">
                            <div className="Voice-main-wrap z-3">
                                <Form.Label>Language : </Form.Label>
                                <div className="Voices-Wrap">
                                    <Select
                                        placeholder="Select Option"
                                        value={selectedOption}
                                        className="LanguageSelect"
                                        options={languages}
                                        onChange={handleLanguageChange}
                                        styles={customStyles2}
                                        getOptionLabel={(e) => (
                                            <div className="d-flex align-items-center">
                                                {/* {e.icon} */}
                                                <span className="ms-2">{e.value}</span>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="Voice-main-wrap z-2">
                                <Form.Label>Voice :</Form.Label>
                                <div className="Voices-Wrap">
                                    <Select
                                        placeholder="Select Option"
                                        value={selectedVoiceForSelectBox}
                                        className="LanguageSelect userSelect"
                                        options={voices}
                                        onChange={handleCategoryChange}
                                        styles={customStyles}
                                        // menuPortalTarget={document.body}
                                        // menuPosition={'fixed'}
                                        getOptionLabel={(e) => (
                                            <div><span style={{ marginLeft: 10 }}>{e.name}</span></div>
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="Voice-main-wrap z-1">
                                <Form.Label>Model  : </Form.Label>
                                <div className="Voices-Wrap">
                                    <Select
                                        placeholder="Select Option"

                                        value={selectedModelOption}
                                        className="LanguageSelect"
                                        options={models}
                                        onChange={handleModelChange}
                                        styles={customStyles2}
                                        getOptionLabel={(e) => (
                                            <div className="d-flex align-items-center">
                                                <span className="ms-2">{e.text}</span>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="FactorySettingTextbox">
                            <h6 className="label-title">AI Prompt Tuner</h6>
                            <textarea id="description" placeholder="Type here..." value={inputPrompt}
                                onChange={handleInputPromptChange} />
                        </div>
                        <hr />
                        <h6 className="label-title">Talking Head Parameters</h6>
                        <div className="TalkingWrap">
                            <div className="Talking50">
                                <h6>Expression Scale ({headMovement})</h6>
                                <Slider
                                    min={1}
                                    max={10}
                                    defaultValue={headMovement}
                                    onChange={(e) => setHeadMovement(e)}
                                />
                                <div className="DropProgressWrapInfo">
                                    <p>Low Expression</p>
                                    <p>High Expression</p>
                                </div>
                            </div>
                            <div className="Talking50">
                                <h6>Yaw ({yawScale})</h6>
                                <Slider
                                    min={0}
                                    max={20}
                                    defaultValue={yawScale}
                                    onChange={(e) => setYaw(e)}
                                />
                                <div className="DropProgressWrapInfo">
                                    <p>No Yaw Change</p>
                                    <p>High Yaw Change</p>
                                </div>
                            </div>
                            <div className="Talking50">
                                <h6>Pitch ({pitchScale})</h6>
                                <Slider
                                    min={0}
                                    max={20}
                                    defaultValue={pitchScale}
                                    onChange={(e) => setPitchScale(e)}
                                />
                                <div className="DropProgressWrapInfo">
                                    <p>No Pitch Change</p>
                                    <p>High Pitch Change</p>
                                </div>
                            </div>
                            <div className="Talking50">
                                <h6>Roll ({rollScale})</h6>
                                <Slider
                                    min={0}
                                    max={20}
                                    defaultValue={rollScale}
                                    onChange={(e) => setRollScale(e)}
                                />
                                <div className="DropProgressWrapInfo">
                                    <p>No Roll Change</p>
                                    <p>High Roll Change</p>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="UploadPopup">
                            <div className="profile-row-unique">
                                <img
                                    src={activeImage}
                                    alt="profile-pic"
                                    className="profile-row-picture"
                                    style={{ borderRadius: activeImage === "https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/chat-default-image.png?alt=media&token=936b0f65-f711-4153-a1d9-0f1250ad1411" ? "0px" : "12px" }}
                                />
                            </div>
                            <div className="name-description-block">
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Name"
                                    className="profile-row-textbox"
                                />
                                <textarea
                                    type="text"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Description"
                                    className="profile-row-textbox"
                                />

                            </div>
                        </div>
                    </Modal.Body>
                )}
                <hr />
                <div className="btn-flex-modal">
                    <Button variant="" className="btn-border-white" onClick={handleClick}>Cancel</Button>
                    <Button variant="" className="btn-white" onClick={handleClick}>Save</Button>
                </div>
            </Modal>
            <div className={`overlay ${visible ? "visible" : "hidden"}`}>
                {countDown2 >= 70 ? (
                    <div style={{ "height": "250px", "width": "250px" }}>
                        <Button
                            variant=""
                            className="chatbot-play-button mobile-btn btn-message"
                            onClick={handlePlayVideo}
                            disabled={isLoading}
                        >
                            <LordIcon
                                src="https://cdn.lordicon.com/jcmfjvpk.json"
                                trigger="hover"
                                style={{ width: "250px", height: "250px" }}
                            />
                            <p>{windowWidth < 600 ? "" : "Press Play to Start Chatbot"}</p>

                        </Button>

                    </div>

                ) : (
                    <CircularProgressbarWithChildren
                        styles={{
                            path: { stroke: "#36e899" },
                            trail: { stroke: "#ffffff14" },
                        }}
                        className="ProgressMainInfo"
                        value={countDown2}
                    >
                        <div className="ProgressInfo">


                            {countDown2 >= 70 ? (

                                <Button
                                    variant=""
                                    className="chatbot-play-button"
                                    onClick={handlePlayVideo}
                                    disabled={isLoading}
                                >
                                    <LordIcon
                                        src="https://cdn.lordicon.com/giasfzki.json"
                                        trigger="hover"
                                        style={{ width: "250px", height: "250px" }}
                                    />
                                    Press to Play

                                </Button>
                            ) : (<div style={{ "textAlign": "center" }}>
                                <img src="/images/big-logo-icon.png" alt="doge" />
                                <strong>{countDown2}%</strong>
                            </div>)}


                        </div>
                    </CircularProgressbarWithChildren>
                )}
            </div>
            <PopupModal
                className="popupShowImage"
                show={isModalOpen}
                handleClose={closeModal}
            >
                <div className="popupModalImage">
                    <Image src={selectedImageUrl} alt="" />
                </div>
            </PopupModal>
        </div >
    );
}

export default ChatBot;
