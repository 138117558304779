import React, {useState} from "react";
import {Button, Form} from "react-bootstrap";
import {Icon} from "@iconify/react";
import {useDispatch, useSelector} from "react-redux";
import PopupModal from "../../PopupModal/PopupModal";
import {updateUserData, userDataSelector} from "../../../Redux/Slices/userDataSlice";
import {Link} from "react-router-dom";
import {uploadToS3} from "../../S3/s3";
import {GetUserData, UpdateUserData} from "../../../Utils/Utils";
import {ProfilePicture} from "../../ProfilePicture/ProfilePicture";
import User from "../../../Models/User";
import toast from "react-hot-toast";

type HandleCloseFunction = () => void;

export const ChangeUserDetailsPopUp = ({ show, handleClose }: { show: boolean; handleClose: HandleCloseFunction }) =>
{

    const dispatch = useDispatch();
    const userDetails = useSelector(userDataSelector);
    const updatedUserData:User = {...userDetails};
    const validFileTypes = ["image/jpg", "image/jpeg", "image/png"];
    const [uploadingImage, setUploadingImage] = useState(false);
    const [error, setError] = useState("");
    const [dataChanged, setDataChanged]= useState(false);
    const [updatedName, setUpdatedName] = useState(updatedUserData.Name);
    const [tempImgUrl, setTempImgUrl] = useState<string | null>(null);

    function onHandleChangeUsername() {
        if (updatedName) {
            const isValid = /^[a-zA-Z0-9]*$/.test(updatedName);

            if (!isValid) {
                // Show error if name contains spaces or special characters
                toast.error("Name can't contain spaces or special characters");
                return;
            }

            updatedUserData.Name = updatedName;
            UpdateUserData(updatedUserData).then((userData) => {
                if (userData === "used") {
                    // Handle the case when the username is already in use
                    toast.error("Name already in use");
                    return;
                }

                dispatch(updateUserData(userData));
                handleClose();
                window.history.pushState({}, document.title, "/profile/" + updatedUserData.Name);
                window.location.reload();
            }).catch((error) => {
                // Handle other potential errors
                console.log(error);
                toast.error("An error occurred while updating the username");
            });
        } else {
            toast.error("Enter your Username");
        }
    }


    function userNameChanged(event: any)
    {
        setDataChanged(true);
        setUpdatedName(event.target.value);
    }

    const handleFileChange = async (event: any) =>
    {
        const file = event.target.files[0];
        if (!validFileTypes.find(type => type === file.type))
        {
            toast.error("File must be in JPG/PNG format");
        }
        else
        {
            setError("");
        }
        if (file) {
            // Set temporary image URL for preview
            const tempUrl = URL.createObjectURL(file);
            setTempImgUrl(tempUrl);
        }
        setUploadingImage(true);
        await uploadToS3(file);
        localStorage.removeItem("userData");
        const user = await GetUserData();
        dispatch(updateUserData(user));
        setUploadingImage(false);
        setDataChanged(true);
    };

    return (
        <PopupModal show={show} handleClose={{handleClose}} className='FactoryListPopup' backdrop={undefined}>
            
            <><Link to="#" className="CloseBtn" onClick={() => {
                if(dataChanged)
                {
                    setDataChanged(false);
                    onHandleChangeUsername();
                }
                handleClose();
            }}>
                <Icon icon="ion:close-circle" color="white"/>
            </Link>
            <h5 className="factoryListTitle">Choose your username</h5>
            <hr />
            <div>
                <p className="text-center mb-0" style={{fontSize: "14px"}}><i>Note: Profile image must be less than 5MB.</i></p>
                <div className="OnboardingModal">
                    <Form.Group className="mb-4 UserProfileBox" controlId="FormUserProfileImg">
                        <Form.Label className="mb-0">
                            <span>
                                <ProfilePicture tempImgUrl={tempImgUrl} />
                            </span>
                        </Form.Label>
                        <Form.Control
                            type="file"
                            name="profile_picture"
                            placeholder="Enter the your username"
                            onChange={handleFileChange}/>
                        <i><Icon icon="fluent:image-edit-24-filled" /></i>    
                    </Form.Group>
                    {error && <Form.Text className="text-danger text-center d-block">{error}</Form.Text>}
                    <Form.Group className="mb-3 Formbox" controlId="formBasicEmail">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            name="user_name"
                            value={updatedName}
                            placeholder="Enter the your username"
                            onChange={userNameChanged}/>
                    </Form.Group>
                </div>
                <hr/>
                <div className="text-end">
                    <Button
                        variant=""
                        type="button"
                        className="btn-gradient"
                        onClick={() => {
                            if(dataChanged)
                            {
                                setDataChanged(false);
                                onHandleChangeUsername();
                            }
                        }}>
                             Update Profile
                    </Button>
                </div>
            </div>
            </>
        </PopupModal>
    );
};