import { useContractRead } from 'wagmi'
import AIContract from "../AITokenization/AIOrb.json";
import OmniAIOrb from "../../Contracts/AITokenization/OmniAIOrb.json";


function ReadFromAIContract(props)
{
  
  const { data, error, isLoading } = useContractRead({
    abi: props.isLZ ? OmniAIOrb.abi : AIContract.abi,
    address: props.address,
    functionName: props.functionName,
    args: props.args? props.args : null,
    chainId: props.chainId? props.chainId : null,
  })

    return { data, error, isLoading}
}

export default ReadFromAIContract


