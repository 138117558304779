import { ConnectButton } from '@rainbow-me/rainbowkit';

export const CustomConnectButtonSpaceID = ({bnbDomain}) => {

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus ||
            authenticationStatus === 'authenticated');
        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <div className='text-center'>
                    <button className='btn btn-white btn-wallet' onClick={openConnectModal} type="button">
                      Connect Wallet
                    </button>
                  </div>
                );
              }
              if (chain.unsupported) {
                return (
                  <div className='text-center'>
                    <button onClick={openChainModal} type="button" className='btn btn-white btn-wallet'>
                      Wrong network
                    </button>
                  </div>
                );
              }
              return (
                <div className='text-center btnWallet'>
                  <button
                    onClick={openChainModal}
                    type="button"
                    className='btn  btn-white btn-wallet d-flex align-items-center'
                  >
                    {chain.hasIcon && (
                      <div
                        style={{
                          background: chain.iconBackground,
                          width: 12,
                          height: 12,
                          borderRadius: 999,
                          overflow: 'hidden',
                          marginRight: 4,
                        }}
                      >
                        {chain.iconUrl && (
                          <img
                            alt={chain.name ?? 'Chain icon'}
                            src={chain.iconUrl}
                            style={{ width: 12, height: 12 }}
                          />
                        )}
                      </div>
                    )}
                    {chain.name}
                  </button>
                  <button className='btn  btn-white btn-wallet' onClick={openAccountModal} type="button">
                 
                    {bnbDomain === null || bnbDomain === "connected to the wrong chain" ?  account.displayName  : bnbDomain}
                    {account.displayBalance
                      ? ` (${account.displayBalance})`
                      : ''}
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};