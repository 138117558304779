import { Nav, Tab } from "react-bootstrap";
import MyCreations from "../../../UserCreations/UserCreations";
import MyFactories from "../../../UserFactories/UserFactories";
import MyChatbots from "../../../UserChatbots/UserChatbots";
import TokenizeAssets from "../../../../Components/TokenizeAssets/TokenizeAssets";
import "./ProfileUserAssets.css";
import React, { useEffect, useState } from "react";
import MyProducts from "./MyProducts";
import { useSelector } from "react-redux";
import { userDataSelector } from "../../../../Redux/Slices/userDataSlice";
import axios from "axios";
import User from "../../../../Models/User";
import { Link } from "react-router-dom";

function ProfileUserAssets({ isPublicProfile }: { isPublicProfile: boolean }) {

    const localUser: User = useSelector(userDataSelector);
    const [userEmail, setUserEmail] = useState("");

    useEffect(() => {
        if (isPublicProfile) {
            const name = window.location.pathname.split("/")[2]; // Get the username from the url
            const data = name.replace("_", " "); // Get the username from the url
            axios.post(import.meta.env.VITE_SERVER_ADDRESS + "getPublicUserInfo", data)
                .then((resp) => {
                    setUserEmail(resp.data as string);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        else {
            setUserEmail(localUser.Email);
        }
    }, []);

    return (
        <>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Nav variant="pills" className="custom-navs ProfileNavs ">
                    <Nav.Item>
                        <Nav.Link eventKey="first">AI companions</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="second">Factories</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="third">AI Assets</Nav.Link>
                    </Nav.Item>
                    {isPublicProfile ? "" :
                        <>
                            <Nav.Item>
                                <Nav.Link eventKey="fourth">NFTs </Nav.Link>
                            </Nav.Item><Nav.Item>
                                <Nav.Link eventKey="fifth">Products Purchased </Nav.Link>
                            </Nav.Item></>
                    }
                </Nav>
                <Tab.Content className="ProfileTabsInfo">
                    <Tab.Pane eventKey="first">
                        <h3>AI companions</h3>
                        <MyChatbots mainTitle={false} user={userEmail} isPublicProfile={isPublicProfile} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                        <h3>My Factories</h3>
                        <MyFactories mainTitle={false} user={userEmail} isPublicProfile={isPublicProfile} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                        <h3>AI Assets</h3>
                        <MyCreations mainTitle={false} user={userEmail} isPublicProfile={isPublicProfile} />
                    </Tab.Pane>
                    {isPublicProfile ? "" :
                        <>
                            <Tab.Pane eventKey="fourth">
                                <h3>NFTs</h3>
                                <TokenizeAssets />
                            </Tab.Pane><Tab.Pane eventKey="fifth">
                                <h3>Products Purchased</h3>
                                <MyProducts />
                            </Tab.Pane></>
                    }
                </Tab.Content>
            </Tab.Container>
        </>
    );
}

export default ProfileUserAssets;
