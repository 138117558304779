import { Icon } from "@iconify/react";
import { Image, Nav, Tab } from "react-bootstrap";
import "./ProfileInfo.css";

function ProfileInfo(props) {


  return (
    <div className="profile-main default-box h-100">
      <div className="profile-bg-img">
        <Image src="/images/profile-bg-img.png" />
      </div>
      <div className="profile-photo-wrap">
        <div className="profile-photo-img">
          <Image src={props.profileData?.image} />
          <h6>{props.profileData?.name}</h6>
        </div>
        <p>
            { props.profileData?.choppedOrbAddress}
          
            <Icon icon="mingcute:copy-2-line" />
          
         
          <a href={`https://testnet.bscscan.com/address/${props.profileData?.orbAddress}`} target="_blank" rel="noreferrer" >
            <Icon icon="ri:share-box-fill" />
          </a>
          
        </p>
      </div>
      <ul className="follower-wrap">
        <li>
          <h6>0</h6>
          <p>
            <span>
              <Icon icon="ic:baseline-remove-red-eye" />
            </span>
            Watchers
          </p>
        </li>
        <li>
          <h6>1</h6>
          <p>
            <span>
              <Icon icon="material-symbols:groups" />
            </span>
            Members
          </p>
        </li>
        <li>
          <h6>0</h6>
          <p>
            <span>
              <Icon icon="ph:globe-hemisphere-west-fill" />
            </span>
            Countries
          </p>
        </li>
      </ul>
      <hr />
      <div className="wingardium-info">
        <h5>{props.profileData?.about}</h5>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav variant="pills" className="wingardium-tabs">
            <Nav.Item>
              <Nav.Link eventKey="first">
                <span>
                  <Icon icon="system-uicons:document-stack" />
                </span>
                All
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">
                <span>
                  <Icon icon="ion:image-outline" />
                </span>
                Posts
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third">
                <span>
                  <Icon icon="uil:create-dashboard" />
                </span>
                Marketplace
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="first">
            <h4 className="comeSoon">
            <span>
            <lord-icon
              src="/images/icons/bricks-toy.json"
              trigger="loop"
              delay="2000">
          </lord-icon>
            </span>
            This is a test community Orb. The system for Orbs is under construction, and soon you will be able to post content, share images/videos, and build your own metaverse community together. <br /><br /> Imagine if Reddit meets GTA-style virtual worlds that you can easily build with AI.</h4>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
            <h4 className="comeSoon">
            <span>
            <lord-icon
              src="/images/icons/bricks-toy.json"
              trigger="loop"
              delay="2000">
          </lord-icon>
            </span>
            This is a test community Orb. The system for Orbs is under construction, and soon you will be able to post content, share images/videos, and build your own metaverse community together. <br /><br /> Imagine if Reddit meets GTA-style virtual worlds that you can easily build with AI.</h4>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
            <h4 className="comeSoon">
            <span>
            <lord-icon
              src="/images/icons/shop.json"
              trigger="loop"
              delay="2000">
          </lord-icon>
            </span>
            Community Marketplaces for virtual goods are under construction. You will be able to create your own metaverse marketplace for virtual collectibles with AI superpowers, and enable people to use them in your metaverse, or other games</h4>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
}

export default ProfileInfo;
