export const categoryList = [
    { title: "General Purpose", checked: false },
    { title: "Characters", checked: false },
    { title: "Environments", checked: false },
    { title: "Buildings", checked: false },
    { title: "Game Items & Collectibles", checked: false },
    { title: "Props", checked: false },
    { title: "Natural Elements", checked: false },
    { title: "Fashion and Wearables", checked: false },
    { title: "Game Maps", checked: false },
    { title: "Pixel Art", checked: false },
    { title: "Vehicles", checked: false },
    { title: "Weapons", checked: false },
    { title: "Concept Art/Illustration", checked: false },
    { title: "UI Components and GUI", checked: false },
    { title: "Photography", checked: false },
];

export const allPrompt = [
    "portals",
    "fantasy world",
    "portrait",
    "cute animal",
    "fantasy architecture",
    "cinematic",
    "anthromorphic",
    "realism",
    "colorful",
    "environmental landscape",
    "artstation",
    "User Interface",
    "logo",
    "vector art",
];

export const AchievementList = {
    InviteFriends: 1,
    Share: 2,
    CreateFactory: 3,
    GenerateImage: 4,
    VisitBlog: 5
};

export const ProductList = {
    ChatBot: 11,
};