class UserImages {
    UserId: number;
    Url: string;
    Likes: number;
    Reports: number;
    Nsfw: number;
    Sentence: string;
    Language: string;
    ImageType: string;
    UserName: string;

    constructor(
        user_id: number = 0,
        url: string = "",
        likes: number = 0,
        reports: number = 0,
        nsfw: number = 0,
        sentence: string = "",
        language: string = "",
        image_type: string = "",
        userName: string = "",
    ) {
        this.UserId = user_id;
        this.UserName = userName;
        this.Url = url;
        this.Likes = likes;
        this.Reports = reports;
        this.Nsfw = nsfw;
        this.Sentence = sentence;
        this.Language = language;
        this.ImageType = image_type;
    }
}

export default UserImages;