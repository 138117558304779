import {Button, Nav, Tab} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ChatbotCard from '../../Components/ChatbotCard/ChatbotCard';
import { useEffect, useState } from 'react';
import axios from 'axios';
import React from 'react';
import { getFirestoreInstance } from '../../Utils/Utils';
import {
  collection,
  onSnapshot,
  query,
  where,
} from '@firebase/firestore';
import { categoryList } from '../../Utils/Constants';
import './ChatbotPage.css';
import { loginPopUpSelector, showPopUpAction } from '../../Redux/Slices/loginPopUpSlice';
import {useDispatch} from 'react-redux';

function ChatbotPage() {
  const [factoryList, setFactoryList] = useState([]);
  const [, setImgSrc] = useState('');
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [filteredFactory, setFilteredFactory] = useState([]);
  const [featuredFactories, setFeaturedFactories] = useState([]);
  const [backendData, setBackendData] = useState({ 'Orbofi': 'Orbofi' });

  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  
  
  useEffect(() => {
    setAllCategories(categoryList);

  }, []);

  // Extract this and the one in homepage to Utils later on
  useEffect(() => {
    // Make a GET request to fetch data from the backend
    axios.get(import.meta.env.VITE_SERVER_ADDRESS + 'getAllEmails')
      .then((response) => {
        // Handle the successful response here
        let emailNamesList = response.data;
        emailNamesList['Orbofi'] = 'Orbofi';
        setBackendData(emailNamesList);
        setLoaded(true);
      })
      .catch((error) => {
        // Handle any errors here
        console.error(error);
      });
  }, []);

  const onHandleSelectCategory = (selectedOptions, filterMode) => {

    if (filterMode === 'filter')
    {
      const selectedCat = selectedOptions;
      const catArray = allCategories.map((category) => ({
        ...category,
        checked: selectedCat.includes(category.title),
      }));

      const filteredData = factoryList.filter((doc) =>
        selectedCat.includes(doc.category)
      );

      setAllCategories(catArray);
      setSelectedCategory(selectedCat);
      setFilteredFactory(filteredData);
    } else
    {
      setAllCategories(categoryList);
      setSelectedCategory([]);
      setFilteredFactory([]);
    }
  };

  const onSelectCategory = (cat) => {
    const catArray = allCategories.map((category) => {
      if (category.title === cat)
      {
        if (category.checked)
        {
          category.checked = false;
        } else[(category.checked = true)];
      }
      return category;
    });

    setAllCategories(catArray);
  };

  const db = getFirestoreInstance();
  useEffect(() => {
    const colRef = collection(db, 'ChatBots');
    const queryRef = query(colRef, where('likes', '==', 0));

    const unsubListener = onSnapshot(queryRef, {
      next: (snapshot) => {
        const lst = snapshot.docs.map((doc) => ({
          voice: doc.data()['voice'],
          inputImage: doc.data()['inputImage'],
          id: doc.data()['id'],
          created_by: backendData[doc.data().created_by] || doc.data().created_by,
          description: doc.data()['description'],
          name: doc.data()['name'],
          creation_date: doc.data()['date_created'],
          likes: doc.data()['likes'],
          inputPrompt: doc.data()['inputPrompt'],
          language: doc.data()['language'],
          tokenized: doc.data()['tokenized'],
          rollScale: doc.data()['rollScale'],
          pitchScale: doc.data()['pitchScale'],
          yawScale: doc.data()['yawScale'],
          expression_scale: doc.data()['expression_scale'],
          generated_intro_vid: doc.data()['generated_intro_vid'],
          generated_hello_vid: doc.data()['generated_hello_video'],
          cover_image: doc.data()['cover_image'],

        }));

        // Filtering the list based on creation_date
        const filteredList = lst.filter(item => item.creation_date > 2000000000000);
        const nonFeatured = lst.filter(item => item.creation_date < 2000000000000);


        // console.log(lst);

        // console.log(lst);

        setFeaturedFactories(
          filteredList.sort((a, b) => b.creation_date - a.creation_date)
        );
        setFactoryList(nonFeatured.sort((a, b) => a.creation_date - b.creation_date));
      },
      error: (err) => {
        console.error(err);
        // TODO: handle error (signed out? no permission? no connection?)
      },
    });

    return unsubListener;
  }, [db]);

  const handleButtonClick = (selectedMode) => {
    setFactoryList([]);
    // Call any other function here. For example:
    if (selectedMode === 'Orbofi')
    {
      const colRef = collection(db, 'models');
      const queryRef = query(colRef, where('numGeneration', '>', 70000));
      onSnapshot(queryRef, {
        next: (snapshot) => {
          snapshot.docs.forEach((doc) => {
            setImgSrc(doc.data()['model_photo_url']);
          });
          const lst = snapshot.docs.map((doc) => ({
            img: doc.data()['model_photo_url'],
            uid: doc.data()['model_id'],
            numGeneration: doc.data()['numGeneration'],
            creator: doc.data()['creator'],
            model_desc: doc.data()['model_desc'],
            model_name: doc.data()['model_name'],
            creation_date: doc.data()['creation_date']['seconds'],
            likes: doc.data()['likes'],
            category: doc.data()['category'],
            voice: doc.data()['voice'],
          }));



          setFactoryList(
            lst.sort((a, b) => (a.numGeneration < b.numGeneration ? 1 : -1))
          );
        },
        error: (err) => {
          console.error(err);
          // TODO: handle error (signed out? no permission? no connection?)
        },
      });
    } else if (selectedMode === 'Uploaded')
    {
      const colRef = collection(db, 'models');
      const queryRef = query(colRef, where('isUploaded', '==', true));
      onSnapshot(queryRef, {
        next: (snapshot) => {
          snapshot.docs.forEach((doc) => {
            setImgSrc(doc.data()['model_photo_url']);
          });
          const lst = snapshot.docs.map((doc) => ({
            img: doc.data()['model_photo_url'],
            uid: doc.data()['model_id'],
            numGeneration: doc.data()['numGeneration'],
            creator: doc.data()['creator'],
            model_desc: doc.data()['model_desc'],
            model_name: doc.data()['model_name'],
            creation_date: doc.data()['creation_date']['seconds'],
            likes: doc.data()['likes'],
            category: doc.data()['category'],
            voice: doc.data()['voice'],
          }));

          setFactoryList(
            lst.sort((a, b) => (a.numGeneration < b.numGeneration ? 1 : -1))
          );
        },
        error: (err) => {
          console.error(err);
          // TODO: handle error (signed out? no permission? no connection?)
        },
      });
    } else
    {
      const colRef = collection(db, 'models');
      const queryRef = query(colRef, where('numGeneration', '<', 50000));
      onSnapshot(queryRef, {
        next: (snapshot) => {
          snapshot.docs.forEach((doc) => {
            setImgSrc(doc.data()['model_photo_url']);
          });
          const lst = snapshot.docs.map((doc) => ({
            img: doc.data()['model_photo_url'],
            uid: doc.data()['model_id'],
            numGeneration: doc.data()['numGeneration'],
            creator: doc.data()['creator'],
            model_desc: doc.data()['model_desc'],
            model_name: doc.data()['model_name'],
            creation_date: doc.data()['creation_date']['seconds'],
            likes: doc.data()['likes'],
            category: doc.data()['category'],
            voice: doc.data()['voice'],
          }));

          setFactoryList(
            lst.sort((a, b) => (a.numGeneration < b.numGeneration ? 1 : -1))
          );
        },
        error: (err) => {
          console.error(err);
          // TODO: handle error (signed out? no permission? no connection?)
        },
      });
    }
  };


  const categoryOptions = categoryList.map((category) => ({
    value: category.title,
    label: category.title,
  }));
  return (
    <div className="main-content home-page">

      <div className="FactoryTitles Factory-bg">
        <h2>AI companions for you and the world</h2>
        <p>
                    Create your personalize AI companion that can boost your personal and processional life, create content for you, do sutff for you, and be there when you need information and advice. Upload your AI companions so that others can use them.

        </p>
        <div className="btn-factory-wrap fw-50">
          { localStorage.getItem('oauthToken') ? 
            (
              <Link to="/CreateChatbot" className="btn btn-white">
                        Create your AI Chatbot
              </Link>
            )
            :
            (
              <Button className="btn btn-white" onClick={() => { dispatch(showPopUpAction(true)); }}>
                    Log in to create your AI Chatbot
              </Button>
            )
          }
        </div>
      </div>
      <div className="Chatpagefilterwrap">
        <div className="Factory-searchbar">
          <input
            type="search"
            placeholder="Search Factory by name and user name"
          />
          <Link to="#">
            <lord-icon
              trigger="hover"
              src="/images/icons/magnifier.json"

            ></lord-icon>
          </Link>
        </div>
      </div>
      <Tab.Container id="left-tabs-example" defaultActiveKey="featuredChatbots">
        <Nav variant="pills" className="custom-navs ChatbotNavs">
          <Nav.Item>
            <Nav.Link eventKey="featuredChatbots">Featured Chatbots</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="newChatbots">New Chatbots</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className="mt-4">
          {loaded ?
            <>
              <Tab.Pane eventKey="featuredChatbots">
                <div className="factoryItemsWrap">
                  {selectedCategory.length > 0 ? (
                    <>
                      <div className="AiFactoryShap AIchatCard CreateAIchatCard card">
                        <Link to="/CreateChatbot" className="CreateAIchatCardInfo">
                          <lord-icon
                            trigger="hover"
                            src="/images/icons/plus.json"
                          ></lord-icon>
                          <h6>Create AI Agent</h6>
                        </Link>

                      </div>
                      {featuredFactories.map((doc) => {
                        if (doc.id !== 'DannyTrejoAI')
                        {
                          return <ChatbotCard
                            key={doc.name}
                            created_by={backendData[doc.created_by]}
                            description={doc.description}
                            inputImage={doc.inputImage}
                            name={doc.name}
                            voice={doc.voice}
                            language={doc.language}
                            inputPrompt={doc.inputPrompt}
                            tokenized={doc.tokenized}
                            likes={doc.likes}
                            rollScale={doc.rollScale}
                            pitchScale={doc.pitchScale}
                            yawScale={doc.yawScale}
                            expression_scale={doc.expression_scale}
                            generated_intro_vid={doc.generated_intro_vid}
                            generated_hello_vid={doc.generated_hello_vid}
                            cover_image={doc.cover_image} />;
                        }

                      })}
                    </>
                  ) : factoryList.length > 0 ? (
                    <>
                      <div className="AiFactoryShap AIchatCard CreateAIchatCard card">
                        <Link to="/CreateChatbot" className="CreateAIchatCardInfo">
                          <lord-icon
                            trigger="hover"
                            src="/images/icons/plus.json"
                          ></lord-icon>
                          <h6>Create AI Agent</h6>
                        </Link>

                      </div>
                      {/* factoryList.reverse()featuredFactories.reverse() */}
                      {factoryList.reverse().map((doc) => doc.id !== 'DannyTrejoAI' &&
                                                <ChatbotCard
                                                  key={doc.name}
                                                  created_by={backendData[doc.created_by]}
                                                  description={doc.description}
                                                  inputImage={doc.inputImage}
                                                  name={doc.name}
                                                  voice={doc.voice}
                                                  language={doc.language}
                                                  inputPrompt={doc.inputPrompt}
                                                  tokenized={doc.tokenized}
                                                  likes={doc.likes}
                                                  rollScale={doc.rollScale}
                                                  pitchScale={doc.pitchScale}
                                                  yawScale={doc.yawScale}
                                                  expression_scale={doc.expression_scale}
                                                  generated_intro_vid={doc.generated_intro_vid}
                                                  generated_hello_vid={doc.generated_hello_vid}
                                                  cover_image={doc.cover_image} />
                      )}
                    </>
                  ) : factoryList.length === 0 ? (<>
                    <div className="grid-container" style={{ height: '500px' }}>
                                            😢 No Chatbots Found 😢
                    </div>
                  </>) : (
                    <>
                      <div className="grid-container" style={{ height: '500px' }}>
                        <div className="text-center">
                          <p>Fetching Chatbots...</p>
                          <lord-icon
                            src="https://cdn.lordicon.com/zuhwwskw.json"
                            colors="primary:#36e899,secondary:#ffffff"
                            trigger="loop"
                          ></lord-icon>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="newChatbots">
                <div className="factoryItemsWrap">
                  <div className="AiFactoryShap AIchatCard CreateAIchatCard card">
                    <Link to="/CreateChatbot" className="CreateAIchatCardInfo">
                      <lord-icon
                        trigger="hover"
                        src="/images/icons/plus.json"
                      ></lord-icon>
                      <h6>Create AI Agent</h6>
                    </Link>

                  </div>
                  {factoryList.reverse().map((doc) => doc.id !== 'DannyTrejoAI' &&
                                        <ChatbotCard
                                          key={doc.name}
                                          created_by={backendData[doc.created_by]}
                                          description={doc.description}
                                          inputImage={doc.inputImage}
                                          name={doc.name}
                                          voice={doc.voice}
                                          language={doc.language}
                                          inputPrompt={doc.inputPrompt}
                                          tokenized={doc.tokenized}
                                          likes={doc.likes}
                                          rollScale={doc.rollScale}
                                          pitchScale={doc.pitchScale}
                                          yawScale={doc.yawScale}
                                          expression_scale={doc.expression_scale}
                                          generated_intro_vid={doc.generated_intro_vid} />
                  )}
                </div>
              </Tab.Pane></>
            : <h6>Loading</h6>
          }
        </Tab.Content>
      </Tab.Container>
    </div >
  );
}

export default ChatbotPage;
