import axios from "axios";
import User from "../Models/User";
import Subscription from "../Models/Subscription";
import { initializeApp } from "firebase/app";
import { Firestore, getFirestore } from "@firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData, userDataSelector } from "../Redux/Slices/userDataSlice";


export function Logout() {
    localStorage.setItem("oauthToken", "");
    localStorage.removeItem("userData");
}

// Get the user data from the DB
export async function GetUserData(): Promise<void | User> {
    if (!localStorage.getItem("userData"))
    {
        const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
        return await axios.get(import.meta.env.VITE_SERVER_ADDRESS + "getUserDetails", config)
            .then((resp) => {
                const userData: User = resp?.data as User;
                localStorage.setItem("userData", JSON.stringify(userData));
                localStorage.setItem("picture", userData.ProfilePicture);
                localStorage.setItem("name", userData.Name);
                return userData;
            })
            .catch((error) => {
                Logout();
                console.log(error);
            });
    }
    else
    {
        const retrievedData = JSON.parse(localStorage.getItem("userData"));
        localStorage.setItem("picture", retrievedData.ProfilePicture);
        localStorage.setItem("name", retrievedData.Name);
        return retrievedData as User;
    }

}

// Modify the data on the backend server
export function UpdateUserData(data: User): Promise<User | string> {
    localStorage.removeItem("userData");
    const config = {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("oauthToken"),
        },
    };

    return axios.post(import.meta.env.VITE_SERVER_ADDRESS + "updateUserDetails", data, config)
        .then(response => {
            return response.data as User;
        })
        .catch(error => {
            if (axios.isAxiosError(error))
            {
                // Check for the specific 'used' response
                if (error.response && error.response.status === 409 && error.response.data === "used")
                {
                    return "used";
                }
            }
            console.log(error);
            throw error;
        });
}


export async function RegisterOrboxUse(productId: number, skipValidation: boolean = true, quantity: number = 1, level: number = 1): Promise<any> {
    const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };

    const payload = {
        skipValidation: skipValidation,
        product_id: productId,
        level: level,
        quantity: quantity
    };

    try
    {
        const resp = await axios.post(import.meta.env.VITE_SERVER_ADDRESS + "useOrbox", payload, config);
        return resp?.data as User; // return the data or "InvalidTransaction"
    } catch (error)
    {
        console.log(error);
        return "InvalidTransaction";
    }
}

export async function ValidateOrboxUse(productId: number, quantity: number = 1, level: number = 1) {
    const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };

    const payload = {
        product_id: productId,
        level: level,
        quantity: quantity
    };

    try
    {
        await axios.post(import.meta.env.VITE_SERVER_ADDRESS + "validateOrbox", payload, config);
    } catch (error)
    {
        console.log(error);
    }
}


export async function GetSubInfo(): Promise<void | Subscription> {
    if (!localStorage.getItem("subscription"))
    {
        const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
        return await axios.get(import.meta.env.VITE_SERVER_ADDRESS + "getSubInfo", config)
            .then((resp) => {
                const subData: Subscription = resp?.data as Subscription;
                localStorage.setItem("subscription", JSON.stringify(subData));
                return subData;
            })
            .catch((error) => {
                console.log(error);
            });
    }
    else
    {
        const retrievedData: Subscription = JSON.parse(localStorage.getItem("subscription")) as Subscription;

        const expirationDate = new Date(retrievedData.SubscriptionExpDate);
        const currentDate = new Date();

        if (expirationDate <= currentDate)
        {
            localStorage.removeItem("subscription");
            return GetSubInfo();
        } else
        {
            return retrievedData as Subscription;
        }
    }
}

export async function CancelSub(): Promise<void | Subscription> {
    const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
    return await axios.get(import.meta.env.VITE_SERVER_ADDRESS + "cancelSub", config);
}

export async function CheckPendingTransaction() {
    const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
    return await axios.get(import.meta.env.VITE_SERVER_ADDRESS + "checkTransaction", config);
}

export function SetImageParams(data, setGeneratedImages, setSelectedImage, setRunTimer, setCountDown, setIsGenerating, setHasGenerating) {
    const allImages = [];
    data.map((image) => {
        const newData = {
            imageUrl: image,
            isChecked: false,
        };
        allImages.push(newData);
        return allImages;
    });
    setGeneratedImages(allImages);
    setSelectedImage([]);

    setRunTimer((t) => false);
    setCountDown(0);
    setIsGenerating((t) => false);
    setHasGenerating(true);
}

export function SetTimerRates(currentNumImage, numImages, timerRateState, timerRates) {
    if (currentNumImage < numImages[0])
    {
        timerRateState(timerRates[0]);
    }
    if (currentNumImage === numImages[1])
    {
        timerRateState(timerRates[1]);
    }
    if (currentNumImage === numImages[2])
    {
        timerRateState(timerRates[2]);
    }
    if (currentNumImage > numImages[2])
    {
        timerRateState(timerRates[3]);
    }
}

export function SetGeneration(setIsGenerating, isGenerating, setHasGenerating, hasGenerated, setRunTimer, startTimer) {
    setIsGenerating((t) => isGenerating);
    setHasGenerating((t) => hasGenerated);
    setRunTimer((t) => startTimer);
}


const firebaseConfig = {
    apiKey: "AIzaSyBPt2X5PQzCTSt9Ef3qLuhG8Idn_LD6cEk",
    authDomain: "orbofibluesky-7f04d.firebaseapp.com",
    projectId: "orbofibluesky-7f04d",
    storageBucket: "orbofibluesky-7f04d.appspot.com",
    messagingSenderId: "193771045657",
    appId: "1:193771045657:web:3828f0c6c364f2614f7b88",
    measurementId: "G-9CXQJEX468",
};

let firebaseInitialized = false;
let db: Firestore | null = null;

export const initializeFirebase = () => {
    if (!firebaseInitialized)
    {
        initializeApp(firebaseConfig);
        db = getFirestore();
        firebaseInitialized = true;
    }
};

export const getFirestoreInstance = () => {
    if (!firebaseInitialized)
    {
        throw new Error("Firebase is not initialized. Call initializeFirebase() first.");
    }
    return db;
};