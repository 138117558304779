import React, {useEffect, useState} from "react";
import {Button, Image} from "react-bootstrap";
import {Toaster} from "react-hot-toast";
import "./ProfileBanner.css";
import {useSelector} from "react-redux";
import {userDataSelector} from "../../Redux/Slices/userDataSlice";
import {ProfilePicture} from "../ProfilePicture/ProfilePicture";
import User from "../../Models/User";
import {ChangeUserDetailsPopUp} from "./ProfilePopUps/ChangeUserDetailsPopUp";
import {CancelSubscriptionPopUp} from "./ProfilePopUps/CancelSubscriptionPopUp";
import {subDataSelector} from "../../Redux/Slices/subscriptionDataSlice";
import Subscription from "../../Models/Subscription";
import axios from "axios";


// This is the upper part of the profile page where we show the user info
function ProfileBanner({isPublicProfile}: {isPublicProfile:boolean}) {
    const [scroll, setScroll] = useState(true);
    const userData: User = useSelector(userDataSelector);
    const subData: Subscription = useSelector(subDataSelector);
    const [showChangeUserDetailsPopUp, setShowChangeUserDetailsPopUp] = useState(false);
    const [showCancelSubPopUp, setShowCancelSubPopUp] = useState(false);
    const [subName, setSubName] = useState("");
    const [dateString, setDateString] = useState("");



    // Public profile data
    const [urlName, setUrlName] = useState(window.location.pathname.split("/")[2]);
    const [userId, setUserId] = useState(0);

    useEffect(() =>
    {
        if(subData)
        {
            const subExpDate = subData.SubscriptionExpDate;
            const indexOfCharacter: number = subExpDate.indexOf("T");
            if (indexOfCharacter !== -1)
            {
                setDateString(subExpDate.substring(0, indexOfCharacter));
            }
        }
    }, [subData]);


    useEffect(() =>
    {
        if(isPublicProfile)
        {
            const curatedName = urlName.replace("_", " ");
            setUrlName(curatedName);
            axios.post(import.meta.env.VITE_SERVER_ADDRESS + "getUserId", curatedName).then((resp) =>
            {
                setUserId(resp.data as number);
            });
            return;
        }
        const config = {headers: {"Authorization": "Bearer " + localStorage.getItem("oauthToken"),},};
        if (!subData)
        {
            return;
        }
        const data = subData.SubscriptionLevel;
        axios.post(import.meta.env.VITE_SERVER_ADDRESS + "getSubName", data.toString(), config).then((resp) =>
        {
            const currentSubName = resp.data as string;
            setSubName(currentSubName);
        });
    }, [subData, urlName]);

    useEffect(() => {
        document.addEventListener("scroll", () => {
            const scrollCheck = window.scrollY < 100;
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck);
            }
        });
    });
    return (
        <>
            <ChangeUserDetailsPopUp show={showChangeUserDetailsPopUp} handleClose={() => setShowChangeUserDetailsPopUp(false)}></ChangeUserDetailsPopUp>
            <CancelSubscriptionPopUp show={showCancelSubPopUp} handleClose={() => setShowCancelSubPopUp(false)}></CancelSubscriptionPopUp>
            <div className="ProfileBannerMain">
                <Image src="/images/ProfileBanner.png" alt="Ex."/>
            </div>
            <div className="MainProfile">
                {isPublicProfile ?
                    <>
                        <div className="MainProfileImage">
                            <ProfilePicture userId={userId} />
                        </div>
                        <h6>{urlName} </h6></>
                    :
                    <>
                        <div className="MainProfileImage">
                            <ProfilePicture/>
                        </div>
                        <h6>{userData?.Name ? userData?.Name : "User Name"} <span>Subscription Level: <strong>{subName}</strong></span></h6>
                        <Button variant="" className="btn btn-white" onClick={() => setShowChangeUserDetailsPopUp(true)}>
                        Edit Profile
                        </Button>
                
                        <Toaster position="bottom-right"/>
                    </>
                }
            </div>
            {subData && !isPublicProfile ?
                <div>
                    <hr/>
                    {subData.SubscriptionLevel > 0 ?
                        <div className="Subscribed">
                            {subData.Recurrent && <>
                                <div><h6>Subscribed on a recurrent plan with: <span>{subData.DirectDebitProvider}</span></h6></div>
                                {
                                    subData.RemainingSubMonths > 0 ? <div><h6> {subData.RemainingSubMonths} Months left until next bill </h6></div> :""
                                }
                            </>
                            }
                            {!subData.Recurrent && <>
                                {subData.RemainingSubMonths > 0 ?
                                    <h6>Remaining Sub Months: {subData.RemainingSubMonths}</h6> 
                                    :
                                    <h6>Subscription Expiring at: {dateString}</h6>
                                }
                            </>
                            }
                            {subData.Recurrent && <Button variant="" className="btn btn-red" onClick={() => setShowCancelSubPopUp(true)}> Cancel Subscription </Button>}
                        </div>
                        :
                        ""
                    }
                </div>
                : ""
            }
        </>
    );
}

export default ProfileBanner;
