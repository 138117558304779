class AchievementsLevels {
    id: number;
    IdAchievement: number;
    Name: string;
    Description: string;
    Level: number;
    Experience: number;
    Next: number;
    Orbox: number;

    constructor(
        id: number = 0,
        IdAchievement: number = 0,
        Name: string = '',
        Description: string = '',
        Level: number = 0,
        Experience: number = 0,
        Next: number = 0,
        Orbox: number = 0,
    ) {
        this.id = id;
        this.IdAchievement = IdAchievement;
        this.Name = Name;
        this.Description = Description;
        this.Level = Level;
        this.Experience = Experience;
        this.Next = Next;
        this.Orbox = Orbox;
    }
}

export default AchievementsLevels;