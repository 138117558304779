import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AIGeneratedImage from "../../Components/AIGeneratedImage/AIGeneratedImage";
import { Col, Image, Row } from "react-bootstrap";
import { onGetImageDetail } from "../AIGeneratedImagePage/action";
import "./AIImageDetailPage.css";
import toast from "react-hot-toast";
import PopupShareContent from "../../Components/PopupModal/HeaderPopups/PopupShareContent";

function AIImageDetailPage(props) {
  const searchParam = useParams();
  const [imageInfo, setImageInfo] = useState({});
  const [promptBtnText, setPromptBtnText] = useState("Copy");

  useEffect(() => {
    onGetImageDetail(searchParam.imageId).then((resp) => {
      if (resp.status === 200) {
        setImageInfo(resp.data[0]);
      }
    });
  }, [searchParam.imageId]);

  return (
    <div className="main-content AIGeneratedPage">
      <Link className="backBTn" to="#" onClick={() => window.history.go(-1)}>
        <lord-icon
          src="https://cdn.lordicon.com/masilncv.json"
          trigger="hover"
        ></lord-icon>
      </Link>
      <div className="AIImageBox default-box mb-5">
        <Row>
          <Col lg={6}>
            <div className="AIImageBoxImg">
              <Image src={imageInfo.url} />
            </div>
          </Col>
          <Col lg={6}>
            <div className="AIImageBoxInfo">
              <h6 className="mb-0">{imageInfo?.prompt?.split("By:")[0]}</h6>
              <hr />
              <ul>
                <li>
                  Creator : <span>{imageInfo?.prompt?.split("By:")[1]}</span>
                </li>
                <li>
                  Seed : <span>{imageInfo?.seed}</span>
                </li>
                <li>
                  Height : <span>{imageInfo?.height}</span>
                </li>
                <li>
                  Tokenized on the blockchain :
                  <span className="TokenizeRadioWrap">
                    <label className="TokenizeRadioBtn">
                      <input type="radio" name="TokenizeRadioWrap" />
                      <i>Yes</i>
                    </label>
                    <label className="TokenizeRadioBtn">
                      <input type="radio" name="TokenizeRadioWrap" checked />
                      <i>No</i>
                    </label>
                  </span>
                </li>
                <li>
                  Model : <span>{imageInfo.model_name}</span>
                </li>
              </ul>
              <div className="AIBtnWrap">
                <Link
                  className="btn btn-gradient w-100"
                  to={"/CreateImage?url=" + imageInfo.url}
                >
                  <span>
                    <lord-icon
                      trigger="loop"
                      src="/images/icons/photo-editor.json"
                    ></lord-icon>
                  </span>{" "}
                  Pass into Controlnet
                </Link>
                <Link
                  className="btn btn-white"
                  to="#"
                  onClick={() => {
                    setPromptBtnText("Copied");
                    setTimeout(() => {
                      setPromptBtnText("Copy");
                    }, 2000);
                    navigator.clipboard.writeText(imageInfo?.prompt);
                  }}
                >
                  <span>
                    <lord-icon
                      trigger="loop"
                      src="/images/icons/copy.json"
                    ></lord-icon>
                  </span>{" "}
                  {promptBtnText} Prompt
                </Link>

                {/* <Link
                  className="btn btn-white"
                  to="#"
                  // onClick={() => {
                  //   toast.success("Copied!");
                  //   navigator.clipboard.writeText(window.location.href);
                  // }}
                > */}
                <PopupShareContent
                  className="btn btn-white"
                  icon={
                    <lord-icon
                      src="https://cdn.lordicon.com/liqllijy.json"
                      trigger="hover"
                      colors="outline:#121331,primary:#e1dae5"
                    ></lord-icon>
                  }
                  buttonText="Share"
                  link={imageInfo.url}
                />
                {/* Share */}
                {/* </Link> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {imageInfo?.url && (
        <AIGeneratedImage
          isSimilar={true}
          searchPrompt={imageInfo.url}
          isCheckbox={false}
        />
      )}
    </div>
  );
}

export default AIImageDetailPage;
