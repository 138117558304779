import {useEffect, useRef, useState} from "react";
import { Icon } from "@iconify/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AIGeneratedImage from "../../Components/AIGeneratedImage/AIGeneratedImage";
import { isEmpty } from "lodash";
import { Button, Image } from "react-bootstrap";
import "./AIGeneratedImagePage.css";
import {allPrompt} from "../../Utils/Constants";

const randomPrompt = allPrompt[Math.floor(Math.random() * allPrompt.length)]

function AIGeneratedImagePage(props)
{
  const searchParam = useParams();
  const navigate = useNavigate();
  let prompt = useRef(!isEmpty(searchParam) ? searchParam.searchPrompt : randomPrompt);
  const [searchPrompt, setSearchPrompt] = useState(prompt)
  const [searchString, setSearchString] = useState('')
  
  useEffect(() =>
  {
    setSearchPrompt(searchParam.searchPrompt)
    prompt.current = !isEmpty(searchParam) ? searchParam.searchPrompt : randomPrompt;
  }, [searchParam, searchParam.searchPrompt])

  const onHandleSearchChange = (event) =>
  {
    setSearchString(event.target.value);
  }

  const onSearch = () =>
  {
    setSearchPrompt(searchString);
    navigate(`/AIGeneratedImage/${searchString}`)
  }

  return (
    <div className="main-content AIGeneratedPage">
      <div className="AIGeneratedInfo mt-lg-4">
        <Link to="/" className="mb-3 d-block" style={{textAlign: 'center' }}><Image src="/images/logo.png" style={{maxWidth: '300px',Width: '100%' }} /></Link>
        <h3>
        Search and Discover the world&apos;s largest hub of AI-generated gaming and media assets, along with their prompts
        </h3>
        <div className="AIGeneratedSearch">
          <input
            type="search"
            placeholder="Search for any kind of assets from 20m+ assets"
            value={searchString}
            onChange={onHandleSearchChange}
          />
          <Link to="/">
            <Icon icon="uil:search" />
          </Link>
        </div>
        <div className="AIGeneratedBtnWrap">
          <Button className="btn btn-gradient" onClick={onSearch}>
            Search
          </Button>
          <Link to="/FactoryView/orbofi-theory"  className="btn btn-white">
            Create AI Asset
          </Link>
        </div>
      </div>
      <AIGeneratedImage searchPrompt={searchPrompt} isCheckbox={false}/>
    </div>
  );
}

export default AIGeneratedImagePage;
