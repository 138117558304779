import React from 'react';
import { Button, Dropdown, Image } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import cx from 'classnames';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { CustomConnectButtonSpaceID } from '../CustomConnectButton/CustomConnectButton';
import { useAccount, useNetwork } from 'wagmi';
import { useDispatch, useSelector } from 'react-redux';
// import SID from '@siddomains/sidjs';
// import * as SIDfunctions from '@siddomains/sidjs';
// Header file
import './Header.css';
import { SetAchievement } from '../AchievementComponents/AchievementsHandler';
import { AchievementList } from '../../Utils/Constants';
import { LoginPopUp } from '../PopupModal/HeaderPopups/LoginPopUp';
import { loginPopUpSelector, showPopUpAction } from '../../Redux/Slices/loginPopUpSlice';
import { updateUserData, userDataSelector } from '../../Redux/Slices/userDataSlice';
import User from '../../Models/User';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';
import { setTheme } from '../../Redux/Slices/themeSlice';
import {Logout} from '../../Utils/Utils';
import { useLocation } from 'react-router-dom';

function Header() {

  const dispatch = useDispatch();
  const showLoginPopUp = useSelector(loginPopUpSelector);
  const authToken = localStorage.getItem('oauthToken');

  const userData = useSelector(userDataSelector);

  const [scroll, setScroll] = useState(true);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [searchString, setSearchString] = useState('');
  const navigate = useNavigate();

  const [bnbDomain, setBnbDomain] = useState('');
  const [timer, setTimer] = useState(null);

  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const location = useLocation();

  useEffect(() => {
    dispatch(setTheme(true));
  }, []);

  // If the user is not logged in open the login pop-up after some time
  useEffect(() => {
    if (!localStorage.getItem('oauthToken'))
    {
      setTimer(setTimeout(() => {
        if (!showLoginPopUp && location.pathname !== '/ChatBots')
        {
          dispatch(showPopUpAction(true));
        }
      }, 30000));
      return () => clearTimeout(timer);
    }
  }, [authToken]);


  useEffect(() => {
    let sid;
    async function getBNBDomain(address) {
      // create the logic to fetch the .bnb name    
      const rpc = 'https://bsc-dataseed.binance.org/';
      const chainId = 56; // BNB Smart chain id

      // sid = new SID({ provider, sidAddress: SIDfunctions.getSidAddress(chainId) })

      if (chain?.id === 56 && isConnected) 
      {
        const name = await sid.getName(address);
        return name ? name.name : 'no bnb name';
      }
      else 
      {
        return null; // this means that the user is connected to another chain
      }

    }
    const fetchBnbDomain = async () => {
      const domain = await getBNBDomain(address);
      setBnbDomain(domain);
    };

    fetchBnbDomain().then(() => { });
  }, [address, chain?.id, isConnected]);

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY < 100;
      if (scrollCheck !== scroll) 
      {
        setScroll(scrollCheck);
      }
    });
  });

  // Set the user achievements upon login
  useEffect(() => {
    if (authToken)
    {
      // axios.get(
      //     // Here you got user achievement current values ordered by achievement ID from backend.
      //     import.meta.env.VITE_SERVER_ADDRESS + "getAchievements", {headers: { Authorization: `Bearer ${authToken}` }})
      //     .then((response) => {
      //         localStorage.setItem("achievementsGot", JSON.stringify(response.data));
      //     })
      //     .catch((error) => {
      //         console.log(error);
      //     });
    }
  }, [authToken]);

  const onToggleSidebar = () => {
    setToggleSidebar(!toggleSidebar);
    let ele = document.querySelector('body');
    ele.classList.toggle('show');
  };

  const onHandleSearch = (event) => {
    setSearchString(event.target.value);
  };
  const keyPress = (event) => {
    if (event.keyCode === 13) 
    {
      navigate(`/AIGeneratedImage/${searchString}`);
    }
  };

  function onOpenLoginPopup() {
    dispatch(showPopUpAction(true));
  }

  function onHandleLogout() {
    Logout();
    const userData = new User();
    dispatch(updateUserData(userData));
    navigate('/');
  }

  function onHandleAchievement()
  {
    SetAchievement(AchievementList.Share, 40, userData.Role);
  }
  const toggleDarkMode = (e) => {
    //   setIsDarkMode(!isDarkMode);
    document.body.classList.toggle('light-version');
    if (!e.target.checked)
    {
      dispatch(setTheme(true));
      localStorage.setItem('theme', 'dark');
    } else
    {
      dispatch(setTheme(false));
      localStorage.setItem('theme', 'light');
    }

  };

  useEffect(() => {
    if (location.pathname.toLowerCase().split('/')[1] !== 'chatbot')
    {
      document.body.classList.remove('light-version');
    }
  }, [location.pathname]);
  return (
    <header className={!scroll ? 'fixed' : ''}>
      <div className="logo_navbarFixed">
        <Link to="/" className="navbar-brand">
          <Image src="/images/logo.png" className="dark-logo" />
          <Image src="/images/logo-light.png" className="light-logo" />
        </Link>
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            id="dropdown-basic"
            className="btn default-btn btn-white-border no-wrap"
          >
                        About
          </Dropdown.Toggle>

          <Dropdown.Menu className="DropDownMenu">
            <a target="_blank" href="https://about.orbofi.com/" rel="noreferrer">
                            Learn about Orbofi
            </a>
            <a onClick={() => onHandleAchievement()} target="_blank" href="http://orbofi.medium.com/" rel="noreferrer">
                            Blog
            </a>
            <a target="_blank" href="https://wiki.orbofi.com/learn/intro/readme" rel="noreferrer">
                            Documentation
            </a>
            <ul className="dropdownSocial">
              <li>
                <a target="_blank" href="https://twitter.com/Orbofi" rel="noreferrer">
                  <Icon icon="ri:twitter-fill" />
                </a>
              </li>
              <li>
                <a target="_blank" href="https://discord.com/invite/orbofi" rel="noreferrer">
                  <Icon icon="ic:round-discord" />
                </a>
              </li>
              <li>
                <a target="_blank" href="https://t.me/orbofi" rel="noreferrer">
                  <Icon icon="ic:round-telegram" />
                </a>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* <h6 className="searchIcon-main chatbotheadertitle">Chatbot</h6> */}

      {(location.pathname.toLowerCase().split('/')[1] === 'chatbot' || location.pathname.toLowerCase().split('/')[1] === 'createchatbot') && <h6 className="searchIcon-main chatbotheadertitle">Chatbot</h6>}
      {location.pathname.toLowerCase().split('/')[1] !== 'chatbot' && location.pathname.toLowerCase().split('/')[1] !== 'createchatbot' && <div className="searchIcon-main">
        <div className="header-searchbar">
          <input
            type="search"
            placeholder="Search for any gaming, metaverse, and media asset"
            onChange={onHandleSearch}
            onKeyDown={keyPress}
          />
          <Link to={`/AIGeneratedImage/${searchString}`}>
            <lord-icon
              trigger="hover"
              src="/images/icons/magnifier.json"
            ></lord-icon>
          </Link>
        </div>
        <Link to="/" className="default-btn btn-header-icon">
          <lord-icon
            trigger="hover"
            src="/images/icons/notification.json"
          ></lord-icon>
        </Link>
      </div>}
      <div className="header-wrap">
        {authToken ?
          <Link to="/Pricing" className="BtnOrboxAdd btn btn-white me-3">
            <span><Icon icon="fe:plus" /> </span>
            <i>{userData?.Orbox + userData?.PremiumOrbox} <small>Orbox</small><Image src="/images/OrboxIcon.jpg" /></i>
          </Link>
          : ''}
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            id="dropdown-basic"
            className="btn CreatBtn btn-white no-wrap me-3"
          >
                        Create
          </Dropdown.Toggle>

          <Dropdown.Menu className="DropDownMenu">
            <Link to="/CreateChatbot">
              <lord-icon
                src="https://cdn.lordicon.com/xzksbhzh.json"
                trigger="hover"
                colors="primary:#38418b,secondary:#08a88a">
              </lord-icon>
                            Create AI  companions</Link>
            <Link to="/FactoryView/orbofi-theory">
              <lord-icon
                src="https://cdn.lordicon.com/ifanxrve.json"
                trigger="hover"
                colors="primary:#3a3347,secondary:#f24c00,tertiary:#2ca58d,quaternary:#f9c9c0,quinary:#ebe6ef">
              </lord-icon>
                            Create AI asset</Link>
            <Link to="/Factory">
              <lord-icon
                src="https://cdn.lordicon.com/xzksbhzh.json"
                trigger="hover"
                colors="primary:#08a88a,secondary:#ebe6ef">
              </lord-icon>
                            Create AI model</Link>
            <Link to="/CreateAiModel">
              <lord-icon
                src="https://cdn.lordicon.com/vhdevsqp.json"
                trigger="hover"
                colors="primary:#ffc738,secondary:#4bb3fd">
              </lord-icon>Upload AI model </Link>
          </Dropdown.Menu>
        </Dropdown>
        {!authToken ? (
          <Link to="#" className="btn btn-white" onClick={onOpenLoginPopup}>
                        Login
          </Link>
        ) : (
          <>
            <Dropdown>
              <Dropdown.Toggle
                variant=""
                id="dropdown-basic"
                className="profileDropToggle"
              >
                <div className="header-profile-wrap">
                  <span>
                    <ProfilePicture />
                  </span>
                  <h6>
                    <b>{userData?.Name ? userData?.Name : 'User Name'}{' '}</b>
                  </h6>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="ProFileDropDown">
                <CustomConnectButtonSpaceID bnbDomain={bnbDomain} />
                <Dropdown.Divider />
                <Dropdown.Item href={`/profile/${userData?.Name}`}>My Profile</Dropdown.Item>
                <Dropdown.Item href="/MyCreations">My Creations</Dropdown.Item>
                {/*This show the my achievement section only if you have an admin role because is currently under deployment*/}
                {userData?.Role === 'admin' ? <Dropdown.Item href="/MyAchievements">My Achievements</Dropdown.Item> : ''}
                {userData?.Role === 'admin' ? <Dropdown.Item href="/CoImage">Co Creation</Dropdown.Item> : ''}
                <Dropdown.Item href="/MyFactories">My Factories</Dropdown.Item>
                {userData?.Role === 'admin' ? <Dropdown.Item href="/AdminPanel">Admin Panel</Dropdown.Item> : ''}
                <Dropdown.Item href="#/action-3">
                                    My Community Orbs (soon){' '}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="Logout" href="#" onClick={onHandleLogout}>
                                    Log out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}

        {/* <div className="">
                    <span className="">
                        <lord-icon src="https://cdn.lordicon.com/pzuyobdc.json" trigger="loop" colors="outline:#121331,primary:#ffc738" ></lord-icon>
                    </span>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        onChange={toggleDarkMode}
                    />
                    <span className="">
                        <lord-icon src="https://cdn.lordicon.com/immloges.json" trigger="loop" colors="primary:#ebe6ef,secondary:#ffc738,tertiary:#121331"></lord-icon>
                    </span>
                </div> */}
      </div>
      {(location.pathname.toLowerCase().split('/')[1] === 'chatbot' || location.pathname.toLowerCase().split('/')[1] === 'createchatbot') &&
                <div className="switchDarkLight">
                  <div className="switch">
                    <input type="checkbox" className="switch__input" id="Switch" onChange={toggleDarkMode} />
                    <label className="switch__label" htmlFor="Switch">
                      <span className="switch__decoration"></span>
                      <span className="switch__indicator"></span>
                    </label>
                  </div>
                </div>
      }
      <Button
        variant=""
        className={cx('menuIcon ms-auto', { active: toggleSidebar })}
        onClick={onToggleSidebar}
      >
        <span></span>
      </Button>
      <div className="mobile-menu">
        {authToken && (
          <>
            <div className="btnOrboxMobileWrap">
              {authToken ?
                <Link to="/Pricing" className="BtnOrboxAdd btn btn-white me-3">
                  <span><Icon icon="fe:plus" /> </span>
                  <i>{userData?.Orbox + userData?.PremiumOrbox} <small>Orbox</small><Image src="/images/OrboxIcon.jpg" /></i>
                </Link>
                : ''}
              {/* <ConnectButton /> */}
            </div>
            <hr />
            <div className="header-profile-wrap mb-3">
              <span>
                <Image
                  src={
                    userData?.ProfilePicture
                      ? userData?.ProfilePicture
                      : '/images/profile-icon.png'
                  }
                  alt="Ex."
                />
              </span>
              <h6>
                {userData?.Name ? userData?.Name : 'User Name'}{' '}
                <small>
                  <i>
                    <Image src="/images/logo-icon.png" />
                  </i>{' '}
                                    Orbox
                </small>{' '}
              </h6>
            </div>
            <Link to={`/profile/${userData?.Name}`}>My Profile</Link>
            <Link to='/FactoryView/orbofi-theory'>Create a Character</Link>
            {userData?.Role === 'admin' ? <Link to='/MyAchievements'>My Achievements</Link> : ''}
            {userData?.Role === 'admin' ? <Link to='/AdminPanel'>Admin Panel</Link> : ''}
            <Link to='/MyCreations'>My Creations</Link>
            <Link to='/MyFactories'>My Factories</Link>
            <Link to="/Factory">Create </Link>
            <Link to="/FactoryView/orbofi-theory">Create AI asset</Link>
            <Link to="/CreateAiModel">Upload AI model </Link>
            <hr />
          </>
        )}
        <h6>About</h6>
        <a href="https://about.orbofi.com/" target="_blank" rel="noreferrer">
          <i>
            <lord-icon trigger="loop" src="/images/icons/idea.json"></lord-icon>
          </i>
                    Learn about Orbofi
        </a>
        <a href="https://orbofi.medium.com/" target="_blank" rel="noreferrer">
          <i>
            <lord-icon trigger="loop" src="/images/icons/blog.json"></lord-icon>
          </i>
                    Blog
        </a>
        <a target="_blank" href="https://wiki.orbofi.com/learn/intro/readme" rel="noreferrer">
          <i>
            <lord-icon trigger="loop" src="/images/icons/Documentation.json"></lord-icon>
          </i>
                    Documentation
        </a>
        <hr />
        {!authToken ? (
          <Link to="#" className="btn btn-white " onClick={onOpenLoginPopup}>
                        Login
          </Link>
        ) : (
          <Link className="Logout" href="#" onClick={onHandleLogout}>
                        Log out
          </Link>
        )}
        <LoginPopUp />
      </div>
    </header>
  );
}

export default Header;