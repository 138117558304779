import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    onGettingAllAiGeneratedImages,
    onGettingAllSimilarImages,
} from "./action";
import "./AIGeneratedImage.css";
import { toast } from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import { Icon } from "@iconify/react";
import { Defer } from "react-progressive-loader";
import PopupModal from "../PopupModal/PopupModal";
import { Image } from "react-bootstrap";
import PopupShareContent from "../PopupModal/HeaderPopups/PopupShareContent";

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

let imageArray = [];

function AIGeneratedImage(props) {
    const [selectedImageUrl, setSelectedImageUrl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [, setWindowSize] = useState(getWindowSize());
    const [allAiGeneratedImages, setAllAiGeneratedImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const itemsPerPage = 30;
    const [hasMore] = useState(true);
    const [records, setrecords] = useState(itemsPerPage);
    const [page, setPage] = useState(0);
    const [, setGuidanceScale] = useState(7);
    const [, setSteps] = useState(30);
    const [, setNumImage] = useState(5);
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const getAiGeneratedImage = useCallback(
        (prompt, pageNo, allImages) => {
            if (props?.isSimilar) {
                onGettingAllSimilarImages(prompt, pageNo).then((resp) => {
                    if (resp.status === 200) {
                        imageArray = allImages.length > 0 ? [...allImages] : [];
                        let images = [...allImages, ...resp.data];
                        // eslint-disable-next-line array-callback-return
                        resp.data.map((img) => {
                            imageArray.push(img);
                        });
                        setAllAiGeneratedImages(images);
                        setrecords(images.length);
                        setIsLoading(false);
                    }
                });
            } else {
                onGettingAllAiGeneratedImages(prompt, pageNo).then((resp) => {
                    if (resp.status === 200) {
                        imageArray = allImages.length > 0 ? [...allImages] : [];
                        let images = [...allImages, ...resp.data];
                        // eslint-disable-next-line array-callback-return
                        resp.data.map((img) => {
                            imageArray.push(img);
                        });
                        setAllAiGeneratedImages(images);
                        setrecords(images.length);
                        setIsLoading(false);
                    }
                });
            }
        },
        [props?.isSimilar]
    );

    useEffect(() => {
        const prompt = props.searchPrompt;
        setIsLoading(true);
        const pageNo = 0;
        getAiGeneratedImage(prompt, pageNo, []);
    }, [getAiGeneratedImage, props.searchPrompt]);

    const loadMore = () => {
        const prompt = props.searchPrompt;
        const pageNo = page + 1;
        setPage(pageNo);
        getAiGeneratedImage(prompt, pageNo, allAiGeneratedImages);
    };

    // eslint-disable-next-line no-unused-vars
    const handleGuidanceScale = (value) => {
        setGuidanceScale(value);
    };
    // eslint-disable-next-line no-unused-vars
    const handleSteps = (value) => {
        setSteps(value);
    };
    // eslint-disable-next-line no-unused-vars
    const handleNumImage = (value) => {
        setNumImage(value);
    };

    return (
        <>
            <div className="AIGeneratedImage-section">
                {!props.isCheckbox && (
                    <h2 className="main-title">
                        <lord-icon
                            trigger="loop"
                            src="/images/icons/telescope.json"
                        ></lord-icon>{" "}
            Search for AI-generated gaming and media assets, and their prompts
                        <span>
              Search and Discover the world&apos;s largest hub of AI-generated
              gaming and media assets, along with their prompts
                        </span>
                    </h2>
                )}
                {!isLoading ? (
                    <div id="scrollableDiv">
                        <InfiniteScroll
                            dataLength={records}
                            next={() => loadMore()}
                            hasMore={hasMore}
                            loader={
                                <div className="AiImageLoader">
                                    <lord-icon
                                        trigger="loop"
                                        src="/images/icons/loader.json"
                                    ></lord-icon>
                                </div>
                            }
                        >
                            {imageArray &&
                imageArray.map((image, index) =>
                    !props.isCheckbox ? (
                        <figure className="AIGenerated-img" key={index}>
                            <Link className="AI_Img" to={`/image/${image?.uuid}`}>
                                <Defer
                                    render={() => (
                                        <img
                                            className="easeload"
                                            src={image.url}
                                            alt={image.prompt}
                                            loadOnScreen={true}
                                        />
                                    )}
                                    renderPlaceholder={() => (
                                        <div>
                                            <img src="/images/Default-image.png" alt={""} />
                                        </div>
                                    )}
                                />
                            </Link>
                            <ul className="AIGenerated-buttons">
                                <li>
                                    <PopupShareContent
                                        icon={
                                            <lord-icon
                                                src="https://cdn.lordicon.com/liqllijy.json"
                                                trigger="hover"
                                                colors="outline:#121331,primary:#ffffff"
                                            ></lord-icon>
                                        }
                                        link={image.url}
                                    />
                                </li>
                                <li>
                                    <Link to="#">
                                        <lord-icon
                                            trigger="click"
                                            src="/images/icons/hifi.json"
                                        ></lord-icon>
                                    </Link>
                                </li>
                                <li>
                                    <a data-fancybox="gallery" href={image.url}>
                                        <lord-icon
                                            trigger="hover"
                                            src="/images/icons/search.json"
                                        ></lord-icon>
                                    </a>
                                </li>
                            </ul>
                            <div className="AIGenerated-Info">
                                <h6>
                                    <span>
                                        <lord-icon
                                            trigger="hover"
                                            src="/images/icons/eye.json"
                                        ></lord-icon>{" "}
                                        {image?.seed}
                                    </span>{" "}
                                </h6>
                                <Link
                                    to={`/image/${image?.uuid}`}
                                    className="text-white"
                                >
                                    <p>{image?.prompt}</p>
                                </Link>
                            </div>
                        </figure>
                    ) : (
                        <div className="ImgLibraryItem" key={index}>
                            <div
                                className="AIGenerated-img"
                                key={index}
                                onClick={() => openModal(image)}
                            >
                                <Image src={image} alt="" className="img-fluid" />
                            </div>
                            <label className="ImgCheckBox" htmlFor={image.url}>
                                <input
                                    type="checkbox"
                                    id={image.url}
                                    checked={image.isChecked}
                                    onClick={() => props.onSelectImages(image.url, index)}
                                />
                                <span>
                                    <Icon icon="mdi:tick" />
                                </span>
                            </label>
                        </div>
                    )
                )}
                        </InfiniteScroll>
                        <PopupModal
                            className="popupShowImage"
                            show={isModalOpen}
                            handleClose={closeModal}
                        >
                            <div className="popupModalImage">
                                <Image src={selectedImageUrl} alt="" />
                            </div>
                        </PopupModal>
                    </div>
                ) : (
                    <div className="text-center">
                        <lord-icon
                            trigger="loop"
                            src="/images/icons/loader.json"
                        ></lord-icon>
                    </div>
                )}
            </div>
        </>
    );
}

export default AIGeneratedImage;
