import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { collection, onSnapshot } from "@firebase/firestore";
import "./UserCreations.css";
import PopupModal from "../../Components/PopupModal/PopupModal";
import { Image } from "react-bootstrap";
import {getFirestoreInstance} from "../../Utils/Utils";

function MyCreations({ mainTitle, user, isPublicProfile }) {
    const [selectedImageUrl, setSelectedImageUrl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (imageUrl) =>
    {
        setSelectedImageUrl(imageUrl);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const [factoryList, setFactoryList] = useState([]);

    const db = getFirestoreInstance();
    useEffect(() => {
        if(user === "")
        {
            return;
        }
        const colRef = collection(db, "Orbox/" +  user + "/generations");
        return onSnapshot(colRef, {
            next: (snapshot) => {
                const lst = snapshot.docs.map((doc) => ({
                    img: doc.data()["image_url"],
                    numGeneration: doc.data()["numImage"],
                }));
                setFactoryList(lst);
            },
            error: (err) => {
                // TODO: handle error (signed out? no permission? no connection?)
            },
        });
    }, [user]);
    return (
        <div className={`${mainTitle ? "main-content" : ""} home-page`}>
            {mainTitle && (
                <div className="FactoryTitles mb-4">
                    <h2>My Generations</h2>
                </div>
            )}
            <div>
                {factoryList.length > 0 ? (
                    <>
                        {
                            <div className="infinite-scroll-component ">
                                {factoryList.length > 0 &&
                  factoryList?.map((image, index) => (
                      <div
                          className="AIGenerated-img"
                          key={index}
                          onClick={() => openModal(image.img)}
                      >
                          <Image src={image.img} alt="" />
                      </div>
                  ))}
                                <PopupModal show={isModalOpen} handleClose={closeModal} className="popupModalImageAIasset">
                                    <div className="popupModalImage">
                                        <Image src={selectedImageUrl} alt="" />
                                    </div>
                                </PopupModal>
                            </div>
                        }
                    </>
                ) : (
                    <>
                        {
                            <div className="FactoryTitles">
                                <h2 style={{ fontSize: "16px" }}>😭 No Generations 😭</h2>
                                { isPublicProfile? "" :
                                    <Link to="/Factory" className="CreatBtn btn btn-white me-3">
                                    Generate Art
                                    </Link>
                                }
                            </div>
                        }
                    </>
                )}
            </div>
        </div>
    );
}

export default MyCreations;
