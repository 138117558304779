import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import ProfileInfo from "../../Components/ProfileInfo/ProfileInfo";
import ProfileMetaverDetail from "../../Components/ProfileMetaverDetail/ProfileMetaverDetail";
import withLoader from "../../Utils/withLoader";
import { Toaster } from "react-hot-toast";
import "./MetaversePage.css";
import {useSelector} from "react-redux";
import {userDataSelector} from "../../Redux/Slices/userDataSlice";

//This is the page that opens when we launch the metaverse game not the profile one, is missnamed
function MetaversePage()
{
    const authToken = localStorage.getItem("oauthToken");
    let [data,] = useState({});


    const randomString = (length, chars) =>
    {
        let result = "";
        for (let i = length; i > 0; --i)
        {
            result += chars[Math.round(Math.random() * (chars.length - 1))];
        }
        return result;
    };

    const onRedirectToMetaverse = () =>
    {
        const userData = useSelector(userDataSelector);
        window.open(userData ? `https://metaverse.orbofi.com/index.html?user=${userData?.Name}&token=${userData?.nakamaToken ? userData?.nakamaToken : randomString(32, "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ")}` : "https://metaverse.orbofi.com/", "_blank");
    };

    return (
        <div className="main-content">
            <Row>
                <Col xl={6}>
                    <ProfileInfo profileData={data}/>
                </Col>
                <Col xl={6}>
                    <div className="profile-main default-box mb-4">
                        <ProfileMetaverDetail profileData={data} />
                    </div>
                    <div className="metaverse-graphics-main default-box">
                        <div className="metaverse-graphics">
                            <iframe
                                title="Orbofi Metaverse Full Map - Chapter 1 - copy 1"
                                frameBorder="0"
                                allowFullScreen
                                mozallowfullscreen="true"
                                webkitallowfullscreen="true"
                                allow="autoplay; fullscreen; xr-spatial-tracking"
                                src="https://sketchfab.com/models/e0b2678532924c4284d22663c11ec67f/embed?autospin=1&autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_watermark_link=0&ui_watermark=0&ui_help=0&ui_settings=0&ui_fullscreen=0&ui_annotations=0"
                            >
                                {" "}
                            </iframe>
                        </div>
                        <Button variant=""
                            disabled={!authToken}
                            onClick={onRedirectToMetaverse}
                            className="btn btn-gradient mt-3"
                        >
                Go to Metaverse
                        </Button> 
             
                    </div>
                </Col>
            </Row>
            <Toaster position="bottom-right" />
        </div>
    );
}

export default withLoader(MetaversePage);