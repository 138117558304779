import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const VerifyAuth = () =>
{
    const [searchParam] = useSearchParams();
    const token = searchParam.get('oauthToken')
    const navigate = useNavigate();
    useEffect(() =>
    {
        navigate('/')
    }, [token, navigate])
}

export default VerifyAuth;