import axios from "axios";

const FeedUrl = import.meta.env.VITE_FEED_API_URL;
const Token = `Bearer ${localStorage.getItem("token")}`;

const Axios = axios.create({
    baseURL: FeedUrl,
});

export const setAuthHeader = (token) => {
    Axios.defaults.headers.common.Authorization =
        token || Token;
};

Axios.defaults.headers.common.Authorization = Token;

//if a 401 happens, the user will be logged out
Axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error && error.response && error.response.status === 401)
        {
            localStorage.removeItem("token");
        }
        return Promise.reject(error);
    }
);

// Set the initial header from storage or something (should surround with try catch in actual app)
setAuthHeader(Token);

export default Axios;
