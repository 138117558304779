import Axios from "../../AxiosApi";

export const onGetImageDetail = (uuid) => {
    return Axios({
      method: "get",
      url: `image?uuid=${uuid}`,
    })
      .then((response) =>
      {
        return response;
      })
      .catch((err) =>
      {
        return err.response;
      });
  };
