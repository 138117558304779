import CreateFormOneModel from "../../Components/CreateAiModelForm/CreateFormoneModel/CreateFormOneModel";
import CreateFormSecondModel from "../../Components/CreateAiModelForm/CreateFormoneModel/CreateFormSecondModel";
import WizardForm from "react-wizard-form";
import "./CreateAiModel.css";

function CreateAiModel() {
    
  return (
    <div className="main-content">
        <WizardForm>
        <CreateFormOneModel />
        <CreateFormSecondModel />
        </WizardForm>
    </div>
  );
}

export default CreateAiModel;
