import React, { useEffect, useState } from "react";
import { Button, Col, Image, Nav, Row, Tab } from "react-bootstrap";
import "./PricingPage.css";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OrboxPackageCard from "./InternalComponents/OrboxPackageCard";
import SubscriptionCard from "./InternalComponents/SubscriptionCard";
import PremiumItemCard from "./InternalComponents/PremiumItemCard";
import Products from "../../Models/Products";
import OrderData from "../../Models/OrdersData";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import ObiTokenPaymentPage from "./ObiTokenPaymentPage";
import {subDataSelector} from "../../Redux/Slices/subscriptionDataSlice";
import {useSelector} from "react-redux";

function PricingPage() {

    const [productsData, setProductsData] = useState<Array<Products>>([]);
    const [subsData, setSubsData] = useState<Array<Products>>([]);
    const [orboxProductData, setOrboxProductData] = useState<Array<Products>>([]);
    const [obiTokenPrice, setObitTokenPrice] = useState(0);
    const [loadingCheckoutPage, setLoadingCheckoutPage] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState<object | undefined>({});
    const [key, setKey] = useState("second");
    const [isTransit, setIsTransit] = useState(false);
    const currentSub = useSelector(subDataSelector);

    function CreateProductOrder(provider: string, productId: number, level: number, recurrent: boolean, quantity: number = 1) {
        setLoadingCheckoutPage(true);
        const dataToSend = new OrderData();
        dataToSend.productId = productId;
        dataToSend.provider = provider;
        dataToSend.level = level;
        dataToSend.quantity = quantity;
        dataToSend.recurrent = recurrent;

        const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
        axios.post(import.meta.env.VITE_SERVER_ADDRESS + "payment/" + provider, dataToSend, config).then((resp) => {
            if (provider !== "obitoken")
            {
                window.location.href = resp.data;
                document.body.classList.remove("showCheckout");
            } else
            {
                setIsModalOpen(true);
                setIsTransit(true);
                setTimeout(() => {
                    setIsTransit(false);
                }, 400);
            }
            setLoadingCheckoutPage(false);
        })
            .catch((error) => { console.log({ error }); setLoadingCheckoutPage(false); });
    }


    function SetProducts(products: Products[])
    {
        if (products.length > 0)
        {
            const subscriptionProducts = products.filter(
                (product) => product.Category === "Subscription"
            );

            // Split the features string into an array for each subscription product
            subscriptionProducts.forEach(product =>
            {
                if (product.Description)
                {
                    product.FeaturesArray = product.Description.split("/");
                }
            });

            const orboxProducts = products.filter(
                (product) => product.Category === "Orbox"
            );

            const otherProducts = products.filter(
                (product) =>
                    product.Category !== "Subscription" &&
                    product.Category !== "Orbox"
            );

            // Split the features string into an array for each other product
            otherProducts.forEach(product =>
            {
                if (product.Description)
                {
                    product.FeaturesArray = product.Description.split("/");
                }
            });

            setSubsData(subscriptionProducts);
            setOrboxProductData(orboxProducts);
            setProductsData(otherProducts);
            setProductsLoaded(true);
        }
    }

    useEffect(() => {
        let products : Products[] = [];
        if(localStorage.getItem("oauthToken"))
        {
            const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
            axios.get(import.meta.env.VITE_SERVER_ADDRESS + "getStoreProducts", config).then((resp) =>
            {
                products = resp?.data as Array<Products>;
                SetProducts(products);
            });
        }
        else
        {
            axios.get(import.meta.env.VITE_SERVER_ADDRESS + "getPublicStoreProducts").then((resp) =>
            {
                products = resp?.data as Array<Products>;
                SetProducts(products);
            });
        }
    }, []);

    useEffect(() => {
        axios("https://api.coingecko.com/api/v3/simple/price?ids=orbofi-ai&vs_currencies=usd").then((resp) => {
            setObitTokenPrice(resp.data["orbofi-ai"]["usd"]);
        })
            .catch(console.error);
    }, []);


    const [paymentMethod, setPaymentMethod] = useState("stripe");
    const [productsLoaded, setProductsLoaded] = useState(false);


    const closeModal = () =>
    {
        setIsTransit(true);
        setTimeout(() =>
        {
            setIsModalOpen(false);
            setIsTransit(false);
        }, 500);
    };
    const closePaymentModal = () => {
        document.body.classList.remove("showCheckout");
        setSelectedPlan({});
        setPaymentMethod("stripe");
    };

    const proceedForPayment = (data: any) => {
        document.body.classList.add("showCheckout");
        setSelectedPlan(data);
    };
    return (
        <>
            <div className="main-content Pricing-page">
                <ToastContainer position="bottom-right" />
                
                <div>
                    <h3>Pick a plan that’s  right for you</h3>
                    <p>Get the right plan for your business. Plan can be upgraded in the future.</p>
                    <Tab.Container id="left-tabs-example" activeKey={key} onSelect={(k: any) => setKey(k)} defaultActiveKey="second">
                        <Nav variant="pills" className="custom-navs pricing-navs">
                            <Nav.Item>
                                <Nav.Link eventKey="second">Subscription</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="first">Orbox</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third">Premium Products</Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div className="prcingboxs">
                                    <Row>
                                        {productsLoaded ?
                                            orboxProductData.map((priceCard, index) => {
                                                let tokenPrice: number;
                                                if (paymentMethod === "obitoken" && obiTokenPrice !== 0)
                                                {
                                                    tokenPrice = priceCard.Price / obiTokenPrice;
                                                } else
                                                {
                                                    tokenPrice = 0;
                                                }
                                                return (
                                                    <Col xl={4} lg={6} md={6} sm={6} key={index} className="mb-4">
                                                        <OrboxPackageCard data={{
                                                            numOrbox: priceCard.OrboxPremiumRewarded,
                                                            price: priceCard.Price,
                                                            obiPrice: tokenPrice
                                                        }} 
                                                        proceedForPayment={() => proceedForPayment(priceCard)}
                                                        />
                                                    </Col>
                                                );
                                            })
                                            : ""
                                        }
                                    </Row>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <div className="prcingboxs">
                                    <Row>
                                        
                                        { currentSub.SubscriptionLevel > 0 ? 
                                            <h6>You are already subscribed, enjoy your subscription</h6>
                                            :
                                            productsLoaded ?
                                                subsData.map((priceCard, index) => {
                                                    let tokenPrice: number;
                                                    if (paymentMethod === "obitoken" && obiTokenPrice !== 0)
                                                    {
                                                        tokenPrice = priceCard.Price / obiTokenPrice;
                                                    } else
                                                    {
                                                        tokenPrice = 0;
                                                    }
                                                    return (
                                                        <Col xl={3} lg={6} md={6} sm={6} key={index}><SubscriptionCard data={{ subName: priceCard.Name, price: priceCard.Price, obiPrice: tokenPrice, featuresIncluded: priceCard.FeaturesArray }} proceedForPayment={() => proceedForPayment(priceCard)} /></Col>
                                                    );
                                                })
                                                : ""
                                        }
                                    </Row>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <div className="prcingboxs">
                                    <Row>
                                        {/*Get all the info on the available products and show them here*/}
                                        {productsLoaded ?
                                            productsData.map((productData, index) => {
                                                let tokenPrice: number;
                                                if (paymentMethod === "obitoken" && obiTokenPrice !== 0)
                                                {
                                                    tokenPrice = productData.Price / obiTokenPrice;
                                                } else
                                                {
                                                    tokenPrice = 0;
                                                }
                                                return (
                                                    <Col xl={4} lg={6} md={6} sm={6} key={index} className="mb-4"><PremiumItemCard data={{ subName: productData.Name, price: productData.Price, obiPrice: tokenPrice, featuresIncluded: productData.FeaturesArray }} proceedForPayment={() => proceedForPayment(productData)} /></Col>
                                                );
                                            })
                                            : ""
                                        }
                                    </Row>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
                   
                
                
            </div>
            <div className="paymentSidebar">            
                {!loadingCheckoutPage ?
                    <>
                        <div className="CheckoutTitle">
                            <h5 className="factoryListTitle">Checkout Process </h5>
                            <Link to="#" onClick={closePaymentModal}><Icon icon="ion:close-circle" color="white" /></Link>
                        </div>
                        <hr />
                        {!isModalOpen ? <>
                            <div className="CheckoutInfo">
                                <i><Image src="/images/product-dummy-img.png" /></i>
                                <h4><small>{key === "first" ? "Orbox" : key === "second" ? "Subscription" : "Premium Products"}</small> <span>{selectedPlan?.Name}</span></h4>
                                <p className="ms-auto text-end mb-0"> <span>{paymentMethod === "obitoken" && obiTokenPrice !== 0 ? `${(selectedPlan?.Price / obiTokenPrice).toFixed(2) + "  Tokens"}` : `$${selectedPlan?.Price}`}</span></p>
                            </div>
                            <hr />
                            <h6 className="paymentOptionLabel">Choose Payment Method </h6>
                            <div className="PaymentSelectOption">
                                <Button variant="" className={` btn-border-white ${paymentMethod === "stripe" ? "active" : ""}`} onClick={() => setPaymentMethod("stripe")}><i><Icon icon="ion:card" color="#4b9fff" /> </i>Card Pay</Button>
                                <Button variant="" className={` btn-border-white ${paymentMethod === "obitoken" ? "active" : ""}`} onClick={() => setPaymentMethod("obitoken")}><i><Image src="/images/orbofiToken.svg" /></i>OBI Tokens</Button>
                                <Button disabled={true} variant="" className={` btn-border-white ${paymentMethod === "binance" ? "active" : ""}`} onClick={() => setPaymentMethod("binance")}><i><Icon icon="simple-icons:binance" color="#F0B90B" /></i>Binance (Coming Soon)</Button>
                            </div>
                            {paymentMethod === "stripe" && (
                                <div className="stripCardDetails">
                                    <span><Image src="/images/cards-pay.png" /></span>
                                </div>
                            )}
                            <hr className="mt-auto" />
                            <h6 className="sub-total">Total <small>{paymentMethod === "obitoken" && obiTokenPrice !== 0 ? `${(selectedPlan?.Price / obiTokenPrice).toFixed(2) + "  Tokens"}` : `$${selectedPlan?.Price}`}</small> </h6>
                            <hr />
                            {paymentMethod && <Button className={`btn-payment ${paymentMethod === "obitoken" ? "btn-OBI" : paymentMethod === "stripe" ? "btn-fiat" : "btn-Binance"}`} variant="" onClick={() => CreateProductOrder(paymentMethod, selectedPlan?.ProductId, selectedPlan?.Level, key==="second")}>{paymentMethod === "obitoken" ? <><span><Image src="/images/orbofiToken.svg" /> OBI Pay</span><i><Icon icon="cil:arrow-right" /></i></> : paymentMethod === "stripe" ? <><span><Icon icon="ion:card" />Pay Now</span><i><Icon icon="cil:arrow-right" /></i></> : <><span><Icon icon="simple-icons:binance" /> Binance Pay</span> <i><Icon icon="cil:arrow-right" /></i></>  }</Button>}
                        </> : <ObiTokenPaymentPage isModalOpen={isModalOpen} closeModal={closeModal} isTransit={isTransit}/>}
                    
                    </> :  
                    <div className="loader-container loader-bg">
                        <div className="spinner">
                            <lord-icon
                                src="https://cdn.lordicon.com/zuhwwskw.json"
                                colors="primary:#36e899,secondary:#ffffff"
                                trigger="loop">
                            </lord-icon>
                        </div>
                    </div>}
                <p className="terms-text">By placing your order, you agree to our <Link to="#">Terms and Conditions</Link>.</p>
                {/* {key === 'second' && paymentMethod === "stripe" ? (
                    <>
                        <hr />
                        <h6 className="paymentOptionLabel">Select above option</h6>
                        <div className="oneOffWrap">
                            <Button variant="" type="button"
                                className={` ${!recurring ? "active btn-white" : "btn-border-white"}`}
                                onClick={() => setRecurring(false)}> One Off</Button>
                            <Button variant="" type="button"
                                className={`${recurring ? "active btn-white" : "btn-border-white"}`}
                                onClick={() => setRecurring(true)}> Recurring</Button>

                        </div></>
                ) : ""} */}
                
            </div>
        </>
    );
}

export default PricingPage;