import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, Form, Image, Row, Modal } from "react-bootstrap";
import Select from 'react-select';
import '../CreateAiModelForm.css'
import Dropzone from "react-dropzone";
import { Icon } from "@iconify/react";
import Resumable from 'resumablejs';
import { getStorage } from "firebase/storage";
import { setDoc } from "@firebase/firestore";
import { doc } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
    ref,
    uploadBytesResumable,
    getDownloadURL
} from "firebase/storage";
import {useSelector} from "react-redux";
import {userDataSelector} from "../../../Redux/Slices/userDataSlice";

import { useNavigate } from 'react-router-dom';
import {getFirestoreInstance} from "../../../Utils/Utils";

const options = [
    { value: 'Euler a', label: 'Euler a' },
    { value: 'Euler', label: 'Euler' },
    { value: 'DPM++ 2M Karras', label: 'DPM++ 2M Karras' },
    { value: 'DDIM', label: 'DDIM' },
    { value: 'PLMS', label: 'PLMS' },
    { value: 'Heun', label: 'Heun' },
    { value: 'LMS', label: 'LMS' },

    // { value: 'vanilla', label: 'Vanilla' }
]


const CreateFormSecondModel = (props) => {
    const [allImages, setAllImages] = useState([]);
    const [imgsSrc, setImgsSrc] = useState([]);
    const [, setUploading] = useState(false);
    const [percent, setPercent] = useState(0);
    const [, setbase64cover] = useState(null);
    const [uploadedCoverURL, setUploadedURL] = useState("");

    const navigate = useNavigate();

    const userData = useSelector(userDataSelector);
    const [countDown, setCountDown] = React.useState(0);
    const [runTimer, setRunTimer] = React.useState(false);
    const [timerRate,] = useState(4000);

    React.useEffect(() => {
        let timerId;

        if (runTimer) {
            setCountDown(0);
            timerId = setInterval(() => {
                setCountDown((countDown) => countDown + 1);
            }, timerRate);
        }
        else {
            clearInterval(timerId);
        }

        return () => clearInterval(timerId);
    }, [runTimer, timerRate]);

    //when countdown reaches > 99, model is finished uploading, redirect user to model page for inference

    React.useEffect(() => {
        if (countDown > 99 && runTimer) {
            setRunTimer(false);
            setCountDown(0);
            navigate('/FactoryView/' + (props['data']['modelName']).replace(/ /g, "-"));
            // window.open('https://www.orbofi.com/FactoryView/' + (props['data']['modelName']).replace(/ /g, "-"));
        }
    }, [countDown, runTimer]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const [model, setModel] = useState('Euler a');

    const handleModelChange = (event) => {
        setModel(event.target.value);
    };

    const [numFactory, setNumFactory] = useState(0);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function uploadImage(uploadedImage) {
        // Create a root reference to the firebase storage
        let storage = getStorage();
        let storageRef = ref(storage, `images/${uploadedImage[0].name}`); // change 'images/myImage.jpg' to your desired path

        // Create a storage reference from our storage service
        let uploadTask = uploadBytesResumable(storageRef, uploadedImage[0]);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded

            },
            (error) => {
                // Handle unsuccessful uploads
                console.error('Upload failed:', error);
            },
            () => {
                // Handle successful uploads on complete
                // Once the image has successfully uploaded, we get its download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setUploadedURL(downloadURL);
                });
            }
        );
    }

    const db = getFirestoreInstance();

    const addDocumentToCollection = async (collectionName, url) => {
        try {
            const docRef = doc(db, collectionName, (props['data']['modelName']).replace(/ /g, "-"));
            await setDoc(docRef, {
                'category': props['data']['category'],
                'likes': 0,
                'model_id': (props['data']['modelName']).replace(/ /g, "-"),
                'model_name': props['data']['modelName'],
                'model_desc': props['data']['modelDesc'],
                'recent_generations': [],
                'usage': 0,
                'creation_date': new Date(),
                'creator': (userData?.Name) ?? 'Unknown',
                'profile_picture': (userData?.ProfilePicture) ?? '',
                'numGeneration': numFactory,
                'model_photo_url': [uploadedCoverURL],
                'model_url': url,
                'model_type': model,
                'isUploaded': true,
            });
            navigate('/FactoryView/' + (props['data']['modelName']).replace(/ /g, "-"));
            window.open('https://www.orbofi.com/FactoryView/' + (props['data']['modelName']).replace(/ /g, "-"));
            incrementNumFactory();
        }
        catch (error) {
            console.error("Error adding document: ", error);
        }
        navigate('/FactoryView/' + (props['data']['modelName']).replace(/ /g, "-"));
        window.open('https://www.orbofi.com/FactoryView/' + (props['data']['modelName']).replace(/ /g, "-"));
    };

    const incrementNumFactory = async () => {
        try {
            await db.collection('OrbofiStats').doc('FactoryStats').update({
                NumFactoriesUploaded: firebase.firestore.FieldValue.increment(1)
            });
            setNumFactory(numFactory + 1);
        }
        catch (error) {
            // setError(error);
        }
    }

    const storage = getStorage(app);


    const r = new Resumable({
        target: '/api/upload',
        chunkSize: 1 * 1024 * 1024, // 1MB
        fileTypeErrorCallback: (file, errorCount) => {
            if (file.file.type !== '.ckpt') {
                alert("Sorry, only .ckpt files are allowed");
            }
        },
    });

    r.on('fileAdded', () => {
        setUploading(true);
    });

    r.on('fileSuccess', (file, message) => {
        console.log('File uploaded successfully');
        setUploading(false);
    });

    r.on('fileError', (file, message) => {
        console.log('File upload error');
        setUploading(false);
    });

    const onDrop = async (acceptedFiles) => {
        acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file), }));
        let data = imgsSrc.map((img) => {
            const reader = new FileReader();
            reader.readAsDataURL(img);
            reader.onload = () => {
                setAllImages((prev) => [...prev, reader.result]);
                setbase64cover(reader.result);
                // data.push(reader.result)
            };
            reader.onerror = () => {
            };
        });
        let uploadedImage = [...imgsSrc, ...acceptedFiles];
        setImgsSrc(uploadedImage);
        await sleep(2000);
        await uploadImage(uploadedImage);
    };
    const onRemoveImage = (index) => {
        const newArr = [...imgsSrc];
        const newArrAll = [...allImages];
        newArr.splice(index, 1);
        newArrAll.splice(index, 1);
        setImgsSrc(newArr);
        setAllImages(newArrAll);
    };
    const thumbs = imgsSrc.map((file, index) => (
        <div className="ImageItems" key={file.name}>
            <img src={file.preview} alt="" />
            <Button
                variant=""
                className="removeBtn"
                onClick={() => onRemoveImage(index)}
            >
                <Icon icon="ion:close-circle" />
            </Button>
        </div>
    ));


    const onNextStep = () => {
        if (uploadedCoverURL == null) {
            alert('Make sure there is a cover image for the Factory!');
        }
        else if (file == null) {
            alert('Make sure you added a model!');
        }
        else {
            handleUpload();
            setShow(true);
        }
    }
    const onPrevStep = () => {
        props.previousStep(props.data);
    }

    const [file, setFile] = useState(null);

    const handleChange = (e) => {
        if (e.target.files[0]) {
            const fileExtension = e.target.files[0].name.slice(e.target.files[0].name.lastIndexOf('.'));

            if (fileExtension === '.ckpt' || fileExtension === '.safetensors') {
                setFile(e.target.files[0]);
            }
            else {
                setFile(null);
                alert('Only .ckpt files are allowed');
            }
        }
    };

    const handleUpload = () => {
        if (!file) {
            alert("Please choose a file first!")
        }

        const storageRef = ref(storage, `files/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                // update progress
                setPercent(percent);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => { addDocumentToCollection('models', url); });
            }
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const doc = await db.collection('OrbofiStats').doc('FactoryStats').get();
                if (doc.exists) {
                    setNumFactory(doc.data()['NumFactoriesUploaded']);
                }
                else {
                    console.log('No such document!');
                }
            }
            catch (error) {
                // Handle Error
            }
        };

        fetchData().then(r => { });
    }, [db]);


    return (
        <div>
            <ul className="Upload-Progress">
                <li className="active">
                    <span>1</span>
                    Create your model
                </li>
                <li className="active">
                    <span>2</span>
                    Upload Model
                </li>
                {/* <li>
                    <span>3</span>
                    Create a post
                </li> */}
            </ul>
            <hr />
            <h3 className="wizard-title">Upload Model</h3>
            <Form className="AiuploadForm">
                <Row>
                    {/* <Col lg={12}>
                        <Form.Group className="mb-3 Formbox">
                            <Form.Label>Name *</Form.Label>
                            <Form.Control
                                type="text"
                                name="user_name"
                                placeholder="Enter the name"
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={12}>
                        <div className="mb-3 Formbox">
                            <Form.Label>Early Access *</Form.Label>
                            <Form.Select>
                                <option value="1">None </option>
                            </Form.Select>
                        </div>
                    </Col> */}
                    <small><strong>Get feedback on your model before full release</strong>This puts your model in the “Early Access” list of models available to <a href="#">Support Tier members</a> of the community. This can be a great way to get feedback from an engaged community before your model is available to the general public. If you choose to enable Early Access, your model will be released to the public after the selected time frame.</small>
                    <Col lg={12}>
                        <div className="mb-3 Formbox">
                            {/* <Form.Group className="mb-3 Formbox"> */}
                            <Form.Label>Chosen Sampler</Form.Label>
                            <Form.Select
                                onChange={handleModelChange}>
                                <option>Euler a</option>
                                <option>Euler</option>
                                <option>DPM++ 2M Karras</option>
                                <option>DDIM</option>
                                <option>PLMS</option>
                                <option>Heun</option>
                                <option>LMS</option>

                                {/* <option>Buildings</option>
                                <option>Game Items & Collectibles</option>
                                <option>Props</option>
                                <option>Natural Elements</option>
                                <option>Fashion and Wearables</option>
                                <option>Game Maps</option>
                                <option>Pixel Art</option>
                                <option>Vehicles</option>
                                <option>Weapons</option>
                                <option>Concept Art/Illustration</option>
                                <option>UI Components and GUI</option>
                                <option>Photography</option> */}
                            </Form.Select>
                            {/* </Form.Group> */}
                        </div>
                    </Col>
                    <h3 className="wizard-title">Add Featured Images</h3>
                    <Col lg={12}>
                        <div className="FectoryCreateImgInfo">
                            <div className="FectoryImgWidth FectoryImgWidth2">
                                <label htmlFor="imageUpload" className="drag-photo-img d-block">
                                    <Dropzone onDrop={onDrop} minSize={10}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <h6 className="btn btn-white">
                                                        click to select files
                                                    </h6>
                                                    <p>Drag &apos;n&apos; drop some files here</p>
                                                </div>
                                                <aside className="ItemsImagesWrap">
                                                    {thumbs}
                                                </aside>
                                            </section>
                                        )}
                                    </Dropzone>
                                </label>
                            </div>
                        </div>
                    </Col>
                    {/* <Col md={6}>
                        <div className="mb-3 Formbox">
                            <Form.Label>Training Epochs *</Form.Label>
                            <Form.Select>
                                <option value="1">Training Epochs </option>
                            </Form.Select>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3 Formbox">
                            <Form.Label>Training Steps *</Form.Label>
                            <Form.Select>
                                <option value="1">Training Steps </option>
                            </Form.Select>
                        </div>
                    </Col> */}
                    <Col lg={12}>
                        <h3 className="wizard-title">Add Model</h3>
                        <div>
                            <div style={styles.container}>
                                <input
                                    type="file"
                                    onChange={handleChange}
                                    accept=".ckpt,.safetensors"
                                    style={styles.input}
                                />
                            </div>
                            {/* <div onClick={handleUpload}>Upload to Firebase</div> */}
                        </div>
                    </Col >
                </Row >
                <div className="text-end mt-3">
                    <Button
                        variant=""
                        className="btn-gradient"
                        onClick={onPrevStep}
                    >
                        Back
                    </Button>

                    <Button
                        variant=""
                        type="button"
                        className="btn-gradient ms-3"
                        onClick={onNextStep}
                    >
                        Upload
                    </Button>
                </div>
            </Form >
            <Modal show={show} onHide={handleClose} centered dialogClassName="modal-90w" className="dark-modal" backdrop="static">
                <Modal.Header >
                    <Modal.Title className="text-center w-100">Uploading Model</Modal.Title>
                </Modal.Header>
                <Modal.Body><div className="progressDiv progressDiv02">
                    <div className="progressClass">
                        {/* <ProgressBar now={countDown} max={17} /> */}
                        <CircularProgressbarWithChildren styles={{ path: { stroke: `#36e899`, }, trail: { stroke: '#ffffff14', } }} className="ProgressMainInfo" value={percent}>
                            <div className="ProgressInfo">
                                <img src="/images/big-logo-icon.png" alt="doge" />
                                <strong>{percent}%</strong>
                            </div>
                        </CircularProgressbarWithChildren>
                    </div>
                </div>
                    <p className="mt-3 text-center">Upload will take 20-25 minutes... You will be redirected to model page once completed.</p>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button> */}
                </Modal.Footer>
            </Modal>

            {/* <div>
                <Modal show={show2} onHide={handleClose2} centered dialogClassName="modal-90w" className="dark-modal">
                    <Modal.Header closeButton>
                        <Modal.Title className="text-center w-100">Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="mt-3 text-center">{error}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose2}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div> */}
        </div >

    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // height: '100vh',
        // backgroundColor: '#1a1a1a',
    },
    input: {
        width: '30%',
        padding: '12px 20px',
        margin: '8px 0',
        boxSizing: 'border-box',
        borderRadius: '4px',
        // backgroundColor: '#333',
        color: '#fff',
        border: 'none',
        outline: 'none',
    },
};

export default CreateFormSecondModel;