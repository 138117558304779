import React, { useEffect, useState } from 'react';
import Products from "../../Models/Products";
import axios from 'axios';
import "./ProductsData.css";

function ProductDetail({productId, goBack, setShouldFetchProducts}) {
    const [productData, setProductData] = useState(new Products());
    const [isEditMode, setIsEditMode] = useState(true);

    const fetchProductById = async (productId) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
            };
            const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getProductById?productId=${productId}`, config);
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const fetchLastID = async () => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
            };
            const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getLastProductId`, config);
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    useEffect(() => {
        if (productId) {
            setIsEditMode(true);  // Edit mode
            const fetchData = async () => {
                const fetchedData = await fetchProductById(productId);
                if (fetchedData) {
                    setProductData(fetchedData);
                }
            };
            fetchData().catch(err => {
                console.error("An error occurred:", err);
            });
        } else {

            let newProduct = new Products();

            newProduct.Level = 1;

            setIsEditMode(false);  // Create mode
            const fetchData = async () => {
                newProduct.ProductId = await fetchLastID();
                setProductData(newProduct);
            };
            fetchData().catch(err => {
                console.error("An error occurred:", err);
                setProductData(newProduct);
            });
        }
    }, [productId]);

    const updateProduct = async (updatedProduct) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
            };
            const response = await axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}updateProduct`, updatedProduct, config);
            if (response.status === 200) {
                console.log("Product updated successfully");  // Debug line
                return response.data;  // Make sure to return data
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const handleAction = () => {
        if (isEditMode) {
            handleUpdate();  // Existing logic for update
        } else {
            handleCreate();  // New logic for create
        }
    };


    const handleUpdate = () => {
        const updatedProduct = {
            ...productData
        };

        updateProduct(updatedProduct).then(response => {
            console.log("Product updated:", response);  // Debug line
            setShouldFetchProducts(true);  // Trigger re-fetch of products
            goBack();  // Go back to the product list
        }).catch(err => {
            console.error("An error occurred while updating product:", err);
        });
    };

    const createProduct = async (newProduct) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
            };
            const response = await axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}createProduct`, newProduct, config);
            if (response.status === 200) {
                console.log("Product created successfully");  // Debug line
                return response.data;  // Make sure to return data
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const handleCreate = () => {
        const newProduct = {
            ...productData
        };

        createProduct(newProduct).then(response => {
            console.log("Product created:", response);  // Debug line
            setShouldFetchProducts(true);  // Trigger re-fetch of products
            goBack();  // Go back to the product list
        }).catch(err => {
            console.error("An error occurred while creating product:", err);
        });
    };


    const handleGoBack = () => {
        goBack();
        setShouldFetchProducts(true);
    };


    return (
        <div className="form-container">
            {productData ? (
                <form>
                    <div className="form-input">
                        <label className="form-label">Product ID:</label>
                        <input type="number" value={productData.ProductId} onChange={(e) => setProductData({...productData, ProductId: parseInt(e.target.value, 10)})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Level:</label>
                        <input type="number" value={productData.Level} onChange={(e) => setProductData({...productData, Level: parseInt(e.target.value, 10)})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Name:</label>
                        <input type="text" value={productData.Name} onChange={(e) => setProductData({...productData, Name: e.target.value})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Description:</label>
                        <input type="text" value={productData.Description} onChange={(e) => setProductData({...productData, Description: e.target.value})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Category:</label>
                        <input type="text" value={productData.Category} onChange={(e) => setProductData({...productData, Category: e.target.value})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Sub-Category:</label>
                        <input type="text" value={productData.SubCategory} onChange={(e) => setProductData({...productData, SubCategory: e.target.value})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Price:</label>
                        <input type="number" value={productData.Price} onChange={(e) => setProductData({...productData, Price: parseFloat(e.target.value)})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Orbox Price:</label>
                        <input type="number" value={productData.OrboxPrice} onChange={(e) => setProductData({...productData, OrboxPrice: parseFloat(e.target.value)})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Orbox Rewarded:</label>
                        <input type="number" value={productData.OrboxRewarded} onChange={(e) => setProductData({...productData, OrboxRewarded: parseInt(e.target.value, 10)})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Orbox Premium Rewarded:</label>
                        <input type="number" value={productData.OrboxPremiumRewarded} onChange={(e) => setProductData({...productData, OrboxPremiumRewarded: parseInt(e.target.value, 10)})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Min Sub Level:</label>
                        <input type="number" value={productData.MinSubLevel} onChange={(e) => setProductData({...productData, MinSubLevel: parseInt(e.target.value, 10)})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Discount:</label>
                        <input type="number" value={productData.Discount} onChange={(e) => setProductData({...productData, Discount: parseInt(e.target.value, 10)})} />
                    </div>
                    {/*<div className="form-input">*/}
                    {/*    <label className="form-label">Stripe ID:</label>*/}
                    {/*    <input type="text" value={productData.StripeId} onChange={(e) => setProductData({...productData, StripeId: e.target.value})} />*/}
                    {/*</div>*/}
                    {/*<div className="form-input">*/}
                    {/*    <label className="form-label">Stripe Recurrent ID:</label>*/}
                    {/*    <input type="text" value={productData.StripeRecurrentId} onChange={(e) => setProductData({...productData, StripeRecurrentId: e.target.value})} />*/}
                    {/*</div>*/}
                    {/*<div className="form-input">*/}
                    {/*    <label className="form-label">Test Stripe ID:</label>*/}
                    {/*    <input type="text" value={productData.TestStripeId} onChange={(e) => setProductData({...productData, TestStripeId: e.target.value})} />*/}
                    {/*</div>*/}
                    {/*<div className="form-input">*/}
                    {/*    <label className="form-label">Test Stripe Recurrent ID:</label>*/}
                    {/*    <input type="text" value={productData.TestStripeRecurrentId} onChange={(e) => setProductData({...productData, TestStripeRecurrentId: e.target.value})} />*/}
                    {/*</div>*/}
                    <div className="form-input">
                        <label className="form-label">Uses:</label>
                        <input type="number" value={productData.Uses} onChange={(e) => setProductData({...productData, Uses: parseInt(e.target.value, 10)})} />
                    </div>
                    <div className="form-input">
                        <label className="form-label">Public Sale:</label>
                        <input
                            type="checkbox"
                            checked={productData.PublicSale}
                            onChange={(e) => setProductData({ ...productData, PublicSale: e.target.checked })}
                        />
                    </div>
                    <div className="button-container">
                        <button type="button" className="form-button" onClick={handleAction}>
                            {isEditMode ? 'Change' : 'Create'}
                        </button>
                        <button type="button" className="form-button" onClick={handleGoBack}>Go Back</button>
                    </div>
                </form>
            ) : (
                'Loading...'
            )}
        </div>
    );
}

export default ProductDetail;
