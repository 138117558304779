
import { Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import OmniAIOrb from "../../Contracts/AITokenization/OmniAIOrb.json";
import { useWaitForTransaction, useAccount, useContractWrite, useContractEvent, useNetwork, useContractRead } from "wagmi";
import { lz_endpoints, addressToBytes, zeroAddressBytes, hexStringToBytes } from '../TokenizeForm/apiFunctions';
import useDebouncedReadFromAIContractLZ from "../../Contracts/wagmi/readContractLZ.js";
import { ethers } from 'ethers';
import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';




const NFTCardLZ = ({ nft}) =>
{
    const [hookArgs, setHookArgs] = useState()
    const chainID = nft.onchain.chainID == 137 ? lz_endpoints["BNB Smart Chain"]["chainID"] : 
    nft.onchain.chainID == 56 ? lz_endpoints["Polygon"]["chainID"] : null;
    const address = nft.onchain.chainID === 137 ? 102 : 109
    const adapterParam = ethers.utils.solidityPack(["uint16", "uint256"], [1, 225000])

    const args = [
    chainID,
    nft.onchain.user_address,
    nft.onchain.tokenID,
    false,
    adapterParam
    ];

   
    const debouncedFetchData = debounce(() =>
    {
      setHookArgs(
        [{
            address: nft.onchain.srcContractAddress,
            functionName: "estimateSendFee",
            abi: OmniAIOrb.abi,
            args: args
        },
        {
            address: nft.onchain.srcContractAddress,
            functionName: "ownerOf",
            abi: OmniAIOrb.abi,
            args: [ nft.onchain.user_address]
        }
    
    ]
      );
    }, 500); // 500ms delay between


    useEffect(() =>
    {
      debouncedFetchData();
    }, [nft]);


    const { data: contractData, error, isLoading } = useContractRead({
        address: nft.onchain.srcContractAddress,
        functionName: "estimateSendFee",
        abi: OmniAIOrb.abi,
        args: args
    });


  

    const { data: function_type_send,  } = useContractRead( {
        address: nft.onchain.srcContractAddress,
        functionName: "FUNCTION_TYPE_SEND",
        abi: OmniAIOrb.abi,
        // args: [1]
   
    });
   
    // assuming its all layerZero for this
    const { data,  error: omniError,  write: sendFrom } = useContractWrite({
        mode: 'recklesslyUnprepared',
        address: nft.onchain.chainID == 137 ? nft.onchain.srcContractAddress : nft.onchain.destContractAddress,
        abi: OmniAIOrb.abi,
        functionName: "sendFrom",
        args: [
            nft.onchain.user_address, // "from" owner of the nft
            nft.onchain.chainID == 137 ? lz_endpoints["BNB Smart Chain"]["chainID"] : nft.onchain.chainID == 56 ? lz_endpoints["Polygon"]["chainID"] : null, // remote  LayerZero chain id
            nft.onchain.user_address, // "to" address to send tokens
            nft.onchain.tokenID + 1, // tokenID to send
            nft.onchain.user_address, // refund address (if too much message fee is sent, it gets refunded)
            "0x000000000000000000000000000000000000dEaD", // address(0x0) if not paying in ZRO (LayerZero Token)
            adapterParam // 
        ],
        overrides: {
            value: contractData?.nativeFee,
          },
        })
      
        // assuming its all layerZero for this
        const { data:approveData,  write:approve } = useContractWrite({
           
            address: nft.onchain.chainID == 137 ? nft.onchain.srcContractAddress : nft.onchain.destContractAddress,
            abi: OmniAIOrb.abi,
            functionName: "approve",
            args: [
                nft.onchain.chainID == 137 ? nft.onchain.srcContractAddress : nft.onchain.destContractAddress,
                nft.onchain.tokenID + 1,
            ],
     
            })


        const { data:setMinDstGasData,  write:setMinDstGas } = useContractWrite({
           
            address: nft.onchain.chainID == 137 ? nft.onchain.srcContractAddress : nft.onchain.destContractAddress,
            abi: OmniAIOrb.abi,
            functionName: "setMinDstGas",
            args: [
                nft.onchain.chainID == 137 ? lz_endpoints["BNB Smart Chain"]["chainID"] : nft.onchain.chainID == 56 ? lz_endpoints["Polygon"]["chainID"] : null,
                parseInt(function_type_send),
                225000,
            ],
            })



        const { data:setTrustedRemoteData,  write:setTrustedRemote } = useContractWrite({
           
                address: nft.onchain.chainID == 137 ? nft.onchain.srcContractAddress : nft.onchain.destContractAddress,
                abi: OmniAIOrb.abi,
                functionName: "setTrustedRemote",
                args: [
                    nft.onchain.chainID == 137 ? lz_endpoints["BNB Smart Chain"]["chainID"] : nft.onchain.chainID == 56 ? lz_endpoints["Polygon"]["chainID"] : null,
                    ethers.utils.solidityPack(
                        ['address','address'],
                        [ nft.onchain.destContractAddress, nft.onchain.srcContractAddress]
                    )
                ],
                })

        const { isLoading: isLoadingForApproval, isSuccess } = useWaitForTransaction({
                hash: approveData?.hash,
                async onSuccess(approveData)
                {
                  
                  sendFrom()
                }
              })
          
      
        const getFirstWord = str => str.split(" ")[0];
      
    return (
        <>
      
        <div className='NFTCard'>
            <Link to={`/assets/${getFirstWord(nft.data.name)}/${nft.data.chainId}/${nft.onchain.srcContractAddress}/lZ`} className='NFTCardImg'>
                <Image  src={nft.data.image}  />
            </Link>
            <div className='NFTCardInfo'>
                <h6>{nft.data?.name}</h6>
            </div>
         
        </div>

           {/* <Button className="btn btn-gradient w-100"  onClick={approve} >
                approve  & send
           </Button> */}

           </>
       
    );
};

export default NFTCardLZ;

<audio src="https://storage.googleapis.com/factories_orbofi/outputs/talking_clones/2023-09-04_10-04-45.mp3" autoplay="" preload="auto"></audio>