import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "./Assets/Fonts/stylesheet.css";
import "./App.css";
import { Route, Routes, } from "react-router-dom";
import HomePage from "./Pages/HomePage/HomePage";
import Header from "./Components/Header/Header";
import FactoryWaitPage from "./Pages/FactoryWait/FactoryWaitPage";
import LeftSidebar from "./Components/LeftSidebar/LeftSidebar";
import Profilepage from "./Pages/MetaversePage/MetaversePage";
import AIGeneratedImagePage from "./Pages/AIGeneratedImagePage/AIGeneratedImagePage";
import CreateCommunity from "./Pages/CreateCommunity/CreateCommunity";
import "rc-slider/assets/index.css";
import { Toaster } from "react-hot-toast";
import AIImageDetailPage from "./Pages/AIImageDetailPage/AIImageDetailPage";
import React from "react";
import VerifyAuth from "./Utils/VerifyAuth";
import FactoryPage from "./Pages/FactoryPage/FactoryPage";
import CreateFactory from "./Pages/CreateFactory/CreateFactory";
import UserCreations from "./Pages/UserCreations/UserCreations";
import UserFactories from "./Pages/UserFactories/UserFactories";
import AdminPanel from "./Pages/AdminPanel/AdminPanelPage";
import ChatBot from "./Pages/ChatBot/ChatBot";
import AIImageMintPage from "./Pages/AIImageMintPage/AIImageMintPage";
import "@rainbow-me/rainbowkit/styles.css";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createClient, WagmiConfig } from "wagmi";
// import {  polygon, bsc, bscTestnet, polygonMumbai } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import FactoryDetailPage from "./Pages/FactoryDetailPage/FactoryDetailPage";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
    injectedWallet,
    rainbowWallet,
    metaMaskWallet,
    coinbaseWallet,
    trustWallet,
    ledgerWallet,
    argentWallet,
    walletConnectWallet,
    okxWallet
} from "@rainbow-me/rainbowkit/wallets";
import PricingPage from "./Pages/PricingPage/PricingPage";
import DocumentMeta from "react-document-meta";
import MainProfilePage from "./Pages/MainProfilePage/MainProfilePage";
import Achievements from "./Pages/Achievements/Achievements";
import CoImage from "./Pages/CoImage/CoImage";
import CreateAiModel from "./Pages/CreateAiModel/CreateAiModel";
import { useDispatch, useSelector } from "react-redux";
import {CheckPendingTransaction, GetSubInfo, GetUserData, initializeFirebase, Logout} from "./Utils/Utils";
import { updateUserData, userDataSelector } from "./Redux/Slices/userDataSlice";
import { AddAchievementToInvitationSenderUser } from "./Components/AchievementComponents/AchievementsHandler";
import ChatbotPage from "./Pages/ChatbotPage/ChatbotPage";
import { updateSubInfo } from "./Redux/Slices/subscriptionDataSlice";
import ObiTokenPaymentPage from "./Pages/PricingPage/ObiTokenPaymentPage";
import axios from "axios";
import CreateChatBot from "./Pages/CreateChatbot/CreateChatbot";
import PublicProfilePage from "./Pages/PublicProfile/PublicProfilePage";
import User from "./Models/User";
import CongratulationPage from "./Pages/CongratulationPage/CongratulationPage";
import { mainnet, polygon, bsc, manta, baseSepolia, skaleChaosTestnet, optimismSepolia, arbitrumSepolia, base, coreDao} from '@wagmi/core/chains'

const injective = {
    id: 2525,
    name: 'Injective',
    // iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png',
    // iconBackground: '#fff',
    nativeCurrency: { name: 'Injective', symbol: 'INJ', decimals: 18 },
    rpcUrls: {
      default: { http: ['https://inevm.calderachain.xyz/http'] },
    },

    blockExplorers: {
      default: { name: 'Inevm', url: 'https://inevm.calderaexplorer.xyz/' },
    },
    testnet: false,
  }

const coreDaoTestnet = {
    id: 1115,
    name: "Core Chain TestNet",
    network: "Core Chain TestNet",

    nativeCurrency: {
      decimals: 18,
      name: "tCORE",
      symbol: "tCORE",
    },
    rpcUrls: {
      default: { http: ["https://rpc.test.btcs.network/"] },
    },
    blockExplorers: {
      default: {
        name: "CoreDAO Explorer",
        url: "https://scan.test.btcs.network/",
      },
    //   etherscan: { name: "CeloScan", url: "https://celoscan.io" },
    },

    testnet: true,
  };
   
export default function App() {
    const [loading, setLoading] = useState(true);
    const userData = useSelector(userDataSelector);
    const dispatch = useDispatch();
  

    // Initialize Firebase
    initializeFirebase();

    const DownloadData = async () => {
        await GetUserData().then((resp) => {
            dispatch(updateUserData(resp));
        })
            .catch((error)=>{
                Logout();
                const userData = new User();
                dispatch(updateUserData(userData));
                console.error(error);
            });
    };

    const DownloadSubInfo = async () => {
        await GetSubInfo().then((resp) => {
            dispatch(updateSubInfo(resp));
        });
    };
    localStorage.removeItem("subscription");
    localStorage.removeItem("userData");
    const CheckTransaction = async () => {
        await CheckPendingTransaction();
    };

    // Here I save the user invitation reference from the link making sure happen only one time.
    const queryParameters = new URLSearchParams(window.location.search);
    if (queryParameters.get("transaction") != null)
    {
        // Check pending transaction
        CheckTransaction().then(() => {
            DownloadData().catch(console.error);
            DownloadSubInfo().catch(console.error);
        }).catch(console.error);
        window.history.pushState({}, document.title, "/");
    }

    const userValue = queryParameters.get("user");
    if (userValue)
    {
        localStorage.setItem("invitation", userValue);
    }


    if (queryParameters.get("auth") != null)
    {
        const oauthToken = queryParameters.get("auth");
        if (oauthToken !== "error")
        {
            localStorage.setItem("oauthToken", oauthToken);
            // call the function and make sure to catch any error
            DownloadData().catch(console.error);
            DownloadSubInfo().catch(console.error);

        }
        const newUser = queryParameters.get("new");
        if (newUser && localStorage.getItem("invitation"))
        {
            AddAchievementToInvitationSenderUser(queryParameters.get("auth"));
        }

        window.history.pushState({}, document.title, "/");
    }
    else
    {
        // Init user data if exists
        if (localStorage.getItem("oauthToken") !== "" && userData?.Name === "")
        {
            // call the function and make sure to catch any error
            DownloadData().catch(console.error);
            DownloadSubInfo().catch(console.error);
        }
    }

        
   
    const { chains, provider } = configureChains(
        [mainnet, polygon, bsc, manta, skaleChaosTestnet, base, coreDao,  injective], //coreDAO, optimismSepolia, arbitrumSepolia
        [
            publicProvider({ priority: 0 }),
            // alchemyProvider({ apiKey: import.meta.env.VITE_REACT_APP_ALCHEMY_API_KEY}),
            
        ],
          
    );


    const projectId = import.meta.env.VITE_REACT_WALLET_CONNECT_PROJECT_ID;
    const connectors = connectorsForWallets([
        {
            groupName: "Popular",
            wallets: [
                injectedWallet({ projectId, chains }),
                metaMaskWallet({ projectId, chains }),
                walletConnectWallet({ projectId, chains }),
                okxWallet({ projectId, chains }),
                rainbowWallet({ projectId, chains }),
                coinbaseWallet({ projectId, chains }),
                trustWallet({ projectId, chains }),
                ledgerWallet({ projectId, chains }),
                argentWallet({ projectId, chains })

            ],
        },
    ]);

    const wagmiClient = createClient({
        autoConnect: true,
        connectors,
        provider
    });

    // For some reason we got a pending obi transaction maybe due to a server disconnect
    if (localStorage.getItem("PendingObiTransaction"))
    {
        // If transaction is completed update the backend
        if (localStorage.getItem("ObiTransactionCompleted") === "Completed")
        {
            const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
            axios.get(import.meta.env.VITE_SERVER_ADDRESS + "completeObiTransaction", config).then((resp) => {
                localStorage.removeItem("ObiTransactionCompleted");
                localStorage.removeItem("PendingObiTransaction");
                window.location.href = document.title + "?transaction";
            });
        }
    }


    useEffect(() => {
        // close the loading animation upon opening the website
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const meta = {
        title: "Orbofi",
        description: "The ultimate AI-generated content platform for web3, games, the metaverse, and every online community ",
        canonical: "/images/featured-image.png",
        meta: {
            charset: "utf-8",
            name: {
                keywords: "react,meta,document,html,tags"
            }
        }
    };
    return (
        <DocumentMeta {...meta}>
            <WagmiConfig client={wagmiClient}>
                <RainbowKitProvider coolMode chains={chains}>
                    {loading ? (
                        <div className="loader-container">
                            <div className="spinner">
                                <lord-icon
                                    src="https://cdn.lordicon.com/zuhwwskw.json"
                                    colors="primary:#36e899,secondary:#ffffff"
                                    trigger="loop">
                                </lord-icon>
                            </div>
                        </div>
                    ) : (
                        <>
                            <Header />
                            <LeftSidebar />
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/Profile/:username/:tokenURI/:AI" element={<Profilepage />} />
                                <Route path="/Profile/:username/:tokenURI/" element={<Profilepage />} />
                                <Route path="/profile/:usename" element={<MainProfilePage />} />
                                <Route path="/user/:usename" element={<PublicProfilePage />} />
                                <Route path="/Assets/:collectionName/:chainId/:contractAddress/:lZ?" element={<AIImageMintPage />} />
                                <Route path="/CreateCommunity" element={<CreateCommunity />} />
                                <Route path="/Pricing" element={<PricingPage />} />
                                <Route path="/ObiPayment" element={<ObiTokenPaymentPage />} />
                                <Route path="/Factory" element={<FactoryPage />} />
                                <Route path="/CreateFactory" element={<CreateFactory />} />
                                <Route path="/MyCreations" element={<UserCreations mainTitle={true} />} />
                                <Route path="/MyAchievements" element={<Achievements />} />
                                <Route path="/CoImage" element={<CoImage />} />
                                <Route path="/MyFactories" element={<UserFactories mainTitle={true} />} />
                                <Route path="/AdminPanel" element={<AdminPanel />} />
                                <Route path="/FactoryWaitPage" element={<FactoryWaitPage />} />
                                {/*<Route path="/CreateImage" element={<CreateImage/>} />*/}
                                <Route path="/CreateAiModel" element={<CreateAiModel />} />
                                <Route path="/AIGeneratedImage/:searchPrompt" element={<AIGeneratedImagePage />} />
                                <Route path="/AIGeneratedImage/" element={<AIGeneratedImagePage />} />
                                <Route path="/image/:imageId" element={<AIImageDetailPage />} />
                                <Route path="/verifyAuth" element={<VerifyAuth />} />
                                <Route path="/ChatBot/:username" element={<ChatBot />} />
                                <Route path="/Chatbots" element={<ChatbotPage />} />
                                <Route path="/CreateChatbot" element={<CreateChatBot />} />
                                <Route path="/FactoryView/:uid" element={<FactoryDetailPage />} />
                                <Route path="/Congratulation" element={<CongratulationPage />} />
                            </Routes>
                        </>
                    )}
                    <Toaster position="bottom-right" />
                </RainbowKitProvider>
            </WagmiConfig>
        </DocumentMeta>
    );
}
