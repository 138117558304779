
// goerli
export const goerli_factory_address="0xEb92ac9e69848FBBe1331073D96845B4A705eb0A"
export const goerli_token_address= "0xF3c85F66E1CfbF7b482f8f4368F725f3c98a6B49"

// bsc_test
export const bsc_test_factory_address="0x67310f41b81ae603904291c074aaC178C211f99a"
export const bsc_mainnet_factory_address="0xab2479C7F60b37034451B8aAd2328D633A4CBd06"
export const bsc_omni_factory_address="0x420c2c9a1096174f5acb4218ff80fc043f6b5ae3"

// bsc_mainnet
export const bsc_omni_mainnet_factory_address="0xA41ccF75C1173ddA6EE531658aE992c97bb0958F"


// polygon-test mumbai
export const mumbai_factory_address="0x6981cF24fb6419A94362A79D34226f16CA84827B"
export const mumbai_omniFactory_address="0x47f93e94671147c445be5db9510ebe805f5b032a"

// polygon main
export const polygon_factory_address="0xd3bd27c5dc7e6fe5494afc96776cfde8c994d340"
export const polygon_omniFactory_address="0xA41ccF75C1173ddA6EE531658aE992c97bb0958F"

// manta testnet
export const manta_testnet_factory = "0xAc17eCf65d10F724EbeAbEe09d6BC75cD9C6738B"

// manta mainnet
export const manta_mainnet_factory = "0xAc17eCf65d10F724EbeAbEe09d6BC75cD9C6738B"

export const skale_chaos_tesnet_factory = "0xAc17eCf65d10F724EbeAbEe09d6BC75cD9C6738B"

// base
export const base_sepolia = "0xAc17eCf65d10F724EbeAbEe09d6BC75cD9C6738B"

// base mainnet 
export const base_mainnet_factory = "0x3C52337a4150A0A10d70Cc55103A8F24BECAE71a"


// optimism
export const optimism_sepolia = "0xAc17eCf65d10F724EbeAbEe09d6BC75cD9C6738B"

// arbitrum
export const arbitrum_sepolia = "0xAc17eCf65d10F724EbeAbEe09d6BC75cD9C6738B"

// injective
export const injective_inevm = "0xAc17eCf65d10F724EbeAbEe09d6BC75cD9C6738B"

// coredao
export const core_dao = "0xAc17eCf65d10F724EbeAbEe09d6BC75cD9C6738B"