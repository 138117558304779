import { useState, useEffect } from 'react';

const withLoader = (WrappedComponent) =>
{
  const WithLoader = (props) =>
  {
    const [loading, setLoading] = useState(true);

    useEffect(() =>
    {
      // Add any loading logic you need here
      // For example, fetching data from an API
      // When the loading is done, set the state to `loading: false`
      setTimeout(() =>
      {
          setLoading(false);
      }, 2000)
    }, []);

    return loading ? <div className="loader-container">
    <div className="spinner">

      <lord-icon
        src="https://cdn.lordicon.com/zuhwwskw.json"
        colors="primary:#36e899,secondary:#ffffff"
        trigger="loop">
      </lord-icon>
    </div>
  </div> : <WrappedComponent {...props} />;
  };

  return WithLoader;
};

export default withLoader;
