import {Icon} from "@iconify/react";
import {Button, Col, Image, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import AIGeneratedImage from "../../Components/AIGeneratedImage/AIGeneratedImage";
import {useEffect, useRef, useState} from "react";
import FactoryCard from "../../Components/FactoryCard/FactoryCard";
import React from "react";
import {collection, onSnapshot, query, where} from "@firebase/firestore";
import "./HomePage.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {allPrompt} from "../../Utils/Constants";
import {getFirestoreInstance} from "../../Utils/Utils";
import ChatbotCard from "../../Components/ChatbotCard/ChatbotCard";
import axios from "axios";

const randomPrompt = allPrompt[Math.floor(Math.random() * allPrompt.length)];
function HomePage() {
    const [factoryList, setFactoryList] = useState([]);
    const [chatbotList, setChatbotsList] = useState([]);
    const [searchString, setSearchString] = useState("");
    const sliderRef = useRef(null);
    const chatFeaturedSliderRef = useRef(null);
    const chatNewSliderRef = useRef(null);

    const [backendData, setBackendData] = useState({"Orbofi":"Orbofi"});
    const [loaded, setLoaded] = useState(false);


    const onHandleSearch = (event) =>
    {
        setSearchString(event.target.value);
    };

    // Extract this and the one in ChatbotPage to Utils later on
    useEffect(() => {
        // Make a GET request to fetch data from the backend
        axios.get(import.meta.env.VITE_SERVER_ADDRESS + "getAllEmails")
            .then((response) => {
                // Handle the successful response here
                let emailNamesList = response.data;
                emailNamesList["Orbofi"] = "Orbofi";
                setBackendData(emailNamesList);
                setLoaded(true);
            })
            .catch((error) => {
                // Handle any errors here
                console.error(error);
            });
    }, []);

    // This should go away
    const settings = {
        infinite: true,
        // autoplay: true,
        autoplaySpeed: 2500,
        arrows: false,
        slidesToShow: 5,
        centerMode: true,
        margin: 10,
        centerPadding: 10,
        useTransform: true,
        scroll: "smooth",
        draggable: false,
        responsive: [
            {
                breakpoint: 1430,
                settings: {
                    centerMode: true,
                    slidesToShow: 3,
                    centerPadding: 30,
                    // slidesToScroll: 3,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    centerMode: true,
                    slidesToShow: 3,
                    centerPadding: 30,
                    // slidesToScroll: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    centerMode: true,
                    slidesToShow: 1,
                    centerPadding: 120,
                    // slidesToScroll: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    centerMode: true,
                    slidesToShow: 1,
                    centerPadding: 30,
                    // slidesToScroll: 1,
                },
            },
        ],
    };
    const goToPreviousSlide = () =>
    {
        sliderRef.current.slickPrev();
    };

    const goToNextSlide = () =>
    {
        sliderRef.current.slickNext();
    };
    const goToPreviousChatbotSlide = () =>
    {
        chatFeaturedSliderRef.current.slickPrev();
    };

    const goToNextChatbotSlide = () =>
    {
        chatFeaturedSliderRef.current.slickNext();
    };
    const goToPreviousNewChatbotSlide = () =>
    {
        chatNewSliderRef.current.slickPrev();
    };

    const goToNextNewChatbotSlide = () =>
    {
        chatNewSliderRef.current.slickNext();
    };

    const db = getFirestoreInstance();
    useEffect(() =>
    {
        const colRef = collection(db, "featured");
        return onSnapshot(colRef, {
            next: (snapshot) =>
            {
                const lst = snapshot.docs.map(doc => ({
                    img: doc.data()["model_photo_url"],
                    uid: doc.data()["model_id"],
                    numGeneration: doc.data()["numGeneration"],
                    creator: doc.data()["creator"],
                    model_desc: doc.data()["model_desc"],
                    model_name: doc.data()["model_name"],
                    creation_date: doc.data()["creation_date"]["seconds"],
                    likes: doc.data()["likes"],
                    category: doc.data()["category"]
                }));

                const sortedList = lst.sort((a, b) =>
                    a.numGeneration < b.numGeneration ? 1 : -1
                );

                const newArray = [];
                const staticArray = [1, 2, 3, 4, 5, 6];
                for (const iterator of staticArray)
                {
                    sortedList.forEach((doc, index) =>
                    {
                        const creator = [0, 1, 2, 3, 4].includes(index)
                            ? "Orbofi"
                            : doc.creator;
                        const data = {
                            key: doc.uid + iterator,
                            content: (
                                <FactoryCard
                                    key={doc.img}
                                    imgSrc={doc.img}
                                    model_uid={doc.uid}
                                    creator={creator}
                                    model_desc={doc.model_desc}
                                    model_name={doc.model_name}
                                    creation_date={doc.creation_date}
                                    numGeneration={doc.numGeneration}
                                    likes={doc.likes}
                                    category={doc.category}
                                />
                            ),
                        };
                        newArray.push(data);
                    });
                }

                setFactoryList([...sortedList, ...sortedList, ...sortedList]);
            },
            error: (err) =>
            {
                console.error(err);
                // TODO: handle error (signed out? no permission? no connection?)
            }
        });

    }, [db]);

    useEffect(() => {
        const colRef = collection(db, "ChatBots");
        const queryRef = query(colRef, where("likes", "==", 0));
        // setLoading(true);
        const unsubListener = onSnapshot(queryRef, {
            next: (snapshot) => {
            // snapshot.docs.forEach((doc) => {
            //   setImgSrc(doc.data()["model_photo_url"]);
            // });
                const lst = snapshot.docs.map((doc) => ({
                    inputImage: doc.data()["inputImage"],
                    id: doc.data()["id"],
                    created_by: doc.data()["created_by"],
                    description: doc.data()["description"],
                    name: doc.data()["name"],
                    creation_date: doc.data()["date_created"],
                    likes: doc.data()["likes"],
                    inputPrompt: doc.data()["inputPrompt"],
                    voice: doc.data()["voice"],
                    language: doc.data()["language"],
                    tokenized: doc.data()["tokenized"],
                    rollScale: doc.data()["rollScale"],
                    pitchScale: doc.data()["pitchScale"],
                    yawScale: doc.data()["yawScale"],
                    expression_scale: doc.data()["expression_scale"],
                    generated_intro_vid: doc.data()["generated_intro_vid"],
                }));

                setChatbotsList(
                    [...lst, ...lst]
                );
            },
            error: (err) => {
                console.error(err);
            // TODO: handle error (signed out? no permission? no connection?)
            },
        });
    
        return unsubListener;
    }, [db]);
    return (
        <div className="main-content home-page">
            <Row>
                <Col xl={12}>
                    <div className="homebanner-main">
                        <h1>
                        The Crazy place where people create and chat with useful, fun and personalized AI agents, that can create anything for you, and for anyone
                        </h1>
                        <div className="homebanner-search">
                            <input
                                type="search"
                                placeholder="Search for any Image and metaverse content"
                                onChange={onHandleSearch}
                            />
                            <Link to="#">
                                <Icon icon="uil:search" />
                            </Link>
                        </div>
                        <div className="btn-wrap">
                            <Link
                                to={`/AIGeneratedImage/${searchString}`}
                                className="btn btn-white"
                            >
                                Search Now
                            </Link>
                            <Link to="/FactoryView/orbofi-theory" className="btn btn-border-white">
                                Create Now
                            </Link>
                        </div>
                        <Image src="/images/homeImage.png" className="homeBanner-video" />
                    </div>
                </Col>
                {/* <Col xl={4}>
                    <div className="metaverse-box default-box">
                        <div className="metaverse-graphics">
                            <iframe
                                title="Orbofi Metaverse Full Map - Chapter 1 - copy 1"
                                frameBorder="0"
                                allowFullScreen
                                mozallowfullscreen="true"
                                webkitallowfullscreen="true"
                                allow="autoplay; fullscreen; xr-spatial-tracking"
                                src="https://app.vectary.com/p/5k8AFTRyCnygcod6rQoRg6"
                            >
                                {" "}
                            </iframe>
                        </div>
                    </div>
                </Col> */}
            </Row>

            <Row className="mb-3">
                <Col lg={4} className="my-4" md={6}>
                    <div className="add-communities Ai-green">
                        <div className="add-communities-img">
                            <Image src="/images/AIImage.gif" />
                        </div>
                        <h6>Create AI-generated assets and sell them for crypto</h6>
                        <p>
              Create in seconds any kind of 2D game assets, characters, concept
              art, graphics, and media assets. Tokenize on the blockchain, and
              sell them for crypto
                        </p>
                        <Link to="/FactoryView/orbofi-theory" className="btn btn-gradient btn-create">
              Create AI-Image
                        </Link>
                    </div>
                </Col>
                <Col lg={4} className="my-4" md={6}>
                    <div className="add-communities Ai-Blue">
                        <div className="add-communities-img">
                            <Image src="/images/CreateFactory.gif" />
                        </div>
                        <h6>Create your own AI agent</h6>
                        <p>
                        AI agents are like AI apps with personalities,that can answer anything, and create anything takes to the create AI agent
                        </p>
                        <Link to="/CreateChatbot" className="btn btn-gradient btn-create">
                        Create AI agent
                        </Link>
                    </div>
                </Col>
                <Col lg={4} className="my-4 d-none" md={6}>
                    <div className="add-communities Ai-Yellow">
                        <div className="add-communities-img">
                            <lord-icon
                                trigger="hover"
                                src="/images/icons/UploadImage.json"
                            ></lord-icon>
                        </div>
                        <h6>
              Upload an AI-generated image and its prompt, tokenize it, and sell
              it for crypto
                        </h6>
                        <p>
              Upload AI-generated images and their prompts created on any other
              platform ( Midjourney, Stable diffusion, Dall-e, etc..) Tokenize
              them on the Blockchain, and Sell them from crypto
                        </p>
                        <Link to="/FactoryView/orbofi-theory" className="btn btn-gradient btn-create">
              Upload Image/Prompt
                        </Link>
                    </div>
                </Col>
                <Col lg={4} className="my-4" md={6}>
                    <div className="add-communities Ai-Gray">
                        <div className="add-communities-img">
                            <lord-icon
                                trigger="hover"
                                src="/images/icons/plus.json"
                            ></lord-icon>
                        </div>
                        <h6>Create AI virtual-good factory</h6>
                        <p>
                        Create your virtual good factory that can produce hundreds of any
              kind of style-consistent game and media assets, in minutes. Earn
              crypto by having people use your factory
                        </p>
                        <Button
                            type="button"
                            variant=""
                            disabled
                            className="btn btn-gray btn-create"
                        >
              coming soon
                        </Button>
                    </div>
                </Col>
            </Row>
            <div className="factoryItemsWrapHome  pb-3">
                <div className="d-flex justify-content-between mb-3">
                    <h3>
                        <lord-icon
                            trigger="loop"
                            src="https://cdn.lordicon.com/pfdjiokz.json"
                            colors="primary:#ffffff,secondary:#00e57f,tertiary:#ffcb85"
                        ></lord-icon>{" "}
                        featured AI agents
                    </h3>
                    <div className="d-flex">
                        <div className="slick-previous-btn" onClick={goToPreviousChatbotSlide}>
                            <Icon icon="ep:arrow-left-bold" />
                        </div>
                        <div className="slick-next-btn" onClick={goToNextChatbotSlide}>
                            <Icon icon="ep:arrow-right-bold" />
                        </div>
                    </div>
                </div>
                {loaded ?
                    chatbotList.length > 0 && <Slider ref={chatFeaturedSliderRef} {...settings}>
                        {chatbotList.slice(0, 15).map((doc) => doc.id !== "DannyTrejoAI" &&
                    <ChatbotCard
                        key={doc.name}
                        created_by={backendData[doc.created_by]}
                        description={doc.description}
                        inputImage={doc.inputImage}
                        name={doc.name}
                        voice={doc.voice}
                        language={doc.language}
                        inputPrompt={doc.inputPrompt}
                        tokenized={doc.tokenized}
                        likes={doc.likes}
                        rollScale={doc.rollScale}
                        pitchScale={doc.pitchScale}
                        yawScale={doc.yawScale}
                        expression_scale={doc.expression_scale}
                        generated_intro_vid={doc.generated_intro_vid}
                    />
                        )}
                    </Slider>
                    : <h6>Loading</h6>
                }
            </div>
            <hr />
            <div className="factoryItemsWrapHome  pb-3">
                <div className="d-flex justify-content-between mb-3">
                    <h3>
                        <lord-icon
                            trigger="loop"
                            src="https://cdn.lordicon.com/pfdjiokz.json"
                            colors="primary:#ffffff,secondary:#00e57f,tertiary:#ffcb85"
                        ></lord-icon>{" "}
                        New AI agents
                    </h3>
                    <div className="d-flex">
                        <div className="slick-previous-btn" onClick={goToPreviousNewChatbotSlide}>
                            <Icon icon="ep:arrow-left-bold" />
                        </div>
                        <div className="slick-next-btn" onClick={goToNextNewChatbotSlide}>
                            <Icon icon="ep:arrow-right-bold" />
                        </div>
                    </div>
                </div>
                { loaded ?
                    chatbotList.length > 0 && <Slider ref={chatNewSliderRef} {...settings}>
                        <div className="AiFactoryShap AIchatCard CreateAIchatCard card " >
                    <Link to="/CreateChatbot" className="CreateAIchatCardInfo CreateAIchatCardInfoSlider">
                      <lord-icon
                        trigger="hover"
                        src="/images/icons/plus.json"
                      ></lord-icon>
                      <h6>Create AI Agent</h6>
                    </Link>

                  </div>
                        {chatbotList.reverse().slice(0,15).map((doc) => doc.id !== "DannyTrejoAI" &&
                    <ChatbotCard
                        key={doc.name}
                        created_by={backendData[doc.created_by]}
                        description={doc.description}
                        inputImage={doc.inputImage}
                        name={doc.name}
                        voice={doc.voice}
                        language={doc.language}
                        inputPrompt={doc.inputPrompt}
                        tokenized={doc.tokenized}
                        likes={doc.likes}
                        rollScale={doc.rollScale}
                        pitchScale={doc.pitchScale}
                        yawScale={doc.yawScale}
                        expression_scale={doc.expression_scale}
                        generated_intro_vid={doc.generated_intro_vid}
                    />
                        )}
                    </Slider>
                    : <h6>Loading</h6>
                }
            </div>
            <hr />
            <div className="factoryItemsWrapHome">
                <div className="d-flex justify-content-between mb-3">
                    <h3>
                        <lord-icon
                            trigger="loop"
                            src="https://cdn.lordicon.com/pfdjiokz.json"
                            colors="primary:#ffffff,secondary:#00e57f,tertiary:#ffcb85"
                        ></lord-icon>{" "}
                    Featured AI Factories
                    </h3>
                    <div className="d-flex">
                        <div className="slick-previous-btn" onClick={goToPreviousSlide}>
                            <Icon icon="ep:arrow-left-bold" />
                        </div>
                        <div className="slick-next-btn" onClick={goToNextSlide}>
                            <Icon icon="ep:arrow-right-bold" />
                        </div>
                    </div>
                </div>
                {factoryList.length > 0 && <Slider ref={sliderRef} {...settings}>
                    {factoryList.map((doc, index) => (
                        <FactoryCard
                            index={index}
                            key={doc.index}
                            imgSrc={doc.img}
                            model_uid={doc.uid}
                            creator={[0, 1, 2, 3, 4].includes(index) ? "Orbofi" : doc.creator}
                            model_desc={doc.model_desc}
                            model_name={doc.model_name}
                            creation_date={doc.creation_date}
                            numGeneration={doc.numGeneration}
                            likes={doc.likes}
                            category={doc.category}
                        />
                    ))}
                </Slider>}
            </div>
          
            
            <AIGeneratedImage searchPrompt={randomPrompt} isCheckbox={false} />
        </div>
    );
}

export default HomePage;
