import React, { useEffect, useMemo, useState } from 'react';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import axios from 'axios';
import Achievements from "../../Models/AchievementLevel";

export const AchievementSection: React.FC<{ shouldFetchAchievements: boolean, setShouldFetchAchievements: React.Dispatch<React.SetStateAction<boolean>>, setSelectedAchievement: React.Dispatch<React.SetStateAction<any>>, setActiveSection: React.Dispatch<React.SetStateAction<string>> }> = ({ shouldFetchAchievements, setShouldFetchAchievements, setSelectedAchievement, setActiveSection }) => {
    const [achievementData, setAchievementData] = useState<Achievements[]>([]);
    const [achievementTotalResults, setAchievementTotalResults] = useState(0);
    const [achievementCurrentPage, setAchievementCurrentPage] = useState(1);
    const [achievementShouldFetch, setAchievementShouldFetch] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);  // Nuevo estado para verificar si hay más datos

    const itemsPerPage = 20; // Number of items per page

    useEffect(() => {
        if (achievementShouldFetch || shouldFetchAchievements) {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
            };

            const fetchAchievementData = async () => {
                const offset = (achievementCurrentPage - 1) * itemsPerPage;
                try {
                    const response = await axios.get(
                        `${import.meta.env.VITE_SERVER_ADDRESS}getAllAchievements?offset=${offset}&limit=${itemsPerPage}`,
                        config
                    );
                    if (response.data.length < itemsPerPage) {
                        setHasMoreData(false);  // No more data to load
                    }
                    setAchievementData(response.data);
                } catch (err) {
                    console.error("An error occurred while fetching achievements:", err);
                }
            };

            fetchAchievementData().catch(err => {
                console.error("An error occurred:", err);
            });

            setAchievementShouldFetch(false);  // Reset flag for this component
            setShouldFetchAchievements(false);  // Reset flag passed from parent (AdminPanel)
        }
    }, [achievementShouldFetch, achievementCurrentPage, shouldFetchAchievements]);

    const handleAchievementClick = (achievementId) => {
        setSelectedAchievement(achievementId);
        setActiveSection('achievementDetails');
    };


    const achievementColumns = useMemo(
        () => [
            { Header: 'ID', accessor: 'id' },
            { Header: 'Achievement ID', accessor: 'IdAchievement' },
            { Header: 'Name', accessor: 'Name' },
            { Header: 'Description', accessor: 'Description' },
            { Header: 'Level', accessor: 'Level' },
            { Header: 'Experience', accessor: 'Experience' },
            { Header: 'Next Level ID', accessor: 'Next' },
            { Header: 'Orbox', accessor: 'Orbox' },
            // Add edit or other controls here if needed
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns: achievementColumns, data: achievementData }, useGlobalFilter, useSortBy);

    const handleDeleteClick = async (achievementId) => {
        const userConfirmed = window.confirm("¿Sure you want to delete the achievement?");
        if (userConfirmed) {
               try {
                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
                };
                const response = await axios.delete(`${import.meta.env.VITE_SERVER_ADDRESS}deleteAchievement?achievementId=${achievementId}`, config);
                if (response.status === 200) {
                    console.log("Achievement deleted successfully"); // Debug line
                    setAchievementShouldFetch(true); // Refresh the table
                }
            } catch (error) {
                console.error("An error occurred:", error);
            }
        }
    };

    return (
        <div>
            {/* Pagination controls */}
            <div className="pagination-controls">
                <button onClick={() => { setAchievementCurrentPage(1); setAchievementShouldFetch(true); }} disabled={achievementCurrentPage === 1}>
                    First
                </button>
                <button onClick={() => { setAchievementCurrentPage(prev => Math.max(prev - 1, 1)); setAchievementShouldFetch(true); }} disabled={achievementCurrentPage === 1}>
                    Previous
                </button>
                <span>
                Page {achievementCurrentPage} of {achievementTotalResults > 0 ? Math.ceil(achievementTotalResults / itemsPerPage) : 1}
            </span>
                <button onClick={() => { setAchievementCurrentPage(prev => prev + 1); setAchievementShouldFetch(true); }} disabled={!hasMoreData}>
                    Next
                </button>
            </div>

            <button onClick={() => { setSelectedAchievement(null); setActiveSection('achievementDetails'); }}>
                Add New Achievement
            </button>

            {/* Achievement Table */}
            <div className="achievementItemsWrap">
                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                </span>
                                </th>
                            ))}
                            <th>Actions</th> {/* Add header for Edit/Delete buttons */}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    );
                                })}
                                <td>
                                    <button onClick={() => handleAchievementClick(row.original.id)}>Edit</button>
                                    {/* Add Delete button */}
                                    <button onClick={() => handleDeleteClick(row.original.id)}>Delete</button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};