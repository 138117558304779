import React from 'react';


export interface achievement
{
    title: string;
    medal: string;
    desc: string;
    level: number;
    finalValue: number;
    currentValue:number;
    opacity:number;
    multipleLevels: boolean;
    exp: number;
    orbox: number;
}


export abstract class AchievementItem extends React.Component<achievement> implements achievement
{

    title: string;
    medal: string;
    desc: string;
    level: number;
    finalValue: number;
    currentValue: number;
    opacity: number;
    multipleLevels: boolean;
    exp: number;
    orbox: number;

    protected constructor(props: achievement)
    {
        super(props);
        this.title = props.title;
        this.medal = props.medal;
        this.desc = props.desc;
        this.level = props.level;
        this.finalValue = props.finalValue;
        this.currentValue = props.currentValue;
        this.opacity = props.opacity;
        this.multipleLevels = props.multipleLevels;
        this.exp = props.exp;
        this.orbox = props.orbox;

    }
}
