import React from "react";
import PopupModal from "../PopupModal";
import {Button, Image} from "react-bootstrap";
import {Icon} from "@iconify/react";
import {Link} from "react-router-dom";
import {ConnectButton} from "@rainbow-me/rainbowkit";
import {useDispatch, useSelector} from "react-redux";
import {loginPopUpSelector, showPopUpAction} from "../../../Redux/Slices/loginPopUpSlice";




export const LoginPopUp = () =>
{
    const dispatch = useDispatch();
    const showPopUp = useSelector(loginPopUpSelector);

    // This function registers or login the user into our backend, the backend handles the authentication by any oauth that we pass into it. We get the jwtauth token back that we need to get user details whenever we need them
    async function RegisterOrLoginUser(loginType: string)
    {
        window.location.href = import.meta.env.VITE_SERVER_ADDRESS + "auth/"+loginType+ "/login";
    }

    return(
        <PopupModal show={showPopUp} handleClose={{}} className={undefined} backdrop={undefined}>
            <Link to="#" className="CloseBtn" onClick={() => dispatch(showPopUpAction(false))}>
                <Icon icon="ion:close-circle" color="white" />
            </Link>
            <Link className="modal-Logo" to="/">
                <Image src="/images/logo.png" />
            </Link>
            <div className="modalText">
                <h6>Make The Jump</h6>
                <p>
                    Start Generating AI-generated content for web3 and games, Create your own AI models, aka factories, and monetize them in crypto. Become a virtual factory owner.
                </p>
            </div>
            <div className="modalGif">
                <Image src="/images/homeImage.png" />
            </div>
            <div className="connectBtnFull">
                <ConnectButton />
            </div>
            <div className="ORLine">
                <span>OR</span>
            </div>
            <div className="MOdalWrap">
                <Button
                    variant=""
                    type="button"
                    className="GoogleLogin BtnDefault"
                    onClick={() => RegisterOrLoginUser("google")}
                >
                    <span>
                        <Icon icon="logos:google-icon" />
                    </span>{" "}
                    Google
                </Button>
                <Button
                    variant=""
                    type="button"
                    className="DiscordLogin btn BtnDefault"
                    onClick={() => RegisterOrLoginUser("discord")}
                >
                    <span>
                        <Icon icon="ic:baseline-discord" color="white" />
                    </span>{" "}
                    Discord
                </Button>

                {/*<Button*/}
                {/*    variant=""*/}
                {/*    type="button"*/}
                {/*    className="AppleLogin btn BtnDefault"*/}
                {/*    onClick={() => RegisterOrLoginUser("apple")}*/}
                {/*>*/}
                {/*    <span>*/}
                {/*        <Icon icon="ic:baseline-apple" color="white" />*/}
                {/*    </span>{" "}*/}
                {/*    Apple*/}
                {/*</Button>*/}

                {/*<Button*/}
                {/*    variant=""*/}
                {/*    type="button"*/}
                {/*    className="FacebookLogin BtnDefault"*/}
                {/*    onClick={() => RegisterOrLoginUser("facebook")}*/}
                {/*>*/}
                {/*    <span>*/}
                {/*        <Icon icon="mdi:facebook" color="white" />*/}
                {/*    </span>{" "}*/}
                {/*    Facebook*/}
                {/*</Button>*/}

                <Button
                    variant=""
                    type="button"
                    className="TwitterLogin BtnDefault"
                    onClick={() => RegisterOrLoginUser("twitter")}
                >
                    <span>
                        <Icon icon="bytesize:twitter" color="white" />
                    </span>{" "}
                    Twitter
                </Button>
            </div>
            <p className="policyText text-center">
                By signing up, you agree to <a target="_blank" rel="noreferrer" href="https://wiki.orbofi.com/learn/terms-of-services-and-privacy/terms-of-services">Terms of services, </a> and
                <a target="_blank" rel="noreferrer" href="https://wiki.orbofi.com/learn/terms-of-services-and-privacy/privacy-policy"> Privacy Policy</a>
            </p>
        </PopupModal>
    );
};