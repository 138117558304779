import {
  achievement,
  AchievementItem as AchievementListItemComponent,
} from "./AchievementItem";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Image, ProgressBar } from "react-bootstrap";

export default class AchievementListItem extends AchievementListItemComponent {
  public constructor(props: achievement) {
    super(props);
  }
  public render() {
    return (
      <div className="listMainview">
        <div className="Listprofilewrap">
          {/*Currently medals are saved on the project with name as the achievement id .webp*/}
          <span>
            <Image alt="No image" src={"public/images/achievementsImage/"+ "1" + ".webp"} />
          </span>
          <i>
            <h6>{this.title}</h6>
            <p>{this.desc}</p>
          </i>
        </div>

        {this.level !== 99 && this.finalValue !== -1 && (
          <div className="progress-main-bar">
            <p>{"Level " + this.level}</p>
            <ProgressBar
              now={(this.currentValue / this.finalValue) * 100}
              label={`${(this.currentValue / this.finalValue) * 100}%`}
            />
            {/*TODO: TSX is not allowing align property I need to fix on the future. This problem is in other places as well */}
            <p>
              {"+ " +
                this.exp +
                "exp " +
                "+ " +
                this.orbox +
                "orbox " +
                this.currentValue +
                " / " +
                this.finalValue}
            </p>
          </div>
        )}
        {this.level === 99 && (
            <h6><>Completed</>
            </h6>
        )}
        {this.level !== 99 && this.finalValue === -1 && (
            <h6><>Not completed</>
            </h6>
        )}
      </div>
    );
  }
}
