import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const NFTCard = ({ nft }) =>
{
    return (
        <div className='NFTCard'>
            <Link to={`/assets/${nft.collectionName}/${nft.data.chainId}/${nft.contractAddress}`} className='NFTCardImg'>
                <Image  src={nft.data.image}  />
            </Link>
            <div className='NFTCardInfo'>
                <h6>{nft.data.name}</h6>
            </div>
        </div>
    );
};

export default NFTCard;