import React, { useState, useRef, useEffect } from "react";
import "./audiocss.css";
import { Icon } from "@iconify/react";

const AudioCard = ({ songUrl, songName, backgroundImage }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const audioRef = useRef();

  const handleAudioLoad = () => {
    setIsLoaded(true);
  };

  const playAudio = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleAudioEnd = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleAudioLoad();
    }, 2700);

    // Cleanup function to clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []); // Empty array means this effect runs once on mount and cleanup on unmount

  return (
    <div className="audioCard" key={songUrl}>
      <div className="audioCardImage">
        <span style={{ backgroundImage: `url(${backgroundImage})` }}></span>
        <i onClick={playAudio}>
          {isPlaying ? (
            <Icon icon="heroicons-solid:pause" />
          ) : (
            <Icon icon="icon-park-solid:play" />
          )}
        </i>
      </div>
      <h6>{songName.length > 250 ? songName.slice(0, 250) : songName}</h6>
      <audio ref={audioRef} src={songUrl} onEnded={handleAudioEnd}></audio>
    </div>
  );
};

export default AudioCard;
