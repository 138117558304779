import { Button, ProgressBar } from "react-bootstrap";
import Slider from "rc-slider";
import { handleRender } from "../TooltipSlider/TooltipSlider";
import { useEffect, useState } from "react";
import "./StepThreeForm.css";

const StepThreeForm = (props) =>
{
  const [socialTokenData, setSocialTokenData] = useState({
    totalSupply: 0,
    price: 0,
    giveaway: 0,
  })
  useEffect(() =>
  {
    if(props?.data?.socialTokenData)
    {
      setSocialTokenData(props.data.socialTokenData)
    }
  }, [props.data.socialTokenData])
  const handleTotalSupplyChange = (value) =>
  {
    setSocialTokenData({ ...socialTokenData, totalSupply: value})
  }
  const handlePriceChange = (event) =>
  {
    setSocialTokenData({ ...socialTokenData, price: event.target.value})
  }
  const handleGiveaway = (value) =>
  {
    setSocialTokenData({ ...socialTokenData, giveaway: value})
  }
  const onNextStep = () =>
  {
    const data = {
      ...props.data,
      socialTokenData: socialTokenData
    }
    props.nextStep(data);
  }
  const onPrevStep = () =>
  {
    props.previousStep(props.data);
  }

  const onChangeRange = (event) =>
  {
    const {name, value} = event.target
    setSocialTokenData({ ...socialTokenData, [name]: Number(value)})
  }
   
  return (
    <div className="wizardFormMain StepThreeForm">
      <h3>Your social token</h3>
      <div className="Progressbar-wrap">
        <p>Step 03</p>
        <ProgressBar now={60} />
        <p>
          <span>03</span>/04
        </p>
      </div>
      <h4>Total supply</h4>
      <div className="formGroup">
        <div className="sliderMain">
          <Slider
            min={10}
            max={100000}
            value={socialTokenData.totalSupply}
            onChange={handleTotalSupplyChange}
          />
          <div className="sliderMainwrap">
            <p>10</p>
            <p>100 K</p>
            <p>10 M</p>
          </div>
        </div>
        <input type="text" name="totalSupply" placeholder="Amount" className="fromGroup02" value={socialTokenData.totalSupply} onChange={onChangeRange}/>
      </div>
      <h4>Price</h4>
      <input type="text" placeholder="Amount" className="fromGroup02" value={socialTokenData.price} onChange={handlePriceChange}/>
      <caption className="pb-1">The price of your social token you want to charge people for buying and owning a piece of your community</caption>
      <h4>Giveaway allocation</h4>
      <div className="formGroup">
        <label>what is the percentage of your total sNFT supply you want to allocate to giveaways/airdrops</label>
        <div className="sliderMain">
          <Slider
            min={2}
            max={50}
            value={socialTokenData.giveaway}
            handleRender={handleRender}
            onChange={handleGiveaway}
          />
          <div className="sliderMainwrap">
            <p>2%</p>
            <p>25%</p>
            <p>50%</p>
          </div>
        </div>
        <input type="text" placeholder="Amount" name="giveaway" className="fromGroup02" value={socialTokenData.giveaway} onChange={onChangeRange}/>
      </div>
      <caption>You can use this giveaway allocation as a free incentive to get people to join your community Orb</caption>
      <div className="wizardBtn-wrap mt-0 mb-2">
        <Button
          variant=""
          className="btn-gradient"
          onClick={onPrevStep}
        >
          Back
        </Button>
        <Button variant="" className="btn-gradient" onClick={onNextStep}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default StepThreeForm;