import { Link } from "react-router-dom";
import ChatbotCard from "../../Components/ChatbotCard/ChatbotCard";
import React, { useEffect, useState } from "react";
import { collection, onSnapshot } from "@firebase/firestore";
import "./UserChatbots.css";
import { getFirestoreInstance } from "../../Utils/Utils";

function MyChatbots({ mainTitle, user, isPublicProfile }) {
    const [chatbotList, setChatbotList] = useState([]);
    const db = getFirestoreInstance();
    useEffect(() => {

        if (user === "")
        {
            return;
        }
        const colRef = collection(db, "Orbox", user, "chatbots");
        return onSnapshot(colRef, {
            next: (snapshot) => {
                snapshot.docs.forEach((doc) => {
                });
                const lst = snapshot.docs.map((doc) => ({
                    voice: doc.data()["voice"],
                    inputImage: doc.data()["inputImage"],
                    id: doc.data()["id"],
                    created_by: doc.data()["created_by"],
                    description: doc.data()["description"],
                    name: doc.data()["name"],
                    creation_date: doc.data()["date_created"],
                    likes: doc.data()["likes"],
                    inputPrompt: doc.data()["inputPrompt"],
                    language: doc.data()['language'],
                    tokenized: doc.data()['tokenized'],
                    rollScale: doc.data()['rollScale'],
                    pitchScale: doc.data()['pitchScale'],
                    yawScale: doc.data()['yawScale'],
                    expression_scale: doc.data()['expression_scale'],
                    generated_intro_vid: doc.data()['generated_intro_vid'],
                    generated_hello_vid: doc.data()['generated_hello_video'],
                    cover_image: doc.data()['cover_image'],

                }));
                lst.sort((a, b) => b.creation_date - a.creation_date)
                setChatbotList(lst);
            },
            error: (err) => {
                // TODO: handle error (signed out? no permission? no connection?)
            },
        });
    }, [user]);
    return (
        <div className={`${mainTitle ? "main-content" : ""} home-page`}>
            {mainTitle && <div className="FactoryTitles mb-4">
                <h2>My Chatbots</h2>
            </div>
            }


            <div className="factoryItemsWrap factoryItemsGrid">
                {chatbotList.length > 0 ? (
                    <>
                        {chatbotList.map((doc) => doc.id !== "DannyTrejoAI" && (
                            <ChatbotCard
                                key={doc.name}
                                description={doc.description}
                                inputImage={doc.inputImage}
                                name={doc.name}
                                voice={doc.voice}
                                language={doc.language}
                                inputPrompt={doc.inputPrompt}
                                tokenized={doc.tokenized}
                                likes={doc.likes}
                                rollScale={doc.rollScale}
                                pitchScale={doc.pitchScale}
                                yawScale={doc.yawScale}
                                expression_scale={doc.expression_scale}
                                generated_intro_vid={doc.generated_intro_vid}
                                generated_hello_vid={doc.generated_hello_vid}
                                cover_image={doc.cover_image}
                            />
                        ))}
                    </>
                ) : (
                    <>
                        {

                            <div className="FactoryTitles w-100">
                                <h2 style={{ fontSize: "16px" }}>😭 No Chatbots 😭</h2>
                                {isPublicProfile ? "" :
                                    <Link to="/CreateChatbot" className="CreatBtn btn btn-white ">Create a Chatbot</Link>
                                }
                            </div>
                        }
                    </>
                )}
            </div>
        </div>
    );
}

export default MyChatbots;
