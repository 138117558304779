import { Icon } from "@iconify/react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSpring, animated } from 'react-spring'
import "./ChatbotCard.css";
import { useState } from "react";

const calc = (x, y) => [-(y - window.innerHeight / 2) / 60, (x - window.innerWidth / 2) / 60, 1.1]
const trans = (x, y, s) => `perspective(1000px) rotateX(${x}deg) rotateY(${y}deg)`

function removeSpaces(str) {
  return str.replace(/\s+/g, '');
}

function ChatbotCard({
  created_by,
  // date_created,
  description,
  inputImage,
  // inputPrompt,
  name,
  voice,
  language,
  inputPrompt,
  tokenized,
  likes,
  rollScale,
  pitchScale,
  yawScale,
  expression_scale,
  generated_intro_vid,
  generated_hello_vid,
  cover_image
}) {
  const [show,] = useState(false);
  const [props, set] = useSpring({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } })

  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)",
  });
  return (
    <animated.div className="AiFactoryShap AIchatCard card" onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ transform: props.xys.interpolate(trans) }} key={name}>
      <figure className="chatCard-img">
        <div className="FWrap">
          <Image src={inputImage} />

        </div>
        <div className="FInfo">
          <h6>{name}</h6>

          <p>{description}</p>

          <ul>

            <li>
              <span>
                <Icon icon="entypo:images" color="white" />
              </span>
            </li>
            <li>
              <span>
                <Icon icon="mdi:user-circle" color="white" />
              </span>
              {created_by}
            </li>
            {
              <span>
                {/* <Image style={{ 'width': '25px' }} src="https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/output-onlinegiftools.gif?alt=media&token=acadb077-c190-4f38-aacb-b6a27630f5b6" /> */}
                {/* <lord-icon
                  src="https://cdn.lordicon.com/hgqdtxby.json"
                  colors="primary:#ffffff,secondary:#ffffff,tertiary:#ffffff"
                  trigger="loop"
                >
                </lord-icon> */}
              </span>
            }
            {name == "Danny Trejo AI" ? (<li>
              <span>
                {/* <Image style={{ 'width': '25px' }} src="https://firebasestorage.googleapis.com/v0/b/orbofibluesky-7f04d.appspot.com/o/output-onlinegiftools.gif?alt=media&token=acadb077-c190-4f38-aacb-b6a27630f5b6" /> */}
                <lord-icon
                  src="https://cdn.lordicon.com/mqgwxagz.json"
                  colors="primary:#ffffff,secondary:#ffffff,tertiary:#ffffff"
                  trigger="loop"
                >
                </lord-icon>
              </span>
              Premium
            </li>) : (<></>)}

          </ul>
          <Link
            to={`/ChatBot/${removeSpaces(name)}`}
            className="btn btn-gradient"
            state={{
              created_by: created_by,
              description: description,
              inputImage: inputImage,
              name: name,
              voice: voice,
              language: language,
              inputPrompt: inputPrompt,
              tokenized: tokenized,
              likes: likes,
              rollScale,
              pitchScale,
              yawScale,
              expression_scale,
              generated_intro_vid,
              generated_hello_vid,
              cover_image
            }}
          >
            Use AI companion
          </Link>
        </div>
      </figure>
    </animated.div>
  );
}

export default ChatbotCard;
