import {useEffect, useState} from "react";
import {Image} from "react-bootstrap";
import {useSelector} from "react-redux";
import {userDataSelector} from "../../Redux/Slices/userDataSlice";
import User from "../../Models/User";
import {fetchUserById} from "../UserData/UserData";

/**
 * Sets the profile picture URL based on the given tempImgUrl and userId.
 * If tempImgUrl is provided, it sets the profile picture URL to the tempImgUrl.
 * If userId is provided, it sets the profile picture URL to the default path in AWS
 * for that user.
 * If the userData has ProfilePicture value as "AWS", it sets the profile picture URL
 * to the default path in AWS for the userData's UserId.
 * If the userData has ProfilePicture value other than "AWS", it sets the profile picture URL
 * to the ProfilePicture value.
 * If none of the above conditions are met, it sets the profile picture URL to the default profile
 * picture.
 *
 * @param {object} props - The input props object.
 * @param {string} [props.tempImgUrl] - The temporary image URL.
 * @param {number} [props.userId] - The user ID.
 *
 * @return {JSX.Element} - The profile picture component.
 */

const DEFAULT_IMG = "/images/profile.png";

export function ProfilePicture({tempImgUrl, userId}: { tempImgUrl?: string | null; userId?: number })
{
    const [profileImgUrl, setProfileImgUrl] = useState<string>("/images/profile.png");
    const userData = useSelector(userDataSelector);

    useEffect(() =>
    {
        if (tempImgUrl)
        {
            // Case for when the user changes the image on the profile
            setProfileImgUrl(tempImgUrl);
            return;
        }

        if (userId && userId !== 0)
        {    // This case is used for the public profiles
            fetchUserById(userId).then((publicUser) =>
            {
                setProfileImgUrl(SetProfilePicture(publicUser));
                return;
            }).catch(()=>
            {
                setProfileImgUrl(DEFAULT_IMG);
                return;
            });
        }

        // Get the correct image from the user in a general context
        setProfileImgUrl(SetProfilePicture(userData));
    }, [userData?.ProfilePicture, tempImgUrl, userId]);

    return <Image src={profileImgUrl} className="w-100 h-100"/>;
}

function SetProfilePicture(user : User) : string
{
    const AWS_PATH = "https://orbofiprofileimages.s3.ap-southeast-1.amazonaws.com/";
    const getDefaultPath = (userId: number) => `${AWS_PATH}${userId}/${userId}`;
    if (user?.ProfilePicture === "AWS")
    {    // The user uploaded their image
        return getDefaultPath(user?.UserId);
    }

    if (user?.ProfilePicture)
    {    // Getting the image from an Oauth service upon user registration
        return user.ProfilePicture;
    }

    return DEFAULT_IMG;
}