
import { Col, Row} from 'react-bootstrap';
import CreateCommunityForm from '../../Components/CreateCommunityForm/CreateCommunityForm';
import CreateCommunityInfo from '../../Components/CreateCommunityInfo/CreateCommunityInfo';
import './CreateCommunity.css';

function CreateCommunity() {

  return (
    <div className="main-content">
        <Row>
            <Col lg={6}>
                <CreateCommunityInfo />
            </Col>
            <Col lg={6} className="orderMobile">
            <CreateCommunityForm />
            </Col>
        </Row>
    </div>
  );
}

export default CreateCommunity;
