import WizardForm from "react-wizard-form";
import StepTwoForm from "../CreateCommWizard/StepTwoForm/StepTwoForm";
import StepThreeForm from "../CreateCommWizard/StepThreeForm/StepThreeForm";
import { useState } from "react";
import "./CreateCommunityForm.css";
const CreateCommunityForm = () => {
  const [formData, setFormData] = useState()
  
  const getFormsData = (name, data) =>
  {
    setFormData({...formData, [name]: data});
  }

  const removeFormData = () =>
  {
  }
  return (
    <div className="CreateCommunityForm">
      <WizardForm>
        <StepTwoForm getFormsData={getFormsData} removeFormData={removeFormData}/>
        <StepThreeForm getFormsData={getFormsData} removeFormData={removeFormData}/>
      </WizardForm>
    </div>
  );
}

export default CreateCommunityForm;