import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import React from "react";
import "./FactoryWaitPage.css";
import {SetImageParams, SetTimerRates} from "../../Utils/Utils";


function FactoryWaitPage()
{
  const [guidanceScale, ] = useState(7);
  const [steps, ] = useState(30);
  const [numImage, ] = useState(4);
  const [modelUid, ] = useState('');

  const [, setIsGenerating] = useState(false);
  const [, setHasGenerating] = useState(false);
  const [message, ] = useState("");
  const [, setGeneratedImages] = useState([]);
  const [, setSelectedImage] = useState([]);

  const [, setCountDown] = React.useState(0);
  const [, setRunTimer] = React.useState(false);
  const [, setTimerRate] = useState(1000);

  const fetchGeneration = () =>
  {
    let numImages = [4, 5, 6];
    let timerRates = [1000, 1700, 2000, 10000];
    SetTimerRates(numImage, numImages, setTimerRate, timerRates);

    setIsGenerating((t) => true);
    setHasGenerating((t) => false);
    setRunTimer((t) => true);
    fetch(
      `https://ipiqpmpbwssgge-3000.proxy.runpod.net/factory_inference?model_uid=${modelUid}&prompt=${message}&cgs=${guidanceScale}&steps=${steps}&n_imgs=${numImage}`
    )
      .then((response) => response.json())
      .then((data) => SetImageParams(data, setGeneratedImages, setSelectedImage, setRunTimer, setCountDown, setIsGenerating, setHasGenerating));
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  return (
    <div className="WaitPage-content">
      <div className="factory-content2">
        🤖🚀 Factory will be emailed in 25 to 30 minutes 🚀🤖
        <br></br>
        <br></br>

        <Link
          to="/Factory"
          className="btn btn-white BtnGenerate"
          onClick={fetchGeneration}
        >
          <span>Go Back!</span>
        </Link>
      </div>

    </div >
  );
}

export default FactoryWaitPage;
