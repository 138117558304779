import {Icon} from "@iconify/react";
import {Button} from "react-bootstrap";
import React, { useState } from "react";
import {Image} from "react-bootstrap";



interface DataProps {
    // picture: string;
    subName: string;
    price?: number | undefined;
    obiPrice?: number | undefined;
    featuresIncluded: string[];
}

interface ComponentProps {
    data: DataProps, proceedForPayment: () => void
}


const PremiumItemCard = (props: ComponentProps) => {
    const { data, proceedForPayment } = props;
    return (
        <div className="PricingCardMain PremiumCardMain">
            {/*<Image src={data.picture} className="w-100 h-100"/>*/}
            <h2>{data?.subName}</h2>
            {/* {data?.obiPrice && data?.obiPrice > 0 ? <h4>{data?.obiPrice.toFixed(2)} <small>Obi Tokens</small></h4> : <h4>${data?.price}</h4>} */}
            {data.price !== 0 ? <h4>${data?.price}</h4>: ""}
            {/* {data.price !== 0 && data?.obiPrice && data?.obiPrice > 0 ? <h6>{data?.obiPrice.toFixed(2)} Obi Tokens</h6> : ""} */}
            <ul className="Pricingplan-detail">
                {data.featuresIncluded.map((feat, index) => (
                    <li key={index}><i><Icon icon="ph:seal-check-fill"  /></i> <p>{feat}</p></li>
                ))}
            </ul>
            {data.price != 0 && localStorage.getItem("oauthToken") ?<Button variant="" type="button" className="btn btn-green" onClick={proceedForPayment}><i><Icon icon="solar:crown-minimalistic-broken" /></i> Purchase!</Button> : ""}
        </div>
    );
};

export default PremiumItemCard;
// you can make a split by "/" character to make as a list Shubham
