import { Accordion, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./FactoryFilter.css";
import { useState, useRef } from "react";

function FactoryFilter({ categoryList, handleButtonClick, mode, onHandleSelectCategory, onSelectCategory, handleSearch }) {
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const searchInput = useRef();

  const onOpenFilterSidebar = () => {
    document.body.classList.add("filterSidebarOpen");
  };
  const onCloseFilterSidebar = () => {
    document.body.classList.remove("filterSidebarOpen");
  };

  const onSelectCat = (event) => {
    const cat = event.target.value
    const catArr = selectedCategory;
    if (catArr.includes(cat)) {
      catArr.splice(catArr.indexOf(cat), 1);
    } else {
      catArr.push(cat);
    }
    setSelectedCategory(catArr);
    onHandleSelectCategory(catArr, 'filter');
  };
  const onClearFilter = () => {
    setSelectedCategory([])
    onHandleSelectCategory([], 'clear')
  }

  console.log({ selectedCategory })
  return (
    <div className="categoryBoxList categoryBoxListWrap">
      <div className="categoryList-select">
        <div className="Factory-searchbar">
          <input
            type="search"
            ref={searchInput}
            placeholder="Search Factory by name and user name"
          />
          <Link to="#">
            <lord-icon
              trigger="hover"
              src="/images/icons/magnifier.json"
              onClick={() => {
                // console.log('hello world');
                handleSearch(searchInput.current.value);
              }}
            ></lord-icon>
          </Link>
        </div>
        <Link
          to="#"
          className="Filter-Factory-Btn btn btn-white mobileFilter"
          onClick={onOpenFilterSidebar}
        >
          <Icon icon="octicon:filter-24" />
        </Link>
      </div>
      <div className="btn-factory-wrap">
        <Button
          variant=""
          className={`btn-border-white ${mode === "Orbofi" ? "active" : " "}`}
          onClick={() => {
            // console.log('hello world');
            handleButtonClick("Orbofi");
          }}
        >
          Featured Factories
        </Button>
        <Button
          variant=""
          className={`btn-border-white ${mode === "User" ? "active" : " "}`}
          onClick={() => {
            // console.log('hello world');
            handleButtonClick("User");
          }}
        >
          User Created Factories
        </Button>

        <Button
          variant=""
          className={`btn-border-white ${mode === "Uploaded" ? "active" : " "
            }`}
          onClick={() => {
            handleButtonClick("Uploaded");
          }}
        >
          Uploaded Factories
        </Button>
      </div>
      <Link
        to="#"
        className="Filter-Factory-Btn btn btn-white desktopFilter"
        onClick={onOpenFilterSidebar}
      >
        <Icon icon="octicon:filter-24" />
      </Link>
      <div className="FilterSidebar">
        <h4>
          Filter{" "}
          <Link onClick={onCloseFilterSidebar} className="sidebarClose">
            <Icon icon="ep:close-bold" />
          </Link>
        </h4>
        <hr />
        <Button variant="" className="btn-white btn-clear" disabled={selectedCategory.length === 0} onClick={onClearFilter}>
          Clear Filter
        </Button>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filter by Category</Accordion.Header>
            <Accordion.Body>
              <ul className="FilterCategory">
                {categoryList.map((category, index) => (
                  <li key={index}>
                    <label
                      className="CustomInputCheckbox"
                      htmlFor={category.title}
                    >
                      <input id={category.title} value={category.title} name="category" type="checkbox" checked={category.checked} onChange={onSelectCat} />
                      <span>
                        <Icon icon="gg:check" />
                      </span>
                      <p>{category.title}</p>
                    </label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <hr />
          <Accordion.Item eventKey="1">
            <Accordion.Header>Filter by Image generation </Accordion.Header>
            <Accordion.Body></Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}

export default FactoryFilter;
