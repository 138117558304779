import "./CoImage.css";
import * as React from "react";
import "../../Components/PopupModal/PopupModal.css";
import "react-circular-progressbar/dist/styles.css";
import {useEffect, useState} from "react";
import {GetCoPromptImages, getProfileImage, GetSharedImage, uploadCopromptingImageToS3} from "../../Components/S3/s3";
import {Row, Col, Button} from "react-bootstrap";
import UserImages from "../../Models/UserImages";
import PopupShareContent from "../../Components/PopupModal/HeaderPopups/PopupShareContent";

const queryParameters = new URLSearchParams(window.location.search);

let loadMenu = queryParameters.get("menu");
let userUrl = queryParameters.get("image");
let userID = queryParameters.get("id");
const mainRoute = window.location.href.split('?')[0]

export async function fetchGeneration(message)
{
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 8000000);

    return fetch(
        `https://yjbx86uhoesmxn-3000.proxy.runpod.net/factory_inference?model_uid=sdxl&prompt=${message}&cgs=8&steps=20&batch_size=4`,
        {
            headers:
                {
                connection: "keep-alive",
                'content-type': 'application/json'
            },
            signal: controller.signal,
            keepalive: true,
        }
    )
        .then((response) =>
        {
            clearTimeout(id);
            return response.json();
        })
        .then(async (data) =>
        {
            return data;
        });
}


function CoImage()
{
    if(!loadMenu) loadMenu = "myPrompt"
    const [currentMenu, setCurrentMenu] = useState(loadMenu);
    const [selectedImage, setSelectedImage] = useState<UserImages | null>(null);

    const handleMenu = (menu: string) =>
    {
        setCurrentMenu(menu);
    };

    return (
        <div align="center" className={`${"main-content"} home-page`}>
            <button onClick={() => handleMenu("newPrompt")}>New prompt</button>
            <button onClick={() => handleMenu("myPrompt")}>My prompts</button>
            <button onClick={() => handleMenu("sharedPrompt")}>Shared prompts</button>
            <br/>
            <br/>
            {currentMenu === "newPrompt" && <PromptCreation setCurrentMenu={setCurrentMenu} />}
            {currentMenu === "myPrompt" && <MyPrompts />}
            {currentMenu === "sharedPrompt" && <SharedImages setCurrentMenu={setCurrentMenu} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />}
            {currentMenu === "selectedImage" && <SelectedImage setCurrentMenu={setCurrentMenu} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />}
            {/*{currentMenu !== "" && <button onClick={() => handleMenu("")}>Close</button>}*/}
        </div>
    );
}

function PromptCreation({setCurrentMenu})
{

    const [sentenceInput, setSentenceInput] = useState("");
    const [loading, setLoading] = useState(false); // Add loading state
    const [createdImage, setCreatedImage] = useState(false);
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const handleChange = (event: any) =>
    {
        setSentenceInput(event.target.value);
    };

    const handleCreateImage = async () =>
    {
        setLoading(true); // Set loading to true before making the fetch request

        try
        {
            // Get the img url from runpod.
            let imageUrls = await fetchGeneration(sentenceInput);
            setImageUrls(imageUrls);
            setCreatedImage(true);
            // Send the image url to download to S3 with the image data, it will include a new row in the database.
          //  await uploadCopromptingImageToS3(imageUrl, sentenceInput, "English", "Shared");
        } catch (error)
        {
            console.error(error);
        } finally {
            setLoading(false); // Set loading back to false when the request is complete
        }
    };

    async function handleUploadImage(urlImage)
    {
        setLoading(true); // Set loading to true before making the fetch request

        try
        {
              // Send the image url to download to S3 with the image data, it will include a new row in the database.
              await uploadCopromptingImageToS3(urlImage, sentenceInput, "English", "Shared");
        } catch (error) {
            console.error(error);
        } finally {
            setCurrentMenu("myPrompt")
            setCreatedImage(false);
            setLoading(false); // Set loading back to false when the request is complete
        }
    }


    return(
        <div>
        <h3>Share a new prompt</h3>
    <div>
        <p>Sentence: <input
            type="text"
            id="nameInput"
            name="nameInput"
            onChange={handleChange}
            value={sentenceInput}
        />   <button onClick={handleCreateImage}>Create image</button></p>
    </div>
    {/* Conditionally render loading spinner or content */}
    {loading ?
        (
        // Render loading spinner here
        <div className="loading-spinner">Loading...</div>
    ) : (
        <>
            {/* Render your content when not loading */}
            {/* Your existing code for showing images */}
        </>
    )}
            {createdImage &&
                imageUrls.map((image) => (
                    <div style={{ display: 'table-cell', flexDirection: 'row' }}>
                        <div>
                                <img style={{ width: '200px', height: '200px', marginLeft: '20px' }} src={image} alt={`User Completed Image`} />
                                <br/>
                                <br/>
                                <Button onClick={() => handleUploadImage(image)}>Share This one</Button>
                        </div>
                    </div>
                    ))
            }
        </div>
    )
}

function MyPrompts ()
{

    const [userSharedImages, setUserSharedImages] = useState<UserImages[]>([]);
    const [userCompletedImages, setUserCompletedImages] = useState<UserImages[]>([]);

    useEffect(() =>
    {

        async function fetchProfileImage()
        {
            try
            {
                // Download first 10 images from the database
                let images: UserImages[] = await GetCoPromptImages();

                // Order the image by different types with images filter.
                const userSharedImages = images.filter((image) => image.ImageType === "Shared" && image.UserName === localStorage.getItem("name"));
                const userCompletedImages = images.filter((image) => image.ImageType === "Completed"&& image.UserName === localStorage.getItem("name"));

                //Set the images to show on different groups on the website.
                setUserSharedImages(userSharedImages);
                setUserCompletedImages(userCompletedImages);

            } catch (error) {
                console.error(error);
            }
        }

        // Call the fetchProfileImage function when the component mounts
        fetchProfileImage();
    }, []);


    return (
        <div>
            <h3>Your shared prompts</h3>
            <div className="image-container">
                <Row>
                    {userSharedImages.map((image, index) => (
                        <Col>
                            <div><img style={{ width: '200px', height: '200px' }} key={index} src={"https://orbofisharedimages.s3.ap-southeast-1.amazonaws.com/"+image.UserId+"/"+image.Url} alt={`User Completed Image ${index}`} />
                                <br/>
                                <br/>
                                <p>Inspired sentences: {image.Sentence}</p>
                                <br/>
                                <PopupShareContent
                                    className="btn btn-white btn-invite"
                                    icon={
                                        <lord-icon src="https://cdn.lordicon.com/liqllijy.json" trigger="loop" colors="outline:#121331,primary:#e1dae5" ></lord-icon>
                                    }
                                    buttonText="Share"
                                    link={mainRoute + "?menu=selectedImage&image="+ image.Url + "&id=" + image.UserId}
                                />


                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
            <h3>Your completed Images</h3>
            <div className="image-container">
                <Row>
                    {userCompletedImages.map((image, index) => (
                        <Col>
                            <div>
                                <img style={{ width: '200px', height: '200px' }} key={index} src={"https://orbofisharedimages.s3.ap-southeast-1.amazonaws.com/"+image.UserId+"/"+image.Url} alt={`User Completed Image ${index}`} />
                                <p>Created by: {image.UserName}</p>
                                <p>Inspired sentences: {image.Sentence}</p>

                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    )
}

function SharedImages({ setCurrentMenu, selectedImage, setSelectedImage })
{
    const [globalSharedImages, setGlobalSharedImages] = useState<UserImages[]>([]);
    const [refreshTable, setRefreshTable] = useState(false);

    const refreshTableButtonFunction = () => { setRefreshTable(!refreshTable);};

    useEffect(() =>
    {

        async function fetchProfileImage()
        {
            try
            {
                // Download first 10 images from the database
                let images: UserImages[] = await GetCoPromptImages();

                // Order the image by different types with images filter.
                const globalSharedImages = images.filter((image) => image.ImageType === "Shared" && image.UserName !== localStorage.getItem("name"));

                //Set the images to show on different groups on the website.
                setGlobalSharedImages(globalSharedImages);

                if(loadMenu === "selectedImage"){
                    globalSharedImages.forEach((image, index) => {
                        if (image.Url === userUrl){
                            setSelectedImage(image)
                        }
                    });

                }

            } catch (error)
            {
                console.error(error);
            }
        }

        // Call the fetchProfileImage function when the component mounts
        fetchProfileImage();
    }, [refreshTable]);

    return(
        <div>
            <button onClick={refreshTableButtonFunction}>Refresh</button>
            <h3>User shared prompts</h3>
            <div className="image-container">
                <Row>
                    {globalSharedImages.map((image, index) => (
                        <Col key={index}>
                            <div key={index} className="image-wrapper">
                                <img
                                    src={"https://orbofisharedimages.s3.ap-southeast-1.amazonaws.com/"+image.UserId+"/"+image.Url}
                                    alt={`User Shared Image ${index}`}
                                    style={{ width: '300px', height: '300px' }}
                                />
                                <br/>
                                <br/>
                                <button
                                    className={`image-button ${selectedImage === image ? 'selected' : ''}`}
                                    onClick={() => {
                                        userUrl = image.Url
                                        userID = image.UserId.toString()
                                        setCurrentMenu("selectedImage");
                                        setSelectedImage(image); // Update the selected image state
                                    }}
                                >Select this one
                                </button>
                                <br/>
                                <br/>
                                <p>Created by: {image.UserName}</p>
                                <p>Inspired sentences: {image.Sentence}</p>

                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    )
}

function SelectedImage({ setCurrentMenu, selectedImage, setSelectedImage })
{

    const [completeSentenceInput, setCompleteSentenceInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [ownImage, setOwnImage] = useState(false);
    const [createdImage, setCreatedImage] = useState(false);
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const [finalSentence, setFinalSentence] = useState("");

    async function handleCreateImage(initialSentence)
    {
        setLoading(true); // Set loading to true before making the fetch request

        try
        {
            // Get the img url from runpod.
            let urls = await fetchGeneration(`${initialSentence} ${completeSentenceInput}`);
            setImageUrls(urls);
            setCreatedImage(true);
            // Send the image url to download to S3 with the image data, it will include a new row in the database.
            //  await uploadCopromptingImageToS3(imageUrl, sentenceInput, "English", "Shared");
        } catch (error)
        {
            console.error(error);
        } finally
        {
            const finalSentence = initialSentence+ " " + completeSentenceInput;
            setFinalSentence(finalSentence)
            setLoading(false); // Set loading back to false when the request is complete
        }
    }

    useEffect(() =>
    {

        async function fetchProfileImage()
        {
            try {
                if(loadMenu === "selectedImage")
                {
                    let images: UserImages[] = await GetSharedImage(userID, userUrl);

                    // Order the image by different types with images filter.
                    if(!images[0]){
                        setOwnImage(true);
                    }
                    else
                    {
                        //Set the images to show on different groups on the website.
                        setSelectedImage(images[0]);
                    }

                    loadMenu = "";

                }
            } catch (error)
            {
                console.error(error);
            }
        }

        // Call the fetchProfileImage function when the component mounts
        fetchProfileImage();
    }, []);


    //This handle is for write complete sentence from the selected image
    const handleChangeCompleteSentence = (event: any) =>
    {
        setCompleteSentenceInput(event.target.value);
    };

    // Upload image with 2 sentences to the database.
    async function handleCompleteImage(url)
    {
        setLoading(true); // Set loading to true before making the fetch request
        try
        {
            // Send the image url to download to S3 with the image data, it will include a new row in the database.
            await uploadCopromptingImageToS3(url, `${finalSentence}`, "English", "Completed");
        } catch (error)
        {
            console.error(error);
        } finally {
            setCurrentMenu("myPrompt");
            setFinalSentence("");
            setLoading(false); // Set loading back to false when the request is complete
        }
    }



    return (
        <div>
            <h3>Selected Image</h3>
            {selectedImage && (
                <div className="selected-image-container">
                    <img width="300"
                         height="300"
                         src={"https://orbofisharedimages.s3.ap-southeast-1.amazonaws.com/"+selectedImage.user_id+"/"+selectedImage.url} alt="Selected Image" />
                    <p>Initial sentence: {selectedImage.sentence}</p>
                    <div>
                        <p>Complete the sentence: <input
                            type="text"
                            id="nameInput"
                            name="nameInput"
                            onChange={handleChangeCompleteSentence}
                            value={completeSentenceInput}
                        />   <button onClick={() => handleCreateImage(selectedImage.sentence) }>Generate completed sentence</button></p>
                    </div>
                    {/* Conditionally render loading spinner or content */}
                    {loading ? (
                        // Render loading spinner here
                        <div className="loading-spinner">Loading...</div>
                    ) : (
                        <>
                            {/* Render your content when not loading */}
                            {/* Your existing code for showing images */}
                        </>
                    )}
                    <button onClick={() => setCurrentMenu("sharedPrompt") }>To shared Prompt</button>
                </div>
            )}
            {ownImage &&
                <div>
                    <p>You cannot complete your own image</p>
                    <button
                        onClick={() => {
                            setCurrentMenu("sharedPrompt");
                            setOwnImage(false);
                        }
                    }>To shared Prompt</button>
                </div>
            }

            {createdImage &&
                imageUrls.map((image) => (
                    <div style={{ display: 'table-cell', flexDirection: 'row' }}>
                        <div>
                            <img style={{ width: '200px', height: '200px', marginLeft: '20px' }} src={image} alt={`User Completed Image`} />
                            <br/>
                            <br/>
                            <Button onClick={() => handleCompleteImage(image)}>Choose this one</Button>
                        </div>
                    </div>
                ))
            }
        </div>

    );
}

export default CoImage;