import {
  achievement,
  AchievementItem as AchievementListItemComponent,
} from "./AchievementItem";
import * as React from "react";
import { Image } from "react-bootstrap";
import { CircularProgressbar } from "react-circular-progressbar";

export default class AchievementTableItem extends AchievementListItemComponent {
  public constructor(props: achievement) {
    super(props);
  }

  public render() {
    return (
      <div className="table-card-main">
        <h5> {this.title}</h5>
        {this.multipleLevels && (
          <div className="ProgressbarTable">
            <CircularProgressbar
              value={(this.currentValue / this.finalValue) * 100}
            />
            <p>{this.currentValue + " / " + this.finalValue}</p>
          </div>
        )}
        <h6 className="my-auto">
          {" "}
          {this.multipleLevels ? (
            <>Level {this.level}</>
          ) : this.opacity != 1 ? (
            <>In progress</>
          ) : (
            <>Completed</>
          )}
        </h6>
        <span>
          Invite your friends to visit Orbofi and register on the website.
        </span>
      </div>
    );
  }
}
