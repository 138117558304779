import { useEffect, useRef } from 'react';
import useThrottle from '../../Utils/useThrottle';
import classNames from 'classnames';
// import useThrottle from "../hooks/useThrottle";

// interface Props {
//   code: string;
//   device: "mobile" | "desktop";
// }

const Preview = (props) => {
  const {code, device} = props;
  const throttledCode = useThrottle(code, 200);
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentDocument) {
      iframe.contentDocument.open();
      iframe.contentDocument.write(throttledCode);
      iframe.contentDocument.close();
    }
  }, [throttledCode]);

  return (
    <div className="CodeViewBoxMain">
      
      <iframe
        ref={iframeRef}
        title="Preview"
      ></iframe>
    </div>
  );
}

export default Preview;