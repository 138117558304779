import {Link} from "react-router-dom";
import FactoryCard from "../../Components/FactoryCard/FactoryCard";
import React, {useEffect, useState} from "react";
import {collection, onSnapshot} from "@firebase/firestore";
import "./UserFactories.css";
import {getFirestoreInstance} from "../../Utils/Utils";

function MyFactories({mainTitle, user, isPublicProfile})
{
    const [factoryList, setFactoryList] = useState([]);
    const db = getFirestoreInstance();
    useEffect(() => {
        
        if(user === "")
        {
            return;
        }
        const colRef = collection(db, "Orbox/" + user + "/models");
        return onSnapshot(colRef, {
            next: (snapshot) => {
                snapshot.docs.forEach((doc) => {
                });
                const lst = snapshot.docs.map((doc) => ({
                    img: doc.data()["model_photo_url"],
                    uid: doc.data()["model_id"],
                    numGeneration: doc.data()["numGeneration"],
                    creator: doc.data()["creator"],
                    model_desc: doc.data()["model_desc"],
                    model_name: doc.data()["model_name"],
                    creation_date: doc.data()["creation_date"]["seconds"],
                    likes: doc.data()["likes"],
                    category: doc.data()["category"],
                }));

                setFactoryList(lst.sort((a, b) => (a.numGeneration < b.numGeneration ? 1 : -1)));
            },
            error: (err) => {
                // TODO: handle error (signed out? no permission? no connection?)
            },
        });
    }, [user]);
    return (
        <div className={`${mainTitle ? "main-content": ""} home-page`}>
            {mainTitle && <div className="FactoryTitles mb-4">
                <h2>My Factories</h2>
            </div>
            }


            {factoryList.length > 0 ? (
                <div className="factoryItemsWrap factoryItemsGrid">
            
                    {factoryList.map((doc) => (
                        <FactoryCard
                            key={doc.img}
                            imgSrc={doc.img}
                            model_uid={doc.uid}
                            creator={doc.creator}
                            model_desc={doc.model_desc}
                            model_name={doc.model_name}
                            creation_date={doc.creation_date}
                            numGeneration={doc.numGeneration}
                            likes={doc.likes}
                            category={doc.category}
                        />
                    ))}                   
                </div>
            )  : (
                <>
                    {
                        <div className="text-center w-100">
                            <h2 style={{ fontSize: "16px" }}>😭 No Factories 😭</h2>
                            { isPublicProfile ? "" :
                                <Link to="/CreateFactory" className="CreatBtn btn btn-white ">Create a Factory</Link>
                            }
                        </div>
                    }
                </>
            )}
        </div>
    );
}

export default MyFactories;
