import axios from "axios";
import UserImagesAndUserName from "../../Models/UserImage/UserImagesAndUserName";
import UserImages from "../../Models/UserImages";

export const uploadToS3 = (file: File) => {

    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}`,
            ContentType: file.type
        }
    };

    const formData = new FormData();
    formData.append('file', file);

    try {
        axios.post(import.meta.env.VITE_SERVER_ADDRESS + "uploadFile", formData, config)
            .then((response) => {
                  console.log("Uploaded successfully", response)
            })
            .catch((error) => {
                console.log(error);
            });
    }catch (error) {
        console.log(error);
        return error;
    }

}

export const uploadCopromptingImageToS3 = async (imageURL: string, sentence: string, language: string, typeImage: string) => {
    // Here I get the user image data and save the into the User Image model to match the model with the backend.
    let image = new UserImages();
    image.ImageType = typeImage;
    image.Language = language;
    image.Sentence = sentence;
    image.Url = imageURL

    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("oauthToken")}`
        }
    };

    // Here I add the file and the userimage model
    const formData = new FormData();
    formData.append('imageData', JSON.stringify(image));

    try {
        await axios.post(import.meta.env.VITE_SERVER_ADDRESS + "UploadCopromtingImage", formData, config)
            .then((response) => {
                //After is finished I send the message uploaded successfully
                console.log("Uploaded successfully")
            })
            .catch((error) => {
                console.log(error);
            });
    } catch (error) {
        console.log(error);
        return error;
    }

}

export const getProfileImage = async () => {

    if(localStorage.getItem("oauthToken") !== "")
    {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
        };

        try {
            // Here you get the profile image
            const response = await axios.get(import.meta.env.VITE_SERVER_ADDRESS + "getProfileImage", config);
            const url = response.data.presignedURL;
            return url;
        } catch (error) {
            console.log(error);
            return "/images/profile.png";
        }
    }
}

export const GetCoPromptImages = async () => {

    // First I check if the user is logged on the system.
    if(localStorage.getItem("oauthToken") !== "")
    {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
        };

        try {
            const response = await axios.get(import.meta.env.VITE_SERVER_ADDRESS + "GetCopromptingImage", config);
            // I saved all user images from the database and save them as UserImage model typo
            return response?.data as UserImages[];
        } catch (error) {
            console.log(error);
            // In case of an error I send empty models.
            let imageDefault : UserImages[] = [new UserImages(), new  UserImages()];
            imageDefault.push(new UserImages());
            return imageDefault;
        }
    }
}

export const GetSharedImage = async (userId: string | null, url: string | null) => {

    // First I check if the user is logged on the system.
    if(localStorage.getItem("oauthToken") !== "")
    {
        console.log("Downloading Image...")
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` }
        };

        try {
            const response = await axios.get(import.meta.env.VITE_SERVER_ADDRESS + "LoadShared?id=" + userId + "&url=" +  url, config);
            // I saved all user images from the database and save them as UserImage model typo
            return response?.data as UserImages[];
        } catch (error) {
            console.log(error);
            // In case of an error I send empty models.
            let imageDefault : UserImages[] = [new UserImages(), new  UserImages()];
            imageDefault.push(new UserImages());
            return imageDefault;
        }
    }
}