class User {
    UserId: number;
    Name: string;
    Email: string;
    Role: string;
    Orbox: number;
    PremiumOrbox: number;
    ProfilePicture: string;
    Platform: string;

    constructor(
        UserId: number = -1,
        Name: string = "",
        Email: string = "",
        Role: string = "",
        Orbox: number = 0,
        PremiumOrbox: number = 0,
        ProfilePicture: string = "",
        Platform: string = "",
    ) {
        this.UserId = UserId;
        this.Name = Name;
        this.Email = Email;
        this.Role = Role;
        this.Orbox = Orbox;
        this.PremiumOrbox = PremiumOrbox;
        this.ProfilePicture = ProfilePicture;
        this.Platform = Platform;
    }
}

export default User;