import { Button, Col, Form, Image, Row, Modal } from "react-bootstrap";
import React, { useState } from "react";
import Select from "react-select";
import { toast } from "react-hot-toast";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "../CreateAiModelForm.css";
// import "../../OnboardWizardPopup/OnboardWizardPopup.css"

const options = [
  { value: "Safe", label: "Safe" },
  { value: "NSFW", label: "NSFW" },
  // { value: 'vanilla', label: 'Vanilla' }
];

const CreateFormOneModel = (props) => {
  const onNextStep = () => {
    if (modelName.length <= 3) {
      toast.error("Model name must contain at least 3 characters!");
    } else {
      const data = {
        ...props?.data,
        modelName,
        modelDesc,
        category,
      };
      props.nextStep(data);
    }
  };

  
  const [modelName, setModelName] = useState("");
  const [modelDesc, setModelDesc] = useState("");
  const [category, setCategory] = useState("General Purpose");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [value, setValue] = useState('');
  const handleClose = () => setShow(false);

  const handleInputChange = (event) => {
    setModelName(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  return (
    <>
      <ul className="Upload-Progress">
        <li className="active">
          <span>1</span>
          Create your model
        </li>
        <li>
          <span>2</span>
          Upload Model
        </li>
        {/* <li>
                    <span>3</span>
                    Create a post
                </li> */}
      </ul>
      <hr />
      <h3 className="wizard-title">Create your model</h3>
      <Form className="AiuploadForm">
        <Row>
          <Col lg={12}>
            <Form.Group className="mb-3 Formbox">
              <Form.Label>Name *</Form.Label>
              <Form.Control
                type="text"
                name="user_name"
                placeholder="Enter the name"
                onChange={handleInputChange}
                value={modelName}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <div className="mb-3 Formbox">
              <Form.Label>Type *</Form.Label>
              <Form.Select>
                <option value="1">Checkpoint </option>
              </Form.Select>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3 Formbox">
              <Form.Label>Checkpoint Type *</Form.Label>
              <Form.Select>
                <option value="1">Trained </option>
              </Form.Select>
            </div>
          </Col>
          <Col lg={12}>
            <Form.Group className="mb-3 Formbox">
              <Form.Label>Category *</Form.Label>
              <Form.Select onChange={handleCategoryChange}>
                <option>General Purpose</option>
                <option>Characters</option>
                <option>Environments</option>
                <option>Buildings</option>
                <option>Game Items & Collectibles</option>
                <option>Props</option>
                <option>Natural Elements</option>
                <option>Fashion and Wearables</option>
                <option>Game Maps</option>
                <option>Pixel Art</option>
                <option>Vehicles</option>
                <option>Weapons</option>
                <option>Concept Art/Illustration</option>
                <option>UI Components and GUI</option>
                <option>Photography</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={12}>
            <div className="mb-3 Formbox">
              <Form.Label>Tags</Form.Label>
              <Select
                styles={{
                  multiValueRemove: (provided, state) => ({
                    ...provided,
                    color: "black", // Set the color of the close button
                    backgroundColor: "#25294a69",
                  }),
                }}
                option
                closeMenuOnSelect={false}
                isMulti
                options={options}
              />
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3 Formbox Formboxtexteditor">
              <Form.Label>Tell us what your model does</Form.Label>
              {/* <CKEditor editor={ClassicEditor}
                                onChange={inputHandler}
                            /> */}
                <ReactQuill theme="snow" value={value} onChange={setValue} />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3 Formbox">
              <Form.Label>
                When Using this model, I give permission for user to:{" "}
              </Form.Label>
              <Form.Check
                className="custom-checkbox"
                type="checkbox"
                label="Use without crediting me"
                id="crediting-checkbox"
              />
              <Form.Check
                className="custom-checkbox"
                type="checkbox"
                label="Share merges of this model"
                id="model-checkbox"
              />
              <Form.Check
                className="custom-checkbox"
                type="checkbox"
                label="Use different permissions on merges"
                id="merges-checkbox"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3 Formbox">
              <Form.Label>Commercial Use </Form.Label>
              <Form.Check
                className="custom-checkbox"
                type="checkbox"
                label="None"
                id="None-checkbox"
              />
              <Form.Check
                className="custom-checkbox"
                type="checkbox"
                label="Sell generated images"
                id="generated-checkbox"
              />
              <Form.Check
                className="custom-checkbox"
                type="checkbox"
                label="Use on generation services"
                id="generation-checkbox"
              />
              <Form.Check
                className="custom-checkbox"
                type="checkbox"
                label="Sell this model or merges"
                id="sellmerges-checkbox"
              />
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3 Formbox">
              <Form.Label>This resource </Form.Label>
              <Form.Check
                className="custom-checkbox"
                type="checkbox"
                label="Depicts an actual person (For example: Tom Cruise or Tom Cruise as Maverick)"
                id="actual-checkbox"
              />
              <Form.Check
                className="custom-checkbox"
                type="checkbox"
                label="Is intended to produce mature themes only"
                id="intended-checkbox"
              />
            </div>
          </Col>
        </Row>
        <div className="text-end mt-3">
          <Button
            variant=""
            type="button"
            className="btn-gradient"
            onClick={onNextStep}
          >
            Next
          </Button>
        </div>
      </Form>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="modal-90w"
        className="dark-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mt-3 text-center">{error}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateFormOneModel;
