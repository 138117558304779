import { Icon } from "@iconify/react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSpring, animated } from 'react-spring'
import "./FactoryCard.css";
import { useState } from "react";

const calc = (x, y) => [-(y - window.innerHeight / 2) / 60, (x - window.innerWidth / 2) / 60, 1.1]
const trans = (x, y, s) => `perspective(1000px) rotateX(${x}deg) rotateY(${y}deg)`

function FactoryCard({
  index,
  imgSrc,
  model_uid,
  creator,
  model_desc,
  creation_date,
  numGeneration,
  model_name,
  likes,
  category,
}) {
  const [show,] = useState(false);
  const [props, set] = useSpring({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } })

  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)",
  });
  return (
    <animated.div className="AiFactoryShap card" onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ transform: props.xys.interpolate(trans) }} key={model_uid}>
      <figure className="AIGenerated-img">
        <Link to={"/FactoryView/" + model_uid} className="FWrap" state={{
              model_uid: model_uid,
              creator: creator,
              model_desc: model_desc,
              creation_date: creation_date,
              model_name: model_name,
              likes: likes,
              imgSrc: imgSrc,
            }}>
          {/* <div className="FImage50"> */}
          {typeof imgSrc === "string" ? (
            <Image src={imgSrc} />
          ) : (
            <Image src={imgSrc[0]} />
          )}
          {/* </div> */}
          {/* <div className="FImage50 FImage502">
            {typeof imgSrc === "string" ? (
              <Image src={imgSrc} />
            ) : (
              <Image src={imgSrc[1]} />
            )}
            {typeof imgSrc === "string" ? (
              <Image src={imgSrc} />
            ) : (
              <Image src={imgSrc[2]} />
            )}
          </div> */}
          <p>{category}</p>
        </Link>
        <div className="FInfo">
          <h6>{model_name}</h6>

          <p>{model_desc}</p>
          <ul>
            <li>
              <span>
                <Icon icon="entypo:images" color="white" />
              </span>
            </li>
            <li>
              <span>
                <Icon icon="mdi:user-circle" color="white" />
              </span>
              {creator}
            </li>
          </ul>
          {/* <Link
            to={"/FactoryView/" + model_uid}
            className="btn btn-gradient"
            state={{
              model_uid: model_uid,
              creator: creator,
              model_desc: model_desc,
              creation_date: creation_date,
              model_name: model_name,
              likes: likes,
              imgSrc: imgSrc,
            }}
          >
            Generate From This Factory
          </Link> */}
        </div>
      </figure>
    </animated.div>
  );
}

export default FactoryCard;
