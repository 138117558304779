import PopupModal from "../PopupModal";
import {Button, Image} from "react-bootstrap";
import {Icon} from "@iconify/react";
import React from "react";
import ReactDOM from "react-dom/client";

interface PopUpProps
{
    root : ReactDOM.Root,
    id: number
}

export const AchievementPopUp : React.FC<PopUpProps> = (props: PopUpProps) =>
{
return(
    <PopupModal show={true} handleClose={{}} className={""} backdrop={"static"}>
    <Button className="CloseBtn" onClick={() => props.root.unmount()}>
        <Icon icon="ion:close-circle" color="white" />
    </Button>
    <div className="modalText">
        <h6>Congrats!</h6>
        <p>
            You got a medal! </p>
    </div>
    <div className="modalGif">
        <Image src={"public/images/achievementsImage/"+ String(props.id) + ".webp"} />
    </div>
    <div className="connectBtnFull">
    </div>
</PopupModal>
    );
};