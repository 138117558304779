import { Icon } from "@iconify/react";
import {Button} from "react-bootstrap";
import React, { useState } from "react";

interface DataProps {
    numOrbox: number;
    price?: number | undefined;
    obiPrice?: number | undefined;
}
// interface componentProps {
//     subName: string; price: number; buttonFunction: () => void, perks: string[]
// }
interface ComponentProps {
    data: DataProps, proceedForPayment: () => void
}

const OrboxPackageCard = (props: ComponentProps) => 
{
    const { data, proceedForPayment } = props;
    return (
        <div className="PricingCardMain">
            <h2>{data?.numOrbox} Orbox</h2>
            {/* {data?.obiPrice && data?.obiPrice > 0 ? <h4>{data?.obiPrice.toFixed(2)} <small>Obi Tokens</small></h4> : <h4>${data?.price}</h4>} */}
            {data.price != 0 ? <h4>${data?.price}</h4>: ""}
            {/* {data?.obiPrice && data?.obiPrice > 0 ? <h6>{data?.obiPrice.toFixed(2)} Obi Tokens</h6> : ""} */}
            {localStorage.getItem("oauthToken") ?
                <Button variant="" type="button" className="btn btn-green" onClick={proceedForPayment}> Buy Orbox</Button>
                :
                ""
            }
        </div>
    );
};

export default OrboxPackageCard;