import { Nav, Tab } from "react-bootstrap";
import "./ProfileMetaverDetail.css";

function ProfileMetaverDetail(props) {
  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Nav variant="pills" className="custom-icon-tabs">
          <Nav.Item>
            <Nav.Link eventKey="first">
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_160_512)">
                    <path
                      d="M23 15.97C23 19.84 19.87 22.97 16 22.97L17.05 21.22"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1 7.96997C1 4.09997 4.13 0.969971 8 0.969971L6.95 2.71997"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.62012 13.0699H9.43012C10.0501 13.0699 10.5601 13.63 10.5601 14.2C10.5601 14.82 10.0601 15.33 9.43012 15.33H6.62012V13.0699Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.62012 15.33H9.84012C10.5501 15.33 11.1301 15.83 11.1301 16.46C11.1301 17.08 10.5501 17.59 9.84012 17.59H6.62012V15.33Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.41895 17.58V18.7"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.41895 11.95V13.0699"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.8498 15.33C14.8498 18.74 12.0898 21.4999 8.67977 21.4999C5.26977 21.4999 2.50977 18.74 2.50977 15.33C2.50977 11.92 5.26977 9.15991 8.67977 9.15991C8.83977 9.15991 8.98977 9.16993 9.15977 9.17993C12.1898 9.40993 14.6098 11.8299 14.8398 14.8599C14.8398 15.0099 14.8498 15.16 14.8498 15.33Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21.5002 8.66998C21.5002 12.08 18.7402 14.84 15.3302 14.84H14.8402C14.6102 11.81 12.1902 9.38997 9.16016 9.15997V8.66998C9.16016 5.25998 11.9202 2.5 15.3302 2.5C18.7402 2.5 21.5002 5.25998 21.5002 8.66998Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_160_512">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="second">
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.52572 11.1266C9.42818 11.1171 9.31114 11.1171 9.20385 11.1266C6.88249 11.0506 5.03906 9.19807 5.03906 6.91807C5.03906 4.59057 6.97028 2.70007 9.36966 2.70007C11.7593 2.70007 13.7003 4.59057 13.7003 6.91807C13.6905 9.19807 11.8471 11.0506 9.52572 11.1266Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.5975 4.6001C18.4897 4.6001 20.0113 6.0916 20.0113 7.9251C20.0113 9.7206 18.5482 11.1836 16.7243 11.2501C16.6463 11.2406 16.5584 11.2406 16.4707 11.2501"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.64918 14.632C2.28881 16.171 2.28881 18.679 4.64918 20.2085C7.33141 21.9565 11.7303 21.9565 14.4125 20.2085C16.7729 18.6695 16.7729 16.1615 14.4125 14.632C11.7401 12.8935 7.34117 12.8935 4.64918 14.632Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.4795 19.8001C19.1817 19.6576 19.845 19.3821 20.3912 18.9736C21.9127 17.8621 21.9127 16.0286 20.3912 14.9171C19.8548 14.5181 19.2013 14.2521 18.5088 14.1001"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="third">
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.5 18.5C16.6 18.5 17.5 17.6 17.5 16.5V7.5C17.5 6.4 16.6 5.5 15.5 5.5C14.4 5.5 13.5 6.4 13.5 7.5V16.5C13.5 17.6 14.39 18.5 15.5 18.5Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 18.5C9.6 18.5 10.5 17.6 10.5 16.5V13C10.5 11.9 9.6 11 8.5 11C7.4 11 6.5 11.9 6.5 13V16.5C6.5 17.6 7.39 18.5 8.5 18.5Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="first">
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            {/* <Members /> */}
            <h4 className="comeSoon">
            <span>
            <lord-icon
              src="/images/icons/people02.json"
              trigger="loop"
              delay="2000">
          </lord-icon>
            </span>
            People who mint this social token are being displayed here. Mint yours now to be the first</h4>
          </Tab.Pane>
          <Tab.Pane eventKey="third">
          <h4 className="comeSoon">
            <span>
            <lord-icon
              src="/images/icons/bar-chart.json"
              trigger="loop"
              delay="2000">
          </lord-icon>
            </span>
            Stats will be displayed once the Orb hits a certain milestone.</h4>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
}

export default ProfileMetaverDetail;
